import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"
import TutorCancelSessionButton from "components/Tutor/components/Sessions/CancelSessionButton"
import RescheduleSessionButton from "components/Tutor/components/Sessions/RescheduleSessionButton"

export type ScheduledSessionsProps = {
  studentId: string
}

const ScheduledSessions: React.FC<ScheduledSessionsProps> = ({ studentId }) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: scheduledSessionsQuery,
    variables: { studentId: studentId, page: page },
  })

  return (
    <>
      <div className="row">
        <WithLoadingIndicator result={result}>
          {({ data: { viewer } }) => (
            <Table
              title="Future Sessions"
              data={viewer.sessions}
              changePage={setPage}
              headers={["Subject", "Date", "Time", "Actions"]}
              valueMapper={session => [
                <span key={session.id} className="flex items-center">
                  {session.directory ? (
                    <DirectoryIcon recordType={"session"} />
                  ) : (
                    <AirtutorsIcon recordType={"session"} />
                  )}{" "}
                  {session.subject.name}
                </span>,
                <LocalTime
                  key={session.id}
                  format="ddd MMM D"
                  timestamp={session.startsAt}
                />,
                <span key={session.id}>
                  <LocalTime omitDate timestamp={session.startsAt} />
                  &mdash;
                  <LocalTime omitDate timestamp={session.endsAt} />
                </span>,
                <div className="flex items-center space-x-1" key={session.id}>
                  <Link
                    to={path("sessionDetails", {
                      id: session.id,
                    })}
                    button
                    size={Size.Small}
                  >
                    View
                  </Link>
                  {!session.immovable && (
                    <RescheduleSessionButton id={session.id} />
                  )}
                  {session.mayCancelByTutor && (
                    <TutorCancelSessionButton id={session.id} size="small" />
                  )}
                </div>,
              ]}
            />
          )}
        </WithLoadingIndicator>
      </div>
      <div className="p-4" />
    </>
  )
}

const scheduledSessionsQuery = gql`
  query ScheduledSessionsQuery($studentId: ID!, $page: Int!) {
    viewer {
      sessions(
        filters: {
          studentId: $studentId
          future: true
          statusState: scheduled
          billState: unbilled
        }
        page: $page
      ) {
        totalPages
        data {
          id
          directory
          airtutors
          startsAt
          endsAt
          immovable
          billState
          mayCancelByTutor
          subject {
            name
          }
        }
      }
    }
  }
`

export default ScheduledSessions
