import React, { useContext } from "react"

import { useFormikContext } from "formik"

import { AlertMessageBox } from "~tailwindui/Basics"

import WizardContext from "./WizardContext"

const Done = () => {
  const formikContext = useFormikContext()
  const { setStep, fieldToStepMap } = useContext(WizardContext)
  const hasErrors =
    formikContext.errors && Object.values(formikContext.errors).length > 0

  return (
    <>
      {hasErrors ? (
        <>
          <p>Looks like there are some incomplete fields</p>
          {Object.keys(formikContext.errors).map(key => (
            <div key={key} className="alert alert-danger mb-3 p-3 text-left">
              <div>{formikContext.errors[key]}</div>
              <div className="mt-3">
                <button
                  onClick={() => setStep(fieldToStepMap[key].index)}
                  className="btn solid small white"
                >
                  Back to {fieldToStepMap[key].component.displayName} page
                </button>
              </div>
            </div>
          ))}
        </>
      ) : (
        <p>Just click submit below and we'll get your profile updated!</p>
      )}

      {formikContext.status && (
        <AlertMessageBox>{formikContext.status}</AlertMessageBox>
      )}
    </>
  )
}

Done.modalTitle = "Almost Done!"
Done.step = "Done"
export default Done
