import React from "react"

import { useField } from "formik"
import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { css } from "@emotion/core"

import { weekdayOptions } from "src/enums"
import { publicClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import {
  CheckboxField,
  ErrorMessage,
} from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const AvailabilityFields = ({ organization }) => {
  const [field, meta] = useField("availabilityIds")
  const [gradeField] = useField("gradeId")

  const loadingState = useQuery(
    buildQuery(
      availabilitiesQuery,
      {
        organizationId: organization.id,
        gradeId: gradeField.value,
      },
      { client }
    )
  )

  if (!gradeField.value) {
    return (
      <>
        <span
          css={css`
            font-weight: 500;
          `}
        >
          Availability
        </span>
        <h4>Please select a grade first</h4>
      </>
    )
  }

  const groupedAvailabilities = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  }

  if (loadingState.data) {
    const availabilitySets = loadingState.data.organization.availabilitySets
    availabilitySets.forEach(availabilitySet => {
      availabilitySet.availabilities.forEach(availability => {
        groupedAvailabilities[availability.weekday].push(availability)
      })
    })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <div className="form-group">
          <span
            css={css`
              font-weight: 500;
            `}
          >
            Availability
          </span>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              @media screen and (max-width: 768px) {
                flex-flow: column;
              }
            `}
          >
            {weekdayOptions
              .map(d => d.label)
              .map(
                weekday =>
                  groupedAvailabilities[weekday].length > 0 && (
                    <div
                      key={weekday}
                      css={css`
                        margin: 0 15px;
                      `}
                    >
                      <h4
                        css={css`
                          font-weight: 500;
                        `}
                      >
                        {weekday}
                      </h4>

                      <CheckboxField
                        isMulti
                        name={field.name}
                        options={ordered(
                          groupedAvailabilities[weekday],
                          "startsAt"
                        ).map(availability => ({
                          value: availability.id,
                          label: (
                            <>
                              <LocalTime
                                timestamp={availability.startsAt}
                                format="LT"
                              />
                              &mdash;
                              <LocalTime
                                timestamp={availability.endsAt}
                                format="LT"
                              />
                            </>
                          ),
                        }))}
                      />
                    </div>
                  )
              )}
          </div>
          <ErrorMessage {...meta} />
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const availabilitiesQuery = compress`
  query($organizationId: ID!, $gradeId: ID!) {
    organization(id: $organizationId) {
      availabilitySets(gradeId: $gradeId) {
        id
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
    }
  }
`

export default AvailabilityFields
