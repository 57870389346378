import React, { ReactNode, forwardRef } from "react"

import { NavLink } from "react-router-dom"

import { Color, Size } from "~tailwindui/types/enums"

import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

export type LinkProps = {
  to: string
  children: ReactNode
  button?: boolean
  size?: Size
  color?: Color
  outline?: boolean
  soft?: boolean
  className?: string
  target?: string
  rel?: string
}

const Link: React.FC<
  LinkProps & Omit<React.HTMLProps<HTMLLinkElement>, "size">
> = forwardRef(
  (
    {
      to,
      children,
      button = false,
      size = Size.Medium,
      color = Color.Blue,
      outline = false,
      soft = false,
      className = "",
    },
    ref: any
  ) => {
    return (
      <NavLink
        ref={ref}
        to={to}
        className={classNames(
          "inline-block",
          button
            ? buttonClassNames({ size, color, outline, soft })
            : "text-sky-600 hover:underline",
          className
        )}
      >
        {children}
      </NavLink>
    )
  }
)

export default Link
