import React from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types/MatchParams"

import NotFound from "components/Tutor/Pages/NotFound"

import ExpenseForm from "../components/ExpenseForm"

const EditExpense: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const id = match.params.id

  const [result] = useQuery({ query: expenseQuery, variables: { id } })
  if (result.data && !result.data.expense) return <NotFound />

  let formState
  if (result.data) {
    formState = { ...result.data.expense, receipts: [], receiptsOptional: true }
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data }) => <ExpenseForm formState={formState} tutor={data.viewer} />}
    </WithLoadingIndicator>
  )
}

const expenseQuery = gql`
  query ExpenseQuery($id: ID!) {
    viewer {
      canSubmitGeneralExpenses
      hasSubmittedFingerprintExpense
      hasSubmittedIntroSessionExpense
    }
    expense(id: $id) {
      id
      expenseType
      amount
      description
      comments
    }
  }
`

export default EditExpense
