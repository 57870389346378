import React from "react"

import { useFormikContext } from "formik"

import { Loading } from "components/Icons"

const SubmitButton = ({
  text = "Save",
  submitting = undefined,
  className = undefined,
}) => {
  let { isSubmitting } = useFormikContext()
  if (submitting !== undefined) isSubmitting = submitting

  return (
    <div>
      <button
        type="submit"
        className={className || "btn btn-info solid blue"}
        disabled={isSubmitting}
      >
        <span>{text}</span>
        {isSubmitting && (
          <div className="ml-2 inline-block">
            <Loading />
          </div>
        )}
      </button>
    </div>
  )
}

export default SubmitButton
