import React, { useEffect, useRef, useState } from "react"

import { Dayjs } from "dayjs"
import { NotificationManager } from "react-notifications"
import { pipe, subscribe } from "wonka"

import { gql } from "@urql/core"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"

import createBlob from "src/createBlob"
import { tutorClient as client } from "src/urql-client"

export type ExportCsvProps = {
  filters: {
    startDate: Dayjs
    endDate: Dayjs
    statusState: string
    billState: string
    subjectIds: string[]
    studyGroupIds: string[]
  }
}

const ExportCsv: React.FC<ExportCsvProps> = ({ filters }) => {
  const [loading, setLoading] = useState(false)
  const [csvUrl, setCsvUrl] = useState<string | null>()

  const csvDownloadLink = useRef<HTMLAnchorElement>()

  const handleExport = () => {
    setLoading(true)
    const { unsubscribe } = pipe(
      client.subscription(exportSubscription, {
        filters,
      }),
      subscribe(result => {
        if (result.error) {
          NotificationManager.error(result.error.message)
          return
        }

        const { status, failures, csv } = result.data.tutorSessionsExport
        switch (status) {
          case "error":
            NotificationManager.error(
              failures.map(failure => failure.message).join(", ")
            )
            break
          case "enqueued":
            NotificationManager.info("Export in progress...")
            break
          case "done":
            setCsvUrl(createBlob(csv, "text/csv"))
            setLoading(false)
            NotificationManager.info("Export complete!")
            unsubscribe()
            break
        }
      })
    )
  }

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl(null)
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  return (
    <>
      <a
        ref={csvDownloadLink}
        href={csvUrl}
        download="sessions export.csv"
        className="hidden"
      >
        csv
      </a>
      <Button
        text="Export Sessions as CSV"
        color={Color.Green}
        loading={loading}
        onClick={handleExport}
      />
    </>
  )
}

const exportSubscription = gql`
  subscription ($filters: TutorSessionFiltersInputObject!) {
    tutorSessionsExport(filters: $filters) {
      status
      csv
      failures {
        message
      }
    }
  }
`

export default ExportCsv
