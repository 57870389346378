import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { path } from "~Tutor/TutorRoutes"
import { H2, Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

export type ChangesRequestedExpensesProps = Record<never, any>

const ChangesRequestedExpenses: React.FC<
  ChangesRequestedExpensesProps
> = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: expensesQuery,
    variables: { page },
  })

  if (result.data && result.data.expenses.data.length === 0) return null

  return (
    <WithLoadingIndicator
      result={result}
      header={<H2>Changes Requested Expenses</H2>}
    >
      {({ data: { expenses } }) => (
        <Table
          data={expenses}
          changePage={setPage}
          headers={[
            "Description",
            "Type",
            "Amount",
            "Your Comments",
            "Admin Feedback",
            "Actions",
          ]}
          valueMapper={expense => [
            expense.description,
            expense.expenseType,
            expense.formattedAmmount,
            expense.comments,
            expense.feedback,
            <Link
              key={expense.id}
              to={path("expenseEdit", { id: expense.id })}
              button
            >
              Edit
            </Link>,
          ]}
        />
      )}
    </WithLoadingIndicator>
  )
}

const expensesQuery = gql`
  query ChangesRequestedExpensesQuery($page: Int!) {
    expenses(page: $page, state: changes_requested) {
      currentPage
      totalPages
      data {
        id
        expenseType
        description
        comments
        feedback
        formattedAmount
      }
    }
  }
`

export default ChangesRequestedExpenses
