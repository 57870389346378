import React from "react"

import { useField } from "formik"

import { ErrorIcon } from "~tailwindui/Basics"

import classNames from "src/classNames"

export type TextAreaInputProps = {
  label: string
  name: string
  description?: string
  subfield?: boolean
}

const TextAreaInput: React.FC<
  TextAreaInputProps & React.HTMLProps<HTMLTextAreaElement>
> = ({
  name,
  label,
  subfield = false,
  description = "",
  placeholder = "",
  ...props
}) => {
  const [field, meta] = useField({ name })
  const error = meta.error ? meta.error : ""
  return (
    <div className="mb-3 w-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div
        className={classNames(
          "mt-2",
          subfield && "ml-8",
          error && "relative rounded-md shadow-sm"
        )}
      >
        <textarea
          {...field}
          rows={4}
          id={name}
          className={classNames(
            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6",
            error ? "ring-red-300" : "ring-gray-300"
          )}
          {...props}
        />
        {error && <ErrorIcon />}
      </div>

      <div className="text-sm">
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </div>
  )
}

export default TextAreaInput
