import React from "react"

import { gql, useQuery } from "urql"

import { H2, Link, WithLoadingIndicator } from "~tailwindui/Basics"

import { path } from "components/Tutor/TutorRoutes"

export type OpenGenerationInvitesProps = Record<string, never>

const OpenGenerationInvites: React.FC<OpenGenerationInvitesProps> = () => {
  const [result] = useQuery({ query })

  return (
    <>
      <H2>Open Study Group Invitations</H2>
      <WithLoadingIndicator result={result}>
        {({ data: { viewer } }) =>
          viewer.openGenerationInvites.length > 0 ? (
            <ul>
              {viewer.openGenerationInvites.map(invite => (
                <li key={invite.id}>
                  <Link
                    to={path("studyGroupTutorInvitationDetails", {
                      id: invite.id,
                    })}
                  >
                    View Study Group Invitation for {invite.organization.name}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <span className="mb-8 text-lg italic">
              There are no open study group invitations at this time.
            </span>
          )
        }
      </WithLoadingIndicator>
    </>
  )
}

const query = gql`
  query openGenerationInvitesQuery {
    viewer {
      openGenerationInvites {
        id
        organization {
          id
          name
        }
      }
    }
  }
`

export default OpenGenerationInvites
