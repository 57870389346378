import React from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import { Color } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { H1, Link, WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types/MatchParams"

import PieChart from "components/TailoredSectionResultChart"
import NotFound from "components/Tutor/Pages/NotFound"

const TailoredAssignmentResults: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { id: tailoredAssignmentId, studentId } = match.params

  const [result] = useQuery({
    query: loadResultsQuery,
    variables: { tailoredAssignmentId, studentId },
  })

  if (
    result.data &&
    (!result.data.tailoredAssignment || !result.data.tailoredSectionSummaries)
  )
    return <NotFound />

  return (
    <WithLoadingIndicator
      result={result}
      error="An error occurred while loading assignment results"
    >
      {({ data }) => {
        const chartData = data.tailoredSectionSummaries
          .map(tailoredSectionSummary => {
            const score = `${(tailoredSectionSummary.score * 100).toFixed(2)}%`
            return {
              sectionId: tailoredSectionSummary.tailoredSection.id,
              sectionReviewPath: path("studentTailoredSectionDetails", {
                studentId,
                tailoredAssignmentId,
                id: tailoredSectionSummary.tailoredSection.id,
              }),
              label: `${tailoredSectionSummary.tailoredSection.name}\n${score}`,
              data: [
                { x: "Correct", y: tailoredSectionSummary.correctCount },
                { x: "Incorrect", y: tailoredSectionSummary.incorrectCount },
              ].filter(d => d.y > 0),
            }
          })
          .filter(d => d.data.length > 0)

        return (
          <React.Fragment>
            <H1 className="text-center">
              Results for {data.tailoredAssignment.name}
            </H1>
            <div className="row">
              {chartData.map(chartData => (
                <div className="center w-1/2" key={chartData.sectionId}>
                  <PieChart chartData={chartData} />
                  <div>
                    <Link
                      to={path("studentTailoredSectionDetails", {
                        studentId,
                        tailoredAssignmentId,
                        id: chartData.sectionId,
                      })}
                      button
                      color={Color.Orange}
                    >
                      Review Answers
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )
      }}
    </WithLoadingIndicator>
  )
}

const loadResultsQuery = gql`
  query ResultsQuery($tailoredAssignmentId: ID!, $studentId: ID!) {
    tailoredAssignment(id: $tailoredAssignmentId) {
      name
    }
    tailoredSectionSummaries(
      tailoredAssignmentId: $tailoredAssignmentId
      studentId: $studentId
    ) {
      correctCount
      incorrectCount
      score
      tailoredSection {
        id
        name
      }
    }
  }
`

export default TailoredAssignmentResults
