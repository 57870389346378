import React, { useContext, useEffect } from "react"

import { NotificationManager } from "react-notifications"
import { RouteComponentProps } from "react-router"
import { useHistory } from "react-router-dom"
import { gql, useQuery } from "urql"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import TutorContext from "~Tutor/TutorContext"
import { path } from "~Tutor/TutorRoutes"
import ProxyNumber from "~Tutor/components/ProxyNumber"
import {
  AlertMessageBox,
  H2,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import DescriptionItem from "~tailwindui/Basics/DescriptionItem"

import ordered from "src/ordered"
import { MatchParams } from "src/types/MatchParams"

import LocalTime from "components/LocalTime"
import NotFound from "components/Tutor/Pages/NotFound"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"

import LessonResources from "../components/LessonResources"
import SessionInfo from "../components/SessionInfo"

import SessionButtons from "./SessionButtons"
import StudentPresenceStatus from "./StudentPresenceStatus"

const SessionDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const sessionId = match.params.id
  const context = useContext(TutorContext)
  const tutorId = context.viewer.id
  const history = useHistory()

  const [result] = useQuery({
    query,
    variables: { sessionId, tutorId },
  })

  useEffect(() => {
    if (result.data && result.data.session?.blocked) {
      history.push(path("dashboard"))
      NotificationManager.warning(
        "This session is blocked. Please wait for the family to resolve their failed payments."
      )
    }
  }, [result, history])

  if (result.data && !result.data.session) return <NotFound />

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { session } }) => (
        <>
          <H2 className="flex items-center">
            {session.directory ? (
              <DirectoryIcon recordType={"session"} />
            ) : (
              <AirtutorsIcon recordType={"session"} />
            )}
            Session Details
          </H2>
          {session.statusState === "canceled" && (
            <AlertMessageBox level={AlertLevel.Warning}>
              This session has been canceled due to {session.cancellationReason}
            </AlertMessageBox>
          )}
          <div className="sm:grid sm:grid-cols-3 sm:gap-4">
            <div className="pr-2 sm:col-span-2">
              {session.future && session.noShow && (
                <AlertMessageBox level={AlertLevel.Warning}>
                  This session was auto-billed as a no show due to student
                  cancellation(s). You will not need to attend this session.
                </AlertMessageBox>
              )}

              <SessionInfo session={session} />

              <DescriptionItem
                label="Students"
                value={
                  <ul>
                    {ordered(
                      session.studentSessions,
                      "student",
                      "lastName"
                    ).map(
                      ({
                        id,
                        student,
                        participationLevel,
                        noShow,
                        excusedAbsence,
                      }) => (
                        <li
                          className="px-4 py-6 odd:bg-gray-200"
                          key={student.id}
                        >
                          <Link
                            className="text-xl"
                            to={path("studentDetails", {
                              id: student.id,
                            })}
                          >
                            {student.fullName}
                          </Link>
                          <br />
                          <ProxyNumber
                            tutorId={tutorId}
                            studentId={student.id}
                          />
                          <br />
                          <StudentPresenceStatus
                            studentSessionId={id}
                            noShow={noShow}
                            excusedAbsence={excusedAbsence}
                            participationLevel={participationLevel}
                          />
                        </li>
                      )
                    )}
                  </ul>
                }
              />

              {session.cloudroomRecordings?.length > 0 && (
                <DescriptionItem
                  label="Cloudroom Recordings"
                  value={
                    <ul>
                      {session.cloudroomRecordings.map(
                        ({ url, name, createdAt, duration }) => (
                          <li key={url}>
                            <Link to={url}>
                              {name} (<LocalTime timestamp={createdAt} />
                              {duration && `, Duration: ${duration}`})
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  }
                />
              )}

              {session.attachments?.length > 0 && (
                <DescriptionItem
                  label="Attachments"
                  value={
                    <ul>
                      {session.attachments.map(({ filename, downloadUrl }) => (
                        <li key={downloadUrl}>
                          <Link to={downloadUrl}>{filename}</Link>
                        </li>
                      ))}
                    </ul>
                  }
                />
              )}

              {session.lessonResourceCompletions.length > 0 && (
                <Link
                  to={path("sessionEvaluationEdit", {
                    id: session.id,
                  })}
                  button
                  color={Color.Orange}
                >
                  Update Student Evaluation
                </Link>
              )}
            </div>
            <div className="sm: col-span-1">
              <SessionButtons session={session} />
            </div>
            <div className="sm: col-span-3">
              {session.lessonResources.length > 0 && (
                <LessonResources airtutorsSessionId={session.id} />
              )}
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query SessionDetailsQuery($sessionId: ID!, $tutorId: ID!) {
    session(id: $sessionId) {
      id
      directory
      airtutors
      type
      startsAt
      substituteNotes
      tutorFeedback
      topicsCovered
      cloudroomActive
      statusState
      billState
      canceledBy
      cancellationReason
      compedReason
      immovable
      future
      mayCancelByTutor
      subRequestsLeft(tutorId: $tutorId)
      blocked
      studentSessions {
        id
        noShow
        excusedAbsence
        participationLevel
        student {
          id
          fullName
          lastName
          notes
          tutorToStudentPhoneNumber(tutorId: $tutorId)
        }
      }
      subject {
        id
        name
      }
      originalTutor {
        id
        fullName
        email
      }
      studyGroup {
        id
        name
        details
      }
      organization {
        id
        name
        details
      }
      cloudroomRecordings {
        url
        name
        createdAt
        duration
      }
      attachments {
        filename
        downloadUrl
      }
      lessonResources {
        id
      }
      lessonResourceCompletions {
        id
      }
    }
  }
`

export default SessionDetails
