import React, { useState } from "react"

import defaultAxios from "axios"
import { Field, Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox, H1, WithLoadingIndicator } from "~tailwindui/Basics"
import {
  CheckboxInput,
  Failures,
  SelectInput,
  SubmitButton,
  TextAreaInput,
  TextInput,
  handleFailure,
} from "~tailwindui/Form"

import { countryOptions, regionOptions, timezoneOptions } from "src/enums"
import { publicClient as client } from "src/urql-client"

import { gql, useMutation, useQuery } from "hooks/urql"

import AvatarPreview from "./AvatarPreview"
import SubjectBucketSelection from "./SubjectBucketSelection"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  emailTimeZone: Yup.string().required("Time Zone is required"),
  country: Yup.string().required("Country is required"),
  region: Yup.string().required("Region is required"),
  bio: Yup.string().required("Bio is required"),
  education: Yup.string().required("Education is required"),
  avatar: Yup.string().required("Avatar is required"),
})

const TutorQuestionnaire = ({ token }) => {
  client.fetchOptions.headers["X-QUESTIONNAIRE-TOKEN"] = token

  const [pendingAvatar, setPendingAvatar] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const history = useHistory()

  const [result] = useQuery({ query: loadTutorQuery, client })

  const [, runMutation] = useMutation(updateTutorMutation, client)

  const buildFormState = tutor => ({
    email: tutor.email,
    firstName: tutor.firstName || "",
    lastName: tutor.lastName || "",
    phoneNumber: tutor.phoneNumber || "",
    emailTimeZone: tutor.emailTimeZone || "",
    country: tutor.address?.country || "United States",
    region: tutor.address?.region || "California",
    bio: tutor.bio || "",
    education: tutor.education || "",
    avatar: tutor.avatar.url || "",
    tierIds: tutor.tiers.map(tier => tier.id),
    subjectBucketIds: tutor.tutorSubjects.map(
      tutorSubject => tutorSubject.subjectBucket.id
    ),
  })

  const handleSubmit = (values, actions) => {
    runMutation({ ...values })
      .then(result => {
        const { failures } = result.data.updateTutor
        if (failures.length > 0) {
          handleFailure(actions, failures)
        } else {
          history.push(`/`)
          NotificationManager.success(
            "Your profile has been updated. You will be contacted if further modifications are required."
          )
        }
      })
      .catch(err => handleFailure(actions))
  }

  const handleAvatarUpload = ({ event, setFieldValue, tutor }) => {
    const file = event.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append("key", tutor.avatar.signedUrl.key)
    formData.append(
      "success_action_status",
      tutor.avatar.signedUrl.successActionStatus
    )
    formData.append("acl", tutor.avatar.signedUrl.acl)
    formData.append("policy", tutor.avatar.signedUrl.policy)
    formData.append("x-amz-credential", tutor.avatar.signedUrl.credential)
    formData.append("x-amz-algorithm", tutor.avatar.signedUrl.algorithm)
    formData.append("x-amz-date", tutor.avatar.signedUrl.date)
    formData.append("x-amz-signature", tutor.avatar.signedUrl.signature)
    formData.append("x-amz-server-side-encryption", "AES256")
    formData.append("Content-Type", file.type)
    formData.append("file", file)

    setIsUploading(true)
    setPendingAvatar(file)

    defaultAxios.post(tutor.avatar.signedUrl.url, formData).then(response => {
      const xmlDocument = new DOMParser().parseFromString(
        response.data,
        "text/xml"
      )
      const location = xmlDocument.getElementsByTagName("Location")[0].innerHTML
      setFieldValue("avatar", decodeURIComponent(location))
      setIsUploading(false)
    })
  }

  return (
    <WithLoadingIndicator
      result={result}
      error="An error occurred while loading your profile. Please refresh or contact an administrator"
      header={<H1 className="text-center">Tutor Questionnaire</H1>}
    >
      {({ data: { tutor, tiers } }) => (
        <div className="m-auto px-2 sm:w-1/2 md:w-1/2">
          <Formik
            initialValues={buildFormState(tutor)}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <TextInput name="email" disabled />

                <div>
                  <TextInput name="firstName" label="First Name" />
                  {tutor.fullNameReviewFeedback && (
                    <div className="rounded-md border-2 border-solid border-red-500 px-2 py-1 text-red-500">
                      {tutor.fullNameReviewFeedback}
                    </div>
                  )}
                </div>

                <TextInput name="lastName" label="Last Name" />

                <div>
                  <TextInput
                    name="phoneNumber"
                    label="Cell Phone Number"
                    description="Real phone numbers only (no Google numbers!)"
                  />
                  {tutor.phoneNumberReviewFeedback && (
                    <div className="rounded-md border-2 border-solid border-red-500 px-2 py-1 text-red-500">
                      {tutor.phoneNumberReviewFeedback}
                    </div>
                  )}
                </div>

                <div>
                  <SelectInput
                    label="Time Zone"
                    name="emailTimeZone"
                    options={timezoneOptions}
                  />
                  {tutor.emailTimeZoneReviewFeedback && (
                    <div className="rounded-md border-2 border-solid border-red-500 px-2 py-1 text-red-500">
                      {tutor.emailTimeZoneReviewFeedback}
                    </div>
                  )}
                </div>

                <SelectInput
                  name="country"
                  label="Country"
                  options={countryOptions}
                  defaultValue={countryOptions.find(
                    o => o.value === values.country
                  )}
                />
                <SelectInput
                  name="region"
                  label="Region"
                  options={regionOptions.filter(r => r.value !== "Other")}
                  defaultValue={regionOptions.find(
                    o => o.value === values.region
                  )}
                />

                <div>
                  <TextAreaInput name="bio" label="Bio" />
                  {tutor.bioReviewFeedback && (
                    <div className="rounded-md border-2 border-solid border-red-500 px-2 py-1 text-red-500">
                      {tutor.bioReviewFeedback}
                    </div>
                  )}
                </div>

                <div>
                  <TextAreaInput
                    name="education"
                    label="Education"
                    description="Include degrees earned and university(ies) attended"
                  />
                  {tutor.educationReviewFeedback && (
                    <div className="rounded-md border-2 border-solid border-red-500 px-2 py-1 text-red-500">
                      {tutor.educationReviewFeedback}
                    </div>
                  )}
                </div>

                <div>
                  <div className="row">
                    <div className="col-1-2">
                      <label>
                        Avatar
                        <br />
                        <Field
                          name="avatar"
                          type="text"
                          className="form-control"
                        >
                          {({ field: { name } }) => (
                            <input
                              type="file"
                              accept="image/*"
                              name={name}
                              onChange={e =>
                                handleAvatarUpload({
                                  event: e,
                                  setFieldValue,
                                  tutor,
                                })
                              }
                            />
                          )}
                        </Field>
                      </label>
                    </div>

                    <div className="col-1-2">
                      <AvatarPreview
                        thumbUrl={tutor.avatar.thumbUrl}
                        pendingAvatar={pendingAvatar}
                        isUploading={isUploading}
                      />
                    </div>

                    <div className="col-1-1">
                      <AlertMessageBox level={AlertLevel.Warning}>
                        This should be an appropriate selfie of yourself and not
                        any other type of image (ie., no pictures of dogs,
                        objects, etc)
                      </AlertMessageBox>
                    </div>
                    <div className="clear" />
                  </div>
                  {tutor.avatarReviewFeedback && (
                    <div className="rounded-md border-2 border-solid border-red-500 px-2 py-1 text-red-500">
                      {tutor.avatarReviewFeedback}
                    </div>
                  )}
                </div>

                <div>
                  <CheckboxInput
                    checkboxType="multi"
                    name="tierIds"
                    label="Grade Ranges"
                    options={tiers.map(tier => ({
                      label: tier.gradeRange,
                      value: tier.id,
                    }))}
                  />
                  {tutor.tierIdsReviewFeedback && (
                    <div className="rounded-md border-2 border-solid border-red-500 px-2 py-1 text-red-500">
                      {tutor.tierIdsReviewFeedback}
                    </div>
                  )}
                </div>

                {values.tierIds.sort().map(tierId => (
                  <SubjectBucketSelection
                    key={tierId}
                    tierId={parseInt(tierId, 10)}
                    subjectBucketIds={values.subjectBucketIds}
                    tutorSubjects={tutor.tutorSubjects}
                  />
                ))}

                <Failures />
                <SubmitButton
                  text="Submit Items for Review"
                  disabled={isSubmitting || isUploading}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const loadTutorQuery = gql`
  query TutorQuery {
    tutor {
      email
      firstName
      lastName
      fullNameReviewFeedback
      phoneNumber
      phoneNumberReviewFeedback
      emailTimeZone
      emailTimeZoneReviewFeedback
      bio
      bioReviewFeedback
      education
      educationReviewFeedback
      avatarReviewFeedback
      tierIdsReviewFeedback
      address {
        country
        region
      }
      avatar {
        url
        thumbUrl
        signedUrl {
          url
          key
          successActionStatus
          acl
          policy
          credential
          algorithm
          date
          signature
        }
      }
      tiers {
        id
      }
      tutorSubjects {
        approvalState
        subjectBucket {
          id
          name
        }
      }
    }
    tiers {
      id
      gradeRange
    }
  }
`

const updateTutorMutation = gql`
  mutation updateTutor(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $emailTimeZone: TimeZoneEnum!
    $country: String!
    $region: String!
    $bio: String!
    $education: String!
    $avatar: String
    $tierIds: [ID!]!
    $subjectBucketIds: [ID!]!
  ) {
    updateTutor(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      emailTimeZone: $emailTimeZone
      country: $country
      region: $region
      bio: $bio
      education: $education
      avatar: $avatar
      tierIds: $tierIds
      subjectBucketIds: $subjectBucketIds
    ) {
      failures {
        message
      }
    }
  }
`

export default TutorQuestionnaire
