import React from "react"

import { gql, useQuery } from "urql"

import StudyGroupsTable from "~Tutor/components/StudyGroupsTable"
import { H2, WithLoadingIndicator } from "~tailwindui/Basics"

export type StudyGroupsNeedingSchedulingProps = Record<never, any>

const StudyGroupsNeedingScheduling: React.FC<
  StudyGroupsNeedingSchedulingProps
> = () => {
  const [result] = useQuery({
    query: studyGroupsNeedingSchedulingQuery,
  })

  if (result.data && result.data.viewer.studyGroups.data.length === 0)
    return (
      <span className="mt-8 italic">
        No study groups need scheduling at this time.
      </span>
    )

  return (
    <div>
      <H2>Newly Accepted Study Groups</H2>
      <WithLoadingIndicator result={result}>
        {({ data: { viewer } }) => (
          <StudyGroupsTable studyGroups={viewer.studyGroups.data} />
        )}
      </WithLoadingIndicator>
    </div>
  )
}

const studyGroupsNeedingSchedulingQuery = gql`
  query StudyGroupsNeedingSchedulingQuery {
    viewer {
      studyGroups(needingScheduling: true) {
        data {
          id
          directory
          name
          students {
            id
            fullName
            avatar {
              thumbUrl
            }
          }
          subjects {
            id
            name
          }
          allAvailabilities {
            id
            startsAt
            endsAt
            weekday
          }
        }
      }
    }
  }
`

export default StudyGroupsNeedingScheduling
