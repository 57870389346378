import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"

import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

import { Color, Size } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"

export type DeclineSessionButtonProps = {
  id: string
  refetchQuery: (options: any) => void
}

const DeclineSessionButton: React.FC<DeclineSessionButtonProps> = ({
  id,
  refetchQuery,
}) => {
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const [, runMutation]: [any, RunMutation] = useMutation(
    declineSessionMutation
  )

  const declineSession = () => {
    runMutation({ id }).then(result => {
      const { failures } = result.data.declineAirtutorsSession
      if (failures.length > 0) {
        setErrorMessage(failures[0].message)
        setOpen(true)
      } else {
        refetchQuery({ requestPolicy: "network-only" })
        NotificationManager.success("The session has been declined")
      }
    })
  }

  return (
    <>
      <Modal.Dialog hideButton isOpen={open} closeModal={() => setOpen(false)}>
        {closeModal => (
          <>
            <Modal.Header
              Icon={ExclamationCircleIcon}
              iconClassNames="text-red-500"
            >
              Decline Failed
            </Modal.Header>
            <Modal.Body>
              <p>{errorMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex justify-end">
                <Button onClick={closeModal}>Okay</Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal.Dialog>

      <Button size={Size.Small} color={Color.Red} onClick={declineSession}>
        Decline
      </Button>
    </>
  )
}

const declineSessionMutation = gql`
  mutation declineSession($id: ID!) {
    declineAirtutorsSession(id: $id) {
      failures {
        message
      }
    }
  }
`

export default DeclineSessionButton
