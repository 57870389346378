import React, { useContext } from "react"

import { Redirect, Route, Switch } from "react-router-dom"

import TutorContext from "components/Tutor/TutorContext"
import { findRoute } from "components/Tutor/TutorRoutes"

import DirectoryNav from "./DirectoryNav"

export type TutorDirectoryProps = Record<string, never>

const TutorDirectory: React.FC<TutorDirectoryProps> = () => {
  const { viewer } = useContext(TutorContext)

  const allowedRoutes = viewer.directory
    ? findRoute("directory").routes
    : [findRoute("directory.welcome")]
  return (
    <div className="flex flex-col space-y-8 sm:flex-row sm:space-y-0 sm:space-x-8">
      <DirectoryNav />
      <div className="w-full pb-10">
        <Switch>
          <Route path="/directory" exact>
            <Redirect
              to={
                viewer.directory
                  ? findRoute("directory.courseClustersIndex").path
                  : findRoute("directory.welcome").path
              }
            />
          </Route>
          {allowedRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </div>
    </div>
  )
}

export default TutorDirectory
