import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import classNames from "src/classNames"

import CloudroomButton from "components/CloudroomButton"
import LocalTime from "components/LocalTime"

import AirtutorsIcon from "../AirtutorsIcon"
import DirectoryIcon from "../DirectoryIcon"

import TutorCancelSessionButton from "./CancelSessionButton"
import RescheduleSessionButton from "./RescheduleSessionButton"

export type ScheduledSessionsProps = {
  hideIfEmpty?: boolean
}

const ScheduledSessions: React.FC<ScheduledSessionsProps> = ({
  hideIfEmpty = false,
}) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: scheduledSessionsQuery,
    variables: { page },
  })

  if (
    hideIfEmpty &&
    result.data &&
    result.data.viewer.sessions.data.length === 0
  )
    return null

  return (
    <WithLoadingIndicator
      result={result}
      error="An error occurred while loading your scheduled sessions"
    >
      {({ data: { viewer } }) => (
        <Table
          title="Scheduled Sessions"
          changePage={setPage}
          data={viewer.sessions}
          headers={["Study Group", "Subject", "Students", "Date", "Actions"]}
          valueMapper={session => [
            session.studyGroup && (
              <Link
                key={session.studyGroup.id}
                to={path("studyGroupDetails", {
                  id: session.studyGroup.id,
                })}
              >
                <span className="flex items-center">
                  {session.directory ? (
                    <DirectoryIcon recordType={"session"} />
                  ) : (
                    <AirtutorsIcon recordType={"session"} />
                  )}{" "}
                  {session.studyGroup.name}
                </span>
              </Link>
            ),
            session.subject.name,
            <ul key={session.id}>
              {session.students.map(student => (
                <li key={student.id}>
                  <Link
                    to={path("studentDetails", {
                      id: student.id,
                    })}
                  >
                    {student.fullName}
                  </Link>
                </li>
              ))}
            </ul>,
            <div key={session.id} className="min-w-[80px]">
              <LocalTime format="ddd MMM D" timestamp={session.startsAt} />
              <br />
              <LocalTime omitDate timestamp={session.startsAt} />
              &mdash;
              <LocalTime omitDate timestamp={session.endsAt} />
            </div>,
            <div key={session.id} className="flex items-center space-x-3">
              {session.cloudroomActive && (
                <CloudroomButton
                  tailwind
                  href={`/tutor${path("sessionCloudroom", {
                    id: session.id,
                  })}`}
                  startsAt={session.startsAt}
                />
              )}
              <div
                className={classNames(
                  "flex",
                  session.cloudroomActive
                    ? "flex-col items-stretch justify-center space-y-1"
                    : "items-center space-x-1"
                )}
              >
                <Link
                  to={path("sessionDetails", {
                    id: session.id,
                  })}
                  button
                  size={Size.Small}
                >
                  View
                </Link>
                {session.future && (
                  <>
                    {!session.immovable && (
                      <RescheduleSessionButton id={session.id} size="small" />
                    )}
                    {session.mayCancelByTutor && (
                      <TutorCancelSessionButton id={session.id} />
                    )}
                  </>
                )}
              </div>
            </div>,
          ]}
        />
      )}
    </WithLoadingIndicator>
  )
}

const scheduledSessionsQuery = gql`
  query ScheduledSessionsQuery($page: Int!) {
    viewer {
      sessions(
        filters: { future: true, statusState: scheduled, billState: unbilled }
        orderDirection: ascending
        page: $page
      ) {
        totalPages
        data {
          id
          type
          directory
          airtutors
          startsAt
          endsAt
          future
          immovable
          mayCancelByTutor
          cloudroomActive
          studyGroup {
            id
            name
          }
          students {
            id
            fullName
          }
          subject {
            name
          }
        }
      }
    }
  }
`

export default ScheduledSessions
