import React, { useContext } from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import TutorContext from "~Tutor/TutorContext"
import { H1, WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types"

import NotFound from "components/Tutor/Pages/NotFound"
import EditSubjectBuckets from "components/Tutor/components/TutorSubjects/EditSubjectBuckets"

const EditTutorSubjects: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const tierId = match.params.id
  const { viewer } = useContext(TutorContext)
  const tutorId = viewer.id

  const [result] = useQuery({
    query,
    variables: { tutorId, tierId },
    requestPolicy: "network-only",
  })

  if (result.data && !result.data.tier) return <NotFound />

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer, tier } }) => (
        <div className="flex flex-col items-center justify-center">
          <H1>Edit Subjects</H1>

          <EditSubjectBuckets
            tier={tier}
            approvalType={"airtutors"}
            viewer={viewer}
          />
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query SubjectBucketsQuery($tutorId: ID!, $tierId: ID!) {
    viewer {
      id
      subjectBuckets(tierId: $tierId, approvalType: airtutors) {
        id
        name
        approvalState(tutorId: $tutorId, approvalType: airtutors)
      }
    }
    tier(id: $tierId) {
      id
      gradeRange
      subjectBuckets {
        id
        name
      }
    }
  }
`

export default EditTutorSubjects
