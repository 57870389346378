import React, { ReactNode } from "react"

import classNames from "src/classNames"

export type H2Props = {
  children: ReactNode
  className?: string
}

const H2: React.FC<H2Props> = ({ children, className = "" }) => {
  return (
    <h2 className={classNames("my-4 text-2xl font-medium", className)}>
      {children}
    </h2>
  )
}

export default H2
