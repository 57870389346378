import React from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { DateObject } from "components/Forms/DatePicker"
import EditableDisplay from "components/Forms/EditableDisplay"
import LocalTime from "components/LocalTime"

import AddAvailability from "./AddAvailability"
import AssignedStudyGroups from "./AssignedStudyGroups"
import Availability from "./Availability"
import BulkRegisterStudents from "./BulkRegisterStudents"
import RegisterStudent from "./RegisterStudent"
import RemoveSection from "./RemoveSection"
import ReservedStudyGroups from "./ReservedStudyGroups"
import UnassignedStudyGroups from "./UnassignedStudyGroups"

const CourseSection = ({ course, courseSection }) => {
  const { runMutation } = useMutation(
    buildMutation(updateCourseSectionMutation)
  )

  return (
    <div className="row" key={courseSection.id}>
      {courseSection.errors && (
        <div className="col-12">
          <div className="alert alert-danger">{courseSection.errors}</div>
        </div>
      )}
      <div className="col-12 col-md-6">
        <dl>
          <dt>Starts On</dt>
          <dd>
            <EditableDisplay
              allowEditing={!course.directory}
              editableValue={new DateObject(courseSection.startsOn)}
              displayValue={
                <LocalTime timestamp={courseSection.startsOn} omitTime />
              }
              type="date"
              save={({ value }) =>
                runMutation({
                  id: courseSection.id,
                  startsOn: value.toDate(),
                })
              }
            />
          </dd>

          <dt>Ends On</dt>
          <dd>
            <EditableDisplay
              allowEditing={!course.directory}
              editableValue={new DateObject(courseSection.endsOn)}
              displayValue={
                <LocalTime timestamp={courseSection.endsOn} omitTime />
              }
              type="date"
              save={({ value }) =>
                runMutation({
                  id: courseSection.id,
                  endsOn: value.toDate(),
                })
              }
            />
          </dd>

          <dt>Registration Ends</dt>
          <dd>
            <EditableDisplay
              allowEditing={!course.directory}
              editableValue={
                new DateObject(courseSection.studentRegistrationEndsAt)
              }
              displayValue={
                <LocalTime
                  timestamp={courseSection.studentRegistrationEndsAt}
                  omitTime
                />
              }
              type="date"
              save={({ value }) =>
                runMutation({
                  id: courseSection.id,
                  studentRegistrationEndsOn: value.toDate(),
                })
              }
            />
          </dd>

          <dt>Tutor Registration Open?</dt>
          <dd>
            <EditableDisplay
              allowEditing={!course.directory}
              displayValue={
                courseSection.tutorRegistrationOpen ? (
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                ) : (
                  <FontAwesomeIcon icon={faTimes} className="text-danger" />
                )
              }
              editableValue={courseSection.tutorRegistrationOpen}
              type="checkbox"
              save={({ value }) =>
                runMutation({
                  id: courseSection.id,
                  tutorRegistrationOpen: value,
                })
              }
            />
          </dd>

          <dt>Rebalanced?</dt>
          <dd>
            {courseSection.studyGroupsRebalanced ? (
              <FontAwesomeIcon icon={faCheck} className="text-success" />
            ) : (
              <FontAwesomeIcon icon={faTimes} className="text-danger" />
            )}
          </dd>

          <dt>Group Limit</dt>
          <dd>
            <EditableDisplay
              allowEditing={!course.directory}
              displayValue={courseSection.groupLimit}
              type="number"
              save={({ value }) =>
                runMutation({
                  id: courseSection.id,
                  groupLimit: parseInt(value, 10),
                })
              }
            />
          </dd>

          <dt>Availabilities</dt>
          <dd>
            {courseSection.availabilities.map(availability => (
              <Availability
                key={availability.id}
                availability={availability}
                courseSectionId={courseSection.id}
              />
            ))}
            {!course.directory && (
              <AddAvailability
                courseSection={courseSection}
                removable={!course.directory}
              />
            )}
          </dd>
        </dl>
        {!course.directory && (
          <>
            <RemoveSection id={courseSection.id} />
            <RegisterStudent course={course} courseSection={courseSection} />
            <BulkRegisterStudents courseSection={courseSection} />
          </>
        )}
      </div>

      <div className="col-12 col-md-6">
        <UnassignedStudyGroups courseSection={courseSection} />
        <ReservedStudyGroups courseSection={courseSection} />
        <AssignedStudyGroups courseSection={courseSection} />
      </div>

      <div className="col-12">
        <hr />
      </div>
    </div>
  )
}

const updateCourseSectionMutation = compress`
  mutation(
    $id: ID!
    $startsOn: Date
    $endsOn: Date
    $studentRegistrationEndsOn: Date
    $groupLimit: Int
    $tutorRegistrationOpen: Boolean
  ) {
    updateCourseSection(
      id: $id
      startsOn: $startsOn
      endsOn: $endsOn
      studentRegistrationEndsOn: $studentRegistrationEndsOn
      groupLimit: $groupLimit
      tutorRegistrationOpen: $tutorRegistrationOpen
    ) {
      errorMessages
      courseSection {
        id
        startsOn
        endsOn
        studentRegistrationEndsAt
        groupLimit
        tutorRegistrationOpen
        studyGroupsRebalanced
      }
    }
  }
`

export default CourseSection
