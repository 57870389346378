import React from "react"

import { Form, Formik } from "formik"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { RocketLaunchIcon } from "@heroicons/react/24/solid"

import { Color } from "~tailwindui/types/enums"

import { Button, Link } from "~tailwindui/Basics"
import {
  Failures,
  SelectInput,
  SubmitButton,
  TextAreaInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import { RunMutation } from "src/types"

import { path } from "components/Tutor/TutorRoutes"

const ValidationSchema = Yup.object().shape({
  subjectIds: Yup.array().min(1, "Please select at least one subject"),
})

export type CreateDirectoryGroupModalProps = {
  student: {
    id: string
    firstName: string
    directory: boolean
  }
  subjects: {
    id: string
    name: string
  }[]
}

const CreateDirectoryGroupModal: React.FC<CreateDirectoryGroupModalProps> = ({
  student,
  subjects,
}) => {
  const history = useHistory()
  const formState = {
    studentId: student.id,
    subjectIds: [],
    details: "",
  }

  const [, runMutation]: [any, RunMutation] = useMutation(
    createDirectoryGroupMutation
  )

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          const { failures, studyGroup } = result.data.createDirectoryGroup
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            history.push(
              path("studyGroupDetails", {
                id: studyGroup.id,
              })
            )
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Modal.Dialog
      buttonText="Create Directory 1-1 Group"
      buttonClassNames={buttonClassNames({
        color: Color.Purple,
      })}
    >
      {closeModal => (
        <>
          <Modal.Header
            Icon={RocketLaunchIcon}
            iconClassNames="text-purple-600"
          >
            Create Directory 1-1 Group
          </Modal.Header>
          <Formik
            initialValues={formState}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex max-h-[calc(70vh-90px)] flex-col">
              <Modal.Body>
                {student.directory ? (
                  <div>
                    <p className="mb-4">
                      This will create a private 1-1 group billed directly to{" "}
                      {student.firstName}. It will be billed at your Directory
                      rate. To view and adjust your rate,{" "}
                      <Link to={path("directory.billing")}>go here</Link>.
                    </p>
                    <SelectInput
                      multi
                      options={subjects.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))}
                      name="subjectIds"
                      label="Subjects"
                      portal
                    />
                    <TextAreaInput
                      label="Group Details (optional)"
                      name="details"
                      description="These notes will not be seen by students."
                    />
                  </div>
                ) : (
                  <p>
                    {student.firstName} does not have access to the Directory
                    yet. To invite them,{" "}
                    <span className="text-red-500">
                      click here (this will be added in #1508)
                    </span>
                  </p>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Failures />
                <div className="flex justify-end space-x-2">
                  {student.directory ? (
                    <>
                      <Button color={Color.Red} onClick={closeModal}>
                        Cancel
                      </Button>
                      <SubmitButton>Create Group</SubmitButton>
                    </>
                  ) : (
                    <Button onClick={closeModal}>Okay</Button>
                  )}
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </>
      )}
    </Modal.Dialog>
  )
}

const createDirectoryGroupMutation = gql`
  mutation CreateDirectoryGroup(
    $studentId: ID!
    $subjectIds: [ID!]!
    $details: String
  ) {
    createDirectoryGroup(
      studentId: $studentId
      subjectIds: $subjectIds
      details: $details
    ) {
      failures {
        message
      }
      studyGroup {
        id
      }
    }
  }
`

export default CreateDirectoryGroupModal
