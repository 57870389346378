import React from "react"

import { gql, useMutation } from "urql"

import { RunMutation } from "src/types"

import CancelSessionButton from "components/AirtutorsSession/CancelSessionButton"

export type TutorCancelSessionButtonProps = {
  id: string
  size?: string
}

const TutorCancelSessionButton: React.FC<
  TutorCancelSessionButtonProps
> = props => {
  const [, runMutation]: [any, RunMutation] = useMutation(cancelSessionMutation)

  return (
    <CancelSessionButton
      viewerType="Tutor"
      tailwind
      size="small"
      runMutation={runMutation}
      {...props}
    />
  )
}

const cancelSessionMutation = gql`
  mutation CancelSession($id: ID!, $cancellationReason: String!) {
    cancelSession(id: $id, cancellationReason: $cancellationReason) {
      failures {
        message
      }
      session {
        id
      }
    }
  }
`

export default TutorCancelSessionButton
