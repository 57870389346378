import React from "react"

import { useField, useFormikContext } from "formik"

import classNames from "src/classNames"

import { Positive } from "components/Icons"

const PrepaidPackageOption = ({ prepaidPackage, index, isLast }) => {
  const [field] = useField("prepaidPackageId")
  const { setFieldValue } = useFormikContext()
  const features = [
    `${prepaidPackage.hours} hours at ${prepaidPackage.formattedEffectiveHourlyRate} per hour`,
    `This can be repurchased`,
    `${prepaidPackage.formattedHourlyRate} per hour after package runs out`,
  ]

  return (
    <div
      key={prepaidPackage.id}
      className={classNames(
        index % 3 === 0 ? "lg:rounded-r-none" : "",
        index % 3 === 1 ? "lg:rounded-r-none lg:rounded-l-none" : "",
        index % 3 === 2 ? "lg:rounded-l-none" : "",
        isLast ? "lg:rounded-r-3xl" : "",
        "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 lg:mt-8 xl:p-10"
      )}
    >
      <div>
        <div className="flex items-center justify-between gap-x-4">
          <h3
            id={prepaidPackage.id}
            className="text-lg font-semibold leading-8 text-gray-900"
          >
            {prepaidPackage.name}
          </h3>
        </div>
        <p className="mt-4 text-sm leading-6 text-gray-600">
          {prepaidPackage.description}
        </p>
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-4xl font-bold tracking-tight text-gray-900">
            {prepaidPackage.formattedPrice}
          </span>
        </p>
        <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
          {features.map(feature => (
            <li key={feature} className="flex gap-x-3">
              <Positive
                className="flex-none self-center text-sky-600"
                TagName="div"
              />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <button
        type="button"
        onClick={() => setFieldValue("prepaidPackageId", prepaidPackage.id)}
        aria-describedby={prepaidPackage.id}
        className={classNames(
          `
            relative mt-8 block rounded-md border-solid bg-white py-2 px-3 text-center text-sm font-semibold leading-6 text-sky-600
            hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
            focus-visible:outline-sky-600
          `,
          field.value === prepaidPackage.id
            ? `border-transparent bg-emerald-600 !text-white hover:bg-emerald-600`
            : `hover:border-transparent hover:bg-sky-600`
        )}
      >
        {field.value === prepaidPackage.id && (
          <span className="absolute left-3">
            <Positive />
          </span>
        )}
        Buy plan
      </button>
    </div>
  )
}

export default PrepaidPackageOption
