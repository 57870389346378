import React, { useContext } from "react"

import { gql, useQuery } from "urql"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox, H2, WithLoadingIndicator } from "~tailwindui/Basics"

import TutorContext from "components/Tutor/TutorContext"
import TutorSubjectsTable from "components/Tutor/components/TutorSubjects/Table"

export type DirectoryTutorSubjectsProps = Record<string, never>

const DirectoryTutorSubjects: React.FC<DirectoryTutorSubjectsProps> = () => {
  const { viewer } = useContext(TutorContext)

  const [result, refetchQuery] = useQuery({
    query,
    variables: { tutorId: viewer.id },
  })

  return (
    <>
      <H2>Directory Subjects</H2>
      <WithLoadingIndicator result={result}>
        {({ data: { viewer } }) => (
          <>
            {viewer.airtutors && (
              <AlertMessageBox level={AlertLevel.Info}>
                These are your approved subjects for the Tutor Directory only.
                They may differ from your organization subjects.
              </AlertMessageBox>
            )}

            <TutorSubjectsTable
              tiers={viewer.tiers}
              approvalType={"directory"}
              refetchQuery={refetchQuery}
            />
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const query = gql`
  query DirectorySubjectsQuery($tutorId: ID!) {
    viewer {
      id
      airtutors
      tiers {
        id
        gradeRange
        subjectBuckets(tutorId: $tutorId, approvalType: directory) {
          id
          name
          approvalState(tutorId: $tutorId, approvalType: directory)
        }
      }
    }
  }
`
export default DirectoryTutorSubjects
