import React, { ReactNode, useContext } from "react"

import { useField, useFormikContext } from "formik"
import { Calendar } from "react-multi-date-picker"

import classNames from "src/classNames"
import dig from "src/dig"

import ValueDisplay from "components/OrganizationAdmin/components/Form/ValueDisplay"

import PersistedValuesContext from "./PersistedValuesContext"

export type InlineCalendarProps = {
  startFieldName: string
  endFieldName: string
  label: string
  description?: string | ReactNode
  range?: boolean
  value?: Date[]
  horizontal?: boolean
}

const InlineCalendar: React.FC<InlineCalendarProps> = ({
  startFieldName,
  endFieldName,
  label,
  description = "",
  range = false,
  value = null,
  horizontal = false,
  ...props
}) => {
  const [_startField, startMeta] = useField(startFieldName)
  const [_endField, endMeta] = useField(endFieldName)
  const persistedValues = useContext(PersistedValuesContext)
  const persistedStartValue = dig(persistedValues, startFieldName.split("."))
  const persistedEndValue = dig(persistedValues, endFieldName.split("."))
  const displayValue =
    persistedStartValue && persistedEndValue
      ? `${persistedStartValue.format("MM/DD/YYYY")} - ${persistedEndValue}`
      : ""
  const { values, setFieldValue } = useFormikContext() as any
  const startError = startMeta.error ? startMeta.error : ""
  const endError = endMeta.error ? endMeta.error : ""
  const error = [startError, endError].filter(Boolean).join(", ")

  return values.editing ? (
    <div
      className={
        horizontal
          ? "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
          : "mb-3"
      }
    >
      <label
        htmlFor={startFieldName}
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
      >
        {label}
      </label>

      <div className={classNames(error && "relative rounded-md", "col-span-2")}>
        <Calendar
          range={range}
          onChange={dates => {
            setFieldValue(startFieldName, dates[0])
            setFieldValue(endFieldName, dates[1])
          }}
          value={value}
        />
        <div
          className={classNames(
            "text-sm",
            horizontal ? "flex h-full items-center" : "mt-1"
          )}
        >
          {error ? (
            <p className="text-red-600">{error}</p>
          ) : (
            <p className="text-gray-500">{description}</p>
          )}
        </div>
      </div>
    </div>
  ) : (
    <ValueDisplay
      label={label}
      description={description}
      {...props}
      value={displayValue}
    />
  )
}

export default InlineCalendar
