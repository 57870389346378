import React from "react"

import { H3 } from "~tailwindui/Basics"
import { CheckboxInput, Error } from "~tailwindui/Form"

import { participationLevelOptions } from "src/enums"
import ordered from "src/ordered"

type Student = {
  id: string
  fullName: string
}

type StudentSession = {
  student: Student
}

type SessionPresence = {
  user: Student
  duration: number
}

export type StudentParticipationProps = {
  studentSessions: StudentSession[]
  sessionPresences: SessionPresence[]
}

const StudentParticipation: React.FC<StudentParticipationProps> = ({
  studentSessions,
  sessionPresences,
}) => {
  const students = studentSessions.map(studentSession => studentSession.student)
  const totalDuration = student =>
    sessionPresences
      .filter(presence => presence.user.id === student.id)
      .map(presence => presence.duration)
      .reduce((prev, curr) => prev + curr, 0)
      .toFixed(0)

  return (
    <div>
      <div className="mb-5 text-center">
        <H3>Student Participation</H3>
        <div className="italic">
          Do not enter a participation level for{" "}
          <span className="font-semibold">ABSENT</span> students
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
        {ordered(students, "lastName").map(student => (
          <div key={student.id} className="mb-3">
            <CheckboxInput
              checkboxType="radio"
              name={`studentParticipation[${student.id}].participationLevel`}
              label={`${student.fullName} (Duration: ${totalDuration(
                student
              )} minutes)`}
              options={participationLevelOptions}
            />
          </div>
        ))}
      </div>

      <Error name="studentParticipation" />
    </div>
  )
}

export default StudentParticipation
