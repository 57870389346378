import React, { useEffect, useState } from "react"

import dayjs from "dayjs"
import { gql, useQuery } from "urql"

import { AlertLevel } from "~tailwindui/types/enums"

import {
  AlertMessageBox,
  H1,
  H2,
  WithLoadingIndicator,
} from "~tailwindui/Basics"

import AggregateToggle from "./AggregateToggle"
import ExportCsv from "./ExportCsv"
import Filters from "./Filters"
import SessionResults from "./Results"
import SessionsGraph from "./SessionsGraph"
import { dateRangeHelper } from "./dateRangeHelper"

export type SessionReportingProps = Record<string, never>

const SessionReporting: React.FC<SessionReportingProps> = () => {
  const [aggregate, setAggregate] = useState(false)
  const [page, setPage] = useState(1)

  const formState = {
    dateType: "year" as "month" | "year" | "custom" | "all",
    monthOption: "current",
    yearOption: "current",
    startDate: dayjs().startOf("year"),
    endDate: dayjs(),
    statusState: undefined,
    billState: undefined,
    subjectIds: [],
    studyGroupIds: [],
    type: undefined,
  }

  const { dateType, monthOption, yearOption, ...initialFilters } = formState
  const [filters, setFilters] = useState(initialFilters)

  const [result, refetchQuery] = useQuery({
    query: aggregate ? aggregateQuery : sessionsQuery,
    variables: { filters, page },
  })

  const handleSubmit = (values, actions) => {
    const { dateType, monthOption, yearOption, startDate, endDate, ...rest } =
      values

    const [startDateValue, endDateValue] = dateRangeHelper({
      dateType,
      monthOption,
      yearOption,
      startDate,
      endDate,
    })

    setFilters({
      startDate: startDateValue,
      endDate: endDateValue,
      ...rest,
    })
    actions.setSubmitting(false)
  }

  useEffect(() => {
    refetchQuery({ requestPolicy: "network-only" })
  }, [filters, page, refetchQuery])

  return (
    <>
      <H1>Reporting</H1>
      <div className="flex flex-col sm:grid sm:grid-cols-2">
        <div className="sm:col-span-1">
          <H2>Session Filters</H2>
          <Filters formState={formState} handleSubmit={handleSubmit} />
        </div>

        <div className="order-first flex items-center justify-center sm:order-last sm:col-span-1">
          <SessionsGraph filters={filters} />
        </div>
      </div>

      <div>
        <hr className="my-4 border-2" />
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-8">
          <H2 className="flex items-center space-x-4">
            <span>Results</span>
            <AggregateToggle
              aggregate={aggregate}
              setAggregate={setAggregate}
            />
          </H2>

          {aggregate ? (
            <div className="sm:ml-10">
              <AlertMessageBox level={AlertLevel.Info}>
                If you have questions about these numbers, please contact us at{" "}
                <a href="mailto:billing@airtutors.org">billing@airtutors.org</a>
                .
              </AlertMessageBox>
            </div>
          ) : (
            <ExportCsv filters={filters} />
          )}
        </div>
        <WithLoadingIndicator result={result}>
          {({ data: { viewer } }) => (
            <SessionResults
              data={viewer}
              aggregate={aggregate}
              filters={filters}
              setPage={setPage}
            />
          )}
        </WithLoadingIndicator>
      </div>
    </>
  )
}

const sessionsQuery = gql`
  query SessionsQuery($filters: TutorSessionFiltersInputObject!, $page: Int) {
    viewer {
      sessions(filters: $filters, page: $page) {
        totalPages
        currentPage
        data {
          id
          airtutors
          directory
          type
          startsAt
          statusState
          billState
          formattedBilledDuration
          formattedActualDuration
          formattedScheduledDuration
          studyGroup {
            id
            name
          }
          students {
            id
            fullName
          }
          subject {
            id
            name
          }
        }
      }
    }
  }
`

const aggregateQuery = gql`
  query AggregateQuery($filters: TutorSessionFiltersInputObject!) {
    viewer {
      sessionReportingStats(filters: $filters) {
        totalSessions
        totalBilledHours
        grossPay
        netPay
      }
    }
  }
`

export default SessionReporting
