import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isToday from "dayjs/plugin/isToday"
import objectSupport from "dayjs/plugin/objectSupport"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(advancedFormat)
dayjs.extend(isToday)
dayjs.extend(objectSupport)
dayjs.extend(timezone)
dayjs.extend(utc)

export default dayjs
