import React, { ReactNode, useRef, useState } from "react"

import dayjs from "dayjs"
import { gql, useClient, useQuery } from "urql"
import { pipe, subscribe } from "wonka"

import { CheckCircleIcon } from "@heroicons/react/24/outline"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import {
  AlertMessageBox,
  Button,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import ConsistentTutorWarning from "components/ConsistentTutorWarning"

export type AcceptReplacementRequestProps = {
  studyGroupTutorReplacementId: string
}

const AcceptReplacementRequest: React.FC<AcceptReplacementRequestProps> = ({
  studyGroupTutorReplacementId,
}) => {
  const [status, setStatus] = useState<ReactNode | undefined>()
  const [errorMessages, setErrorMessages] = useState([])
  const [inFlight, setInFlight] = useState(false)
  const submitClicked = useRef<boolean>()

  const client = useClient()
  const [result] = useQuery({
    query: replacementQuery,
    variables: { id: studyGroupTutorReplacementId },
  })

  const acceptRequest = () => {
    if (submitClicked.current) {
      return
    }
    submitClicked.current = true
    setInFlight(true)
    setErrorMessages([])
    setStatus("Starting")

    const { unsubscribe } = pipe(
      client.subscription(acceptRequestSubscription, {
        studyGroupTutorReplacementId,
      }),
      subscribe(result => {
        if (result.data) {
          const { failures, studyGroup, requestedSessions } =
            result.data.acceptStudyGroupTutorReplacement

          if (failures.length > 0) {
            setErrorMessages(failures.map(failure => failure.message))
            setStatus(undefined)
            submitClicked.current = false
            unsubscribe()
          }

          if (studyGroup) {
            setStatus(
              <div>
                <div> Registration successful!</div>
                <div>
                  <Link
                    to={path("studyGroupDetails", {
                      id: studyGroup.id,
                    })}
                  >
                    Click here to view your new group
                  </Link>
                </div>

                {requestedSessions?.length > 0 && (
                  <div>
                    This group also has the following outstanding session(s):
                    <ul className="list-unstyled">
                      {requestedSessions.map(session => {
                        const day = dayjs(session.startsAt)
                          .startOf("day")
                          .isSame(dayjs().startOf("day"))
                          ? "today"
                          : dayjs(session.startsAt).format("ddd, MMM D")
                        return (
                          <Link
                            to={path("sessionDetails", {
                              id: session.id,
                            })}
                            key={session.id}
                          >
                            {day} at {dayjs(session.startsAt).format("h:mm A")}
                          </Link>
                        )
                      })}
                    </ul>
                    If you are available to pick any of them up, please accept
                    as soon as possible.
                  </div>
                )}
              </div>
            )
            unsubscribe()
          } else {
            setStatus(
              <>
                In Progress <br />
                (Please be patient, this process can take some time)
              </>
            )
          }
        } else if (result.error) {
          setStatus(undefined)
          setErrorMessages([result.error?.message])
          setInFlight(false)
          submitClicked.current = false
          unsubscribe()
        }
      })
    )
  }

  const resetState = () => {
    setStatus(undefined)
    setErrorMessages([])
    setInFlight(false)
    submitClicked.current = false
  }

  return (
    <Modal.Dialog
      buttonText="Accept Study Group"
      afterClose={resetState}
      buttonClassNames={buttonClassNames({
        color: Color.Green,
      })}
    >
      {closeModal => (
        <WithLoadingIndicator result={result}>
          {({
            data: {
              viewer: {
                studyGroupTutorReplacement: { studyGroup },
              },
            },
          }) => (
            <>
              <Modal.Header
                Icon={CheckCircleIcon}
                iconClassNames="text-emerald-600"
              >
                Accept Study Group
              </Modal.Header>
              <Modal.Body>
                {(studyGroup.maxSubsEstimate ||
                  studyGroup.maxSubsEstimate === 0) && (
                  <ConsistentTutorWarning
                    minTutorConsistencyRate={studyGroup.minTutorConsistencyRate}
                    maxSubsEstimate={studyGroup.maxSubsEstimate}
                  />
                )}
                <p>
                  Click confirm below to accept this group and all its scheduled
                  sessions.
                </p>
              </Modal.Body>

              <Modal.Footer>
                {status && (
                  <AlertMessageBox level={AlertLevel.Info}>
                    {status}
                  </AlertMessageBox>
                )}

                {errorMessages.length > 0 && (
                  <AlertMessageBox>
                    <div className="flex list-disc flex-col space-y-1 pl-5">
                      {errorMessages.map(error => (
                        <div key={error}>
                          <p className="m-0">{error}</p>
                        </div>
                      ))}
                    </div>
                  </AlertMessageBox>
                )}

                <div className="flex w-full justify-end space-x-2">
                  <Button onClick={closeModal} color={Color.Red}>
                    Cancel
                  </Button>
                  <Button
                    disabled={inFlight}
                    onClick={acceptRequest}
                    color={Color.Green}
                  >
                    Confirm
                  </Button>
                </div>
              </Modal.Footer>
            </>
          )}
        </WithLoadingIndicator>
      )}
    </Modal.Dialog>
  )
}

const replacementQuery = gql`
  query ReplacementQuery($id: ID!) {
    viewer {
      studyGroupTutorReplacement(id: $id) {
        studyGroup {
          id
          minTutorConsistencyRate
          maxSubsEstimate
        }
      }
    }
  }
`

const acceptRequestSubscription = gql`
  subscription ($studyGroupTutorReplacementId: ID!) {
    acceptStudyGroupTutorReplacement(
      studyGroupTutorReplacementId: $studyGroupTutorReplacementId
    ) {
      failures {
        message
      }
      studyGroup {
        id
      }
      requestedSessions {
        id
        startsAt
      }
    }
  }
`

export default AcceptReplacementRequest
