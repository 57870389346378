import React, { useMemo } from "react"

import { gql, useQuery } from "urql"

import { H1, H2, WithLoadingIndicator } from "~tailwindui/Basics"

import ordered from "src/ordered"

import useWebsocket from "hooks/useWebsocket"

import LessonResource from "./Details"
import LessonPlan from "./LessonPlan"

export type LessonResourcesProps = {
  airtutorsSessionId: string
}

const LessonResources: React.FC<LessonResourcesProps> = ({
  airtutorsSessionId,
}) => {
  const [result] = useQuery({
    query: lessonResourcesQuery,
    variables: { airtutorsSessionId },
  })

  const channelProps = useMemo(() => {
    return { id: airtutorsSessionId }
  }, [airtutorsSessionId])

  useWebsocket({
    channelName: "SessionChannel",
    channelProps,
  })

  return (
    <div>
      <WithLoadingIndicator result={result}>
        {({ data }) => (
          <>
            <H1 className="text-center">Current Lesson Resources</H1>
            <ul className="inline-block text-left">
              {ordered(
                ordered(
                  ordered(
                    data.airtutorsSession.currentLessonResources,
                    "sortOrder"
                  ),
                  "lessonTopic",
                  "sortOrder"
                ),
                "lessonPlan",
                "name"
              ).map(lessonResource => (
                <React.Fragment key={lessonResource.id}>
                  <H2>{lessonResource.lessonPlan.name}</H2>
                  <div className="align-center flex flex-col justify-between">
                    <h3 className="ml-3 font-medium">
                      {lessonResource.lessonTopic.name}
                    </h3>
                    <LessonResource
                      key={lessonResource.id}
                      airtutorsSessionId={airtutorsSessionId}
                      lessonResource={lessonResource}
                      isCurrent={true}
                    />
                  </div>
                </React.Fragment>
              ))}
            </ul>

            <div className="text-left">
              <H1 className="text-center">Lesson Plans</H1>
              {data.airtutorsSession.lessonPlans.map(lessonPlan => (
                <LessonPlan
                  key={lessonPlan.id}
                  sessionId={airtutorsSessionId}
                  lessonPlanId={lessonPlan.id}
                  name={lessonPlan.name}
                />
              ))}
            </div>
          </>
        )}
      </WithLoadingIndicator>
    </div>
  )
}

const lessonResourcesQuery = gql`
  query LessonResourcesQuery($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      lessonPlans {
        id
        name
      }
      currentLessonResources {
        id
        name
        sortOrder
        file {
          thumbUrl
          previewUrl
        }
        lessonTopic {
          id
          name
          sortOrder
        }
        lessonPlan {
          id
          name
        }
        lessonResourceCompletions(sessionId: $airtutorsSessionId) {
          id
          createdAt
          studentMastery
          student {
            id
            fullName
          }
        }
      }
    }
  }
`

export default LessonResources
