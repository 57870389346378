import React, { SVGProps } from "react"

const AnimatedDog: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    .none {\n        animation: example 2s ease-out infinite;\n        transform-origin: center;\n        transform-box: fill-box\n    }\n\n    @keyframes example {\n        0% {\n            transform: rotate( -40deg );\n        }\n\n        25% {\n            transform: rotate( 0deg );\n        }\n\n        50% {\n            transform: rotate( 40deg );\n        }\n\n        75% {\n            transform: rotate( 0deg );\n        }\n\n        100% {\n            transform: rotate( -39deg );\n        }\n    }\n",
        }}
      />
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 283.5 283.5"
        {...props}
      >
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n            .cls-1 {\n                fill: #1c1c1e;\n            }\n\n            .cls-1, .cls-10, .cls-11, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {\n                fill-rule: evenodd;\n            }\n\n            .cls-2 {\n                fill: #242426;\n            }\n\n            .cls-3 {\n                fill: #bc9250;\n            }\n\n            .cls-4 {\n                fill: #2c2c2d;\n            }\n\n            .cls-5 {\n                fill: #060606;\n            }\n\n            .cls-6, .cls-9 {\n                fill: #c1914f;\n            }\n\n            .cls-7 {\n                fill: #030303;\n            }\n\n            .cls-8 {\n                fill: #020202;\n            }\n\n            .cls-10 {\n                fill: #eaeaea;\n            }\n\n            .cls-11 {\n                fill: #d3d3d3;\n            }\n        ",
            }}
          />
        </defs>
        <path
          id="path18754"
          className="cls-1"
          d="M181.49,199.76c2.76,1.16,3.45,3.16,2.92,6.58,2.38,4.07,2.1,4.54,0,6.59,2.05,3.8,1.41,5.35-.35,7.13,1.54,4.57.45,4.76-1.48,6.11.93,4.76.12,4.76-1.83,6.59.81,3.73-1.07,5.49-3.16,5.94a62.24,62.24,0,0,0-25.13-1c-1.33,0-1.55,1.64-4.16,0-2.38,1.55-2.59,1.45-6.23,0-2.38,1.67-1.9,1.33-6.25,0a7,7,0,0,1-6.23,0,35.69,35.69,0,0,0-24.82.5,4.61,4.61,0,0,1-3.81-5.28,4.13,4.13,0,0,1,.24-.85c-2.09-1.9-2.92-1.86-2-6.59-2-1.5-3.19-1.57-1.52-6.13-1.88-1.71-2.57-3.26-.41-7.13-2.23-2.05-2.76-2.52,0-6.59-.57-3.4.17-5.52,3.09-6.61.83-1.76,3.59-1.88,4.26-2,9.79-14,19-21.4,35.83-22.42,18.78.62,29,14,32.76,19.64a98.42,98.42,0,0,1,8.25,5.49Z"
        />
        <path
          className="cls-2"
          d="M131.59,202.52l.64.8.05.07a5.74,5.74,0,0,1-.29,6.54,7.68,7.68,0,0,1-.38,6.26c1,2.18.4,3.13-.31,5.51,1.36,1.64.57,2.59-.33,5.21a4.2,4.2,0,0,1-.53,5.75c1.07,1.31,1.91,2.71-.26,4.35.48,1.6-2.07,1.52-3.59.5a3,3,0,0,1-.88,1.36,3.19,3.19,0,0,1-4.49-.29,3.42,3.42,0,0,1-1,1h0a3.84,3.84,0,0,1-5.37-1,3.86,3.86,0,0,1-.59.62s0,0,0,0a4.44,4.44,0,0,1-6.25-.64c-4.42,1.4-4.92-2.66-3.09-5a4,4,0,0,1-.6-5.51,6.63,6.63,0,0,1-.19-6.35c-1.56-1.88-.85-2.4-.45-5.66-1.45-1.71-1.28-3.26-.24-6.8a5,5,0,0,1-1.26-5.71,3.92,3.92,0,0,1-1.16-.83,3.75,3.75,0,0,1,.12-5.3c-.93-2-2.71-5.28-.93-7.3a6.87,6.87,0,0,1-.21-7.8,4.81,4.81,0,0,1-1-1.11,4.74,4.74,0,0,1,1.33-6.54,6.56,6.56,0,0,1,1.33-8,5.22,5.22,0,0,1,.41-7.13v.09c16.47-23.11,30.38,13.72,32.76,29a6.77,6.77,0,0,1-2.16,8.13,3.86,3.86,0,0,1,.76,1.07s0,.07,0,.1A3.53,3.53,0,0,1,131.59,202.52Z"
        />
        <path
          className="cls-2"
          d="M181.7,190.63c1.76,2,0,5.2-.95,7.27a3.79,3.79,0,0,1-1.16,6.11,5,5,0,0,1-1.26,5.71c1,3.57,1.21,5.11-.24,6.85.38,3.09,1,3.68-.29,5.39a.9.9,0,0,1-.16.24,6.63,6.63,0,0,1-.19,6.35,4,4,0,0,1-.62,5.51c1.83,2.38,1.33,6.45-3.07,5a4.37,4.37,0,0,1-6.18.58h0a5.06,5.06,0,0,1-.67-.72,3.83,3.83,0,0,1-5.37,1h0a3.42,3.42,0,0,1-1-1,3.15,3.15,0,0,1-5.51-1.07c-1.55,1-4.07,1.1-3.59-.5-2.14-1.66-1.31-3.06-.24-4.35a4.18,4.18,0,0,1-.52-5.75c-.93-2.64-1.74-3.54-.36-5.23-.74-2.38-1.28-3.31-.33-5.49a7.68,7.68,0,0,1-.34-6.26,5.54,5.54,0,0,1,.38-7.39,3.59,3.59,0,0,1-.38-.21,3.52,3.52,0,0,1-1.38-4.47s0-.05,0-.08a3.34,3.34,0,0,1,.81-1.09c-2.52-1.38-3.19-6.7-1.83-8.82,4.75-11.89,2-23.35,9.51-30.91,2.37-2.26,8-1.45,10.17-3.59,4-4,15.6-2.76,13.77,5.52l.12.24c2.23,2.18,2,6.58-.5,7.63a6.57,6.57,0,0,1,1.3,8,4.71,4.71,0,0,1,.31,7.66A6.9,6.9,0,0,1,181.7,190.63Z"
        />
        <path
          className="cls-3"
          d="M131.61,216.19c1,2.18.4,3.13-.31,5.51,1.36,1.64.57,2.59-.33,5.21a4.2,4.2,0,0,1-.53,5.75c1.07,1.31,1.91,2.71-.26,4.35.48,1.6-2.07,1.52-3.59.5a3,3,0,0,1-.88,1.36,3.19,3.19,0,0,1-4.49-.29,3.42,3.42,0,0,1-1,1h0a3.84,3.84,0,0,1-5.37-1,3.86,3.86,0,0,1-.59.62s0,0,0,0a4.44,4.44,0,0,1-6.25-.64c-4.42,1.4-4.92-2.66-3.09-5a4,4,0,0,1-.6-5.51,6.63,6.63,0,0,1-.19-6.35,8,8,0,0,0,2.76-2.76c.74-1.52,2.83-1.26,3.35-2.54s2.55,1.28,2.81,0a5.61,5.61,0,0,1,1.16-2.55c.93-1.52,3.17-1.26,4.19-2.8s2.54-.5,2-3.43,5.87-3.94,5.87-3.94c2-4.35,5.38-1.14,5.38-1.14l.64.8.05.07a5.74,5.74,0,0,1-.29,6.54A7.68,7.68,0,0,1,131.61,216.19Z"
        />
        <path
          className="cls-3"
          d="M176.83,234.06c1.83,2.38,1.33,6.45-3.07,5a4.37,4.37,0,0,1-6.18.58h0a5.06,5.06,0,0,1-.67-.72,3.83,3.83,0,0,1-5.37,1h0a3.42,3.42,0,0,1-1-1,3.15,3.15,0,0,1-5.51-1.07c-1.55,1-4.07,1.1-3.59-.5-2.14-1.66-1.31-3.06-.24-4.35a4.18,4.18,0,0,1-.52-5.75c-.93-2.64-1.74-3.54-.36-5.23-.74-2.38-1.28-3.31-.33-5.49a7.68,7.68,0,0,1-.34-6.26,5.54,5.54,0,0,1,.38-7.39,3.59,3.59,0,0,1-.38-.21l.15-.19s3.35-3.21,5.39,1.14c0,0,6.37,1,5.85,3.94s1,1.9,2.05,3.43,3.23,1.28,4.16,2.8.9,1.29,1.16,2.55,2.31-1.26,2.83,0,2.59,1,3.33,2.54a8.14,8.14,0,0,0,2.76,2.76l.54.28a.9.9,0,0,1-.16.24,6.63,6.63,0,0,1-.19,6.35A4,4,0,0,1,176.83,234.06Z"
        />
        <path
          className="cls-4"
          d="M180.28,160.69s0,0,0,.05a3.73,3.73,0,0,1-3.3,2,1.34,1.34,0,0,1,.09.31c1.12,3.5.05,6.23-3.4,6.83a4.51,4.51,0,0,1-2.83,5.7,5.15,5.15,0,0,1-.9.22c.43,4.87-3.52,5.37-6.75,4.75a4.59,4.59,0,0,1-3.92,5.14c-.22,0-.41,0-.62,0a4.37,4.37,0,0,1-2.31-.64c-.21,3.52-6,4.45-8.58,3.14a2.47,2.47,0,0,1-.79-.59,7,7,0,0,1-11.48,0c-1.78,2.09-8.49,1.73-8.72-2.22a4.56,4.56,0,0,1-6.26-1.64,4.85,4.85,0,0,1-.62-2.35,3.38,3.38,0,0,1,0-.55c-3.21.57-7.13,0-6.78-4.75a4.49,4.49,0,0,1-3.92-5,6.7,6.7,0,0,1,.21-.91c-3.56-.66-4.59-3.49-3.3-7.13a3.7,3.7,0,0,1-2.78-.93l0,0a3.79,3.79,0,0,1-1.21-2.43,3.73,3.73,0,0,1,2.31-3.75c-1.5-1.83-2.38-6.66,1.09-7.47a4.76,4.76,0,0,1-.5-6.61,5.2,5.2,0,0,1,1.78-1.26,5.24,5.24,0,0,1,2.17-6.11c-1.07-1.78-2.24-4.75-.88-6.11,26.27-26.15,53.71-29.27,66.14,5.85h-.09a5.32,5.32,0,0,1,2.16,6.13,4.76,4.76,0,0,1,2.59,6.09,4.55,4.55,0,0,1-1.31,1.76c3.57.83,2.59,5.52,1.12,7.49A3.68,3.68,0,0,1,180.28,160.69Z"
        />
        <path
          className="cls-4"
          d="M103.32,162.19l2.78.93A3.7,3.7,0,0,1,103.32,162.19Z"
        />
        <g className="none">
          <path
            id="path18727-3"
            className="cls-2"
            d="M164.59,132.71c3.06,2,1.71,4.47-1.22,5.09.88,2.95,0,3.59-3,3.59-.47,3.64-3.14,4.75-5.68,3.28.31,3.35-2.21,3.8-4.76,3.28.17,4-3,3.83-5.68,3.26-.88,3.64-6.42,3.11-6.35,0-2.75.52-5.8.69-5.68-3.31-2.52.53-5,0-4.75-3.28-2.55,1.52-5.21.36-5.69-3.28-3,0-3.82-.62-3-3.56-2.92-.6-4.28-3.12-1.19-5.07-22.56-42.44,72-26.58,46.86.12Z"
          />
          <path
            id="path18656"
            className="cls-1"
            d="M125.05,63.74a28,28,0,0,0-13.84,4.75c-3.49.58-9.79,6.35-8.11,10.75-4.13,1.48-5.16,7.61-8.72,9.51,3.92,6.85-5.54,9-2.62,16.64a9.64,9.64,0,0,1,1.5-3.35c-1.4,5.66-2.38,9.51,2.59,12.41-.71-3.56.88,4.5,1.24,6.71,1.61,2.61,3,6.63,5.28,8-2.21-2.37-.53-2.37-.86-3.9,1,5.09,2,7,6.13,8.35-.4-1.69-.52-2.38-.71-3.4,1,3.49,3.4,2.07,7.13-.31C124.5,120.42,135.22,74.7,125,63.69Z"
          />
          <path
            id="path18656-2"
            className="cls-1"
            d="M157.93,63.74a28,28,0,0,1,13.84,4.75c3.49.58,9.79,6.35,8.1,10.75,4.14,1.48,5.19,7.61,8.73,9.51-3.9,6.85,5.54,9,2.61,16.64a9.1,9.1,0,0,0-1.49-3.35c1.4,5.66,2.37,9.51-2.57,12.41.69-3.56-.88,4.5-1.26,6.71-1.62,2.61-2.95,6.63-5.28,8,2.21-2.37.52-2.37.86-3.9-1.05,5.09-2,7-6.11,8.35.38-1.69.49-2.38.69-3.4-1.05,3.49-3.4,2.07-7.14-.31C158.48,120.42,147.73,74.7,158,63.69Z"
          />
          <path
            className="cls-2"
            d="M171.53,93.84c-1.6-11.39-30.77-6.42-30.77-6.42l.12.52-.38-.52s-29.17-5-30.91,6.42a18.12,18.12,0,0,0-2.37,13.43l2.37-3.64s-1.57,7.9.48,10.94v-2.71s3.83,6.28,6.11,9.3,24.23,4.16,24.23,4.16l.33-.76v.76s21.94-1.12,24.23-4.16,6.11-9.3,6.11-9.3v2.71c2-3,.45-10.94.45-10.94l2.38,3.64A18,18,0,0,0,171.53,93.84Z"
          />
          <path
            id="path18650"
            className="cls-5"
            d="M125.86,91.82c-6.4.71-6.11,8.75-1.45,8.3C132.16,99.35,129.73,91.37,125.86,91.82Z"
          />
          <path
            className="cls-6"
            d="M166,107.68c-3-2.38-4.4-8.13-25.06-8.13-17.55,0-22.9,6.6-24.35,8.86-4.06,4.33-4.54,12.87-2.18,15.55,0-2.38.76-6.16,2.37-6.16a11.11,11.11,0,0,0-.28,10.68c.36-3,.47-3.64,2-4.76-1,7.37,1.88,9.51,6.8,13.32-1-2.17-2.37-4.92-1.71-7.13l1-.57v.11c1,2.57,2.19,2.62,4,3.09,2.83,1.76,3.14,3.31,6.59,3.76-.17-1.71-.88-2.21-.88-3.54a6.21,6.21,0,0,0,7.13,2.5,5.55,5.55,0,0,0,7.13-2.38c0,1.31-.74,1.8-.88,3.52,3.45-.43,3.83-2,6.59-3.74,1.8-.47,2.92-.52,4-3.09h0v-.16a9,9,0,0,0,1,.52,8.66,8.66,0,0,1-.24,7.13c4.95-3.75,9.18-7.91,5.33-13.22,1.57,1.14,2.57,4.76,2.9,7.85a14.08,14.08,0,0,0-1.81-14.5c1.53,0,2.38,4.75,4.31,5.82C170.24,122.23,171.34,112,166,107.68Z"
          />
          <path
            id="path18650-8"
            className="cls-5"
            d="M157.14,91.82c6.4.71,6,8.82,1.31,8.39C151.32,99.62,153.32,91.37,157.14,91.82Z"
          />
          <path
            id="path18652"
            className="cls-7"
            d="M141.43,120.73c-7.56.14-10.3-10.87-.43-11.46S149,120.49,141.43,120.73Z"
          />
          <circle className="cls-8" cx="157.81" cy="95.98" r="2.02" />
          <circle className="cls-8" cx="125.24" cy="95.98" r="2.02" />
          <path
            className="cls-4"
            d="M172.69,83l2.1,2.66a13.39,13.39,0,0,0-3.71-9.05l2.37,1.9a18.81,18.81,0,0,0-8.89-9.94l4.4,1.74a15.59,15.59,0,0,0-10.6-5l4.75-.53a30.34,30.34,0,0,0-10.58-2l4.76-.76a33.67,33.67,0,0,0-9.51,0l3.63-2c-4.25-.14-8-.57-10.36,1.1a26.72,26.72,0,0,0-10-1.1l3.64,2a33.67,33.67,0,0,0-9.51,0l4.75.76a30.38,30.38,0,0,0-10.58,2l4.76.53A15.55,15.55,0,0,0,113,70.42l4.38-1.74a19,19,0,0,0-8.89,9.94l2.37-1.9a13.33,13.33,0,0,0-3.73,9.06l2.09-2.66c-3.71,5.06-2.37,12.95-2.37,12.95a21.93,21.93,0,0,1,2.52-7.13,12,12,0,0,0-.72,9.51,11.1,11.1,0,0,1,3.45-8.11,24.48,24.48,0,0,0-.38,8.49,13.31,13.31,0,0,1,6.4-9.39,15.6,15.6,0,0,0-1,7c1-6.37,5.35-7.51,5.35-7.51a6.1,6.1,0,0,0-1.36,4,7.63,7.63,0,0,1,5.42-4c-1.14.38-2,3-2,3a7.12,7.12,0,0,1,8.44-1.71,7.82,7.82,0,0,0-3.36,1,6.5,6.5,0,0,1,6.69,3.64,10.08,10.08,0,0,0-4.38-1.23,7.32,7.32,0,0,1,3.8,3.92,7.41,7.41,0,0,0-4.49.9,7.81,7.81,0,0,1,4.49.31L133.76,100a6.32,6.32,0,0,1,2.65,1h-1.54a12.84,12.84,0,0,1,1.91,1.06h0a2,2,0,0,0-1.37.62,3.54,3.54,0,0,1,1.65.21h0a2.89,2.89,0,0,0-1.15,1.16,3.94,3.94,0,0,1,1.51-.15h0a1.83,1.83,0,0,0-.83.82,3.12,3.12,0,0,1,1.71-.08s-.76-.09-1.46.94a6.68,6.68,0,0,1,3.57-.34,3.19,3.19,0,0,0-1.4.63l1.4-.29-.45.7,1.16-.68,1,.68-.45-.7,1.4.29a3.19,3.19,0,0,0-1.4-.63,6.68,6.68,0,0,1,3.57.34c-.7-1-1.46-.94-1.46-.94a3.12,3.12,0,0,1,1.71.08,1.83,1.83,0,0,0-.83-.82,4,4,0,0,1,1.51.15,2.89,2.89,0,0,0-1.16-1.16,3.59,3.59,0,0,1,1.66-.21,2,2,0,0,0-1.37-.62A12.36,12.36,0,0,1,147.2,101h-1.54a6.32,6.32,0,0,1,2.65-1l-1.92-1.13,0,0v0a7.82,7.82,0,0,1,4.49-.31,7.38,7.38,0,0,0-4.49-.9,7.34,7.34,0,0,1,3.83-3.92,10,10,0,0,0-4.4,1.23,6.47,6.47,0,0,1,6.68-3.64,7.77,7.77,0,0,0-3.35-1,7.13,7.13,0,0,1,8.34,1.57s-.85-2.66-2-3a7.74,7.74,0,0,1,5.44,4,6.31,6.31,0,0,0-1.38-4s4.4,1.15,5.35,7.52a15.6,15.6,0,0,0-1.05-7,13.26,13.26,0,0,1,6.4,9.39,24,24,0,0,0-.38-8.49,11.1,11.1,0,0,1,3.45,8.11,12,12,0,0,0-.72-9.51,21.85,21.85,0,0,1,2.52,7.13S176.47,88.09,172.69,83Z"
          />
          <path
            className="cls-9"
            d="M134.51,89.84s-.36-4.63-3.21-5.8l.93,2.6s-.69-3.26-6.28-3l2,1a10.09,10.09,0,0,0-7.3.65h2.62s-5.16.76-7,3.75l2.38-1.09a8,8,0,0,0-3.47,5.18l1.31-1.69a34.23,34.23,0,0,0-1.31,4.76,12.65,12.65,0,0,1,5.54-5l-1.88,2a17,17,0,0,1,7.82-3.4l-1.85,1.31A86.89,86.89,0,0,1,134.51,89.84Z"
          />
          <path
            className="cls-9"
            d="M148.47,89.84s.35-4.63,3.21-5.8l-.91,2.6s.67-3.26,6.26-3l-1.95,1.1a10.06,10.06,0,0,1,7.29.64h-2.61s5.18.76,7,3.75L164.35,88a8.09,8.09,0,0,1,3.49,5.18l-1.33-1.68a34.09,34.09,0,0,1,1.31,4.75,12.57,12.57,0,0,0-5.54-4.94l1.88,1.94a17,17,0,0,0-7.82-3.4l1.85,1.31A86,86,0,0,0,148.47,89.84Z"
          />
          <path
            className="cls-10"
            d="M201.19,147.33c-.39-.13-6.83-2.24-8.32,5.26-1,4.91-4.68,7.85-7.25,9.33a16,16,0,0,1-2.61,1.26s-4.38-3.74-6,0c0,0-2.66-1.43-3.14.91,0,0-2.77-3.22-4.63.85,0,0-21-19.84-34.58-20.55S112,158.67,112,158.67s-7-6.84-14.15-7.66-8.09,1.05-13.22,2.32l-.41.09a14.58,14.58,0,0,1-3.92.3,15.64,15.64,0,0,1-5.25-1.13s25.51-18.09,50.24-24.87h0a68.57,68.57,0,0,1,13.06-2.4l16.71,2.21s2.29.73,5.9,2C171.29,133,192.24,140.68,201.19,147.33Z"
          />
          <path
            className="cls-11"
            d="M192.87,152.59c-1,4.91-4.68,7.85-7.25,9.33,0,0-21.85-16.82-35.38-22.65S183.12,142.69,192.87,152.59Z"
          />
          <path
            className="cls-11"
            d="M109.62,137.45s-19.67,11-25.38,16a14.58,14.58,0,0,1-3.92.3C80.91,153.23,94.05,142.44,109.62,137.45Z"
          />
          <path
            className="cls-11"
            d="M112.26,146.72s5.54-7.58,8.81-9.27,10.22-4.66,9.52-3.26S112.26,146.72,112.26,146.72Z"
          />
          <path
            className="cls-11"
            d="M161,129.5c-2.62.37-5.8.57-7.33-.29-2.85-1.61-4.38.16-5.92.16s-3-1.44-4.08-1.61-3.86,2.74-5,2.37-.65-3.33-1.61-3.65-10.65,1.08-11.75,1.24a68.57,68.57,0,0,1,13.06-2.4l16.71,2.21S157.4,128.26,161,129.5Z"
          />
        </g>
      </svg>
    </>
  )
}

export default AnimatedDog
