import React from "react"

import { Link } from "~tailwindui/Basics"
import Timeline from "~tailwindui/Timeline"

import { path } from "components/Tutor/TutorRoutes"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"

export type ScheduleProps = {
  sessions: any[]
}

const Schedule: React.FC<ScheduleProps> = ({ sessions }) => {
  if (sessions.length === 0)
    return <span className="italic text-gray-600">No scheduled sessions</span>

  const sessionEvents = sessions.map(session => ({
    timestamp: session.startsAt,
    component: (
      <Link
        to={path("sessionDetails", { id: session.id })}
        className="text-gray-700"
      >
        <span>
          {session.directory ? (
            <DirectoryIcon recordType={"session"} />
          ) : (
            <AirtutorsIcon recordType={"session"} />
          )}
          {session.subject?.name} (
          {session.studyGroup
            ? session.studyGroup?.name
            : session.students[0].fullName}
          )
        </span>
      </Link>
    ),
  }))

  return <Timeline items={sessionEvents} />
}

export default Schedule
