import React, { useState } from "react"

import dayjs from "dayjs"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { gql, useQuery } from "urql"

import {
  ChartBarIcon,
  CheckCircleIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid"

import { Color, LayoutDirection, ValueFormat } from "~tailwindui/types/enums"

import AlertMessageBox from "~tailwindui/Basics/AlertMessageBox"
import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"
import Chip from "~tailwindui/Chip"
import Table from "~tailwindui/Table/Table"

import { MatchParams } from "src/types/MatchParams"

import LocalTime from "components/LocalTime"
import DataDateRange from "components/OrganizationAdmin/components/DataDateRange"
import DisplayableFormikForm from "components/OrganizationAdmin/components/DisplayableFormikForm"
import Form from "components/OrganizationAdmin/components/Form"
import Header from "components/OrganizationAdmin/components/Form/Header"
import { studentPresenceIcon } from "components/OrganizationAdmin/components/presenceIcon"

const StudentDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const [pastSessionsPage, setPastSessionsPage] = useState(1)
  const [futureSessionsPage, setFutureSessionsPage] = useState(1)

  const defaultStartingAt = dayjs().startOf("year")
  const defaultEndingAt = dayjs()
  const [startingAt, setStartingAt] = useState(defaultStartingAt)
  const [endingAt, setEndingAt] = useState(defaultEndingAt)

  const [result] = useQuery({
    query: studentQuery,
    variables: { id, startingAt, endingAt },
  })
  const [pastSessionsResult] = useQuery({
    query: pastSessionsQuery,
    variables: { id, pastSessionsPage },
  })
  const [futureSessionsResult] = useQuery({
    query: futureSessionsQuery,
    variables: { id, futureSessionsPage },
  })

  return (
    <>
      <WithLoadingIndicator result={result}>
        {({ data: { student } }) => <Header title={student.fullName} />}
      </WithLoadingIndicator>

      <DataDateRange
        startDate={startingAt}
        setStartDate={setStartingAt}
        endDate={endingAt}
        setEndDate={setEndingAt}
      />
      <WithLoadingIndicator result={result}>
        {({ data: { student } }) =>
          student === null ? (
            <AlertMessageBox>
              You do not have access to this student
            </AlertMessageBox>
          ) : (
            <>
              <div className="mx-auto w-full max-w-7xl grow-0 lg:flex xl:px-2">
                <Chip.List
                  heading=""
                  layoutDirection={LayoutDirection.Horizontal}
                >
                  <Chip.Item
                    label="Sessions Attended"
                    value={student.presenceCount}
                    valueFormat={ValueFormat.Number}
                    Icon={UserCircleIcon}
                    iconColor={Color.Green}
                  />
                  <Chip.Item
                    label="Attendance Rate"
                    value={student.attendanceRate}
                    valueFormat={ValueFormat.Percent}
                    Icon={ChartBarIcon}
                    iconColor={Color.Blue}
                  />
                  <Chip.Item
                    label="No Shows"
                    value={student.rangedNoShowCount}
                    valueFormat={ValueFormat.Number}
                    Icon={XMarkIcon}
                    iconColor={Color.Red}
                  />
                  <Chip.Item
                    label="Excused Absences"
                    value={student.excusedAbsenceCount}
                    valueFormat={ValueFormat.Number}
                    Icon={CheckCircleIcon}
                    iconColor={Color.Yellow}
                  />
                </Chip.List>
              </div>
              <div className="flex flex-col"></div>

              <DisplayableFormikForm.Wrapper
                editable={false}
                initialValues={student}
                title=""
                displayData={student}
                layoutDirection={LayoutDirection.Horizontal}
              >
                <>
                  <Form.Section title="Student Information">
                    <DisplayableFormikForm.TextInput
                      label="School"
                      name="school.name"
                    />
                    <DisplayableFormikForm.TextInput
                      label="Grade"
                      name="grade.name"
                    />
                    <DisplayableFormikForm.TextInput
                      label="Email"
                      name="email"
                    />
                  </Form.Section>

                  <Form.Section title="Parent Information">
                    <DisplayableFormikForm.TextInput
                      label="Name"
                      name="parentName"
                    />
                    <DisplayableFormikForm.TextInput
                      label="Email"
                      name="parentEmail"
                    />
                    <DisplayableFormikForm.TextInput
                      label="Phone Number"
                      name="parentPhoneNumber"
                    />
                  </Form.Section>
                </>
              </DisplayableFormikForm.Wrapper>
            </>
          )
        }
      </WithLoadingIndicator>

      <WithLoadingIndicator result={futureSessionsResult}>
        {({ data: { student } }) =>
          student === null ? null : (
            <Table
              data={student.futureSessions}
              changePage={setFutureSessionsPage}
              title="Upcoming Sessions"
              headers={["Subject", "Date", "Scheduled Duration", "Tutor"]}
              valueMapper={session => [
                session.subject.name,
                <LocalTime
                  key="startsAt"
                  timestamp={session.startsAt}
                  format="ddd lll"
                />,
                `${session.scheduledDurationMinutes} minutes`,
                session.tutor?.fullName,
              ]}
              rowCallback={session =>
                (window.location.href = `/organization_admin/sessions/${session.id}`)
              }
            />
          )
        }
      </WithLoadingIndicator>

      <WithLoadingIndicator result={pastSessionsResult}>
        {({ data: { student } }) =>
          student === null ? null : (
            <Table
              data={student.pastSessions}
              changePage={setPastSessionsPage}
              title="Recent Sessions"
              headers={["Subject", "Date", "Tutor", "Present?", "Duration"]}
              valueMapper={session => [
                session.subject.name,
                <LocalTime
                  key="startsAt"
                  timestamp={session.startsAt}
                  format="ddd lll"
                />,
                session.tutor?.fullName,
                studentPresenceIcon(session.studentSession, session),
                session.formattedActualDuration,
              ]}
              rowCallback={session =>
                (window.location.href = `/organization_admin/sessions/${session.id}`)
              }
            />
          )
        }
      </WithLoadingIndicator>
    </>
  )
}

const sessionFields = gql`
  fragment SessionFields on AirtutorsSession {
    id
    startsAt
    endsAt
    statusState
    billState
    tutor {
      id
      fullName
    }
    subject {
      id
      name
    }
  }
`

const studentQuery = gql`
  query StudentDetails($id: ID!, $startingAt: DateTime!, $endingAt: DateTime!) {
    viewer {
      id
      organization {
        id
        activeSemester {
          startsOn
          endsOn
        }
      }
    }
    student(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      parentName
      parentEmail
      parentPhoneNumber
      rangedNoShowCount(startDate: $startingAt, endDate: $endingAt)
      excusedAbsenceCount(startDate: $startingAt, endDate: $endingAt)
      presenceCount(startDate: $startingAt, endDate: $endingAt)
      attendanceRate(startDate: $startingAt, endDate: $endingAt)

      school {
        id
        name
      }

      subjects {
        id
        name
      }

      grade {
        id
        name
      }
    }
  }
`

const pastSessionsQuery = gql`
  ${sessionFields}
  query pastStudentSessions($id: ID!, $pastSessionsPage: Int!) {
    student(id: $id) {
      pastSessions: airtutorsSessions(page: $pastSessionsPage, past: true) {
        totalPages
        currentPage
        data {
          ...SessionFields
          formattedActualDuration
          studentSession(studentId: $id) {
            noShow
            excusedAbsence
          }
        }
      }
    }
  }
`

const futureSessionsQuery = gql`
  ${sessionFields}
  query futureStudentSessions($id: ID!, $futureSessionsPage: Int!) {
    student(id: $id) {
      futureSessions: airtutorsSessions(
        page: $futureSessionsPage
        per: 5
        future: true
      ) {
        totalPages
        currentPage
        data {
          ...SessionFields
          scheduledDurationMinutes
        }
      }
    }
  }
`

export default withRouter(StudentDetails)
