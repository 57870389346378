import React, { createRef, useEffect, useRef } from "react"

import { PieChart as ApachePieChart } from "echarts/charts"
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import * as echarts from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"

type PieChartProps = {
  title: string
  data: { value: number; name: string }[]
}

const PieChart: React.FC<PieChartProps> = ({ title, data }) => {
  const chartOpts = {
    color: ["#91cc75", "#fac858", "#ee6666"],
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: title,
        type: "pie",
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: { show: true, position: "inner" },
      },
    ],
  }
  const chartContainer = createRef<HTMLDivElement>()
  const chartObject = useRef<echarts.ECharts>()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    echarts.use([
      ApachePieChart,
      CanvasRenderer,
      GridComponent,
      TitleComponent,
      TooltipComponent,
      LegendComponent,
    ])
    chartObject.current = echarts.init(chartContainer.current)

    return () => {
      if (chartContainer.current) {
        echarts.dispose(chartContainer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (chartOpts) chartObject.current.setOption(chartOpts)
  }, [data])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const resize = () => {
      if (chartObject.current) chartObject.current.resize()
    }
    window.addEventListener("resize", resize)

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  return <div className="h-[35vh] w-[35vh]" ref={chartContainer} />
}

export default PieChart
