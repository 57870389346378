import React, { useRef, useState } from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import ordered from "src/ordered"

import EditableList from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, RadioGroup, SubmitButton } from "components/Forms/Formik"
import RemoveButton from "components/RemoveButton"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  hours: Yup.number().required(),
  price: Yup.number().required(),
  hourlyRate: Yup.number().required(),
})

const NewPrepaidPackage = props => {
  const [errorMessage, setErrorMessage] = useState()
  const partnerRef = useRef()
  const formState = {
    name: "",
    hours: 0,
    price: 0,
    hourlyRate: 0,
    amp: false,
    partners: [],
  }

  const { runMutation } = useMutation(
    buildMutation(createPrepaidPackageMutation)
  )

  const handleSubmit = (values, actions) => {
    const { name, hours, price, hourlyRate, partners, amp } = values
    const partnerIds = partners.map(partner => partner.id)
    runMutation({ name, hours, price, hourlyRate, amp, partnerIds })
      .then(
        response => {
          actions.setSubmitting(false)
          if (response.errors) {
            setErrorMessage(JSON.stringify(response.errors))
          } else if (response.createPrepaidPackage.errorMessages) {
            setErrorMessage(
              response.createPrepaidPackage.errorMessages.join(", ")
            )
          } else {
            window.location.href =
              response.createPrepaidPackage.prepaidPackage.showPath
          }
        },
        err => {
          actions.setSubmitting(false)
          setErrorMessage("Something went wrong")
        }
      )
      .catch(err => {
        actions.setSubmitting(false)
        setErrorMessage("Something went wrong")
      })
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          <div className="form-group">
            <label className="form-label">
              Name
              <Field name="name" className="form-control" />
              <ErrorMessage name="name" />
            </label>
          </div>

          <div className="form-group">
            <label className="form-label">
              Hours
              <Field name="hours" className="form-control" />
              <ErrorMessage name="hours" />
            </label>
          </div>

          <div className="form-group">
            <label className="form-label">
              Price
              <Field name="price" className="form-control" />
              <ErrorMessage name="price" />
            </label>
          </div>

          <div className="form-group">
            <label className="form-label">
              Hourly Rate
              <Field name="hourlyRate" className="form-control" />
              <ErrorMessage name="hourlyRate" />
            </label>
          </div>

          <div className="form-group">
            <RadioGroup
              type="checkbox"
              values={[{ value: true, label: "AMP" }]}
              name="amp"
            />
          </div>

          <div className="form-group">
            <label className="form-label w-100">
              Permitted Partners
              <FieldArray name="partners">
                {arrayHelpers => (
                  <>
                    <AutocompleteSelect
                      ref={partnerRef}
                      api="/api/admins/partners/autocomplete_full_name"
                      onChange={selectedOption => {
                        if (!selectedOption?.value) return
                        const selectedId = String(selectedOption.value)
                        arrayHelpers.push({
                          id: selectedId,
                          fullName: selectedOption.label,
                        })
                        setTimeout(() => partnerRef.current.clearValue(), 0)
                      }}
                      placeholder="Add Partner"
                    />

                    <div className="mt-3">
                      <EditableList>
                        {ordered(values.partners, "fullName").map(
                          ({ id, fullName }) => (
                            <li key={id}>
                              <RemoveButton
                                onClick={() =>
                                  arrayHelpers.remove(
                                    values.partners.findIndex(s => s.id === id)
                                  )
                                }
                              />
                              <span>{fullName}</span>
                            </li>
                          )
                        )}
                      </EditableList>
                    </div>
                  </>
                )}
              </FieldArray>
              <ErrorMessage name="partners" />
            </label>
          </div>

          <SubmitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

const createPrepaidPackageMutation = compress`
  mutation(
    $name: String!
    $hours: String!
    $price: String!
    $hourlyRate: String!
    $amp: Boolean!
    $partnerIds: [ID!]
  ) {
    createPrepaidPackage(
      name: $name
      hours: $hours
      price: $price
      hourlyRate: $hourlyRate
      amp: $amp
      partnerIds: $partnerIds
    ) {
      errorMessages
      prepaidPackage {
        showPath
      }
    }
  }
`

export default NewPrepaidPackage
