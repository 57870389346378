import React from "react"

import { gql, useQuery } from "urql"

import { Color } from "~tailwindui/types/enums"

import { WithLoadingIndicator } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

import Form from "./Form"

export type ReplacementRequestProps = {
  id: string
}

const ReplacementRequest: React.FC<ReplacementRequestProps> = ({ id }) => {
  const [result] = useQuery({
    query: studyGroupQuery,
    variables: { id },
  })
  return (
    <>
      <Modal.Dialog
        buttonText="Give up group"
        buttonClassNames={classNames(
          "my-2",
          buttonClassNames({ color: Color.Orange })
        )}
      >
        {closeModal => (
          <>
            <Modal.Header>Request Replacement Tutor</Modal.Header>
            <WithLoadingIndicator result={result}>
              {({ data: { studyGroup } }) => (
                <Form studyGroup={studyGroup} closeModal={closeModal} />
              )}
            </WithLoadingIndicator>
          </>
        )}
      </Modal.Dialog>
    </>
  )
}

const studyGroupQuery = gql`
  query StudyGroupQuery($id: ID!) {
    studyGroup(id: $id) {
      id
      imminentSession {
        id
        startsAt
      }
    }
  }
`

export default ReplacementRequest
