import React from "react"

import UnbilledSessions from "components/Tutor/components/Sessions/UnbilledSessions"

export type UnbilledSessionsPageProps = Record<string, never>

const UnbilledSessionsPage: React.FC<UnbilledSessionsPageProps> = () => {
  return <UnbilledSessions />
}

export default UnbilledSessionsPage
