import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import PropTypes from "prop-types"
import * as Yup from "yup"

import {
  FormFailures,
  SubmitButton,
  TextAreaField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  compedReason: Yup.string().required(),
})

const CompSessionButton = ({ id, size = undefined }) => {
  const formState = { compedReason: "", id }
  const { runMutation } = useMutation(buildMutation(compSessionMutation))

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { failures } = response.compAirtutorsSession
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      buttonClassName={`btn btn-danger ${
        size === "normal" ? "" : "btn-sm"
      } red`}
      buttonText="Comp Session"
      modalClassName="bootstrap-modal"
      modalTitle="Comp Session"
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <p className="lead">
                Are you sure? This will refund the billed card.
              </p>

              <TextAreaField name="compedReason" label="Reason" autoFocus />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <SubmitButton text="Comp" />
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const compSessionMutation = compress`
  mutation($id: ID!, $compedReason: String!) {
    compAirtutorsSession(id: $id, compedReason: $compedReason) {
      failures {
        message
      }
    }
  }
`

CompSessionButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default CompSessionButton
