import React, { ReactNode } from "react"

import { ArrowRightIcon } from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import { H3, Link } from "~tailwindui/Basics"
import {
  backgroundColorClassNames,
  borderColorClassNames,
  divideColorClassNames,
  textColorClassNames,
} from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

export type ActionListProps = {
  title: string
  color: Color
  emptyText: string
  listItems: {
    icon: ReactNode
    path: string
    text: string
  }[]
}

const ActionList: React.FC<ActionListProps> = ({
  title,
  color,
  emptyText,
  listItems,
}) => {
  const nonEmptyListItems = listItems.filter(Boolean)
  return (
    <div
      className={classNames(
        "mb-8 mr-10 rounded border border-solid p-4",
        borderColorClassNames(color),
        backgroundColorClassNames(color),
        textColorClassNames(color)
      )}
    >
      <H3 className="text-xl font-bold uppercase">{title}</H3>
      <ul
        className={classNames(
          "mx-4 divide-y text-lg",
          divideColorClassNames(color)
        )}
      >
        {nonEmptyListItems.length > 0 ? (
          nonEmptyListItems.map(({ icon, text, path }, idx) => (
            <li key={idx} className="flex items-center py-4">
              {icon}
              <Link
                className={classNames(
                  "flex items-center",
                  textColorClassNames(color)
                )}
                to={path}
              >
                {text} <ArrowRightIcon className="ml-2 inline-block h-4 w-4" />
              </Link>
            </li>
          ))
        ) : (
          <span>{emptyText}</span>
        )}
      </ul>
    </div>
  )
}

export default ActionList
