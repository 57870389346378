import React, { useContext, useState } from "react"

import { useHistory } from "react-router"
import { gql, useMutation } from "urql"

import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import TutorContext from "~Tutor/TutorContext"
import { AlertMessageBox, Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import { path } from "components/Tutor/TutorRoutes"

export type CalendarInfoModalProps = {
  calendarId: string
}
const CalendarInfoModal: React.FC<CalendarInfoModalProps> = ({
  calendarId,
}) => {
  const history = useHistory()
  const [copied, setCopied] = useState(false)

  const { viewer } = useContext(TutorContext)
  const [error, setError] = useState<string | null>()

  const [, runMutation] = useMutation(dismissCalendarInfoMutation)

  const handleSubmit = () => {
    runMutation({ tutorId: viewer.id })
      .then(result => {
        const { failures } = result.data.dismissCalendarInfo
        if (failures.length > 0) setError("Something went wrong")
        else window.location.href = path("dashboard")
      })
      .catch(() => setError("Something went wrong"))
  }

  return (
    <Modal.Dialog
      hideButton
      isOpen={true}
      closeModal={() => history.push(path("dashboard"))}
    >
      {closeModal => (
        <>
          <Modal.Header>Shared Calendar</Modal.Header>
          <Modal.Body>
            <p>
              In order to add a calendar with your scheduled sessions, follow
              the below instructions:
            </p>
            <ol className="my-4 ml-6 list-decimal">
              <li>
                Login to{" "}
                <a className="text-sky-600" href="https://calendar.google.com">
                  calendar.google.com
                </a>
                .
              </li>
              <li>
                At the bottom of the left sidebar, click the + button next to
                "Other Calendars".
              </li>
              <li>Select "Subscribe to new calendar".</li>
              <li>
                Click the icon below to copy your calendar id:
                <span className="overflow-hide my-2 flex w-fit items-center rounded-md bg-gray-50 py-1 px-2 ring-1 ring-inset ring-gray-500">
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(calendarId)
                      setCopied(true)
                    }}
                    className="h-8 w-8 pr-2"
                  >
                    {copied ? (
                      <ClipboardDocumentCheckIcon className="text-emerald-400" />
                    ) : (
                      <ClipboardDocumentIcon className="text-gray-400" />
                    )}
                  </button>
                  <pre className="truncate sm:max-w-xs">{calendarId}</pre>
                </span>
              </li>
              <li>Paste the calendar id into the "Add Calendar" text box.</li>
              <li>Hit enter.</li>
            </ol>

            <p>
              The shared calendar should now be added to your account! You
              should be able to access it from any other calendar application
              that is linked to your account (ie iOS).
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              {error && <AlertMessageBox>{error}</AlertMessageBox>}
              <Button
                onClick={() => {
                  closeModal()
                  setCopied(false)
                }}
                color={Color.Orange}
              >
                Okay, I'll do it later
              </Button>
              <Button onClick={handleSubmit}>
                Done (Don't remind me again)
              </Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal.Dialog>
  )
}

const dismissCalendarInfoMutation = gql`
  mutation dismissCalendarInfo($tutorId: ID!) {
    dismissCalendarInfo(tutorId: $tutorId) {
      failures {
        message
      }
    }
  }
`

export default CalendarInfoModal
