import React from "react"

import classNames from "src/classNames"

import Form from "components/OrganizationAdmin/components/Form"

export type TextInputProps = {
  label: string
  placeholder?: string
  name: string
  description?: string
  error?: string
  subfield?: boolean
  horizontal?: boolean
}

const TextInput: React.FC<
  TextInputProps & React.HTMLProps<HTMLInputElement>
> = ({
  label,
  name,
  error = "",
  description = "",
  placeholder = "",
  subfield = false,
  horizontal = false,
  ...props
}) => {
  return (
    <div
      className={
        horizontal
          ? "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
          : "mb-3 w-full"
      }
    >
      <label
        htmlFor={name}
        className={classNames(
          "block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5",
          subfield && "ml-8"
        )}
      >
        {label}
      </label>

      <div
        className={classNames(
          !horizontal && "mt-1",
          subfield && "ml-8",
          error && "relative rounded-md shadow-sm"
        )}
      >
        <input
          type="text"
          name={name}
          id={name}
          className={classNames(
            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6",
            error ? "ring-red-300" : "ring-gray-300"
          )}
          placeholder={placeholder}
          aria-describedby={`${name}-description`}
          {...props}
        />
        {error && <Form.ErrorIcon />}
      </div>
      <div
        className={classNames(
          "text-sm",
          horizontal ? "flex h-full items-center" : "mt-1"
        )}
      >
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </div>
  )
}

export default TextInput
