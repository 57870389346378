import React, { useRef, useState } from "react"

import dayjs from "dayjs"
import { useField, useFormikContext } from "formik"
import DatePicker from "react-multi-date-picker"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox } from "~tailwindui/Basics"

import emptyFunction from "src/emptyFunction"

import CloseButton from "./DateInputCloseButton"
import TextInput from "./TextInput"

export type DateRangeInputProps = {
  rangeStartName: string
  rangeEndName: string
  label: string
  displayWarnings: boolean
  description?: string
}

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  rangeStartName,
  rangeEndName,
  label,
  displayWarnings,
  description,
}) => {
  const [datePickerClosed, setDatePickerClosed] = useState(true)
  const [warning, setWarning] = useState<string | null>()
  const datePicker = useRef() as any
  const { setFieldValue } = useFormikContext()
  const [rangeStartField, rangeStartMeta] = useField({ name: rangeStartName })
  const [rangeEndField, rangeEndMeta] = useField({ name: rangeEndName })

  const rangeStartError = rangeStartMeta.error ? rangeStartMeta.error : ""
  const rangeEndError = rangeEndMeta.error ? rangeEndMeta.error : ""

  const openCalendar = () => {
    datePicker.current.openCalendar()
  }

  const updateWarnings = date => {
    if (displayWarnings) {
      if (date.isBefore(dayjs()))
        setWarning("This date is in the past. Is that what you intended?")
      else if (date.diff(dayjs(), "hours") < 48)
        setWarning(
          "This date is less than 2 days away. Please choose a later date if possible."
        )
      else setWarning(null)
    }
  }

  return (
    <div>
      <div className="relative flex w-52">
        <TextInput
          name={`${rangeStartName}-display`}
          label={label}
          value={`${rangeStartField.value?.format("MM/DD/YYYY") || ""} - ${
            rangeEndField.value?.format("MM/DD/YYYY") || ""
          }`}
          onClick={openCalendar}
          onChange={emptyFunction}
          description={description}
          errorMessage={rangeStartError || rangeEndError}
        />

        <div className="absolute left-0 top-14 z-10 origin-top-left">
          <DatePicker
            value={[
              new Date(rangeStartField.value),
              new Date(rangeEndField.value),
            ]}
            ref={datePicker}
            onChange={dates => {
              const dayjsStartDate = dates[0]
                ? dayjs(dates[0].toString())
                : undefined
              const dayjsEndDate = dates[1]
                ? dayjs(dates[1].toString())
                : undefined
              updateWarnings(dayjsStartDate)
              setFieldValue(rangeStartName, dayjsStartDate)
              setFieldValue(rangeEndName, dayjsEndDate)
            }}
            inputClass="hidden"
            editable={false}
            arrow={false}
            range
            onOpen={() => setDatePickerClosed(false)}
            onClose={() => datePickerClosed}
            plugins={[
              <CloseButton
                key="close"
                position="bottom"
                setClosed={setDatePickerClosed}
              />,
            ]}
          />
        </div>
      </div>

      {displayWarnings && warning && (
        <AlertMessageBox className="w-60" level={AlertLevel.Warning}>
          {warning}
        </AlertMessageBox>
      )}
    </div>
  )
}

export default DateRangeInput
