import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"

import AirtutorsIcon from "../AirtutorsIcon"
import DirectoryIcon from "../DirectoryIcon"

export type BilledSessionsProps = Record<never, any>

const BilledSessions: React.FC<BilledSessionsProps> = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: billedSessionsQuery,
    variables: { page },
  })

  return (
    <WithLoadingIndicator
      result={result}
      error="An error occurred while loading your billed sessions"
    >
      {({ data: { viewer } }) => (
        <>
          <Table
            title="Billed Sessions"
            changePage={setPage}
            data={viewer.sessions}
            headers={[
              "Study Group",
              "Subject",
              "Students",
              "Date",
              "Duration",
              "Actions",
            ]}
            valueMapper={session => [
              session.studyGroup && (
                <Link
                  key={session.studyGroup.id}
                  to={path("studyGroupDetails", {
                    id: session.studyGroup.id,
                  })}
                >
                  <span className="flex items-center">
                    {session.directory ? (
                      <DirectoryIcon recordType={"session"} />
                    ) : (
                      <AirtutorsIcon recordType={"session"} />
                    )}{" "}
                    {session.studyGroup.name}
                  </span>
                </Link>
              ),
              session.subject.name,
              <ul key={session.id}>
                {session.students.map(student => (
                  <li key={student.id}>
                    <Link
                      to={path("studentDetails", {
                        id: student.id,
                      })}
                    >
                      {student.fullName}
                    </Link>
                  </li>
                ))}
              </ul>,
              <div key={session.id} className="min-w-[80px]">
                <LocalTime format="ddd MMM D" timestamp={session.startsAt} />
                <br />
                <LocalTime omitDate timestamp={session.startsAt} />
                &mdash;
                <LocalTime omitDate timestamp={session.endsAt} />
              </div>,
              session.formattedBilledDuration,
              <div key={session.id} className="flex items-center space-x-1">
                <Link
                  to={path("sessionDetails", {
                    id: session.id,
                  })}
                  button
                  size={Size.Small}
                >
                  View
                </Link>
              </div>,
            ]}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const billedSessionsQuery = gql`
  query billedSessionsQuery($page: Int!) {
    viewer {
      sessions(filters: { billState: billed }, page: $page) {
        currentPage
        totalPages
        data {
          id
          type
          directory
          airtutors
          startsAt
          endsAt
          formattedBilledDuration
          students {
            id
            fullName
          }
          subject {
            name
          }
          studyGroup {
            id
            name
          }
        }
      }
    }
  }
`

export default BilledSessions
