type StudentData = {
  participationLevel: string
  tutorAssessment: string
}

const noShow = (studentParticipation, tutorAssessment) => {
  if (!studentParticipation || !tutorAssessment) return

  return (
    Object.values(studentParticipation).every(
      (student: StudentData) => student.participationLevel === "absent"
    ) &&
    Object.values(tutorAssessment).every(
      (student: StudentData) => student.tutorAssessment === "absent"
    )
  )
}

export const durationValidator = (values, session) => {
  const {
    durationHours,
    durationMinutes,
    studentParticipation,
    tutorAssessment,
  } = values
  const {
    organization,
    canExceedScheduledDuration,
    roundedScheduledDurationMinutes,
    canBillLongSessions,
  } = session

  if (durationHours === null || durationMinutes === null)
    return "Please select an option for both hours and minutes."

  if (durationHours + durationMinutes / 60 > 3)
    return "Maximum session length is 3 hours"

  const longDuration = durationHours + durationMinutes / 60 > 1.0

  if (longDuration) {
    const allowLongNoShows = organization?.allowLongNoShows

    if (!allowLongNoShows && noShow(studentParticipation, tutorAssessment))
      return "You cannot bill no shows longer than 1 hour for this session"
    else if (!canBillLongSessions)
      return "You cannot bill sessions longer than 1 hour for this study group"
  }

  const totalDuration = durationHours * 60 + durationMinutes
  if (
    !(
      canExceedScheduledDuration ||
      totalDuration <= roundedScheduledDurationMinutes
    )
  )
    return `Duration cannot exceed scheduled duration of ${session.roundedScheduledDurationMinutes} minutes`

  return true
}
