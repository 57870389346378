import * as Yup from "yup"

import { to24HourTime } from "src/dateHelpers"
import dayjs from "src/dayjs"

const ValidationSchema = Yup.object()
  .shape({
    weekday: Yup.string().required("Please specify a weekday"),
    frequency: Yup.string().required("Please specify a frequency"),
  })
  .test("endTime", function (values) {
    const start = dayjs({
      hour: to24HourTime(values.startTime[0], values.startTime[2]),
      minute: values.startTime[1],
    })
    const end = dayjs({
      hour: to24HourTime(values.endTime[0], values.endTime[2]),
      minute: values.endTime[1],
    })

    if (end.isBefore(start)) {
      return this.createError({
        message: "End time must occur after start time",
        path: "endTime",
      })
    }
    if (end.diff(start, "minute") < 30) {
      return this.createError({
        message: "Availability must be at least 30 minutes",
        path: "endTime",
      })
    }
    if (values.endTime[0] === 12 && values.endTime[2] === "am") {
      return this.createError({
        message: "Availability may not end on midnight",
        path: "endTime",
      })
    }

    return true
  })
  .test("startDate", function (values) {
    if (values.weekday !== dayjs(values.startDate).format("dddd"))
      return this.createError({
        message: `Start date must fall on a ${values.weekday}`,
        path: "startDate",
      })
    else return true
  })
  .test("endDate", function (values) {
    if (values.weekday !== dayjs(values.endDate).format("dddd"))
      return this.createError({
        message: `End date must fall on a ${values.weekday}`,
        path: "endDate",
      })
    else if (dayjs(values.endDate).diff(dayjs(values.startDate), "month") > 2)
      return this.createError({
        message: "Availability schedule cannot be longer than 3 months",
        path: "endDate",
      })
    else return true
  })

export default ValidationSchema
