import React from "react"

import { Dayjs } from "dayjs"
import { Form, Formik, FormikBag } from "formik"
import { gql, useQuery } from "urql"
import * as Yup from "yup"

import { WithLoadingIndicator } from "~tailwindui/Basics"
import {
  Failures,
  SelectInput,
  SubmitButton,
  TextAreaInput,
  TextInput,
} from "~tailwindui/Form"

import numberOptions from "src/numberOptions"

import Sections from "./Sections"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter a name"),
  description: Yup.string().required("Please enter a description"),
  subjectId: Yup.string().required("Please select a subject"),
  gradeIds: Yup.array().min(1, "Please select at least 1 grade"),
  prepaidPrice: Yup.number().min(1, "Please specify a price"),
  durationWeeks: Yup.number().min(1, "Please specify a duration in weeks"),
  minimumStudyGroupSize: Yup.number().min(1, "Please specify a min group size"),
  maximumStudyGroupSize: Yup.mixed().when(
    "minimumStudyGroupSize",
    minimumStudyGroupSize => {
      return Yup.number().min(
        minimumStudyGroupSize,
        "Maximum group size must be larger than minimum"
      )
    }
  ),
  courseSections: Yup.mixed().test(
    "courseSections",
    "You must complete your course section before continuing",
    courseSections => !courseSections.some(section => section.draft)
  ),
})

type FormState = {
  name: string
  description: string
  gradeIds: string[]
  subjectId: string
  foreignLanguageId: string
  prepaidPrice: number
  durationWeeks: number
  sessionsPerWeek: number
  minimumStudyGroupSize: number
  maximumStudyGroupSize: number
  courseSections: {
    startsOn: Dayjs
    endsOn: Dayjs
    availabilities: {
      weekday: string
      startsAt: Dayjs
      endsAt: Dayjs
    }[]
    studyGroups: {
      id: string
    }[]
  }[]
}

export type CourseClusterFormProps = {
  onSubmit: (values: FormState, actions: FormikBag<any, FormState>) => void
  courseCluster?: Record<string, any>
}

const CourseClusterForm: React.FC<CourseClusterFormProps> = ({
  onSubmit,
  courseCluster,
}) => {
  const formState = {
    id: courseCluster?.id || undefined,
    name: courseCluster?.name || "",
    description: courseCluster?.description || "",
    gradeIds: courseCluster?.grades.map(grade => grade.id) || [],
    subjectId: courseCluster?.subject.id || undefined,
    foreignLanguageId: courseCluster?.foreignLanguage?.id || undefined,
    prepaidPrice: courseCluster?.courses[0].prepaidPrice || 0,
    durationWeeks: courseCluster?.courses[0].durationWeeks || 0,
    sessionsPerWeek: courseCluster?.courses[0].sessionsPerWeek || 1,
    minimumStudyGroupSize: courseCluster?.courses[0].minimumStudyGroupSize || 1,
    maximumStudyGroupSize: courseCluster?.courses[0].maximumStudyGroupSize || 2,
    courseSections:
      courseCluster?.courses[0].courseSections.map(
        ({ id, studyGroups, startsOn, endsOn, availabilities }) => ({
          id,
          editable: !studyGroups.length,
          startsOn,
          endsOn,
          availabilities: availabilities.map(
            ({ id, startsAt, endsAt, weekday }) => ({
              id,
              startsAt,
              endsAt,
              weekday,
            })
          ),
        })
      ) || [],
  }

  const [result] = useQuery({ query })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <Formik
          initialValues={formState}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form>
            <div className="font-lg my-4 border-b border-solid font-semibold">
              Course Info
            </div>

            <div className="grid grid-cols-2 gap-x-6 gap-y-2">
              <TextInput name="name" label="Course Name" />
              <TextInput
                name="prepaidPrice"
                label="Price (per student)"
                type="number"
              />
              <div className="col-span-2">
                <TextAreaInput name="description" label="Course Description" />
              </div>
              <SelectInput
                multi
                name="gradeIds"
                label="Grades"
                options={viewer.grades.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
              <SelectInput
                name="subjectId"
                label="Subject"
                options={viewer.directorySubjects.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
              {viewer.foreignLanguages.length > 0 && (
                <div className="col-end-2">
                  <SelectInput
                    name="foreignLanguageId"
                    label="Language"
                    options={viewer.foreignLanguages.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                </div>
              )}
              <div className="col-start-1">
                <TextInput
                  name="durationWeeks"
                  label="Duration (in weeks)"
                  type="number"
                />
              </div>
              <SelectInput
                name="sessionsPerWeek"
                label="Sessions Per Week"
                options={numberOptions(1, 7)}
              />
              <SelectInput
                name="minimumStudyGroupSize"
                label="Minimum Group Size"
                options={numberOptions(1, 10)}
                description="Course will be canceled if registrations do not meet this number."
              />
              <SelectInput
                name="maximumStudyGroupSize"
                label="Maximum Group Size"
                options={numberOptions(1, 10)}
                description="Once this number is met, registration will close."
              />
            </div>

            <Sections />

            <div className="mt-4">
              <Failures />
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query ViewerQuery {
    viewer {
      id
      foreignLanguages {
        id
        name
      }
      grades {
        id
        name
      }
      directorySubjects: subjects(approved: true, approvalType: directory) {
        id
        name
      }
    }
  }
`

export default CourseClusterForm
