import React from "react"

import { FieldArray, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import airbrake from "src/airbrake-notifier"
import { weekdayOptions } from "src/enums"
import { studentClient } from "src/graphql-config"
import ordered from "src/ordered"

import { ErrorMessage, RadioGroup, SubmitButton } from "components/Forms/Formik"
import LocalTime from "components/LocalTime"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const ValidationSchema = Yup.object().shape({
  availabilityIds: Yup.array().test(
    "availabilityIds",
    "Please select more availabilities",
    function () {
      const min = this.parent.requiredAvailabilities
      if (this.parent.availabilityIds.length < min) {
        return this.createError({
          message: `Please select at least ${min} availabilities`,
          path: "availabilityIds",
        })
      } else {
        return true
      }
    }
  ),
})

const EditAvailabilities = ({ gradeId }) => {
  const loadingState = useQuery(
    buildQuery(availabilitiesQuery, { gradeId }, { client: studentClient })
  )
  const { runMutation } = useMutation(
    buildMutation(updateAvailabilitiesMutation, { client: studentClient })
  )

  const formState = {
    availabilityIds: [],
  }

  const groupedAvailabilities = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  }

  if (loadingState.data) {
    formState.requiredAvailabilities =
      loadingState.data.organization.minimumAvailabilityWindows
    formState.availabilityIds = loadingState.data.availabilities.map(
      oa => oa.id
    )

    const availabilitySets = loadingState.data.organization.availabilitySets
    availabilitySets.forEach(availabilitySet => {
      availabilitySet.availabilities.forEach(availability => {
        groupedAvailabilities[availability.weekday].push(availability)
      })
    })
  }

  const handleSubmit = (values, actions) => {
    runMutation({
      availabilityIds: values.availabilityIds,
    })
      .then(
        response => {
          const { errorMessages } = response.updateAvailabilities
          if (errorMessages) {
            actions.setStatus(errorMessages)
          } else {
            window.location.href = "/"
          }
        },
        error => {
          actions.setStatus(`Something went wrong: ${error?.message}`)
          airbrake.notify({
            error,
            name: "StudentEditAvailabilities",
          })
        }
      )
      .catch(error => {
        actions.setStatus(`Something went wrong: ${error?.message}`)
        airbrake.notify({
          error,
          name: "StudentEditAvailabilities",
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          {({ status, values, isSubmitting }) => (
            <Form>
              <>
                <h1
                  css={css`
                    text-align: center;
                    font-weight: 500;
                  `}
                >
                  Edit Availabilities
                </h1>
                <div
                  className="form-group"
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <FieldArray name="availabilityIds">
                    {arrayHelpers => (
                      <>
                        <div
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            @media screen and (max-width: 768px) {
                              flex-flow: column;
                            }
                          `}
                        >
                          {weekdayOptions
                            .map(d => d.label)
                            .map(
                              weekday =>
                                groupedAvailabilities[weekday].length > 0 && (
                                  <div
                                    key={weekday}
                                    css={css`
                                      margin: 0 15px;
                                    `}
                                  >
                                    <h4
                                      css={css`
                                        font-weight: 500;
                                      `}
                                    >
                                      {weekday}
                                    </h4>
                                    <RadioGroup
                                      key={weekday}
                                      type="checkbox"
                                      name="availabilityIds"
                                      values={ordered(
                                        groupedAvailabilities[weekday],
                                        "startsAt"
                                      ).map(availability => ({
                                        value: availability.id,
                                        checked:
                                          values.availabilityIds.includes(
                                            availability.id
                                          ),
                                        label: (
                                          <>
                                            <LocalTime
                                              timestamp={availability.startsAt}
                                              format="LT"
                                            />
                                            &mdash;
                                            <LocalTime
                                              timestamp={availability.endsAt}
                                              format="LT"
                                            />
                                          </>
                                        ),
                                      }))}
                                      onChange={e => {
                                        if (e.currentTarget.checked) {
                                          arrayHelpers.push(
                                            e.currentTarget.value
                                          )
                                        } else {
                                          arrayHelpers.remove(
                                            values.availabilityIds.findIndex(
                                              a => a === e.currentTarget.value
                                            )
                                          )
                                        }
                                      }}
                                    />
                                  </div>
                                )
                            )}
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
                <div className="text-center">
                  <ErrorMessage name="availabilityIds" />
                </div>
                <div
                  css={css`
                    margin-top: 30px;
                    text-align: center;
                  `}
                >
                  {status && (
                    <div
                      className="alert"
                      css={css`
                        padding: 9px 15px;
                        margin-bottom: 15px;
                        text-align: left;
                        display: inline-block;
                      `}
                    >
                      {status}
                    </div>
                  )}

                  <SubmitButton
                    isSubmitting={isSubmitting}
                    text="Save"
                    containerCss={css`
                      margin-right: 15px;
                    `}
                  />
                </div>
              </>
            </Form>
          )}
        </Formik>
      )}
    </WithLoadingIndicator>
  )
}

const availabilitiesQuery = compress`
  query($gradeId: ID!) {
    organization {
      minimumAvailabilityWindows
      availabilitySets(gradeId: $gradeId) {
        id
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
    }
    availabilities {
      id
    }
  }
`

const updateAvailabilitiesMutation = compress`
  mutation($availabilityIds: [ID!]!) {
    updateAvailabilities(availabilityIds: $availabilityIds) {
      success
      errorMessages
    }
  }
`

export default EditAvailabilities
