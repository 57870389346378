import React, { useEffect, useState } from "react"

import { Field, Form, Formik } from "formik"
import {
  buildMutation,
  buildQuery,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import * as Yup from "yup"

import axios from "src/axios"

import TutorSubjects from "components/Admin/TutorSubjects"
import Checkbox from "components/Forms/Checkbox"
import { ErrorMessage } from "components/Forms/Formik"
import LoadingIndicator from "components/LoadingIndicator"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import loadTutorQuery from "./loadTutorQuery"
import updateTutorMutation from "./updateTutorMutation"

const schema = Yup.object().shape({
  fullNameReviewFeedback: Yup.mixed().when("fullNameReviewRequired", {
    is: true,
    then: Yup.string().required("please specify a reason for review"),
  }),
  phoneNumberReviewFeedback: Yup.mixed().when("phoneNumberReviewRequired", {
    is: true,
    then: Yup.string().required("please specify a reason for review"),
  }),
  emailTimeZoneReviewFeedback: Yup.mixed().when("emailTimeZoneReviewRequired", {
    is: true,
    then: Yup.string().required("please specify a reason for review"),
  }),
  bioReviewFeedback: Yup.mixed().when("bioReviewRequired", {
    is: true,
    then: Yup.string().required("please specify a reason for review"),
  }),
  educationReviewFeedback: Yup.mixed().when("educationReviewRequired", {
    is: true,
    then: Yup.string().required("please specify a reason for review"),
  }),
  avatarReviewFeedback: Yup.mixed().when("avatarReviewRequired", {
    is: true,
    then: Yup.string().required("please specify a reason for review"),
  }),
  tierIdsReviewFeedback: Yup.mixed().when("tierIdsReviewRequired", {
    is: true,
    then: Yup.string().required("please specify a reason for review"),
  }),
})

const AdminTutorReviewForm = ({ tutorId }) => {
  const [formValues, setFormValues] = useState({
    fullNameReviewRequired: false,
    fullNameReviewFeedback: "",
    phoneNumberReviewRequired: false,
    phoneNumberReviewFeedback: "",
    emailTimeZoneReviewRequired: false,
    emailTimeZoneReviewFeedback: "",
    bioReviewRequired: false,
    bioReviewFeedback: "",
    educationReviewRequired: false,
    educationReviewFeedback: "",
    avatarReviewRequired: false,
    avatarReviewFeedback: "",
    tierIdsReviewRequired: false,
    tierIdsReviewFeedback: "",
  })

  const loadingState = useQuery(buildQuery(loadTutorQuery, { tutorId }))
  const { loaded, data } = loadingState

  const { running, runMutation } = useMutation(
    buildMutation(updateTutorMutation)
  )

  useEffect(() => {
    if (loaded && data) {
      setFormValues({
        fullNameReviewRequired: data.tutor.fullNameReviewRequired,
        fullNameReviewFeedback: data.tutor.fullNameReviewFeedback,
        phoneNumberReviewRequired: data.tutor.phoneNumberReviewRequired,
        phoneNumberReviewFeedback: data.tutor.phoneNumberReviewFeedback,
        emailTimeZoneReviewRequired: data.tutor.emailTimeZoneReviewRequired,
        emailTimeZoneReviewFeedback: data.tutor.emailTimeZoneReviewFeedback,
        bioReviewRequired: data.tutor.bioReviewRequired,
        bioReviewFeedback: data.tutor.bioReviewFeedback,
        educationReviewRequired: data.tutor.educationReviewRequired,
        educationReviewFeedback: data.tutor.educationReviewFeedback,
        avatarReviewRequired: data.tutor.avatarReviewRequired,
        avatarReviewFeedback: data.tutor.avatarReviewFeedback,
        tierIdsReviewRequired: data.tutor.tierIdsReviewRequired,
        tierIdsReviewFeedback: data.tutor.tierIdsReviewFeedback,
      })
    }
  }, [data, loaded])

  const handleSubmit = (values, actions) => {
    runMutation({ tutorId, ...values })
      .then(response => {
        if (response.updateTutorReview.success === "true") {
          axios
            .post("/api/set_flash", {
              type: "notice",
              message: "Tutor updated successfully",
            })
            .then(response => {
              window.location.href = `/admin/tutors/${tutorId}`
            })
        } else {
          actions.setStatus("Unable to update tutor")
        }
      })
      .catch(err => {
        actions.setStatus("Unable to update tutor")
      })
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading the tutor"
    >
      {({ data }) => {
        const { tutor, tiers } = data
        return (
          <div className="row">
            <div className="col-12 col-md-6">
              <Formik
                initialValues={formValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={schema}
              >
                {({ values, status }) => (
                  <Form>
                    <div className="row mb-4">
                      <div className="col-6">
                        <Field name="fullNameReviewRequired">
                          {({ field: { onChange, name, value } }) => (
                            <Checkbox
                              onChange={onChange}
                              name={name}
                              checked={value}
                              value={true}
                            >
                              Full Name needs review
                            </Checkbox>
                          )}
                        </Field>
                        {values.fullNameReviewRequired && (
                          <Field
                            name="fullNameReviewFeedback"
                            type="string"
                            className="form-control"
                          />
                        )}
                        <ErrorMessage name="fullNameReviewFeedback" />
                      </div>
                      <div className="col-6">{tutor.fullName}</div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <Field name="phoneNumberReviewRequired">
                          {({ field: { onChange, name, value } }) => (
                            <Checkbox
                              onChange={onChange}
                              name={name}
                              checked={value}
                              value={true}
                            >
                              Phone Number needs review
                            </Checkbox>
                          )}
                        </Field>
                        {values.phoneNumberReviewRequired && (
                          <Field
                            name="phoneNumberReviewFeedback"
                            type="string"
                            className="form-control"
                          />
                        )}
                        <ErrorMessage name="phoneNumberReviewFeedback" />
                      </div>
                      <div className="col-6">{tutor.phoneNumber}</div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <Field name="emailTimeZoneReviewRequired">
                          {({ field: { onChange, name, value } }) => (
                            <Checkbox
                              onChange={onChange}
                              name={name}
                              checked={value}
                              value={true}
                            >
                              Email Time Zone needs review
                            </Checkbox>
                          )}
                        </Field>
                        {values.emailTimeZoneReviewRequired && (
                          <Field
                            name="emailTimeZoneReviewFeedback"
                            type="string"
                            className="form-control"
                          />
                        )}
                        <ErrorMessage name="emailTimeZoneReviewFeedback" />
                      </div>
                      <div className="col-6">{tutor.emailTimeZone}</div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <Field name="bioReviewRequired">
                          {({ field: { onChange, name, value } }) => (
                            <Checkbox
                              onChange={onChange}
                              name={name}
                              checked={value}
                              value={true}
                            >
                              Bio needs review
                            </Checkbox>
                          )}
                        </Field>
                        {values.bioReviewRequired && (
                          <Field
                            name="bioReviewFeedback"
                            type="string"
                            className="form-control"
                          />
                        )}
                        <ErrorMessage name="bioReviewFeedback" />
                      </div>
                      <div className="col-6">
                        {tutor.bio &&
                          tutor.bio
                            .split("\n")
                            .map((str, idx) => <p key={idx}>{str}</p>)}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <Field name="educationReviewRequired">
                          {({ field: { onChange, name, value } }) => (
                            <Checkbox
                              onChange={onChange}
                              name={name}
                              checked={value}
                              value={true}
                            >
                              Education needs review
                            </Checkbox>
                          )}
                        </Field>
                        {values.educationReviewRequired && (
                          <Field
                            name="educationReviewFeedback"
                            type="string"
                            className="form-control"
                          />
                        )}
                        <ErrorMessage name="educationReviewFeedback" />
                      </div>
                      <div className="col-6">
                        {tutor.education &&
                          tutor.education
                            .split("\n")
                            .map((str, idx) => <p key={idx}>{str}</p>)}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <Field name="avatarReviewRequired">
                          {({ field: { onChange, name, value } }) => (
                            <Checkbox
                              onChange={onChange}
                              name={name}
                              checked={value}
                              value={true}
                            >
                              Avatar needs review
                            </Checkbox>
                          )}
                        </Field>
                        {values.avatarReviewRequired && (
                          <Field
                            name="avatarReviewFeedback"
                            type="string"
                            className="form-control"
                          />
                        )}
                        <ErrorMessage name="avatarReviewFeedback" />
                      </div>
                      <div className="col-6">
                        {tutor.avatar && (
                          <a
                            target="_blank"
                            href={tutor.avatar.originalUrl}
                            rel="noopener noreferrer"
                          >
                            <img src={tutor.avatar.thumbUrl} alt="thumbnail" />
                          </a>
                        )}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <Field name="tierIdsReviewRequired">
                          {({ field: { onChange, name, value } }) => (
                            <Checkbox
                              onChange={onChange}
                              name={name}
                              checked={value}
                              value={true}
                            >
                              Grade Range needs review
                            </Checkbox>
                          )}
                        </Field>
                        {values.tierIdsReviewRequired && (
                          <Field
                            name="tierIdsReviewFeedback"
                            type="string"
                            className="form-control"
                          />
                        )}
                        <ErrorMessage name="tierIdsReviewFeedback" />
                      </div>
                      <div className="col-6">
                        {tiers.map(tier => (
                          <Checkbox
                            key={tier.id}
                            checked={tutor.tiers
                              .map(t => t.id)
                              .includes(tier.id)}
                            disabled={true}
                          >
                            {tier.gradeRange}
                          </Checkbox>
                        ))}
                      </div>
                    </div>

                    <div className="d-inline-flex align-items-center">
                      <button type="submit" className="btn btn-danger">
                        Submit Items for Review
                      </button>
                      {running && !status && <LoadingIndicator size="small" />}
                      {status && (
                        <span className="alert text-danger m-0">{status}</span>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="col-12 col-md-6">
              <TutorSubjects tutorId={tutorId} />
            </div>
          </div>
        )
      }}
    </WithLoadingIndicator>
  )
}

export default AdminTutorReviewForm
