import React from "react"

import { Dayjs } from "dayjs"
import { Form, Formik } from "formik"
import { gql, useMutation } from "urql"

import { CalendarIcon } from "@heroicons/react/24/outline"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button, H3 } from "~tailwindui/Basics"
import {
  CheckboxInput,
  DateRangeInput,
  Failures,
  SelectInput,
  SubmitButton,
  TimeInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"

import { isLocal, to12HourTime, to24HourTime, weekdays } from "src/dateHelpers"
import dayjs from "src/dayjs"
import titlecase from "src/titlecase"
import { VoidReturn } from "src/types/VoidReturn"

import LocalTime from "components/LocalTime"

import { TutorSchedule } from "./TutorScheduleType"
import ValidationSchema from "./validator"

export type ScheduleModalProps = {
  open: boolean
  mode: "edit" | "new"
  closeModal: VoidReturn
  defaultStart?: Dayjs
  defaultEnd?: Dayjs
  schedule: TutorSchedule
  afterCreate: VoidReturn
  viewerTimeZone: string
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({
  open,
  mode,
  closeModal,
  defaultStart,
  defaultEnd,
  schedule,
  afterCreate,
  viewerTimeZone,
}) => {
  const initialStartTime = schedule
    ? [
        to12HourTime(schedule.startHour),
        schedule.startMinute,
        schedule.startHour < 12 ? "am" : "pm",
      ]
    : defaultStart
    ? [
        to12HourTime(defaultStart.hour()),
        defaultStart.minute(),
        defaultStart.hour() < 12 ? "am" : "pm",
      ]
    : [12, 0, "pm"]

  const initialEndTime = schedule
    ? [
        to12HourTime(schedule.endHour),
        schedule.endMinute,
        schedule.endHour < 12 ? "am" : "pm",
      ]
    : defaultEnd
    ? [
        to12HourTime(defaultEnd.hour()),
        defaultEnd.minute(),
        defaultEnd.hour() < 12 ? "am" : "pm",
      ]
    : [12, 0, "pm"]

  const initialValues = {
    weekday:
      titlecase(schedule?.weekday) || defaultStart?.format("dddd") || "Monday",
    startTime: initialStartTime,
    endTime: initialEndTime,
    startDate: (schedule && dayjs(schedule.startDate)) || dayjs(defaultStart),
    endDate:
      (schedule && dayjs(schedule.endDate)) ||
      dayjs(defaultStart).add(12, "weeks"),
    frequency: schedule?.frequency || "weekly",
  }

  const [, runDeleteMutation] = useMutation(deleteScheduleMutation)
  const [, runUpdateMutation] = useMutation(updateScheduleMutation)
  const [, runCreateMutation] = useMutation(createScheduleMutation)

  const handleDelete = (setStatus, setSubmitting) => {
    runDeleteMutation({ scheduleId: schedule.id })
      .then(
        result => {
          const { failures } = result.data.deleteTutorSchedule
          if (failures.length > 0) {
            setSubmitting(false)
            setStatus(failures)
          } else closeModal()
        },
        err => {
          setStatus([{ message: "Something went wrong" }])
          setSubmitting(false)
        }
      )
      .catch(err => {
        setStatus([{ message: "Something went wrong" }])
        setSubmitting(false)
      })
  }

  const convertValuesToInput = values => {
    const { startTime, endTime, startDate, endDate, ...rest } = values
    const [startHour, startMinute, startMeridian] = startTime
    const [endHour, endMinute, endMeridian] = endTime

    return {
      id: schedule?.id,
      startHour: to24HourTime(startHour, startMeridian),
      startMinute,
      endHour: to24HourTime(endHour, endMeridian),
      endMinute,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      ...rest,
    }
  }

  const handleUpdate = (values, actions) => {
    runUpdateMutation({
      input: convertValuesToInput(values),
    })
      .then(
        result => {
          const { failures } = result.data.updateTutorSchedule
          if (failures.length > 0) handleFailure(actions, failures)
          else {
            closeModal()
          }
        },
        err => handleFailure(actions)
      )
      .catch(err => handleFailure(actions))
  }

  const handleCreate = (values, actions) => {
    runCreateMutation({ input: convertValuesToInput(values) })
      .then(
        result => {
          const { failures } = result.data.createTutorSchedule
          if (failures.length > 0) handleFailure(actions, failures)
          else {
            afterCreate()
            closeModal()
          }
        },
        err => handleFailure(actions)
      )
      .catch(err => handleFailure(actions))
  }

  const weekdayOptions = weekdays.map(weekday => ({
    label: weekday,
    value: weekday,
  }))

  return (
    <Modal.Dialog hideButton isOpen={open} closeModal={closeModal}>
      <Modal.Header Icon={CalendarIcon}>
        {mode === "edit" ? "Update" : "New"} Availability
      </Modal.Header>
      <Formik
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={mode === "edit" ? handleUpdate : handleCreate}
      >
        {({ values, setStatus, setSubmitting }) => (
          <Form className="flex max-h-[calc(70vh-90px)] flex-col">
            <Modal.Body>
              {!isLocal(viewerTimeZone) && (
                <AlertMessageBox level={AlertLevel.Warning}>
                  Please keep in mind that all times are shown in{" "}
                  {dayjs().tz(viewerTimeZone).format("zzz")}, which is outside
                  of your current timezone.
                </AlertMessageBox>
              )}

              {values.frequency !== "nonrecurring" ? (
                <SelectInput
                  name="weekday"
                  label="Weekday"
                  options={weekdayOptions}
                  className="w-6"
                  portal
                  description="Start and end date must fall on this day"
                />
              ) : (
                <H3>
                  <LocalTime omitTime timestamp={defaultStart.toString()} /> (
                  {defaultStart?.format("dddd")})
                </H3>
              )}

              <TimeInput name="startTime" label="Start Time" arrayValue />
              <TimeInput name="endTime" label="End Time" arrayValue />

              <CheckboxInput
                name="frequency"
                checkboxType="radio"
                options={[
                  { label: "Do Not Repeat", value: "nonrecurring" },
                  { label: "Every 2 Weeks", value: "biweekly" },
                  { label: "Weekly", value: "weekly" },
                ]}
              />

              {values.frequency !== "nonrecurring" && (
                <div className="flex space-x-4">
                  <DateRangeInput
                    rangeStartName="startDate"
                    rangeEndName="endDate"
                    label="Effective"
                    displayWarnings={false}
                    description="Maximum 3 months"
                  />
                </div>
              )}

              {mode === "edit" && (
                <Button
                  onClick={() => handleDelete(setStatus, setSubmitting)}
                  color={Color.Red}
                >
                  Delete Availability
                </Button>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="flex flex-col">
                <Failures />
                <div className="flex justify-end space-x-2">
                  <Button onClick={closeModal} color={Color.Red}>
                    Cancel
                  </Button>
                  <SubmitButton>Save</SubmitButton>
                </div>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal.Dialog>
  )
}

const deleteScheduleMutation = gql`
  mutation deleteTutorSchedule($scheduleId: ID!) {
    deleteTutorSchedule(scheduleId: $scheduleId) {
      failures {
        message
      }
      tutorSchedule {
        id
      }
    }
  }
`

const updateScheduleMutation = gql`
  mutation updateTutorSchedule($input: TutorScheduleInputObject!) {
    updateTutorSchedule(input: $input) {
      failures {
        message
      }
      tutorSchedule {
        id
        startHour
        startMinute
        endHour
        endMinute
        dayjsTimeZone
        weekday
        startDate
        endDate
        frequency
      }
    }
  }
`

const createScheduleMutation = gql`
  mutation createTutorSchedule($input: TutorScheduleInputObject!) {
    createTutorSchedule(input: $input) {
      failures {
        message
      }
      tutorSchedule {
        id
        startHour
        startMinute
        endHour
        endMinute
        dayjsTimeZone
        weekday
        startDate
        endDate
        frequency
      }
    }
  }
`

export default ScheduleModal
