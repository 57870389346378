import React from "react"

const TimlineItem = ({ time, component }) => {
  return (
    <li className="relative mb-2">
      <i className="absolute top-2 left-7 box-border inline-block h-3 w-3 rounded-full border-2 border-sky-600 bg-white" />
      <div className="ml-12 flex justify-between border-b border-solid border-gray-300 py-1">
        <div className="w-3/4 text-gray-700">{component}</div>
        <span className="text-gray-400">{time}</span>
      </div>
    </li>
  )
}

export default TimlineItem
