import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button, Link } from "~tailwindui/Basics"
import {
  Failures,
  SelectInput,
  SubmitButton,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"

import { path } from "components/Tutor/TutorRoutes"

const ValidationSchema = Yup.object().shape({
  subjectIds: Yup.array().min(1, "Please select at least one subject"),
})

export type EditSubjectsProps = {
  isOpen: boolean
  closeModal: () => void
  studyGroup: {
    id: string
    name: string
    subjects: {
      id: string
      name: string
    }[]
  }
  subjects: { id: string; name: string }[]
}

const EditSubjects: React.FC<EditSubjectsProps> = ({
  isOpen,
  closeModal,
  studyGroup,
  subjects,
}) => {
  const initialFormState = {
    studyGroupId: studyGroup.id,
    subjectIds: studyGroup.subjects.map(subject => subject.id),
  }

  const [, runMutation]: [any, RunMutation] = useMutation(editSubjectsMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          const { failures } = result.data.editStudyGroupSubjects
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.success("Subjects updated successfully")
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Modal.Dialog hideButton isOpen={isOpen} closeModal={closeModal}>
      <>
        <Modal.Header>Edit Subjects for {studyGroup.name}</Modal.Header>
        <Formik
          initialValues={initialFormState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <Form className="flex max-h-[calc(70vh-90px)] flex-col">
            <Modal.Body>
              <div className="h-52">
                <AlertMessageBox level={AlertLevel.Info}>
                  Don't see the subject you're looking for? Update your
                  directory subjects{" "}
                  <Link to={path("directory.tutorSubjectsIndex")}>here</Link>.
                </AlertMessageBox>

                <SelectInput
                  multi
                  options={subjects.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))}
                  name="subjectIds"
                  label="Subjects"
                  portal
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Failures />
              <div className="flex justify-end space-x-2">
                <Button color={Color.Red} onClick={closeModal}>
                  Cancel
                </Button>
                <SubmitButton>Update Subjects</SubmitButton>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </>
    </Modal.Dialog>
  )
}

const editSubjectsMutation = gql`
  mutation EditStudyGroupSubjects($studyGroupId: ID!, $subjectIds: [ID!]!) {
    editStudyGroupSubjects(
      studyGroupId: $studyGroupId
      subjectIds: $subjectIds
    ) {
      failures {
        message
      }
      studyGroup {
        id
      }
    }
  }
`

export default EditSubjects
