import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"

import { path } from "~Tutor/TutorRoutes"
import { WithLoadingIndicator } from "~tailwindui/Basics"
import { Failures, SubmitButton, handleFailure } from "~tailwindui/Form"

import { RunMutation } from "src/types"
import { MatchParams } from "src/types/MatchParams"

import Evaluation from "../components/Evaluation"

const EditEvaluation: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const airtutorsSessionId = match.params.id
  const history = useHistory()
  const [result] = useQuery({
    query: airtutorsSessionQuery,
    variables: { airtutorsSessionId },
  })

  const [, runMutation]: [any, RunMutation] = useMutation(
    updateEvaluationMutation
  )

  const formState = {
    lessonResourceCompletions: {},
  }

  if (result.data) {
    const resourceCompletions = {}
    result.data.airtutorsSession.lessonResourceCompletions.forEach(
      completion => {
        resourceCompletions[completion.id] = {
          id: completion.id,
          tutorMastery: completion.tutorMastery,
          tutorTopicMastery: completion.tutorTopicMastery,
        }
      }
    )
    formState.lessonResourceCompletions = resourceCompletions
  }

  const handleSubmit = (values, actions) => {
    runMutation({
      airtutorsSessionId,
      lessonResourceCompletions: Object.values(
        values.lessonResourceCompletions
      ),
    })
      .then(
        result => {
          const { failures } = result.data.updateLessonResourceCompletions
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            history.push(
              path("sessionDetails", {
                id: airtutorsSessionId,
              })
            )
            NotificationManager.success("Evaluation updated")
          }
        },
        error => {
          actions.setStatus("Something went wrong")
          actions.setSubmitting(false)
        }
      )
      .catch(error => {
        actions.setStatus("Something went wrong")
        actions.setSubmitting(false)
      })
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Evaluation
                airtutorsSession={data.airtutorsSession}
                values={values}
                setFieldValue={setFieldValue}
              />

              <div className="text-center">
                <Failures />
                <SubmitButton text="Update Evaluation" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </WithLoadingIndicator>
  )
}

const airtutorsSessionQuery = gql`
  query LessonResourceCompletionsQuery($airtutorsSessionId: ID!) {
    airtutorsSession(id: $airtutorsSessionId) {
      lessonResourceCompletions {
        id
        tutorMastery
        tutorTopicMastery
        lessonResource {
          name
          sortOrder
        }
        lessonTopic {
          sortOrder
        }
        student {
          fullName
        }
        completedLessonTopic {
          id
          name
          sortOrder
        }
      }
    }
  }
`

const updateEvaluationMutation = gql`
  mutation updateLessonResourceCompletions(
    $airtutorsSessionId: ID!
    $lessonResourceCompletions: [LessonResourceCompletionInputObject!]!
  ) {
    updateLessonResourceCompletions(
      airtutorsSessionId: $airtutorsSessionId
      lessonResourceCompletions: $lessonResourceCompletions
    ) {
      failures {
        message
      }
    }
  }
`

export default EditEvaluation
