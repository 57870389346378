import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { Color, Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"

import AirtutorsIcon from "../AirtutorsIcon"
import DirectoryIcon from "../DirectoryIcon"

export type UnbilledSessionsProps = {
  hideIfEmpty?: boolean
}

const UnbilledSessions: React.FC<UnbilledSessionsProps> = ({
  hideIfEmpty = false,
}) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: unbilledSessionsQuery,
    variables: { page },
  })

  if (
    hideIfEmpty &&
    result.data &&
    result.data.viewer.sessions.data.length === 0
  )
    return null

  return (
    <WithLoadingIndicator
      result={result}
      error="An error occurred while loading your unbilled sessions"
    >
      {({ data: { viewer } }) => (
        <Table
          title="Unbilled Sessions"
          changePage={setPage}
          data={viewer.sessions}
          headers={[
            "Study Group",
            "Subject",
            "Students",
            "Date",
            "Estimated Duration",
            "Actions",
          ]}
          valueMapper={session => [
            session.studyGroup && (
              <Link
                key={session.studyGroup.id}
                to={path("studyGroupDetails", {
                  id: session.studyGroup.id,
                })}
              >
                <span className="flex items-center">
                  {session.directory ? (
                    <DirectoryIcon recordType={"session"} />
                  ) : (
                    <AirtutorsIcon recordType={"session"} />
                  )}{" "}
                  {session.studyGroup.name}
                </span>
              </Link>
            ),
            session.subject.name,
            <ul key={session.id}>
              {session.students.map(student => (
                <li key={student.id}>
                  <Link
                    to={path("studentDetails", {
                      id: student.id,
                    })}
                  >
                    {student.fullName}
                  </Link>
                </li>
              ))}
            </ul>,
            <div key={session.id} className="min-w-[80px]">
              <LocalTime format="ddd MMM D" timestamp={session.startsAt} />
              <br />
              <LocalTime omitDate timestamp={session.startsAt} />
              &mdash;
              <LocalTime omitDate timestamp={session.endsAt} />
            </div>,
            session.formattedScheduledDuration,
            <div key={session.id} className="flex items-center space-x-1">
              <Link
                to={path("sessionDetails", {
                  id: session.id,
                })}
                button
                size={Size.Small}
              >
                View
              </Link>
              <Link
                button
                color={Color.Green}
                size={Size.Small}
                to={path("sessionBill", { id: session.id })}
              >
                Complete
              </Link>
            </div>,
          ]}
        />
      )}
    </WithLoadingIndicator>
  )
}

const unbilledSessionsQuery = gql`
  query UnbilledSessionsQuery($page: Int!) {
    viewer {
      sessions(
        filters: { past: true, billState: unbilled, statusState: scheduled }
        page: $page
      ) {
        totalPages
        data {
          id
          type
          directory
          airtutors
          startsAt
          endsAt
          formattedScheduledDuration
          students {
            id
            fullName
          }
          subject {
            name
          }
          studyGroup {
            id
            name
          }
        }
      }
    }
  }
`

export default UnbilledSessions
