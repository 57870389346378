import React from "react"

import { Tooltip } from "@chakra-ui/core"
import { RocketLaunchIcon } from "@heroicons/react/24/solid"

import titlecase from "src/titlecase"

export type DirectoryIconProps = {
  recordType?: string
}

const DirectoryIcon: React.FC<DirectoryIconProps> = ({ recordType = "" }) => {
  return (
    <>
      <Tooltip
        label={`Directory ${titlecase(recordType)}`}
        className="z-50 rounded-sm bg-gray-400 p-1 text-white"
        aria-label={`Directory ${titlecase(recordType)}`}
      >
        <RocketLaunchIcon className="mr-3 inline-block h-5 w-5 text-purple-600" />
      </Tooltip>
    </>
  )
}

export default DirectoryIcon
