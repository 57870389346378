import React, { useRef, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { useField, useFormikContext } from "formik"
import DatePicker, { DateObject } from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker"

import { to12HourTime, to24HourTime } from "src/dateHelpers"
import emptyFunction from "src/emptyFunction"

import TextInput from "components/OrganizationAdmin/components/Form/TextInput"

import CloseButton from "./DateInputCloseButton"

export type TimeInputProps = {
  name: string
  label: string
  afterChange?: (date: Dayjs) => void
  arrayValue?: boolean
  description?: string
}

const TimeInput: React.FC<TimeInputProps> = ({
  name,
  label,
  afterChange = emptyFunction,
  arrayValue = false,
  description = "",
}) => {
  const [timePickerClosed, setTimePickerClosed] = useState(true)
  const timePicker = useRef() as any
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField({ name })
  const error = meta.error || ""

  const openCalendar = () => {
    timePicker.current.openCalendar()
  }

  return (
    <div>
      <div className="relative flex w-48">
        <TextInput
          error={error}
          name={`${name}-display`}
          label={label}
          description={description}
          value={
            field.value.includes(undefined)
              ? ""
              : `${field.value[0]}:${field.value[1]
                  .toString()
                  .padStart(2, "0")} ${field.value[2]}`
          }
          onClick={openCalendar}
          onChange={emptyFunction}
        />
        <div className="absolute left-0 top-14 z-10 origin-top-left">
          <DatePicker
            value={new DateObject()
              .setHour(to24HourTime(field.value[0], field.value[2]))
              .setMinute(field.value[1])}
            disableDayPicker
            ref={timePicker}
            format="h:mm a"
            onChange={date => {
              const dayjsDate = dayjs(date.toString())
              if (arrayValue) {
                setFieldValue(name, [
                  to12HourTime(date.hour),
                  date.minute,
                  date.hour < 12 ? "am" : "pm",
                ])
              } else {
                setFieldValue(name, dayjsDate)
              }
              afterChange(dayjsDate)
            }}
            onOpen={() => setTimePickerClosed(false)}
            onClose={() => timePickerClosed}
            plugins={[
              <TimePicker key="time" position="right" hideSeconds mStep={5} />,
              <CloseButton
                key="close"
                position="bottom"
                setClosed={setTimePickerClosed}
              />,
            ]}
            inputClass="hidden"
          />
        </div>
      </div>
    </div>
  )
}

export default TimeInput
