import React, { useContext } from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { Color } from "~tailwindui/types/enums"

import TutorContext from "~Tutor/TutorContext"
import { Button } from "~tailwindui/Basics"
import {
  AutocompleteSelect,
  Failures,
  SubmitButton,
  TextAreaInput,
  TextInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Subject name is required"),
  tierId: Yup.string().required("Tier is required"),
})

export type RequestSubjectModalProps = {
  isOpen: boolean
  closeModal: () => void
}

const RequestSubjectModal: React.FC<RequestSubjectModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const { viewer } = useContext(TutorContext)

  const [, runMutation]: [any, RunMutation] = useMutation(
    requestSubjectMutation
  )

  const formState = {
    userId: viewer.id,
    name: "",
    tierId: "",
    additionalInfo: "",
  }

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(result => {
        const { failures } = result.data.requestSubject
        if (failures.length > 0) {
          handleFailure(actions, failures)
        } else {
          NotificationManager.success(
            "Subject request submitted! We'll contact once your subject has been added."
          )
          closeModal()
        }
      })
      .catch(() => handleFailure(actions))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <>
      <Modal.Dialog hideButton isOpen={isOpen} closeModal={closeModal}>
        {closeModal => (
          <>
            <Modal.Header>Request New Subject</Modal.Header>
            <Formik
              initialValues={formState}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="flex max-h-[calc(70vh-90px)] flex-col">
                <Modal.Body>
                  <AutocompleteSelect
                    name="tierId"
                    label="Tier"
                    resource="tiers"
                    column="gradeRange"
                  />
                  <TextInput name="name" label="Subject Name" />
                  <TextAreaInput
                    name="additionalInfo"
                    label="Additional Info"
                  />
                </Modal.Body>

                <Modal.Footer>
                  <div className="flex flex-col">
                    <div className="flex justify-end space-x-2">
                      <Failures />
                      <Button color={Color.Red} onClick={closeModal}>
                        Cancel
                      </Button>
                      <SubmitButton text="Submit Request" className="w-full" />
                    </div>
                  </div>
                </Modal.Footer>
              </Form>
            </Formik>
          </>
        )}
      </Modal.Dialog>
    </>
  )
}

const requestSubjectMutation = gql`
  mutation RequestSubject(
    $userId: ID!
    $name: String!
    $tierId: ID!
    $additionalInfo: String
  ) {
    requestSubject(
      userId: $userId
      name: $name
      tierId: $tierId
      additionalInfo: $additionalInfo
    ) {
      failures {
        message
      }
    }
  }
`

export default RequestSubjectModal
