import React from "react"

import NewCourseClusterButton from "./New"
import CourseClustersTable from "./Table"

const CourseClustersIndex = ({ directory, bulkUploadsPath }) => {
  return (
    <>
      {!directory && (
        <div className="row">
          <div className="col">
            <NewCourseClusterButton />
            <a href={bulkUploadsPath} className="btn btn-info">
              Bulk Uploads
            </a>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col">
          <CourseClustersTable directory={directory} />
        </div>
      </div>
    </>
  )
}

export default CourseClustersIndex
