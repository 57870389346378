import React, { useEffect, useMemo, useRef, useState } from "react"

import { gql, useMutation, useQuery } from "urql"

import { Color } from "~tailwindui/types/enums"

import { Button, Spinner, WithLoadingIndicator } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import EventEmitter from "src/EventEmitter"
import classNames from "src/classNames"

import useWebsocket from "hooks/useWebsocket"

export type CompleteWorksheetButtonsProps = {
  sessionId: string
}

const CompleteWorksheetButtons: React.FC<CompleteWorksheetButtonsProps> = ({
  sessionId,
}) => {
  const resourceDownloadLink = useRef<HTMLAnchorElement>()
  const [downloadUrl, setDownloadUrl] = useState<string>("")
  const [errors, setErrors] = useState<string>("")

  useEffect(() => {
    if (downloadUrl === "" || !resourceDownloadLink.current) return
    resourceDownloadLink.current.click()
  }, [downloadUrl])

  const [result, refetchSession] = useQuery({
    query: currentLessonResourcesQuery,
    variables: { sessionId },
  })

  const [completeResourceState, runCompleteResource]: any[] = useMutation(
    completeLessonResourceMutation
  )

  const [downloadResourceState, runDownloadResource]: any[] = useMutation(
    downloadLessonResourceMutation
  )

  const completeResource = lessonResource => {
    runCompleteResource({
      sessionId,
      lessonResourceId: lessonResource.id,
    }).then(response => {
      const { failures, session, nextResource } =
        response.data.completeLessonResource
      if (failures.length > 0) {
        setErrors(failures[0].message)
        return
      }

      setErrors("")
      if (session) refetchSession({ requestPolicy: "network-only" })
      if (nextResource) {
        runDownloadResource({
          sessionId,
          lessonResourceId: nextResource.id,
        }).then(response => {
          setDownloadUrl(
            response.data.downloadLessonResource.lessonResource.file.downloadUrl
          )
        })
      }
    })
  }

  const channelProps = useMemo(() => {
    return { id: sessionId }
  }, [sessionId])

  useWebsocket({
    channelName: "SessionChannel",
    channelProps,
  })

  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      refetchSession({ requestPolicy: "network-only" })
    })
  })

  return (
    <>
      <a
        ref={resourceDownloadLink}
        href={downloadUrl}
        download
        className="hidden"
      >
        download
      </a>
      <WithLoadingIndicator result={result}>
        {({ data }) =>
          data.session.currentLessonResources.map(lessonResource => (
            <Modal.Dialog
              key={lessonResource.id}
              buttonText={`Complete ${lessonResource.name}`}
              buttonClassNames={classNames(
                "mb-1",
                buttonClassNames({
                  color: Color.Orange,
                })
              )}
            >
              {closeModal => (
                <>
                  <Modal.Header>Complete {lessonResource.name}</Modal.Header>
                  <Modal.Body>
                    <p>
                      This will complete {lessonResource.name} and automatically
                      download the next worksheet.
                    </p>
                    <p>
                      So make sure to let students know to complete the survey
                      at the end of this session!
                    </p>
                  </Modal.Body>

                  <Modal.Footer>
                    <div className="flex flex-col">
                      {errors && <div className="alert mb-3 p-3">{errors}</div>}
                      <div className="flex justify-end space-x-2">
                        <Button onClick={closeModal} color={Color.Red}>
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            completeResourceState.fetching ||
                            downloadResourceState.fetching
                          }
                          onClick={() => completeResource(lessonResource)}
                        >
                          <div className="flex space-x-2">
                            <span>Complete + Download</span>{" "}
                            {(completeResourceState.fetching ||
                              downloadResourceState.fetching) && <Spinner />}
                          </div>
                        </Button>
                      </div>
                    </div>
                  </Modal.Footer>
                </>
              )}
            </Modal.Dialog>
          ))
        }
      </WithLoadingIndicator>
    </>
  )
}

const currentLessonResourcesQuery = gql`
  query ($sessionId: ID!) {
    session(id: $sessionId) {
      currentLessonResources {
        id
        name
      }
    }
  }
`

const completeLessonResourceMutation = gql`
  mutation ($sessionId: ID!, $lessonResourceId: ID!) {
    completeLessonResource(
      sessionId: $sessionId
      lessonResourceId: $lessonResourceId
    ) {
      failures {
        message
      }
      session {
        currentLessonResources {
          id
          name
        }
      }
      nextResource {
        id
        name
        file {
          downloadUrl
          fileName
        }
      }
    }
  }
`

const downloadLessonResourceMutation = gql`
  mutation ($sessionId: ID!, $lessonResourceId: ID!) {
    downloadLessonResource(
      sessionId: $sessionId
      lessonResourceId: $lessonResourceId
    ) {
      lessonResource {
        id
        file {
          downloadUrl
        }
      }
    }
  }
`

export default CompleteWorksheetButtons
