import React, { Dispatch, SetStateAction } from "react"

import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import ModalWithProvidedBody from "./ModalWithProvidedBody"

export type TimeProtectedModalProps = {
  tailwind: boolean
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  timeString: string
  onSubmit: () => void
}

const TimeProtectedModal: React.FC<TimeProtectedModalProps> = ({
  tailwind,
  isOpen,
  setOpen,
  timeString,
  onSubmit,
}) => {
  return tailwind ? (
    <Modal.Dialog hideButton isOpen={isOpen} closeModal={() => setOpen(false)}>
      {closeModal => (
        <>
          <Modal.Header
            Icon={ExclamationCircleIcon}
            iconClassNames="text-red-500"
          >
            This is a future session
          </Modal.Header>
          <Modal.Body>
            <p className="lead">
              This session is not scheduled to start for {timeString}. Are you
              sure this is the session you are trying to access?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button
                onClick={() => {
                  onSubmit()
                  setOpen(false)
                }}
                color={Color.Green}
                className="w-16"
              >
                Yes
              </Button>
              <Button onClick={closeModal} color={Color.Red} className="w-16">
                No
              </Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal.Dialog>
  ) : (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalTitle="This is a future session"
      closeModal={() => setOpen(false)}
      modalClassName="bootstrap-modal"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p className="lead">
              This session is not scheduled to start for {timeString}. Are you
              sure this is the session you are trying to access?
            </p>
          </div>
          <div className="modal-footer">
            <div className="w-100">
              <button
                onClick={() => {
                  onSubmit()
                  closeModal()
                }}
                className="btn solid green"
              >
                Yes
              </button>
              <button onClick={closeModal} className="btn solid red">
                No
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

export default TimeProtectedModal
