import React, { useRef } from "react"

import { Form, Formik } from "formik"

import { VoidReturn } from "src/types/VoidReturn"
import { adminClient as client } from "src/urql-client"

import { gql, useMutation } from "hooks/urql"

import {
  AutocompleteField,
  CheckboxField,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

export type AddTutorSubjectProps = {
  tutorId: string
  tier: {
    id: string
    gradeRange: string
  }
  refetchQuery: VoidReturn
}

const AddTutorSubject: React.FC<AddTutorSubjectProps> = ({
  tutorId,
  tier,
  refetchQuery,
}) => {
  const formState = {
    tutorId,
    subjectBucketId: "",
    directory: false,
  }
  const selectRef = useRef(null)

  const [_, runMutation]: any[] = useMutation(addTutorSubjectMutation, client)

  const handleSubmit = (values, actions) => {
    const { directory, ...rest } = values
    const params = {
      approvalType: directory ? "directory" : "airtutors",
      ...rest,
    }
    runMutation(params)
      .then(
        result => {
          const { failures } = result.data.addTutorSubject
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            selectRef.current.clearValue()
            actions.resetForm()
            refetchQuery()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <div className="flex w-full items-center space-x-4">
            <div className="w-52">
              <AutocompleteField
                ref={selectRef}
                name="subjectBucketId"
                api={"/api/admins/subject_buckets/autocomplete_name"}
                searchOptions={`tier_id=${tier.id}`}
                placeholder={`Add ${tier.gradeRange} subject`}
              />
            </div>
            <div>
              <CheckboxField name="directory" label="Directory" />
            </div>
          </div>
          <div className="flex items-center">
            {values.subjectBucketId && <SubmitButton text="Add Subject" />}
            <FormFailures />
          </div>
        </Form>
      )}
    </Formik>
  )
}

const addTutorSubjectMutation = gql`
  mutation AddTutorSubject(
    $tutorId: ID!
    $subjectBucketId: ID!
    $approvalType: ApprovalTypeEnum!
  ) {
    addTutorSubject(
      tutorId: $tutorId
      subjectBucketId: $subjectBucketId
      approvalType: $approvalType
    ) {
      tutorSubject {
        id
      }
      failures {
        message
      }
    }
  }
`

export default AddTutorSubject
