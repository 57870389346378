import React, { useEffect } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { css } from "@emotion/core"

import EventEmitter from "src/EventEmitter"
import ordered from "src/ordered"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import GenerateGroupsForm from "./GenerateGroups/Form"

import ResetStudentSubjects from "./ResetStudentSubjects"
import TeacherSubject from "./TeacherSubject"

const OrganizationTeacherSubjects = ({ id }) => {
  const loadingState = useQuery(buildQuery(teacherSubjectsQuery, { id }))

  useEffect(() => {
    EventEmitter.subscribe("groupGenerationComplete", data => {
      if (data.redirectTo) {
        window.location.href = data.redirectTo
      }
    })
  }, [])

  return (
    <>
      <WithLoadingIndicator
        loadingState={loadingState}
        heading={<h4>Manage Generated Study Groups</h4>}
      >
        {({ data }) => (
          <>
            <ResetStudentSubjects organizationId={id} />
            <a
              href={data.organization.generatedStudyGroupsPath}
              className="btn btn-info"
              css={css`
                font-weight: 600 !important;
              `}
            >
              View All Generated Groups
            </a>
            <a
              href={`${data.organization.generatedStudyGroupsPath}?unassigned=true`}
              className="btn btn-info"
              css={css`
                font-weight: 600 !important;
              `}
            >
              Generations with unassigned students
            </a>
            <ModalWithProvidedBody
              modalTitle="Generate Groups for All"
              buttonText="Generate for All Teacher Subjects"
              buttonClassName="btn btn-success"
            >
              {({ closeModal }) => (
                <GenerateGroupsForm
                  organizationId={id}
                  closeModal={closeModal}
                />
              )}
            </ModalWithProvidedBody>
            {data.organization.teacherSubjects.filter(
              ts => ts.students.length > 0
            ).length < 1 ? (
              <span className="font-italic">
                <br />
                No teacher subjects to display
              </span>
            ) : (
              <>
                <div>
                  <div className="alert alert-info d-inline-block">
                    Tip: hover over a teacher subject to view associated
                    students (student links open in a new tab!)
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <table className="table-striped table">
                      <tbody>
                        {ordered(
                          ordered(
                            data.organization.teacherSubjects.filter(
                              ts => ts.students.length > 0
                            ),
                            "subject",
                            "name"
                          ),
                          "teacher",
                          "lastName"
                        ).map(teacherSubject => (
                          <tr key={teacherSubject.id}>
                            <TeacherSubject teacherSubject={teacherSubject} />
                            <td>
                              {teacherSubject.students.length > 0 && (
                                <ModalWithProvidedBody
                                  modalTitle="Generate Groups"
                                  buttonText="Generate"
                                  buttonClassName="btn btn-sm btn-success"
                                >
                                  {({ closeModal }) => (
                                    <GenerateGroupsForm
                                      organizationId={id}
                                      teacherSubjectId={teacherSubject.id}
                                      closeModal={closeModal}
                                    />
                                  )}
                                </ModalWithProvidedBody>
                              )}
                            </td>
                            <td>
                              {teacherSubject.studyGroupGeneration && (
                                <a
                                  href={
                                    teacherSubject.studyGroupGeneration.showPath
                                  }
                                  className="btn btn-sm btn-info"
                                  css={css`
                                    font-weight: 600 !important;
                                  `}
                                >
                                  View
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const teacherSubjectsQuery = compress`
  query($id: ID!) {
    organization(id: $id) {
      generatedStudyGroupsPath
      teacherSubjects {
        id
        students {
          id
          fullName
          lastName
          showPath
        }
        teacher {
          id
          fullName
          lastName
        }
        subject {
          id
          name
        }
        studyGroupGeneration {
          id
          showPath
        }
      }
    }
  }
`

export default OrganizationTeacherSubjects
