import React from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import { H2, WithLoadingIndicator } from "~tailwindui/Basics"

import ordered from "src/ordered"
import { MatchParams } from "src/types/MatchParams"

import LessonPlan from "components/LessonPlan"

const StudentLessonPlans: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const studentId = match.params.id
  const [result] = useQuery({
    query: lessonPlansQuery,
    variables: { studentId },
  })

  if (result.data && result.data.student.lessonPlanAssignments.length === 0)
    return (
      <span className="italic">
        There are no lesson plans for this student.
      </span>
    )

  return (
    <WithLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <H2>Lesson Plans</H2>
          <div>
            {ordered(
              data.student.lessonPlanAssignments,
              "lessonPlan",
              "name"
            ).map(lessonPlanAssignment => (
              <React.Fragment key={lessonPlanAssignment.id}>
                <div>
                  <LessonPlan lessonPlanAssignment={lessonPlanAssignment} />
                </div>
                <hr />
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const lessonPlansQuery = gql`
  query LessonPlansQuery($studentId: ID!) {
    student(id: $studentId) {
      lessonPlanAssignments {
        id
        lessonPlan {
          id
          name

          lessonTopics {
            id
            name
            lessonResources {
              id
              name
              completed(studentId: $studentId)
              current(studentId: $studentId)
              lessonResourceCompletions(studentId: $studentId) {
                id
                createdAt
                studentMastery
                session {
                  startsAt
                  tutor {
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StudentLessonPlans
