import React from "react"

import { useField, useFormikContext } from "formik"

import { Switch } from "@headlessui/react"

import { ErrorIcon } from "~tailwindui/Basics"

import classNames from "src/classNames"

export type ToggleInputProps = {
  label: string
  name: string
  description?: string
  subfield?: boolean
  displayValue?: any
}

const ToggleInput: React.FC<
  ToggleInputProps & React.HTMLProps<HTMLInputElement>
> = ({ name, label, subfield, displayValue, description = "" }) => {
  const [field, meta] = useField({ name })
  const { setFieldValue } = useFormikContext() as any
  const error = meta.error ? meta.error : ""
  return (
    <div className="mb-3">
      <label
        htmlFor={name}
        className={classNames(
          "block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5",
          subfield && "ml-8"
        )}
      >
        {label}
      </label>
      <div className="relative">
        <Switch
          checked={field.value}
          name={name}
          onChange={newVal => {
            setFieldValue(field.name, newVal)
          }}
          className={classNames(
            field.value ? "bg-sky-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2",
            error && "ring-2 ring-red-500"
          )}
        >
          <span className="sr-only">{label}</span>
          <span
            className={classNames(
              field.value ? "translate-x-5" : "translate-x-0",
              "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          >
            <span
              className={classNames(
                field.value
                  ? "opacity-0 duration-100 ease-out"
                  : "opacity-100 duration-200 ease-in",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-gray-400"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={classNames(
                field.value
                  ? "opacity-100 duration-200 ease-in"
                  : "opacity-0 duration-100 ease-out",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-sky-600"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
        {error && <ErrorIcon />}
      </div>

      <div className="text-sm">
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </div>
  )
}

export default ToggleInput
