import React from "react"

import { Field, Form, Formik } from "formik"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  useToast,
} from "@chakra-ui/core"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
  email: Yup.string().required("Please enter a valid email"),
  timeZone: Yup.string().required(
    "Please select a timezone for use in email notifications"
  ),
})

const EditProfileForm = ({ viewer }) => {
  const toast = useToast()
  const history = useHistory()
  const formState = {
    firstName: viewer.firstName,
    lastName: viewer.lastName,
    email: viewer.email,
    timeZone: viewer.emailTimeZoneEnum,
  }
  const [, runMutation] = useMutation(updateProfileMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          actions.setSubmitting(false)
          if (result.error) {
            toast({
              title: "Error",
              description:
                "An error occurred while updating your profile. Please resolve any validation errors and try again.",
              status: "error",
              variant: "solid",
              duration: null,
              isClosable: true,
              position: "top",
            })
          } else if (result.data.updateProfile.errors) {
            actions.setStatus(
              "Failed to update profile. Please correct validation errors and resubmit."
            )
            response.updateProfile.errors.forEach(error => {
              actions.setFieldError(error.field, error.messages.join(", "))
            })
          } else {
            const description =
              viewer.email === values.email ? (
                ""
              ) : (
                <>
                  <Box>
                    You will receive an email to confirm your updated email
                    address.
                  </Box>
                  <Box fontStyle="italic">
                    Your login information will not be updated until you click
                    the confirmation link.
                  </Box>
                </>
              )
            toast({
              title: "Profile Updated",
              description: description,
              status: "success",
              variant: "solid",
              duration: 10000,
              isClosable: true,
              position: "top",
            })
            history.push("/")
          }
        },
        err => {
          actions.setSubmitting(false)
          toast({
            title: "Error",
            description:
              "An error occurred while updating your profile. Please resolve any validation errors and try again.",
            status: "error",
            variant: "solid",
            duration: null,
            isClosable: true,
            position: "top",
          })
          throw err
        }
      )
      .catch(err => {
        actions.setSubmitting(false)
        toast({
          title: "Error",
          description:
            "An error occurred while updating your profile. Please resolve any validation errors and try again.",
          status: "error",
          variant: "solid",
          duration: null,
          isClosable: true,
          position: "top",
        })
        throw err
      })
  }

  return (
    <>
      <Heading fontSize="xl">Edit Profile</Heading>
      <Box py={4}>
        <Button
          as={RouterLink}
          to="/password"
          variant="solid"
          variantColor="orange"
        >
          Change Password
        </Button>
      </Box>
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form>
            <Field name="firstName">
              {({ field, form }) => (
                <FormControl isInvalid={!!form.errors[field.name]} mt="4">
                  <FormLabel htmlFor={field.name}>First Name</FormLabel>
                  <Flex
                    align={{ md: "center" }}
                    direction={["column", "column", "row"]}
                  >
                    <Input id={field.name} {...field} flexBasis="33%" />
                  </Flex>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="lastName">
              {({ field, form }) => (
                <FormControl isInvalid={!!form.errors[field.name]} mt="4">
                  <FormLabel htmlFor={field.name}>Last Name</FormLabel>
                  <Flex
                    align={{ md: "center" }}
                    direction={["column", "column", "row"]}
                  >
                    <Input id={field.name} {...field} flexBasis="33%" />
                  </Flex>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="email">
              {({ field, form }) => (
                <FormControl isInvalid={!!form.errors[field.name]} mt="4">
                  <FormLabel htmlFor={field.name}>Email</FormLabel>
                  <Flex
                    align={{ md: "center" }}
                    direction={["column", "column", "row"]}
                  >
                    <Input
                      id={field.name}
                      {...field}
                      type="email"
                      flexBasis="33%"
                    />
                  </Flex>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="timeZone">
              {({ field, form }) => (
                <FormControl isInvalid={!!form.errors[field.name]} mt="4">
                  <FormLabel htmlFor={field.name}>Time Zone</FormLabel>
                  <Flex
                    align={{ md: "center" }}
                    direction={["column", "column", "row"]}
                  >
                    <Select id={field.name} {...field} flexBasis="33%">
                      <option value="Alaska">Alaska</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Pacific">Pacific</option>
                      <option value="Mountain">Mountain</option>
                      <option value="Central">Central</option>
                      <option value="Eastern">Eastern</option>
                    </Select>
                    <FormHelperText
                      flexGrow="1"
                      my={{ md: "0" }}
                      ml={{ md: "8" }}
                    >
                      Selected time zone will be used for dates and times in
                      email notifications
                    </FormHelperText>
                  </Flex>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex align="center" mt="4">
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Saving..."
                variant="solid"
                variantColor="gray"
              >
                Save Profile
              </Button>
              {status && (
                <Box bg="red.500" color="white" p="4" ml="8" rounded="md">
                  {status}
                </Box>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}

const updateProfileMutation = gql`
  mutation UpdateProfileMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $timeZone: TimeZoneEnum!
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      timeZone: $timeZone
    ) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export default EditProfileForm
