import React from "react"

import { gql, useQuery } from "urql"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox, H2, WithLoadingIndicator } from "~tailwindui/Basics"

import ordered from "src/ordered"

import CourseSection from "./CourseSection"

export type CourseSectionRegistrationProps = Record<never, any>

const CourseSectionRegistration: React.FC<
  CourseSectionRegistrationProps
> = () => {
  const [result] = useQuery({ query: courseClustersQuery })

  if (result.data && !result.data.viewer.canAcceptGroups)
    return (
      <AlertMessageBox level={AlertLevel.Warning}>
        You have given up a study group in the last 48 hours, so you are
        ineligible to accept new groups at this time.
      </AlertMessageBox>
    )

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) =>
        viewer.registerableCourseClusters.length > 0 ? (
          <div>
            {ordered(viewer.registerableCourseClusters, "name").map(
              courseCluster => (
                <div key={courseCluster.id}>
                  <H2>{courseCluster.name}</H2>
                  <p className="italic">{courseCluster.description}</p>
                  <dl className="mb-0">
                    <dt>Grades</dt>
                    <dd>
                      <ul className="m-0">
                        {ordered(courseCluster.grades, "number").map(grade => (
                          <li key={grade.id}>{grade.name}</li>
                        ))}
                      </ul>
                    </dd>

                    <dt>Subject</dt>
                    <dd>{courseCluster.subject.name}</dd>
                  </dl>
                  <hr />
                  {ordered(
                    courseCluster.tutorRegisterableCourseSections,
                    "startsOn"
                  ).map(courseSection => (
                    <CourseSection
                      key={courseSection.id}
                      courseSection={courseSection}
                    />
                  ))}
                </div>
              )
            )}
          </div>
        ) : (
          <span className="mt-8 text-lg italic">
            Sorry, all available groups have been registered for at this time.
          </span>
        )
      }
    </WithLoadingIndicator>
  )
}

const courseClustersQuery = gql`
  query CourseClustersQuery {
    viewer {
      canAcceptGroups
      foreignLanguages {
        id
      }
      registerableCourseClusters {
        id
        name
        description
        subject {
          id
          name
        }
        grades {
          id
          name
          number
        }
        tutorRegisterableCourseSections {
          id
          startsOn
          endsOn
          maxSubsEstimate
          organization {
            minTutorConsistencyRate
          }
          availabilities {
            id
            startsAt
            endsAt
            weekday
            duration
          }
        }
      }
    }
  }
`

export default CourseSectionRegistration
