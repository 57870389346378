import React from "react"

import AirtutorsFaviconImageSrc from "assets/airtutors_favicon_57x57.png"

import { Tooltip } from "@chakra-ui/core"

import titlecase from "src/titlecase"

export type AirtutorsIconProps = {
  recordType?: string
}

const AirtutorsIcon: React.FC<AirtutorsIconProps> = ({ recordType = "" }) => {
  return (
    <>
      <Tooltip
        label={`Air Tutors ${titlecase(recordType)}`}
        className="z-50 rounded-sm bg-gray-400 p-1 text-white"
        aria-label={`Air Tutors ${titlecase(recordType)}`}
      >
        <img
          src={AirtutorsFaviconImageSrc}
          alt={`Air Tutors ${titlecase(recordType)}`}
          className="mr-3 inline-block h-5 w-5"
        />
      </Tooltip>
    </>
  )
}

export default AirtutorsIcon
