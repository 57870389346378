import React from "react"

import { gql, useQuery } from "urql"

import { H3, WithLoadingIndicator } from "~tailwindui/Basics"
import { CheckboxInput } from "~tailwindui/Form"

import { publicClient as client } from "src/urql-client"

const SubjectBucketSelection = ({ tierId, tutorSubjects }) => {
  const [result] = useQuery({
    query: loadSubjectBucketsQuery,
    variables: { tierId },
    client,
  })

  const approvalStateFor = subjectBucketId => {
    const tutorSubject = tutorSubjects.find(
      tutorSubject => tutorSubject.subjectBucket.id === subjectBucketId
    )
    if (!tutorSubject) return null
    return tutorSubject.approvalState
  }

  return (
    <WithLoadingIndicator result={result} error="Unable to load subjects">
      {({ data: { tier } }) => (
        <div className="mh-[250px] mb-4 overflow-y-scroll rounded-md border-2 border-solid border-sky-200 px-2">
          <H3>{tier.gradeRange} Subjects</H3>
          <div>
            <CheckboxInput
              key={tierId}
              checkboxType="multi"
              name="subjectBucketIds"
              options={tier.subjectBuckets.map(bucket => ({
                label: `${bucket.name} ${
                  approvalStateFor(bucket.id)
                    ? `(${approvalStateFor(bucket.id)})`
                    : ""
                }`,
                value: bucket.id,
                disabled: ["approved", "rejected"].includes(
                  approvalStateFor(bucket.id)
                ),
              }))}
            />
          </div>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const loadSubjectBucketsQuery = gql`
  query TierQuery($tierId: ID!) {
    tier(id: $tierId) {
      id
      gradeRange
      subjectBuckets {
        id
        name
      }
    }
  }
`
export default SubjectBucketSelection
