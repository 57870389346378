import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"

import ordered from "src/ordered"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import { ErrorMessage } from "components/Forms/Formik"
import {
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import AddPaymentMethod from "../AddPaymentMethod"
import ExistingPaymentMethods from "../ExistingPaymentMethods"

import PrepaidPackageOption from "./PrepaidPackageOption"

const ValidationSchema = Yup.object().shape({
  prepaidPackageId: Yup.string().required("Please select a payment package"),
})

const OneOnOneTutoring = ({ tier, student }) => {
  const formState = {
    studentId: student.id,
    prepaidPackageId: "",
    stripeToken: "",
  }

  const client = useClient()
  const [result] = useQuery({ query, variables: { tierId: tier.id }, client })
  const [, register] = useMutation(oneOnOneRegistrationMutation, client)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = (values, actions) => {
    actions.setStatus()

    const cardElement = elements.getElement(CardElement)
    if (cardElement) {
      stripe
        .createSource(cardElement, { type: "card" })
        .then(
          ({ error, source }) => {
            if (error) {
              actions.setStatus([error])
              actions.setSubmitting(false)
              return
            }

            values.stripeToken = source.id
            submitRegistration(values, actions)
          },
          e => handleFailure(actions, [e])
        )
        .catch(e => handleFailure(actions, [e]))
    } else {
      submitRegistration(values, actions)
    }
  }

  const submitRegistration = (values, actions) => {
    register(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { failures, redirectTo } = result.data.oneOnOneRegistration
          if (failures) {
            handleFailure(actions, failures)
            return
          }

          window.location.href = redirectTo
        },
        e => handleFailure(actions, [e])
      )
      .catch(e => handleFailure(actions, [e]))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <UrqlLoadingIndicator result={result} heading={<h1>1-1 Tutoring</h1>}>
      {({ data }) => (
        <>
          <Formik
            initialValues={formState}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="my-12">
                <h2 className="mb-2 text-center lg:text-left">
                  Select a Prepaid Package Option
                </h2>
                <div className="isolate mx-auto grid max-w-md grid-cols-1 gap-y-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  {ordered(data.prepaidPackages, "price").map(
                    (prepaidPackage, index) => (
                      <PrepaidPackageOption
                        key={prepaidPackage.id}
                        prepaidPackage={prepaidPackage}
                        index={index}
                        isLast={index === data.prepaidPackages.length - 1}
                      />
                    )
                  )}
                </div>
                <div className="mt-3 ml-1">
                  <ErrorMessage name="prepaidPackageId" />
                </div>
              </div>

              {data.viewer.stripePaymentMethods.length > 0 ? (
                <ExistingPaymentMethods viewer={data.viewer} />
              ) : (
                <AddPaymentMethod />
              )}
              <FormFailures />
              <SubmitButton text="Complete Registration" />
            </Form>
          </Formik>
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query ($tierId: ID!) {
    viewer {
      stripePaymentMethods {
        id
        default
        type
        brand
        last4
        expirationMonth
        expirationYear
      }
    }
    prepaidPackages(amp: false) {
      id
      name
      hours
      price
      formattedPrice
      hourlyRate
      formattedHourlyRate
      formattedEffectiveHourlyRate
    }
    subjectBuckets(tierId: $tierId) {
      id
      name
    }
  }
`

const oneOnOneRegistrationMutation = gql`
  mutation ($studentId: ID!, $prepaidPackageId: ID!, $stripeToken: String) {
    oneOnOneRegistration(
      studentId: $studentId
      prepaidPackageId: $prepaidPackageId
      stripeToken: $stripeToken
    ) {
      failures {
        message
      }
      redirectTo
    }
  }
`
export default OneOnOneTutoring
