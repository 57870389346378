import React, { useContext } from "react"

import { NavLink, useLocation } from "react-router-dom"

import {
  BeakerIcon,
  BookOpenIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  HomeIcon,
} from "@heroicons/react/24/outline"

import classNames from "src/classNames"

import TutorContext from "components/Tutor/TutorContext"
import { findRoute } from "components/Tutor/TutorRoutes"

export type DirectoryNavProps = Record<never, any>

const DirectoryNav: React.FC<DirectoryNavProps> = () => {
  const location = useLocation()
  const { viewer } = useContext(TutorContext)

  const navigation = [
    {
      name: "Welcome",
      path: findRoute("directory.welcome").path,
      icon: HomeIcon,
      restricted: false,
    },
    {
      name: "Courses",
      path: findRoute("directory.courseClustersIndex").path,
      icon: BookOpenIcon,
      restricted: true,
    },
    {
      name: "Subjects",
      path: findRoute("directory.tutorSubjectsIndex").path,
      icon: BeakerIcon,
      restricted: true,
    },
    {
      name: "Availability",
      path: findRoute("directory.tutorSchedules").path,
      icon: CalendarIcon,
      restricted: true,
    },
    {
      name: "Billing",
      path: findRoute("directory.billing").path,
      icon: CurrencyDollarIcon,
      restricted: true,
    },
  ]

  return (
    <nav
      aria-label="Sidebar"
      className="mb-10 mt-16 flex w-full flex-1 flex-col rounded-lg bg-purple-50 px-8 py-4 sm:min-h-screen sm:max-w-max"
    >
      <ul className="-mx-2 space-y-1">
        {navigation.map(item =>
          !viewer.directory && item.restricted ? (
            <li key={item.name}>
              <span className="flex gap-x-3 rounded-md p-2 text-sm font-semibold italic leading-6 text-gray-400">
                <item.icon
                  aria-hidden="true"
                  className="h-6 w-6 shrink-0 text-gray-400"
                />
                {item.name}
              </span>
            </li>
          ) : (
            <li key={item.name}>
              <NavLink
                to={item.path}
                className={classNames(
                  location.pathname.includes(item.path)
                    ? "bg-purple-100 text-purple-700"
                    : "text-gray-700 hover:bg-purple-100 hover:text-purple-700",
                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                )}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    location.pathname.includes(item.path)
                      ? "text-purple-700"
                      : "text-gray-400 group-hover:text-purple-700",
                    "h-6 w-6 shrink-0"
                  )}
                />
                {item.name}
              </NavLink>
            </li>
          )
        )}
      </ul>
    </nav>
  )
}

export default DirectoryNav
