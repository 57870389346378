import React, { useState } from "react"

import { Dayjs } from "dayjs"
import { Form, Formik } from "formik"
import { DateObject } from "react-multi-date-picker"
import { NotificationManager } from "react-notifications"
import { Redirect, useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { path } from "~Tutor/TutorRoutes"
import TutorNote from "~Tutor/components/TutorNote"
import { AlertMessageBox, H2, Link } from "~tailwindui/Basics"
import { DateTimeInput, Error, SubmitButton, TextInput } from "~tailwindui/Form"

import airbrake from "src/airbrake-notifier"
import classNames from "src/classNames"
import { RunMutation } from "src/types"

import LocalTime from "components/LocalTime"

import StudyGroupSubjects from "./StudyGroupSubjects"

const ValidationSchema = Yup.object().shape({
  subjectId: Yup.string().nullable().required("Please select a subject"),
  sessionDate: Yup.string().required("Please specify a date"),
  durationMinutes: Yup.number()
    .required("Please specify a duration")
    .min(15, "Sessions must be at least 15 minutes long"),
})

type StudyGroup = {
  id: string
  name: string
  details: string
}

export type ScheduleSessionFormProps = {
  subjectId?: string
  startsAt?: Dayjs
  studyGroup: StudyGroup
}

const ScheduleSessionForm: React.FC<ScheduleSessionFormProps> = ({
  subjectId,
  startsAt,
  studyGroup,
}) => {
  const [errors, setErrors] = useState([])
  const history = useHistory()

  const sessionDate =
    startsAt || new DateObject().setHour(12).setMinute(0).setSecond(0)

  const formState = {
    subjectId: subjectId,
    sessionDate: sessionDate,
    durationMinutes: 60,
    studyGroupId: studyGroup.id,
  }

  const [, runMutation]: [any, RunMutation] = useMutation(
    scheduleSessionMutation
  )

  const handleSubmit = (values, actions) => {
    const { sessionDate, ...rest } = values
    runMutation({ sessionDate: sessionDate.toDate(), ...rest })
      .then(
        response => {
          const { session, failures } = response.data.scheduleSession
          if (session) {
            NotificationManager.success("Session scheduled")
            history.push(
              path("sessionDetails", {
                id: session.id,
              })
            )
          } else {
            actions.setStatus("Failed to schedule session")
            setErrors(failures)
          }
        },
        error => {
          actions.setStatus("Something went wrong")
          airbrake.notify({ error, name: "ScheduleSessionForm" })
        }
      )
      .catch(error => {
        actions.setStatus("Something went wrong")
        airbrake.notify({ error, name: "ScheduleSessionForm" })
      })
      .finally(() => actions.setSubmitting(false))
  }

  if (!studyGroup) return <Redirect to={path("dashboard")} />

  return (
    <div className="m-auto w-full md:w-1/2">
      <H2>Schedule Group Session for {studyGroup.name}</H2>
      <TutorNote title="Group Details" content={studyGroup.details} />

      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        {({ setFieldValue }) => (
          <Form>
            <label>Subject</label>
            <StudyGroupSubjects
              studyGroupId={studyGroup.id}
              onChange={selected => setFieldValue("subjectId", selected.id)}
              subjectId={subjectId}
            />
            <Error name="subjectId" />

            <div className="flex flex-col md:flex-row md:space-x-10">
              <DateTimeInput
                name="sessionDate"
                label="Session Date"
                displayWarnings
              />

              <TextInput
                name="durationMinutes"
                label="Duration (in minutes)"
                type="number"
                min="15"
                step="15"
              />
            </div>

            {errors.length > 0 && (
              <AlertMessageBox>
                <div
                  className={classNames(
                    errors.length > 1
                      ? "flex list-disc flex-col space-y-1 pl-5"
                      : "list-none"
                  )}
                >
                  {errors.map(error => (
                    <li key={error}>
                      <span>{error.message}</span>
                      {error.conflictingSessions && (
                        <ul>
                          {error.conflictingSessions.map(session => (
                            <li key={session.id}>
                              <Link
                                to={path("sessionDetails", {
                                  id: session.id,
                                })}
                                className="underline"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <LocalTime timestamp={session.startsAt} />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </div>
              </AlertMessageBox>
            )}

            <SubmitButton text="Schedule" />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const scheduleSessionMutation = gql`
  mutation scheduleSession(
    $subjectId: ID!
    $studyGroupId: ID!
    $sessionDate: DateTime!
    $durationMinutes: Int!
  ) {
    scheduleSession(
      subjectId: $subjectId
      studyGroupId: $studyGroupId
      sessionDate: $sessionDate
      durationMinutes: $durationMinutes
    ) {
      failures {
        message
        conflictingSessions {
          id
          startsAt
        }
      }
      session {
        id
      }
    }
  }
`

export default ScheduleSessionForm
