import React from "react"

import { useFormikContext } from "formik"

const FormStatus = () => {
  const { status, errors } = useFormikContext()

  return (
    <div className="mt-8">
      {(status || Object.keys(errors).length > 0) && (
        <div className="my-3 mx-1 rounded bg-red-500 p-3 text-left text-white">
          {status || "Please fix validation errors above"}
        </div>
      )}
    </div>
  )
}

export default FormStatus
