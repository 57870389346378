import React from "react"

import {
  BellIcon,
  BookOpenIcon,
  MegaphoneIcon,
  RocketLaunchIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import { path } from "components/Tutor/TutorRoutes"
import ActionList from "components/Tutor/components/ActionList"

export type TutoringOpportunitiesProps = {
  viewer: any
}

const TutoringOpportunities: React.FC<TutoringOpportunitiesProps> = ({
  viewer,
}) => {
  return (
    <ActionList
      title="Tutoring Opportunities"
      color={Color.Green}
      emptyText="No new opportunities right now. Check back later!"
      listItems={[
        !viewer.directory && {
          icon: (
            <RocketLaunchIcon className="mr-3 inline-block h-8 w-8 text-green-600" />
          ),
          path: path("directory"),
          text: "Join the Tutor Directory",
        },
        viewer.requestedSessions.data.length && {
          icon: (
            <BellIcon className="mr-3 inline-block h-8 w-8 text-green-600" />
          ),
          path: path("requestedSessionsIndex"),
          text: `Requested Sessions (${viewer.requestedSessions.data.length})`,
        },
        viewer.availableStudyGroupReplacements.length > 0 && {
          icon: (
            <UserGroupIcon className="mr-3 inline-block h-8 w-8 text-green-600" />
          ),
          path: path("availableStudyGroupReplacementsIndex"),
          text: `Study Group Replacements (${viewer.availableStudyGroupReplacements.length})`,
        },
        viewer.registerableCourseClusters.length && {
          icon: (
            <BookOpenIcon className="mr-3 inline-block h-8 w-8 text-green-600" />
          ),
          path: path("courseClustersIndex"),
          text: `View Open Course Clusters (${viewer.registerableCourseClusters.length})`,
        },
        viewer.openGenerationInvites.length > 0 && {
          icon: (
            <MegaphoneIcon className="mr-3 inline-block h-8 w-8 text-green-600" />
          ),
          path: path("openGenerationInvitesIndex"),
          text: `Open Study Group Invitations (${viewer.openGenerationInvites.length})`,
        },
      ]}
    />
  )
}

export default TutoringOpportunities
