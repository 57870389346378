import React, { useMemo, useState } from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"

import { findRoute, path } from "~Tutor/TutorRoutes"
import {
  CheckboxInput,
  Failures,
  SubmitButton,
  handleFailure,
} from "~tailwindui/Form"

import { RunMutation } from "src/types"

import RequestSubjectModal from "./RequestSubjectModal"

export type EditSubjectBucketsProps = {
  approvalType: "airtutors" | "directory"
  tier: {
    id: string
    subjectBuckets: {
      id: string
      name: string
    }[]
  }
  viewer: {
    id: string
    subjectBuckets: {
      id: string
      name: string
      approvalState: string
    }[]
  }
}

const EditSubjectBuckets: React.FC<EditSubjectBucketsProps> = ({
  tier,
  approvalType,
  viewer,
}) => {
  const history = useHistory()
  const [requestModalOpen, setRequestModalOpen] = useState(false)

  const formState = {
    tutorId: viewer.id,
    tierId: tier.id,
    subjectBucketIds: viewer.subjectBuckets.map(bucket => bucket.id),
    approvalType,
  }

  const [, runMutation]: [any, RunMutation] = useMutation(
    updateTutorSubjectsMutation
  )

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(result => {
        const { failures } = result.data.updateTutorSubjects
        if (failures.length > 0) handleFailure(actions, failures)
        else {
          history.push(
            approvalType === "directory"
              ? findRoute("directory.tutorSubjectsIndex").path
              : path("subjectBucketsIndex")
          )
          NotificationManager.success(
            "Your subjects have been updated. If approval is necessary, an admin will review them shortly."
          )
        }
      })
      .catch(() => handleFailure(actions))
      .finally(() => actions.setSubmitting(false))
  }

  const subjectBucketOptions = useMemo(() => {
    return tier.subjectBuckets.map(bucket => {
      const tutorSubjectBucket = viewer.subjectBuckets.find(
        sb => sb.id === bucket.id
      )

      return {
        label: `${bucket.name}${
          tutorSubjectBucket?.approvalState === "submitted" ? " (pending)" : ""
        }`,
        value: bucket.id,
        disabled: tutorSubjectBucket?.approvalState === "rejected",
      }
    })
  }, [tier, viewer])

  return (
    <>
      <RequestSubjectModal
        isOpen={requestModalOpen}
        closeModal={() => setRequestModalOpen(false)}
      />

      <Formik initialValues={formState} onSubmit={handleSubmit}>
        <Form>
          <CheckboxInput
            name="subjectBucketIds"
            checkboxType="multi"
            options={subjectBucketOptions}
          />
          <p>
            Subject not listed? Click{" "}
            <span
              className="cursor-pointer text-sky-600 hover:underline"
              onClick={() => setRequestModalOpen(true)}
            >
              here
            </span>{" "}
            to request it!
          </p>

          <Failures />
          <SubmitButton className="mt-4 w-full" />
        </Form>
      </Formik>
    </>
  )
}

const updateTutorSubjectsMutation = gql`
  mutation UpdateTutorSubjects(
    $tutorId: ID!
    $tierId: ID!
    $subjectBucketIds: [ID!]!
    $approvalType: ApprovalTypeEnum!
  ) {
    updateTutorSubjects(
      tutorId: $tutorId
      tierId: $tierId
      subjectBucketIds: $subjectBucketIds
      approvalType: $approvalType
    ) {
      failures {
        message
      }
    }
  }
`

export default EditSubjectBuckets
