import { createContext } from "react"

type Tutor = {
  id: string
  directory: boolean
  airtutors: boolean
  fullName: string
  firstName: string
  lastName: string
  email: string
  createdAt: Date
  calendarId: string
  dismissedCalendarInfo: boolean
  requiresNewSchedule: boolean
  stripeAccountId: string
  stripeBankAccountLinked: boolean
  mayCreateExpense: boolean
  avatar: {
    thumbUrl: string
  }
}

type TutorContext = {
  viewer?: Tutor
}

export default createContext<TutorContext>({})
