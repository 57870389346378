import React, { useState } from "react"

import { Form, Formik } from "formik"
import { compress } from "micro-graphql-react"

import { adminClient as client } from "src/graphql-config"

import { useGradeOptions } from "hooks/remoteTable"

import { ErrorMessage, requireInput } from "components/Forms/Formik"
import {
  AutocompleteField,
  Field,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import Results from "../Results"

const AddStudents = ({ addRecipients }) => {
  const formState = {
    tagList: [],
    organizationId: [],
    schoolId: [],
    gradeId: [],
    studentFullName: "",
  }

  const [students, setStudents] = useState()
  const gradeOptions = useGradeOptions()

  const performSearch = (values, actions) => {
    client
      .runQuery(studentSearchQuery, values)
      .then(
        response => {
          setStudents(response.data.students)
        },
        () => {
          actions.setStatus("An error occurred")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("An error occurred")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Students"
      buttonClassName="btn btn-info"
      buttonText="Add Students"
      afterClose={() => {
        setStudents()
      }}
    >
      {({ closeModal }) =>
        students ? (
          <Results
            users={students}
            addRecipients={addRecipients}
            sourceOptions={[
              { label: "Use Student Phone", value: "student" },
              { label: "Use Parent Phone", value: "parent" },
            ]}
            closeModal={closeModal}
          />
        ) : (
          <Formik
            initialValues={formState}
            onSubmit={performSearch}
            validate={requireInput}
          >
            <Form>
              <div className="modal-body">
                <AutocompleteField
                  name="tagList"
                  label="Tags"
                  api="/api/admins/students/autocomplete_tags"
                  isMulti
                  valueAttribute="label"
                />
                <AutocompleteField
                  name="organizationIds"
                  label="Organizations"
                  api="/api/admins/organizations/autocomplete_name"
                  isMulti
                />
                <AutocompleteField
                  name="schoolIds"
                  label="Schools"
                  api="/api/admins/schools/autocomplete_name"
                  isMulti
                />
                <SelectField
                  name="gradeIds"
                  label="Grades"
                  options={gradeOptions}
                  isClearable
                  isMulti
                  styles={{
                    container: provided => ({
                      ...provided,
                      maxWidth: 250,
                    }),
                  }}
                />
                <Field name="studentFullName" label="Student Name" />
              </div>

              <div className="modal-footer flex-column">
                <FormStatus />
                <ErrorMessage name="base" />
                <SubmitButton text="Search" />
              </div>
            </Form>
          </Formik>
        )
      }
    </ModalWithProvidedBody>
  )
}

const studentSearchQuery = compress`
  query($tagList: [String!], $organizationIds: [ID!], $schoolIds: [ID!], $gradeIds: [ID!], $studentFullName: String) {
    students(
      tagList: $tagList
      organizationIds: $organizationIds
      schoolIds: $schoolIds
      gradeIds: $gradeIds
      studentFullName: $studentFullName
    ) {
      id
      fullName
      lastName
      phoneNumber
      parentPhoneNumber
    }
  }
`

export default AddStudents
