import React, { createRef, useEffect, useRef } from "react"

import { PieChart as ApacheDonutChart } from "echarts/charts"
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import * as echarts from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"

import { default as classNamesHelper } from "src/classNames"

type DonutChartProps = {
  title: string
  data: { value: number; name: string }[]
  height?: "small" | "medium" | "large"
  color?: string[]
  classNames?: string
}

const DonutChart: React.FC<DonutChartProps> = ({
  title,
  data,
  classNames,
  color,
  height = "small",
}) => {
  const chartOpts = {
    tooltip: { trigger: "item" },
    series: [
      {
        name: title,
        type: "pie",
        radius: ["40%", "70%"],
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }
  if (color) chartOpts["color"] = color

  const chartContainer = createRef<HTMLDivElement>()
  const chartObject = useRef<echarts.ECharts>()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    echarts.use([
      ApacheDonutChart,
      CanvasRenderer,
      GridComponent,
      TitleComponent,
      TooltipComponent,
    ])
    chartObject.current = echarts.init(chartContainer.current)

    return () => {
      if (chartContainer.current) {
        echarts.dispose(chartContainer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (chartOpts) chartObject.current.setOption(chartOpts)
  }, [data])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const resize = () => {
      if (chartObject.current) chartObject.current.resize()
    }
    window.addEventListener("resize", resize)

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  const heightClass = {
    small: "h-[25vh]",
    medium: "h-[50vh]",
    large: "h-[80vh]",
  }

  return (
    <div
      className={classNamesHelper(heightClass[height], classNames)}
      ref={chartContainer}
    />
  )
}

export default DonutChart
