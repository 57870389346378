import React from "react"

import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"

import { H2 } from "~tailwindui/Basics"
import { handleFailure } from "~tailwindui/Form"

import { RunMutation } from "src/types"

import { findRoute } from "components/Tutor/TutorRoutes"

import CourseClusterForm from "../Form"

export type NewCourseClusterProps = Record<string, never>

const NewCourseCluster: React.FC<NewCourseClusterProps> = () => {
  const history = useHistory()

  const [, runMutation]: [any, RunMutation] = useMutation(
    createCourseClusterMutation
  )

  const handleSubmit = (values, actions) => {
    const { prepaidPrice, courseSections, ...rest } = values
    runMutation({
      input: {
        ...rest,
        prepaidPriceCents: values.prepaidPrice * 100,
        courseSections: courseSections.map(
          ({ editable, ...section }) => section
        ),
      },
    })
      .then(
        result => {
          const { failures } = result.data.createDirectoryCourseCluster
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            history.push(findRoute("directory.courseClustersIndex").path)
            NotificationManager.success("Your course has been created")
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <div className="pb-10 lg:w-2/3">
      <H2>New Course</H2>
      <CourseClusterForm onSubmit={handleSubmit} />
    </div>
  )
}

const createCourseClusterMutation = gql`
  mutation createDirectoryCourseCluster($input: DirectoryCourseInputObject!) {
    createDirectoryCourseCluster(input: $input) {
      failures {
        message
      }
      courseCluster {
        id
      }
    }
  }
`

export default NewCourseCluster
