import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import titlecase from "src/titlecase"

import LocalTime from "components/LocalTime"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"

export type PastSessionsProps = {
  studyGroupId: string
}

const PastSessions: React.FC<PastSessionsProps> = ({ studyGroupId }) => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query,
    variables: { studyGroupId, page },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <Table
          title="Past Sessions"
          data={viewer.pastGroupSessions}
          changePage={setPage}
          headers={["Date", "Subject", "Students", "State", "Actions"]}
          valueMapper={session => [
            <span key={session.id} className="flex items-center">
              {session.directory ? (
                <DirectoryIcon recordType={"session"} />
              ) : (
                <AirtutorsIcon recordType={"session"} />
              )}
              <div key={session.id} className="min-w-[80px]">
                <LocalTime format="ddd MMM D" timestamp={session.startsAt} />
                <br />
                <LocalTime omitDate timestamp={session.startsAt} />
                &mdash;
                <LocalTime omitDate timestamp={session.endsAt} />
              </div>
            </span>,
            session.subject.name,
            <ul key={session.id}>
              {session.students.map(student => (
                <li className="flex items-center" key={student.id}>
                  <div>
                    <Link
                      to={path("studentDetails", {
                        id: student.id,
                      })}
                    >
                      {student.fullName}
                    </Link>
                  </div>
                </li>
              ))}
            </ul>,
            <span key={session.id}>
              {titlecase(session.statusState)} | {titlecase(session.billState)}
            </span>,
            <div className="flex items-center space-x-1" key={session.id}>
              <Link
                to={path("sessionDetails", {
                  id: session.id,
                })}
                size={Size.Small}
                button
              >
                View
              </Link>
            </div>,
          ]}
        />
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query StudyGroupPastSessions($studyGroupId: ID!, $page: Int) {
    viewer {
      pastGroupSessions: sessions(
        filters: { past: true, studyGroupIds: [$studyGroupId] }
        page: $page
      ) {
        currentPage
        totalPages
        data {
          id
          directory
          airtutors
          startsAt
          endsAt
          statusState
          billState
          subject {
            id
            name
          }
          students {
            id
            fullName
          }
        }
      }
    }
  }
`

export default PastSessions
