import React from "react"

import { Redirect, RouteComponentProps } from "react-router"

import { MatchParams } from "src/types/MatchParams"

import AvailableStudyGroupReplacements from "./Pages/AvailableStudyGroupReplacements"
import CourseClusters from "./Pages/CourseClusters/Index"
import TutorDashboard from "./Pages/Dashboard"
import Directory from "./Pages/Directory"
import DirectoryBilling from "./Pages/Directory/Billing"
import EditCourse from "./Pages/Directory/Courses/Edit"
import DirectoryCourseClustersIndex from "./Pages/Directory/Courses/Index"
import NewCourse from "./Pages/Directory/Courses/New"
import TutorSchedules from "./Pages/Directory/TutorSchedules/ManageSchedules"
import DirectorySubjectBucketEdit from "./Pages/Directory/TutorSubjects/Edit"
import DirectoryTutorSubjects from "./Pages/Directory/TutorSubjects/Index"
import Welcome from "./Pages/Directory/Welcome"
import EditExpense from "./Pages/Expenses/Edit"
import Expenses from "./Pages/Expenses/Index"
import NewExpense from "./Pages/Expenses/New"
import NotFound from "./Pages/NotFound"
import OpenGenerationInvites from "./Pages/OpenGenerationInvites"
import Profile from "./Pages/Profile/Details"
import EditProfile from "./Pages/Profile/Edit"
import SessionReporting from "./Pages/Reporting"
import BillSession from "./Pages/Sessions/Bill"
import Cloudroom from "./Pages/Sessions/Cloudroom"
import SessionDetails from "./Pages/Sessions/Details"
import RequestDurationChange from "./Pages/Sessions/DurationChange"
import EditEvaluation from "./Pages/Sessions/EditEvaluation"
import Sessions from "./Pages/Sessions/Index"
import RequestedSessionsPage from "./Pages/Sessions/RequestedSessions"
import RescheduleSession from "./Pages/Sessions/Reschedule"
import UnbilledSessionsPage from "./Pages/Sessions/UnbilledSessions"
import LessonPlan from "./Pages/Sessions/components/LessonResources/LessonPlan"
import StudentDetails from "./Pages/Students/Details"
import Students from "./Pages/Students/Index"
import TailoredAssignmentResults from "./Pages/Students/TailoredAssignments/Details"
import TailoredAssignments from "./Pages/Students/TailoredAssignments/Index"
import TailoredSectionReview from "./Pages/Students/TailoredAssignments/TailoredSectionReview"
import ExamInstanceDetails from "./Pages/Students/TestPrep/ExamInstances/Details"
import ExamInstances from "./Pages/Students/TestPrep/ExamInstances/Index"
import SectionInstanceDetails from "./Pages/Students/TestPrep/SectionInstances/Details"
import StudyGroupTutorInvitation from "./Pages/StudyGroupInvitation/Details"
import StudyGroupDetails from "./Pages/StudyGroups/Details"
import StudyGroups from "./Pages/StudyGroups/Index"
import StudyGroupsNeedingScheduling from "./Pages/StudyGroups/NeedingScheduling"
import ScheduleStudyGroupSession from "./Pages/StudyGroups/ScheduleSession"
import EditTutorSubjects from "./Pages/TutorSubjects/Edit"
import TutorSubjects from "./Pages/TutorSubjects/Index"

import {
  CourseClusterName,
  ExamInstanceName,
  SectionInstanceName,
  SessionTitle,
  StudentName,
  StudyGroupInvitationName,
  StudyGroupName,
  TailoredAssignmentName,
  TailoredSectionName,
  TierRange,
} from "./TutorBreadcrumbs"

export type RouteConfig = {
  name?: string
  path: string
  component: React.FC<any>
  exact: boolean
  breadcrumb: string | React.FC<RouteComponentProps<MatchParams>>
  subRoutes?: Record<string, RouteConfig>
  routes?: RouteConfig[]
}

export const findRoute = (routeName: string): RouteConfig => {
  const routeNames = routeName.split(".")
  let route = TutorRoutes.find(
    routeConfig => routeConfig.name === routeNames[0]
  )
  routeNames
    .slice(1)
    .forEach(
      routeName =>
        (route = route.routes.find(subRoute => subRoute.name === routeName))
    )
  return route
}

export const path = (routeName: string, ids: Record<string, string> = {}) => {
  const route = findRoute(routeName)

  let path = route.path.slice()
  for (const key in ids) {
    const value = ids[key]
    const wildcard = `/:${key}`
    path = path.replace(wildcard, `/${value}`)
  }

  if (path.includes("/:")) {
    throw new Error(
      `path error: Appropriate keys must be provided to ${routeName}`
    )
  }
  return path
}

const TutorRoutes: RouteConfig[] = [
  {
    name: "home",
    path: "/",
    component: () => <Redirect to="/dashboard" />,
    breadcrumb: null,
    exact: true,
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: TutorDashboard,
    breadcrumb: "Dashboard",
    exact: true,
  },
  {
    name: "profileDetails",
    path: "/profile",
    component: Profile,
    breadcrumb: "Profile",
    exact: true,
  },
  {
    name: "profileEdit",
    path: "/profile/edit",
    component: EditProfile,
    breadcrumb: "Edit",
    exact: true,
  },
  {
    name: "sessionsIndex",
    path: "/sessions",
    component: Sessions,
    breadcrumb: "Sessions",
    exact: true,
  },
  {
    name: "sessionReporting",
    path: "/reporting",
    component: SessionReporting,
    breadcrumb: "Reporting",
    exact: true,
  },
  {
    name: "unbilledSessionsIndex",
    path: "/sessions/unbilled",
    component: UnbilledSessionsPage,
    breadcrumb: "Unbilled",
    exact: true,
  },
  {
    name: "requestedSessionsIndex",
    path: "/sessions/requested",
    component: RequestedSessionsPage,
    breadcrumb: "Requested",
    exact: true,
  },
  {
    name: "sessionDetails",
    path: "/sessions/:id",
    component: SessionDetails,
    breadcrumb: ({ match }) => <SessionTitle id={match.params.id} />,
    exact: true,
  },
  {
    name: "sessionReschedule",
    path: "/sessions/:id/reschedule",
    component: RescheduleSession,
    breadcrumb: "Reschedule",
    exact: true,
  },
  {
    name: "sessionBill",
    path: "/sessions/:id/bill",
    component: BillSession,
    breadcrumb: "Bill",
    exact: true,
  },
  {
    name: "sessionCloudroom",
    path: "/sessions/:id/cloudroom",
    component: Cloudroom,
    breadcrumb: null,
    exact: true,
  },
  {
    name: "sessionDurationChange",
    path: "/sessions/:id/duration_change",
    component: RequestDurationChange,
    breadcrumb: "Duration Change",
    exact: true,
  },
  {
    name: "sessionEvaluationEdit",
    path: "/sessions/:id/evaluation",
    component: EditEvaluation,
    breadcrumb: "Edit Evaluation",
    exact: true,
  },
  {
    name: "studyGroupsIndex",
    path: "/study_groups",
    component: StudyGroups,
    breadcrumb: "Study Groups",
    exact: true,
  },
  {
    name: "studyGroupsIndexNeedingScheduling",
    path: "/study_groups/need_scheduling",
    component: StudyGroupsNeedingScheduling,
    breadcrumb: "Need Scheduling",
    exact: true,
  },
  {
    name: "studyGroupDetails",
    path: "/study_groups/:id",
    component: StudyGroupDetails,
    breadcrumb: ({ match }) => <StudyGroupName id={match.params.id} />,
    exact: true,
  },
  {
    name: "studyGroupScheduleSession",
    path: "/study_groups/:id/schedule_session",
    component: ScheduleStudyGroupSession,
    breadcrumb: "Schedule Session",
    exact: true,
  },
  {
    name: "studentsIndex",
    path: "/students",
    component: Students,
    breadcrumb: "Students",
    exact: true,
  },
  {
    name: "studentDetails",
    path: "/students/:id",
    component: StudentDetails,
    breadcrumb: ({ match }) => <StudentName id={match.params.id} />,
    exact: true,
  },
  {
    name: "studentTestPrepExamsIndex",
    path: "/students/:id/test_prep_exams",
    component: ExamInstances,
    breadcrumb: "Test Prep Exams",
    exact: true,
  },
  {
    name: "studentTestPrepExamInstanceDetails",
    path: "/students/:studentId/test_prep_exams/:id",
    component: ExamInstanceDetails,
    breadcrumb: ({ match }) => <ExamInstanceName id={match.params.id} />,
    exact: true,
  },
  {
    name: "studentTestPrepSectionInstanceDetails",
    path: "/students/:studentId/test_prep_exams/:examInstanceId/:id",
    component: SectionInstanceDetails,
    breadcrumb: ({ match }) => <SectionInstanceName id={match.params.id} />,
    exact: true,
  },
  {
    name: "studentTailoredAssignmentsIndex",
    path: "/students/:id/tailored_assignments",
    component: TailoredAssignments,
    breadcrumb: "Tailored Assignments",
    exact: true,
  },
  {
    name: "studentTailoredAssignmentDetails",
    path: "/students/:studentId/tailored_assignments/:id",
    component: TailoredAssignmentResults,
    breadcrumb: ({ match }) => <TailoredAssignmentName id={match.params.id} />,
    exact: true,
  },
  {
    name: "studentTailoredSectionDetails",
    path: "/students/:studentId/tailored_assignments/:tailoredAssignmentId/:id",
    component: TailoredSectionReview,
    breadcrumb: ({ match }) => <TailoredSectionName id={match.params.id} />,
    exact: true,
  },
  {
    name: "studentLessonPlansIndex",
    path: "/students/:id/lesson_plans",
    component: LessonPlan,
    breadcrumb: "Lesson Plans",
    exact: true,
  },
  {
    name: "directory",
    path: "/directory",
    component: Directory,
    breadcrumb: "Tutor Directory",
    exact: false,
    routes: [
      {
        name: "welcome",
        path: "/directory/welcome",
        component: Welcome,
        breadcrumb: "Welcome",
        exact: true,
      },
      {
        name: "courseClustersIndex",
        path: "/directory/courses",
        component: DirectoryCourseClustersIndex,
        breadcrumb: "Courses",
        exact: true,
      },
      {
        name: "courseClusterNew",
        path: "/directory/courses/new",
        component: NewCourse,
        breadcrumb: "New Course",
        exact: true,
      },
      {
        name: "courseClusterEdit",
        path: "/directory/courses/:id",
        component: EditCourse,
        breadcrumb: ({ match }) => <CourseClusterName id={match.params.id} />,
        exact: true,
      },
      {
        name: "tutorSubjectsIndex",
        path: "/directory/subjects",
        component: DirectoryTutorSubjects,
        breadcrumb: "Subjects",
        exact: true,
      },
      {
        name: "tutorSubjectsEdit",
        path: "/directory/subjects/:id",
        component: DirectorySubjectBucketEdit,
        breadcrumb: ({ match }) => <TierRange id={match.params.id} />,
        exact: true,
      },
      {
        name: "tutorSchedules",
        path: "/directory/availability",
        component: TutorSchedules,
        breadcrumb: "Availability",
        exact: true,
      },
      {
        name: "billing",
        path: "/directory/billing",
        component: DirectoryBilling,
        breadcrumb: "Billing",
        exact: true,
      },
      {
        name: "notFound",
        path: "/directory/*",
        component: NotFound,
        breadcrumb: "Not Found",
        exact: false,
      },
    ],
  },
  {
    name: "subjectBucketsIndex",
    path: "/subjects",
    component: TutorSubjects,
    breadcrumb: "Subjects",
    exact: true,
  },
  {
    name: "tutorSubjectsEdit",
    path: "/subjects/:id",
    component: EditTutorSubjects,
    breadcrumb: ({ match }) => <TierRange id={match.params.id} />,
    exact: true,
  },
  {
    name: "expensesIndex",
    path: "/expenses",
    component: Expenses,
    breadcrumb: "Expenses",
    exact: true,
  },
  {
    name: "expenseNew",
    path: "/expenses/new",
    component: NewExpense,
    breadcrumb: "New",
    exact: true,
  },
  {
    name: "expenseEdit",
    path: "/expenses/:id/edit",
    component: EditExpense,
    breadcrumb: "Edit",
    exact: true,
  },
  {
    name: "courseClustersIndex",
    path: "/course_clusters",
    component: CourseClusters,
    breadcrumb: "Course Clusters",
    exact: true,
  },
  {
    name: "openGenerationInvitesIndex",
    path: "/study_group_invitations",
    component: OpenGenerationInvites,
    breadcrumb: "Study Group Invitations",
    exact: true,
  },
  {
    name: "availableStudyGroupReplacementsIndex",
    path: "/available_study_group_replacements",
    component: AvailableStudyGroupReplacements,
    breadcrumb: "Available Study Group Replacements",
    exact: true,
  },
  {
    name: "studyGroupTutorInvitationDetails",
    path: "/study_group_invitations/:id",
    component: StudyGroupTutorInvitation,
    breadcrumb: ({ match }) => (
      <StudyGroupInvitationName id={match.params.id} />
    ),
    exact: true,
  },
  {
    name: "notFound",
    path: "/*",
    component: NotFound,
    breadcrumb: "Not Found",
    exact: false,
  },
]

export default TutorRoutes
