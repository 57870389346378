import React from "react"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const RecordsSearch = () => {
  return (
    <div className="mx-3">
      <AutocompleteSelect
        api="/api/admins/dashboard/autocomplete_records"
        placeholder="Search"
        onChange={selected => {
          if (!selected?.value) return
          window.location.href = selected.value
        }}
      />
    </div>
  )
}

export default RecordsSearch
