import React, { useEffect, useState } from "react"

import dayjs from "dayjs"
import {
  FormikContextType,
  FormikValues,
  useField,
  useFormikContext,
} from "formik"

import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button } from "~tailwindui/Basics"
import { DateInput, SelectInput, TimeInput } from "~tailwindui/Form"

import { weekdayOptions } from "src/enums"

import LocalTime from "components/LocalTime"

import { sectionValidator } from "./sectionValidator"

export type SectionsProps = Record<string, never>

const Sections: React.FC<SectionsProps> = () => {
  const { values, setFieldValue }: FormikContextType<FormikValues> =
    useFormikContext()
  const [_, meta] = useField("courseSections")

  const [selectedSectionIdx, setSelectedSectionIdx] = useState(
    values.courseSections.length - 1
  )
  const [sectionFormOpen, setSectionFormOpen] = useState(false)
  const [editingSection, setEditingSection] = useState(false)
  const [sectionError, setSectionError] = useState<string | null>()

  useEffect(() => {
    setFieldValue("courseSections", [])
    setSectionFormOpen(false)
  }, [values.sessionsPerWeek, setFieldValue])

  useEffect(() => {
    if (meta.error) setSectionError(meta.error)
  }, [meta, setSectionError])

  useEffect(() => {
    if (!values.durationWeeks) return

    const updatedSections = values.courseSections.map(section =>
      section.startsOn
        ? {
            ...section,
            endsOn: dayjs(section.startsOn).add(values.durationWeeks, "weeks"),
          }
        : section
    )
    setFieldValue("courseSections", updatedSections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.durationWeeks, setFieldValue])

  useEffect(() => {
    setSelectedSectionIdx(values.courseSections.length - 1)
  }, [values.courseSections.length])

  return (
    <>
      <div className="font-lg my-4 border-b border-solid font-semibold">
        Course Sections
      </div>
      <ul>
        {values.courseSections.map((section, idx) =>
          section.draft ? null : (
            <li
              key={idx}
              className="mb-4 flex flex-col justify-between space-y-2 rounded-md bg-gray-100 p-4 text-gray-700 sm:flex-row sm:items-center sm:space-y-0"
            >
              <div className="font-medium">
                <div>
                  <LocalTime timestamp={section.startsOn.toString()} omitTime />{" "}
                  - <LocalTime timestamp={section.endsOn.toString()} omitTime />
                </div>
                <ul className="list-none text-sm">
                  {section.availabilities.map((availability, idx) => (
                    <li key={idx}>
                      {availability.weekday}s{" "}
                      <LocalTime
                        timestamp={availability.startsAt.toString()}
                        omitDate
                      />{" "}
                      -{" "}
                      <LocalTime
                        timestamp={availability.endsAt.toString()}
                        omitDate
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex space-x-4">
                {section.editable && !editingSection && (
                  <Button
                    soft
                    type="button"
                    onClick={() => {
                      setSelectedSectionIdx(idx)
                      setEditingSection(true)
                      setSectionFormOpen(true)
                    }}
                  >
                    Edit
                    <PencilSquareIcon className="ml-2 h-5 w-5 sm:mt-0" />
                  </Button>
                )}

                <Button
                  soft
                  type="button"
                  color={Color.Red}
                  onClick={() => {
                    const otherSections = values.courseSections.filter(
                      sec => JSON.stringify(sec) !== JSON.stringify(section)
                    )
                    setFieldValue("courseSections", otherSections)
                  }}
                >
                  Remove
                  <TrashIcon className="ml-2 h-5 w-5 sm:mt-0" />
                </Button>
              </div>
            </li>
          )
        )}
      </ul>

      {!sectionFormOpen && (
        <Button
          type="button"
          soft
          className="mb-4 cursor-pointer"
          onClick={() => {
            setSelectedSectionIdx(values.courseSections.length)
            setFieldValue("courseSections", [
              ...values.courseSections,
              {
                draft: true,
                editable: true,
                availabilities: new Array(values.sessionsPerWeek || 0)
                  .fill(0)
                  .map(() => ({
                    startsAt: dayjs().set("hour", 12).set("minute", 0),
                    endsAt: dayjs().set("hour", 12).set("minute", 0),
                  })),
              },
            ])
            setSectionFormOpen(true)
          }}
        >
          Add New Section
        </Button>
      )}

      {sectionFormOpen && (
        <div className="mb-4 ml-4">
          <DateInput
            name={`courseSections[${selectedSectionIdx}].startsOn`}
            label="Start Date"
            displayWarnings={false}
            afterChange={date => {
              if (date) {
                setFieldValue(
                  `courseSections[${selectedSectionIdx}].endsOn`,
                  date.add(values.durationWeeks, "weeks")
                )
              }
            }}
          />

          <ul className="space-y-10">
            {new Array(values.sessionsPerWeek || 0).fill(0).map((_, idx) => (
              <li key={idx}>
                <SelectInput
                  label={`Session ${idx + 1} Weekday`}
                  name={`courseSections[${selectedSectionIdx}].availabilities[${idx}].weekday`}
                  options={weekdayOptions}
                  className="w-6"
                  portal
                />
                <TimeInput
                  label={`Session ${idx + 1} Start Time`}
                  name={`courseSections[${selectedSectionIdx}].availabilities[${idx}].startsAt`}
                />
                <TimeInput
                  label={`Session ${idx + 1} End Time`}
                  name={`courseSections[${selectedSectionIdx}].availabilities[${idx}].endsAt`}
                />
              </li>
            ))}
          </ul>

          {sectionError && <AlertMessageBox>{sectionError}</AlertMessageBox>}
          <div className="space-x-4">
            <Button
              type="button"
              onClick={() => {
                const error = sectionValidator(
                  values.courseSections[selectedSectionIdx]
                )
                if (error) {
                  setSectionError(error)
                } else {
                  setFieldValue(
                    `courseSections[${selectedSectionIdx}].draft`,
                    undefined
                  )
                  setSectionFormOpen(false)
                  setEditingSection(false)
                  setSectionError(null)
                }
              }}
              soft
            >
              {editingSection ? "Done Editing" : "Complete Section"}
            </Button>
            {!editingSection && (
              <Button
                soft
                type="button"
                color={Color.Red}
                onClick={() => {
                  setFieldValue(
                    `courseSections`,
                    values.courseSections.slice(0, selectedSectionIdx)
                  )
                  setSectionFormOpen(false)
                  setSectionError(null)
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Sections
