import React, { ReactNode, useRef } from "react"

import ReactModal from "react-modal"

import { VoidReturn } from "src/types/VoidReturn"

import { modalHOC } from "components/Modal"

type ModalWithProvidedBodyProps = {
  content?: string
  children?: ReactNode
  closeModal: VoidReturn
  hideTrigger?: boolean
  openModal: VoidReturn
  buttonClassName?: string
  buttonText?: string
  hideCloseButton?: boolean
  isOpen: boolean
  afterOpenModal: VoidReturn
  onRequestClose?: VoidReturn
  modalClassName?: string
  modalDialogClassName?: string
  modalTitle: string
}

const ModalWithProvidedBody: React.FC<ModalWithProvidedBodyProps> = ({
  content,
  children,
  closeModal,
  hideTrigger = false,
  openModal,
  buttonClassName,
  buttonText,
  hideCloseButton = false,
  isOpen,
  afterOpenModal,
  onRequestClose,
  modalClassName = "",
  modalDialogClassName = "",
  modalTitle,
}) => {
  const subtitle = useRef(null)
  let modalContent = <div dangerouslySetInnerHTML={{ __html: content }} />
  if (children) {
    modalContent = (
      <div className="contents">
        {typeof children === "function" ? children({ closeModal }) : children}
      </div>
    )
  }

  let modalTrigger
  if (!hideTrigger)
    modalTrigger = (
      <button type="button" onClick={openModal} className={buttonClassName}>
        {buttonText}
      </button>
    )
  let closeButton
  if (!hideCloseButton) {
    closeButton = (
      <button
        type="button"
        className="close"
        onClick={closeModal}
        aria-label="Close"
        tabIndex={-1}
      >
        <span aria-hidden="true">
          <i className="nc-icon nc-simple-remove text-danger" />
        </span>
      </button>
    )
  }

  return (
    <React.Fragment>
      {modalTrigger}
      <ReactModal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={onRequestClose || closeModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel={buttonClassName}
        bodyOpenClassName="modal-open"
        ariaHideApp={false}
        className={`modal show ${modalClassName}`}
      >
        <div className={`modal-dialog ${modalDialogClassName}`} role="document">
          <div
            className="modal-content max-h-[80vh] overflow-y-scroll [&_form]:contents
            [&_.modal-body]:overflow-y-scroll"
          >
            <div className="modal-header">
              <h2 className="modal-title m-0 text-2xl" ref={subtitle}>
                {modalTitle}
              </h2>
              {closeButton}
            </div>
            {modalContent}
          </div>
        </div>
      </ReactModal>
    </React.Fragment>
  )
}

export default modalHOC(ModalWithProvidedBody)
