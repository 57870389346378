import React, { ReactNode } from "react"

import AlertMessageBox from "~tailwindui/Basics/AlertMessageBox"
import Spinner from "~tailwindui/Basics/Spinner"

import { reportGraphQLError } from "src/airbrake-notifier"

export type WithLoadingIndicatorProps = {
  result: any
  children: ReactNode
  header?: ReactNode | string
  error?: string
}

const WithLoadingIndicator = ({
  result,
  children,
  header = null,
  error = "",
}) => {
  if (result.fetching)
    return (
      <React.Fragment>
        {header}
        <Spinner
          size="large"
          className="fill-blue-600 text-gray-200 dark:text-gray-600"
        />
      </React.Fragment>
    )

  if (result.error) {
    reportGraphQLError({
      result,
      component: "~tailwindui/Basics/WithLoadingIndicator",
    })
    return (
      <React.Fragment>
        <AlertMessageBox>
          {process.env.NODE_ENV === "development"
            ? result.error.message
            : error}
        </AlertMessageBox>
      </React.Fragment>
    )
  }

  if (result.data) {
    return (
      <React.Fragment>
        {header}
        {typeof children === "function"
          ? children({ data: result.data })
          : children}
      </React.Fragment>
    )
  }
  return null
}

export default WithLoadingIndicator
