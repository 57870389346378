import React, { createRef, useEffect, useRef } from "react"

import { BarChart as ApacheBarChart } from "echarts/charts"
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import * as echarts from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"

type BarChartProps = {
  title: string
  data: {
    labels: string[]
    correctCounts: number[]
    incorrectCounts: number[]
    skippedCounts: number[]
    totalCounts: number[]
  }
}

const BarChart: React.FC<BarChartProps> = ({ title, data }) => {
  const chartOpts = {
    title: { show: true, text: title, left: "center" },
    legend: { show: true, top: "bottom" },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      valueFormatter: value => `${value}%`,
    },
    color: ["#91cc75", "#fac858", "#ee6666"],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}%",
      },
      min: 0,
      max: 100,
    },
    yAxis: {
      type: "category",
      data: data.labels,
    },
    series: [
      {
        name: "Correct",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          formatter: "{c}%",
        },
        emphasis: {
          focus: "series",
        },
        data: [...Array.from(Array(data.labels.length).keys())].map(idx =>
          ((data.correctCounts[idx] / data.totalCounts[idx]) * 100).toFixed(0)
        ),
      },
      {
        name: "Skipped",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          formatter: "{c}%",
        },
        emphasis: {
          focus: "series",
        },
        data: [...Array.from(Array(data.labels.length).keys())].map(idx =>
          ((data.skippedCounts[idx] / data.totalCounts[idx]) * 100).toFixed(0)
        ),
      },
      {
        name: "Incorrect",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          formatter: "{c}%",
        },
        emphasis: {
          focus: "series",
        },
        data: [...Array.from(Array(data.labels.length).keys())].map(idx =>
          ((data.incorrectCounts[idx] / data.totalCounts[idx]) * 100).toFixed(0)
        ),
      },
    ],
  }
  const chartContainer = createRef<HTMLDivElement>()
  const chartObject = useRef<echarts.ECharts>()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    echarts.use([
      ApacheBarChart,
      CanvasRenderer,
      GridComponent,
      TitleComponent,
      TooltipComponent,
      LegendComponent,
    ])
    chartObject.current = echarts.init(chartContainer.current)

    return () => {
      if (chartContainer.current) {
        echarts.dispose(chartContainer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (chartOpts) chartObject.current.setOption(chartOpts)
  }, [data])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const resize = () => {
      if (chartObject.current) chartObject.current.resize()
    }
    window.addEventListener("resize", resize)

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  return <div className="h-[25vh]" ref={chartContainer} />
}

export default BarChart
