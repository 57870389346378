import React, { Fragment } from "react"

import logoImageSrc from "assets/airtutors_banner.png"
import { NavLink, useLocation } from "react-router-dom"

import { Disclosure, Menu, Transition } from "@headlessui/react"
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"

import classNames from "src/classNames"

type NavItem = {
  name: string
  path: string
  absolutePath?: boolean
}

type User = {
  id: string
  firstName: string
  fullName: string
  email: string
  avatar: {
    thumbUrl: string
  }
}

export type NavProps = {
  navItems: NavItem[]
  viewer: User
  profilePath: string
  logout: () => void
}

const Nav: React.FC<NavProps> = ({ navItems, viewer, profilePath, logout }) => {
  const location = useLocation()

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-14 w-auto"
                    src={logoImageSrc}
                    alt="Air Tutors"
                  />
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-4">
                  {navItems.map(item =>
                    item.absolutePath ? (
                      <a
                        key={item.name}
                        href={item.path}
                        className={classNames(
                          "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900",
                          location.pathname.startsWith(item.path)
                            ? "border-brand-light text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        )}
                      >
                        {item.name}
                      </a>
                    ) : (
                      <NavLink
                        key={item.name}
                        to={item.path}
                        className={classNames(
                          "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900",
                          location.pathname.startsWith(item.path)
                            ? "border-brand-light text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        )}
                      >
                        {item.name}
                      </NavLink>
                    )
                  )}
                </div>
              </div>
              <div className="hidden md:ml-6 md:flex md:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="relative mx-3">
                  <div>
                    <Menu.Button className="relative flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={viewer.avatar?.thumbUrl}
                        alt=""
                      />
                      <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                          aria-hidden="true"
                        >
                          {viewer.fullName}
                        </span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            to={profilePath}
                            className="block px-4 py-2 text-sm text-gray-700"
                          >
                            Profile
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={logout}
                            className={"block px-4 py-2 text-sm text-gray-700"}
                          >
                            Logout
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navItems.map(item => (
                <NavLink
                  key={item.name}
                  to={item.path}
                  className={classNames(
                    "block border-l-4 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-brand-700",
                    location.pathname.startsWith(item.path)
                      ? "border-brand-light bg-sky-50 text-brand-700"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  )}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={viewer.avatar?.thumbUrl}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {viewer.fullName}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {viewer.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <NavLink
                  to={profilePath}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Profile
                </NavLink>
                <Disclosure.Button
                  onClick={logout}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Nav
