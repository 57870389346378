import React, { MouseEventHandler, ReactNode } from "react"

import { Color, Size } from "~tailwindui/types/enums"

import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

import Spinner from "./Spinner"

export type ButtonProps = {
  children?: ReactNode
  text?: string | ReactNode
  size?: Size
  color?: Color
  className?: string
  outline?: boolean
  soft?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  type?: "button" | "submit" | "reset"
  loading?: boolean
}

const Button: React.FC<
  ButtonProps & Omit<React.HTMLProps<HTMLButtonElement>, "size">
> = ({
  children,
  text,
  size = Size.Medium,
  color = Color.Blue,
  className = "",
  outline = false,
  soft = false,
  loading = false,
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames(
        buttonClassNames({ size, color, outline, soft }),
        className
      )}
      {...props}
    >
      <span className="flex items-center justify-center text-lg">
        {children || text}
        {loading && (
          <span className="ml-4 inline-block">
            <Spinner className="fill-white" />
          </span>
        )}
      </span>
    </button>
  )
}

export default Button
