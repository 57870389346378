import React, { Fragment, useRef, useState } from "react"

import { useField, useFormikContext } from "formik"
import { usePopper } from "react-popper"
import { Portal } from "react-portal"

import { Listbox, Transition } from "@headlessui/react"
import { ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { XMarkIcon } from "@heroicons/react/24/outline"

import { SelectOption } from "~tailwindui/types/SelectOption"

import { ErrorIcon } from "~tailwindui/Basics"

import classNames from "src/classNames"

export type SelectInputProps = {
  name: string
  label: string
  multi?: boolean
  options: SelectOption[]
  description?: string
  subfield?: boolean
  portal?: boolean
  displayValue?: any
  className?: string
  clearable?: boolean
  onChange?: (opt: Selected) => void
  placeholder?: string
}

type Selected = SelectOption[] | SelectOption | undefined

const SelectInput: React.FC<
  SelectInputProps &
    Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "onChange">
> = ({
  name,
  label,
  options,
  multi = false,
  subfield = false,
  description = "",
  portal = false,
  displayValue,
  clearable = false,
  className = "",
  placeholder = "None",
  ...props
}) => {
  const [field, meta] = useField({ name })
  const { setFieldValue } = useFormikContext() as any
  const error = meta.error ? meta.error : ""

  let initialSelectedValue: Selected
  if (!field.value) initialSelectedValue = multi ? [] : undefined
  else if (multi) {
    initialSelectedValue = options.filter(opt =>
      field.value.includes(opt.value)
    )
  } else {
    initialSelectedValue = options.find(opt => opt.value === field.value)
  }

  const [selected, setSelected] = useState<Selected>(initialSelectedValue)

  const onChange = selected => {
    setSelected(selected)
    setFieldValue(
      name,
      multi
        ? (selected as SelectOption[]).map(selected => selected?.value)
        : selected.value
    )
  }

  const removeItem = (optionToRemove: SelectOption) => {
    if (multi) {
      const newOpts = (selected as SelectOption[]).filter(
        opt => opt.value !== optionToRemove.value
      )
      onChange(newOpts)
    } else onChange(undefined)
  }

  const popperElRef = useRef(null)
  const [targetElement, setTargetElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  const items = open => {
    let divAttributes = {}
    if (portal) {
      divAttributes = {
        ref: popperElRef,
        style: { zIndex: 100, ...styles.popper },
        ...attributes.popper,
      }
    }

    const content = (
      <div {...divAttributes}>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          beforeEnter={() => setPopperElement(popperElRef.current)}
          afterLeave={() => setPopperElement(null)}
        >
          <Listbox.Options
            className={classNames(
              portal ? "-top-2.5 w-auto" : "w-full",
              "absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            )}
          >
            {options.length === 0 ? (
              <div className="min-w-max py-2 px-3 italic">No options found</div>
            ) : (
              options.map(option => {
                const isSelected =
                  (multi && field.value.includes(option.value)) ||
                  (!multi && field.value === option.value)
                return (
                  <Listbox.Option
                    key={`${option.label}-${option.sublabel}`}
                    className={({ active }) =>
                      classNames(
                        isSelected
                          ? "bg-sky-600 text-white"
                          : active
                          ? "bg-sky-400 text-white"
                          : "text-gray-900",
                        "relative cursor-default select-none py-2 px-3"
                      )
                    }
                    value={option}
                  >
                    {({ active }) => {
                      return (
                        <>
                          <div className="flex">
                            <span className="truncate">{option.label}</span>

                            <span
                              className={classNames(
                                active || isSelected
                                  ? "text-sky-200"
                                  : "text-gray-500",
                                "ml-2 truncate"
                              )}
                            >
                              {option.sublabel}
                            </span>
                          </div>
                        </>
                      )
                    }}
                  </Listbox.Option>
                )
              })
            )}
          </Listbox.Options>
        </Transition>
      </div>
    )

    if (portal) {
      return <Portal>{content}</Portal>
    } else {
      return content
    }
  }

  return (
    <div className="mb-3">
      <Listbox
        multiple={multi}
        value={selected}
        onChange={onChange}
        name={name}
        {...props}
      >
        {({ open }) => (
          <>
            <label
              htmlFor={name}
              className={classNames(
                subfield && "ml-8",
                "block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              )}
            >
              {label}
            </label>

            <div
              className={classNames(
                "relative min-w-[160px]",
                subfield && "ml-8",
                className
              )}
            >
              <div ref={setTargetElement}>
                <Listbox.Button
                  className={classNames(
                    "relative min-h-[2.25rem] w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6",
                    error ? "ring-red-300" : "ring-gray-300"
                  )}
                >
                  {multi ? (
                    <div className="flex flex-auto flex-wrap">
                      {(selected as SelectOption[]).map(option => (
                        <div
                          key={option.value}
                          className="m-[1px] flex items-center justify-center rounded border border-sky-300 bg-white bg-sky-100 py-[.2rem] px-1 font-medium text-sky-700 "
                        >
                          <div className="max-w-full flex-initial text-xs font-normal leading-none">
                            {option.label}
                          </div>
                          <div
                            className="flex flex-auto flex-row-reverse"
                            onClick={() => removeItem(option)}
                          >
                            <XMarkIcon
                              className="h-4 w-4 cursor-pointer hover:text-sky-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="inline-flex w-full truncate">
                      <span className="truncate">
                        {(selected as SelectOption)?.label || (
                          <span className="text-gray-500">{placeholder}</span>
                        )}
                      </span>
                      <span className="ml-2 truncate text-gray-500">
                        {(selected as SelectOption)?.sublabel}
                      </span>
                    </span>
                  )}

                  <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    {error ? (
                      <ErrorIcon />
                    ) : clearable &&
                      (multi
                        ? (selected as SelectOption[]).length > 0
                        : selected) ? (
                      <span
                        onClick={e => {
                          setSelected(multi ? [] : undefined)
                          setFieldValue(name, multi ? [] : undefined)
                          e.stopPropagation()
                        }}
                      >
                        <XMarkIcon
                          className="h-5 w-5 cursor-pointer text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </Listbox.Button>
              </div>

              {items(open)}
            </div>
          </>
        )}
      </Listbox>

      <div className="text-sm">
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </div>
  )
}

export default SelectInput
