import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox } from "~tailwindui/Basics"

import {
  adminClient,
  studentClient,
  teacherClient,
  tutorClient,
} from "src/graphql-config"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required("Please specify a contact number"),
})

const SupportModal = ({
  phoneNumber,
  sessionId,
  userType,
  error,
  browser,
  color,
}) => {
  const formState = {
    sessionId: sessionId,
    errorName: error?.name,
    errorMessage: error?.message,
    phoneNumber: phoneNumber,
    browserName: browser?.name,
    browserVersion: browser?.version,
    os: browser?.os,
  }

  const client =
    userType === "Tutor"
      ? tutorClient
      : userType === "Teacher"
      ? teacherClient
      : userType === "Admin"
      ? adminClient
      : studentClient

  const { runMutation } = useMutation(
    buildMutation(supportMutation, { client })
  )

  const handleSubmit = (values, actions) => {
    runMutation({ input: values })
      .then(
        response => {
          const { success, errorMessages } = response.supportRequest
          if (success) {
            actions.setStatus({
              info: "A member of our support team will text you at the number you provided",
            })
          }
          if (errorMessages) {
            actions.setStatus({ error: errorMessages })
          }
        },
        error => {
          actions.setStatus({ error: "Something went wrong" })
        }
      )
      .catch(error => {
        actions.setStatus({ error: "Something went wrong" })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonText="Contact Support"
      buttonClassName={`btn solid ${color ? color : "green"} mr-1`}
      modalTitle="Support Request"
      modalClassName="bootstrap-modal"
    >
      {({ closeModal }) => (
        <Formik
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
          initialValues={formState}
        >
          {({ setFieldValue, status, isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div className="form-group">
                  <label className="w-100">
                    Contact Number
                    <Field
                      name="phoneNumber"
                      type="tel"
                      onChange={e =>
                        setFieldValue(
                          "phoneNumber",
                          e.currentTarget.value.replace(/\D+/g, "")
                        )
                      }
                    />
                  </label>
                  <ErrorMessage name="phoneNumber" />
                </div>
              </div>
              <div
                className="modal-footer"
                css={css`
                  display: flex;
                  flex-flow: column;
                `}
              >
                {status?.error && (
                  <AlertMessageBox>{status.error}</AlertMessageBox>
                )}
                {status?.info && (
                  <AlertMessageBox level={AlertLevel.Info}>
                    {status.info}
                  </AlertMessageBox>
                )}
                {!status?.info && (
                  <SubmitButton isSubmitting={isSubmitting} text="Submit" />
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const supportMutation = compress`
  mutation($input: SupportRequestInputObject!) {
    supportRequest(input: $input) {
      success
      errorMessages
    }
  }
`

export default SupportModal
