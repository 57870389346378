import { Kind, parse } from "graphql"
// actioncable transport taken from @gopeter here: https://github.com/FormidableLabs/urql/issues/787#issuecomment-697413674
import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  subscriptionExchange,
} from "urql"
import { Observable } from "zen-observable-ts"

import { devtoolsExchange } from "@urql/devtools"

import cable from "src/cable"
import csrfToken from "src/csrf-token"

// based on https://github.com/rmosolgo/graphql-ruby/blob/master/javascript_client/src/subscriptions/ActionCableLink.ts
class ActionCableExchange {
  constructor(options) {
    this.cable = options.cable
    this.channelName = options.channelName || "GraphqlChannel"
    this.actionName = options.actionName || "execute"
    this.connectionParams = options.connectionParams || {}
  }

  getOperationName(query) {
    for (let i = 0, l = query.definitions.length; i < l; i++) {
      const node = query.definitions[i]
      if (node.kind === Kind.OPERATION_DEFINITION && node.name) {
        return node.name.value
      }
    }
  }

  request(operation) {
    return new Observable(observer => {
      const channelId = Math.round(
        Date.now() + Math.random() * 100000
      ).toString(16)
      const actionName = this.actionName
      const operationName = this.getOperationName(parse(operation.query))
      const subscription = this.cable.subscriptions.create(
        Object.assign(
          {},
          {
            channel: this.channelName,
            channelId: channelId,
          },
          this.connectionParams
        ),
        {
          connected: function () {
            this.perform(actionName, {
              query: operation.query ? operation.query : null,
              variables: operation.variables,
              // TODO: add this to support persisted queries. But we need to get the operationName at first
              // operationId: (operation as { operationId?: string }).operationId,
              operationName: operationName,
            })
          },
          received: function (payload) {
            if (payload.result.data || payload.result.errors) {
              observer.next(payload.result)
            }

            if (payload.result.error) {
              observer.error(payload.result)
            }

            if (!payload.more) {
              observer.complete()
            }
          },
        }
      )

      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(subscription, { closed: false })
    })
  }
}

const actionCableClient = new ActionCableExchange({ cable })

const defaultExchanges = [
  devtoolsExchange,
  dedupExchange,
  cacheExchange,
  errorExchange({
    onError: (error, operation) => {
      logger.error("URQL Error", error, operation)
    },
  }),
  fetchExchange,
  subscriptionExchange({
    forwardSubscription: operation => {
      return actionCableClient.request(operation)
    },
  }),
]

// note: passing this context to useQuery only sets url and fetchOptions -- the exchanges value is ignored
// (hence the warning message...yet somehow the url and fetchOptions values _do_ work)

const client = createClient({
  url: "/api/admins/graphql",
  exchanges: defaultExchanges,
  fetchOptions: {
    headers: {
      "X-CSRF-TOKEN": csrfToken,
    },
  },
})

const tutorClient = createClient({
  url: "/api/tutors/graphql",
  exchanges: defaultExchanges,
  fetchOptions: {
    headers: {
      "X-CSRF-TOKEN": csrfToken,
    },
  },
  requestPolicy: "cache-and-network",
})

const studentClient = createClient({
  url: "/api/students/graphql",
  exchanges: defaultExchanges,
  fetchOptions: {
    headers: {
      "X-CSRF-TOKEN": csrfToken,
    },
  },
})

const parentClient = createClient({
  url: "/api/parents/graphql",
  exchanges: defaultExchanges,
  fetchOptions: {
    headers: {
      "X-CSRF-TOKEN": csrfToken,
    },
  },
})

const organizationAdminClient = createClient({
  url: "/api/organization_admins/graphql",
  exchanges: defaultExchanges,
  fetchOptions: {
    headers: {
      "X-CSRF-TOKEN": csrfToken,
    },
  },
  maskTypename: true,
})

const teacherClient = createClient({
  url: "/api/teachers/graphql",
  exchanges: defaultExchanges,
  fetchOptions: {
    headers: {
      "X-CSRF-TOKEN": csrfToken,
    },
  },
})

const publicClient = createClient({
  url: "/api/public/graphql",
  exchanges: defaultExchanges,
  fetchOptions: {
    headers: {
      "X-CSRF-TOKEN": csrfToken,
    },
  },
})

export default client
export {
  client as adminClient,
  organizationAdminClient,
  parentClient,
  publicClient,
  studentClient,
  tutorClient,
  teacherClient,
}
