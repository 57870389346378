import React from "react"

const ErrorMessage = ({ error = undefined }) => {
  if (!error) return null

  const message =
    typeof error === "string"
      ? error
      : typeof error[0] === "string"
      ? error.join(", ")
      : Array.isArray(error)
      ? error.map(e => e && Object.values(e).join(", ")).join(", ")
      : Object.values(error).join(", ")

  return (
    <div className="form-error">
      {error && (
        <div className="my-2 inline-block rounded-md border-solid border-red-500 py-1 px-2 text-red-500">
          {message}
        </div>
      )}
    </div>
  )
}

export default ErrorMessage
