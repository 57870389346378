import React, { useCallback, useMemo } from "react"

import { Calendar, Views, dayjsLocalizer } from "react-big-calendar"

import dayjs from "src/dayjs"

import { TutorSchedule } from "./TutorScheduleType"

const localizer = dayjsLocalizer(dayjs)

export type TutorScheduleCalendarProps = {
  openEvent: (event: any) => void
  createEvent: (event: any) => void
  schedules: TutorSchedule[]
  smallCalendar?: boolean
}

const TutorScheduleCalendar: React.FC<TutorScheduleCalendarProps> = ({
  openEvent,
  createEvent,
  schedules,
  smallCalendar = false,
}) => {
  const scrollToTime = useMemo(() => new Date(1970, 0, 0, 5), []) // start with scroller at 5am

  const events = useMemo(() => {
    const events = []
    schedules.forEach(schedule => {
      const startDate = dayjs(schedule.startDate)
      const endDate = dayjs(schedule.endDate)
      let currentDate = startDate

      while (!currentDate.isAfter(endDate)) {
        const startTime = currentDate
          .set("hour", schedule.startHour)
          .set("minute", schedule.startMinute)
          .tz(schedule.dayjsTimeZone, true) // set the date's "real" time zone
          .local() // convert to local time zone for displaying to user

        const endTime = currentDate
          .set("hour", schedule.endHour)
          .set("minute", schedule.endMinute)
          .tz(schedule.dayjsTimeZone, true) // set the date's "real" time zone
          .local() // convert to local time zone for displaying to user

        events.push({
          id: schedule.id,
          title: `${startTime.format("h:mm A")} - ${endTime.format(
            "h:mm A z"
          )}`,
          start: startTime.toDate(), // these have to be date objects, not dayjs objects
          end: endTime.toDate(),
          resource: {
            frequency: schedule.frequency,
          },
        })

        if (schedule.frequency === "nonrecurring") {
          break
        } else {
          currentDate = currentDate.add(
            schedule.frequency === "biweekly" ? 2 : 1,
            "weeks"
          )
        }
      }
    })

    return events
  }, [schedules])

  return (
    <Calendar
      localizer={localizer}
      events={events}
      selectable
      onSelectEvent={useCallback(event => openEvent(event), [openEvent])}
      onSelectSlot={useCallback(event => createEvent(event), [createEvent])}
      defaultView={Views.WEEK}
      scrollToTime={scrollToTime}
      startAccessor="start"
      endAccessor="end"
      style={{ height: smallCalendar ? 400 : 800 }}
      views={
        smallCalendar
          ? [Views.WEEK, Views.MONTH]
          : [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]
      }
      formats={{ eventTimeRangeFormat: () => null }}
      titleAccessor={({ title, resource }) => (
        <span className="text-sm">
          {title} ({resource.frequency})
        </span>
      )}
      eventPropGetter={() => ({
        style: { backgroundColor: "!bg-emerald-500" },
      })}
    />
  )
}

export default TutorScheduleCalendar
