import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { DateObject, DateTimePicker } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  sendAt: Yup.number().required("Please specify a date"),
})

const Clone = ({ id }) => {
  const formState = {
    id: id,
    sendAt: new DateObject().setHour(12).setMinute(0).setSecond(0),
  }

  const { runMutation } = useMutation(buildMutation(cloneMutation))

  const handleSubmit = (values, actions) => {
    const { sendAt, ...rest } = values
    runMutation({ sendAt: sendAt.toDate(), ...rest })
      .then(
        response => {
          if (response.batchTextClone.redirectTo) {
            window.location.href = response.batchTextClone.redirectTo
          } else {
            actions.setStatus(response.errorMessage)
          }
        },
        err => actions.setStatus("An error occurred")
      )
      .catch(err => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <ModalWithProvidedBody
      buttonText="Copy"
      buttonClassName="btn btn-info"
      modalTitle="Copy Batch Text"
    >
      {({ closeModal }) => (
        <Formik
          validationSchema={ValidationSchema}
          initialValues={formState}
          onSubmit={handleSubmit}
        >
          {({ status, values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="modal-body">
                <div
                  className="form-group"
                  css={css`
                    min-height: 150px;
                  `}
                >
                  <label className="form-label w-100">
                    Send At
                    <DateTimePicker
                      fixed
                      value={values.sendAt}
                      onChange={date => setFieldValue("sendAt", date)}
                    />
                  </label>
                  <ErrorMessage name="sendAt" />
                </div>
              </div>

              <div className="modal-footer flex-column">
                {status && (
                  <div className="alert alert-danger mt-3">{status}</div>
                )}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  text="Copy"
                  containerCss={css`
                    margin-right: 15px;
                  `}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const cloneMutation = compress`
  mutation($id: ID!, $sendAt: DateTime!) {
    batchTextClone(id: $id, sendAt: $sendAt) {
      errorMessages
      redirectTo
    }
  }
`

export default Clone
