import React, { useState } from "react"

import dayjs from "dayjs"
import { gql, useQuery } from "urql"

import { CurrencyDollarIcon } from "@heroicons/react/20/solid"

import {
  AlertLevel,
  LayoutDirection,
  ValueFormat,
} from "~tailwindui/types/enums"

import AlertMessageBox from "~tailwindui/Basics/AlertMessageBox"
import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"
import Chip from "~tailwindui/ChipSimple"

import formatValue from "src/formatValue"

import DataDateRange from "components/OrganizationAdmin/components/DataDateRange"
import ExportDropDown from "components/OrganizationAdmin/components/ExportDropDown"
import GroupedBarChart from "components/OrganizationAdmin/components/GroupedBarChart"

import AmountBilledDataSetChip from "./AmountBilledDataSetChip"
import AttendanceChip from "./AttendanceChip"
import BilledSessionsChip from "./BilledSessionsChip"
import StudentsDataSetChip from "./StudentsDataSetChip"

const DashboardPage = () => {
  const [result] = useQuery({ query: query })

  const defaultStartingAt = dayjs().startOf("year")
  const defaultEndingAt = dayjs()
  const [startingAt, setStartingAt] = useState(defaultStartingAt)
  const [endingAt, setEndingAt] = useState(defaultEndingAt)

  const remainingBudget =
    result.data?.viewer.organization.activeBudgets.data.reduce(
      (sum: number, budget: { balance: number }) => sum + budget.balance,
      0
    )

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <>
          <h2 className="mb-6 flex justify-between text-xl font-semibold leading-6 text-gray-900">
            <DataDateRange
              startDate={startingAt}
              setStartDate={setStartingAt}
              endDate={endingAt}
              setEndDate={setEndingAt}
            />
            {viewer.canOrganizationAdminAccessFinances && (
              <div>
                <span className="flex">
                  <CurrencyDollarIcon
                    className="h6 mr-2 inline-block w-6 text-emerald-600"
                    aria-hidden="true"
                  />
                  {`Remaining Budget:
              ${formatValue(remainingBudget, ValueFormat.Currency)}`}
                </span>
              </div>
            )}
          </h2>

          <>
            {viewer.canOrganizationAdminAccessSemesters &&
              !viewer.organization.activeSemester && (
                <AlertMessageBox className="mb-4" level={AlertLevel.Info}>
                  To see data only from this semester, please add your current
                  semester's dates on the{" "}
                  <a href="/organization_admin/settings">Settings</a> page.
                </AlertMessageBox>
              )}
          </>

          <div className="mb-8 rounded-lg border-2 border-gray-900/5">
            <div className="mx-auto max-w-7xl px-2 pt-4 pb-4 sm:px-2 lg:px-4">
              <Chip.List layoutDirection={LayoutDirection.Horizontal}>
                <StudentsDataSetChip
                  label="Tutored Students"
                  startingAt={startingAt}
                  endingAt={endingAt}
                  attending
                />
                <BilledSessionsChip
                  label={
                    viewer.canOrganizationAdminAccessFinances
                      ? "Billed Sessions"
                      : "Sessions"
                  }
                  startDate={startingAt}
                  endDate={endingAt}
                />
                <AttendanceChip
                  label="Group Attendance Rate"
                  startDate={startingAt}
                  endDate={endingAt}
                  groupRate={true}
                />
                {viewer.canOrganizationAdminAccessFinances && (
                  <>
                    <AmountBilledDataSetChip
                      label="Amount Billed"
                      startingAt={startingAt}
                      endingAt={endingAt}
                    />
                  </>
                )}
              </Chip.List>
            </div>
          </div>

          <div className="mb-3">
            <div className="mb-5">
              <ExportDropDown
                startingAt={startingAt}
                endingAt={endingAt}
                timezone={viewer.emailTimeZone}
              />
            </div>
          </div>

          <div className="mt-8">
            <GroupedBarChart
              title="Group Attendance"
              query={attendanceBySchoolQuery}
              variables={{ startingAt, endingAt }}
              dataSetKey="attendanceDataSet"
              groupByField="school"
              uniqBy="id"
              nameField="name"
            />
          </div>

          <div className="mt-8">
            <GroupedBarChart
              title="Group No Show Sessions"
              query={noShowsBySchoolQuery}
              variables={{ startingAt, endingAt }}
              dataSetKey="sessionsDataSet"
              groupByField="school"
              uniqBy="id"
              nameField="name"
            />
          </div>

          <hr />
          <div className="mt-8">
            <GroupedBarChart
              title="Sessions per School"
              query={sessionsBySchoolQuery}
              variables={{ startingAt, endingAt }}
              dataSetKey="sessionsDataSet"
              groupByField="school"
              uniqBy="id"
              nameField="name"
            />
          </div>

          <hr />

          <div className="mt-8">
            <GroupedBarChart
              title="Sessions per Subject Type"
              query={sessionsBySubjectTypeQuery}
              variables={{ startingAt, endingAt }}
              dataSetKey="sessionsDataSet"
              groupByField="subjectBucketsSubjectType"
            />
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query viewer {
    viewer {
      canOrganizationAdminAccessFinances
      canOrganizationAdminAccessSemesters
      emailTimeZone
      organization {
        activeBudgets {
          data {
            balance
          }
        }
        activeSemester {
          startsOn
          endsOn
        }
      }
    }
  }
`

const noShowsBySchoolQuery = gql`
  query MonthlyNoShowsGroupedBySchool(
    $startingAt: DateTime!
    $endingAt: DateTime!
  ) {
    viewer {
      organization {
        sessionsDataSet(
          filters: {
            startingAt: $startingAt
            endingAt: $endingAt
            noShow: true
            groupOnly: true
          }
          groupByOptions: {
            groups: [school]
            timePeriod: { periodLength: month, dateField: startsAt }
          }
        ) {
          total
          groupedData {
            value
            groupingStartDate
            school {
              id
              name
            }
          }
        }
      }
    }
  }
`

const attendanceBySchoolQuery = gql`
  query MonthlyAttendanceBySchool(
    $startingAt: DateTime!
    $endingAt: DateTime!
  ) {
    viewer {
      organization {
        attendanceDataSet(
          filters: {
            startingAt: $startingAt
            endingAt: $endingAt
            groupRate: true
          }
          groupByOptions: {
            groups: [school]
            timePeriod: { periodLength: month, dateField: startsAt }
          }
        ) {
          total
          groupedData {
            totalCount
            presences
            groupingStartDate
            school {
              id
              name
            }
          }
        }
      }
    }
  }
`

const sessionsBySchoolQuery = gql`
  query MonthlySessionsGroupedBySchool(
    $startingAt: DateTime!
    $endingAt: DateTime!
  ) {
    viewer {
      organization {
        sessionsDataSet(
          filters: { startingAt: $startingAt, endingAt: $endingAt }
          groupByOptions: {
            groups: [school]
            timePeriod: { periodLength: month, dateField: startsAt }
          }
        ) {
          total
          groupedData {
            value
            groupingStartDate
            school {
              id
              name
            }
          }
        }
      }
    }
  }
`

const sessionsBySubjectTypeQuery = gql`
  query MonthlySessionsGroupedByMonthAndSubjectType(
    $startingAt: DateTime!
    $endingAt: DateTime!
  ) {
    viewer {
      organization {
        sessionsDataSet(
          filters: { startingAt: $startingAt, endingAt: $endingAt }
          groupByOptions: {
            groups: [subjectBucketsSubjectType]
            timePeriod: { periodLength: month, dateField: startsAt }
          }
        ) {
          total
          groupedData {
            value
            groupingStartDate
            subjectBucketsSubjectType
          }
        }
      }
    }
  }
`

export default DashboardPage
