import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"

import { Color, Size } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import {
  Failures,
  SubmitButton,
  TextAreaInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import { RunMutation } from "src/types"

import { TextAreaField } from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import { path } from "components/Tutor/TutorRoutes"

const ValidationSchema = Yup.object().shape({
  cancellationReason: Yup.string().required(
    "Please provide a cancellation reason"
  ),
})

export type CancelSessionButtonProps = {
  id: string
  viewerType: "Tutor" | "Admin"
  runMutation: RunMutation
  size?: string
  tailwind?: boolean
}

const CancelSessionButton: React.FC<CancelSessionButtonProps> = ({
  id,
  viewerType,
  runMutation,
  size,
  tailwind,
}) => {
  const history = useHistory()

  const initialFormState = {
    cancellationReason: "",
  }

  const handleSubmit = (values, actions, closeModal) => {
    actions.setStatus("Submitting...")
    runMutation({ id, cancellationReason: values.cancellationReason })
      .then(
        result => {
          const { failures } = result.data.cancelSession
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.success("The session has been canceled")
            if (viewerType === "Tutor") history.push(path("dashboard"))
            else closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return tailwind ? (
    <Modal.Dialog
      buttonText="Cancel"
      buttonClassNames={buttonClassNames({
        size: size === "small" ? Size.Small : Size.Medium,
        color: Color.Red,
      })}
    >
      {closeModal => (
        <>
          <Modal.Header>Cancel Session</Modal.Header>
          <Formik
            initialValues={initialFormState}
            onSubmit={(values, actions) =>
              handleSubmit(values, actions, closeModal)
            }
            validationSchema={ValidationSchema}
          >
            <Form className="flex max-h-[calc(70vh-90px)] flex-col">
              <Modal.Body>
                <p>
                  Are you sure you want to cancel this session? This action is
                  not reversible.
                </p>
                <TextAreaInput
                  label="Cancellation Reason"
                  name="cancellationReason"
                />
              </Modal.Body>
              <Modal.Footer>
                <Failures />
                <div className="flex justify-end space-x-2">
                  <Button color={Color.Blue} onClick={closeModal}>
                    No, I'll keep it
                  </Button>
                  <SubmitButton color={Color.Red} text="Yes, cancel it" />
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </>
      )}
    </Modal.Dialog>
  ) : (
    <ModalWithProvidedBody
      buttonClassName="btn solid btn-danger"
      buttonText="Cancel"
      modalClassName="bootstrap-modal"
      modalTitle="Cancel Session"
    >
      {({ closeModal }) => (
        <React.Fragment>
          <Formik
            initialValues={initialFormState}
            onSubmit={(values, actions) =>
              handleSubmit(values, actions, closeModal)
            }
            validationSchema={ValidationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="modal-body">
                  <p>
                    Are you sure you want to cancel this session? This action is
                    not reversible.
                  </p>
                  <TextAreaField
                    label="Cancellation Reason"
                    name="cancellationReason"
                  />
                </div>
                <div className="modal-footer" style={{ flexFlow: "column" }}>
                  <Failures />
                  <div className="w-100 d-flex justify-content-between">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn solid red btn-danger"
                    >
                      Yes, cancel it!
                    </button>
                    <button
                      className="btn solid blue btn-info"
                      onClick={closeModal}
                    >
                      No, I'll keep it
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </ModalWithProvidedBody>
  )
}

export default CancelSessionButton
