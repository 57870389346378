import React from "react"

import { Dayjs } from "dayjs"
import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import StackedBarChart from "./StackedBarChart"

export type SessionsGraphProps = {
  filters: {
    startDate: Dayjs
    endDate: Dayjs
    statusState: string
    billState: string
    subjectIds: string[]
    studyGroupIds: string[]
  }
}

const SessionsGraph: React.FC<SessionsGraphProps> = ({ filters }) => {
  const [result] = useQuery({ query, variables: { filters } })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <StackedBarChart
          groupedData={viewer.sessionsChartData}
          hideLegend={!!viewer.sessionsChartData["none"]}
        />
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query TutorSessionsChartQuery($filters: TutorSessionFiltersInputObject!) {
    viewer {
      sessionsChartData(filters: $filters)
    }
  }
`

export default SessionsGraph
