import React, { Fragment, useRef, useState } from "react"

import { usePopper } from "react-popper"
import { Portal } from "react-portal"

import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"

import { SelectOption } from "~tailwindui/types/SelectOption"

import ErrorIcon from "~tailwindui/Basics/ErrorIcon"

import classNames from "src/classNames"

export type SingleSelectProps = {
  label: string
  name: string
  options: SelectOption[]
  onChange: (value: string) => void
  error?: string
  value?: string
  description?: string
  valueAttribute?: string
  subfield?: boolean
  portal?: boolean
  inputWrapperClassNames?: string
  horizontal?: boolean
}

const SingleSelect: React.FC<
  SingleSelectProps & React.SelectHTMLAttributes<HTMLSelectElement>
> = ({
  label,
  name,
  value,
  options,
  onChange,
  error = "",
  description = "",
  valueAttribute = "value",
  subfield = false,
  portal = false,
  horizontal = false,
  inputWrapperClassNames = "",
  ...props
}) => {
  const selectedOption = options.find(opt => opt[valueAttribute] === value)

  const popperElRef = useRef(null)
  const [targetElement, setTargetElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  const items = open => {
    let divAttributes = {}
    if (portal) {
      divAttributes = {
        ref: popperElRef,
        style: { zIndex: 100, ...styles.popper },
        ...attributes.popper,
      }
    }

    const content = (
      <div {...divAttributes}>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          beforeEnter={() => setPopperElement(popperElRef.current)}
          afterLeave={() => setPopperElement(null)}
        >
          <Listbox.Options
            className={classNames(
              portal ? "-top-2.5 w-auto" : "w-full",
              "absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            )}
          >
            {options.map(option => (
              <Listbox.Option
                key={`${option.label}-${option.sublabel}`}
                className={({ active }) =>
                  classNames(
                    active ? "bg-sky-600 text-white" : "text-gray-900",
                    "relative cursor-default select-none py-2 pl-3 pr-9"
                  )
                }
                value={option}
              >
                {({ active }) => (
                  <>
                    <div className="flex">
                      <span
                        className={classNames(
                          selectedOption.value === option.value
                            ? "font-semibold"
                            : "font-normal",
                          "truncate"
                        )}
                      >
                        {option.label}
                      </span>

                      <span
                        className={classNames(
                          active ? "text-sky-200" : "text-gray-500",
                          "ml-2 truncate"
                        )}
                      >
                        {option.sublabel}
                      </span>
                    </div>

                    {selectedOption.value === option.value ? (
                      <span
                        className={classNames(
                          active ? "text-white" : "text-sky-600",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )

    if (portal) {
      return <Portal>{content}</Portal>
    } else {
      return content
    }
  }

  return (
    <div
      className={
        horizontal
          ? "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
          : "mb-3"
      }
    >
      <Listbox onChange={onChange} name={name} {...props}>
        {({ open }) => (
          <>
            <label
              htmlFor={name}
              className={classNames(
                "block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5",
                subfield && "ml-8"
              )}
            >
              {label}
            </label>

            <div
              className={classNames(
                subfield && "ml-8",
                "relative",
                inputWrapperClassNames
              )}
            >
              <div ref={setTargetElement}>
                <Listbox.Button
                  className={classNames(
                    "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6",
                    error ? "ring-red-300" : "ring-gray-300"
                  )}
                >
                  <span className="inline-flex w-auto truncate">
                    <span className="truncate">{selectedOption?.label}</span>
                    <span className="ml-2 truncate text-gray-500">
                      {selectedOption?.sublabel}
                    </span>
                  </span>

                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    {error ? (
                      <ErrorIcon />
                    ) : (
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </Listbox.Button>
              </div>

              {items(open)}
            </div>
          </>
        )}
      </Listbox>

      <div
        className={classNames(
          "text-sm",
          horizontal ? "flex h-full items-center" : "mt-1"
        )}
      >
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </div>
  )
}

export default SingleSelect
