import React from "react"

import classNames from "src/classNames"

import { Loading } from "components/Icons"

const SubmitButton = ({ isSubmitting, text, ...props }) => {
  const containerCss = props.containerCss
    ? props.containerCss
    : "inline-block mt-4 mr-4"

  return (
    <div css={containerCss}>
      <button
        type="submit"
        className={classNames("btn btn-info solid blue", props.className)}
        disabled={isSubmitting}
      >
        <span>{text}</span>
        {isSubmitting && (
          <div className="ml-4 inline-block">
            <Loading />
          </div>
        )}
      </button>
    </div>
  )
}

SubmitButton.defaultProps = {
  text: "Save",
}

export default SubmitButton
