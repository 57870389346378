import React, { useEffect, useMemo, useRef, useState } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import { css } from "@emotion/core"

import EventEmitter from "src/EventEmitter"
import createBlob from "src/createBlob"

import useWebsocket from "hooks/useWebsocket"

import EditableDisplay from "components/Forms/EditableDisplay"
import { Loading } from "components/Icons"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import LessonTopic from "./LessonTopic"
import NewTopic from "./NewTopic"
import lessonTopicsFragment from "./lessonTopicsFragment"

const LessonPlanShow = ({ id }) => {
  const csvExportDownloadLink = useRef()
  const [exporting, setExporting] = useState(false)
  const [csvExportUrl, setCsvExportUrl] = useState("")
  const loadingState = useQuery(
    buildQuery(
      lessonPlanQuery,
      { id },
      {
        onMutation: [
          {
            when: "updateLessonPlan",
            run: ({ softReset, currentResults }, response) => {
              const { lessonPlan } = response.updateLessonPlan
              currentResults.lessonPlan.name = lessonPlan.name
              softReset(currentResults)
            },
          },
          {
            when: "createLessonTopic",
            run: ({ softReset, currentResults }, response) => {
              const { lessonPlan } = response.createLessonTopic
              currentResults.lessonPlan.lessonTopics = lessonPlan.lessonTopics
              softReset(currentResults)
            },
          },
          {
            when: "updateLessonTopic",
            run: ({ softReset, currentResults }, response) => {
              const { lessonTopic } = response.updateLessonTopic
              currentResults.lessonPlan.lessonTopics.forEach(topic => {
                if (topic.id !== lessonTopic.id) return
                topic.name = lessonTopic.name
              })
              softReset(currentResults)
            },
          },
          {
            when: "removeLessonTopic",
            run: ({ softReset, currentResults }, response) => {
              const { lessonPlan } = response.removeLessonTopic
              currentResults.lessonPlan.lessonTopics = lessonPlan.lessonTopics
              softReset(currentResults)
            },
          },
          {
            when: "createLessonResource",
            run: ({ softReset, currentResults }, response) => {
              const { lessonTopic } = response.createLessonResource
              currentResults.lessonPlan.lessonTopics.forEach((topic, idx) => {
                if (topic.id !== lessonTopic.id) return
                currentResults.lessonPlan.lessonTopics[idx] = lessonTopic
              })
              softReset(currentResults)
            },
          },
          {
            when: "removeLessonResource",
            run: ({ softReset, currentResults }, response) => {
              const { lessonTopic } = response.removeLessonResource
              currentResults.lessonPlan.lessonTopics.forEach((topic, idx) => {
                if (topic.id !== lessonTopic.id) return
                currentResults.lessonPlan.lessonTopics[idx].lessonResources =
                  lessonTopic.lessonResources
              })
              softReset(currentResults)
            },
          },
          {
            when: "updateLessonResource",
            run: ({ softReset, currentResults }, response) => {
              const { lessonResource } = response.updateLessonResource
              currentResults.lessonPlan.lessonTopics.forEach(topic => {
                topic.lessonResources.forEach(resource => {
                  if (resource.id !== lessonResource.id) return
                  resource.name = lessonResource.name
                })
              })
              softReset(currentResults)
            },
          },
          {
            when: "decrementLessonTopicOrder",
            run: ({ softReset, currentResults }, response) => {
              const { lessonPlan } = response.decrementLessonTopicOrder
              currentResults.lessonPlan.lessonTopics = lessonPlan.lessonTopics
              softReset(currentResults)
            },
          },
          {
            when: "incrementLessonTopicOrder",
            run: ({ softReset, currentResults }, response) => {
              const { lessonPlan } = response.incrementLessonTopicOrder
              currentResults.lessonPlan.lessonTopics = lessonPlan.lessonTopics
              softReset(currentResults)
            },
          },
          {
            when: "decrementLessonResourceOrder",
            run: ({ softReset, currentResults }, response) => {
              const { lessonTopic } = response.decrementLessonResourceOrder
              currentResults.lessonPlan.lessonTopics.forEach(localTopic => {
                if (localTopic.id !== lessonTopic.id) return
                localTopic.lessonResources = lessonTopic.lessonResources
              })
              softReset(currentResults)
            },
          },
          {
            when: "incrementLessonResourceOrder",
            run: ({ softReset, currentResults }, response) => {
              const { lessonTopic } = response.incrementLessonResourceOrder
              currentResults.lessonPlan.lessonTopics.forEach(localTopic => {
                if (localTopic.id !== lessonTopic.id) return
                localTopic.lessonResources = lessonTopic.lessonResources
              })
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  const { runMutation } = useMutation(buildMutation(updateLessonPlanMutation))
  const { runMutation: removeLessonPlan } = useMutation(
    buildMutation(removeLessonPlanMutation)
  )
  const { runMutation: exportLessonPlan } = useMutation(
    buildMutation(exportLessonPlanMutation)
  )

  useEffect(() => {
    if (csvExportUrl === "") return
    csvExportDownloadLink.current.click()
  }, [csvExportUrl])

  const channelProps = useMemo(() => {
    return { id }
  }, [id])

  useWebsocket({
    channelName: "LessonPlanChannel",
    channelProps,
  })

  useEffect(() => {
    EventEmitter.subscribe("exportComplete", data => {
      setExporting(false)
      setCsvExportUrl(createBlob(data.csv, "text/csv"))
    })
  }, [])

  const csvExport = () => {
    setExporting(true)
    exportLessonPlan({ id })
      .then(
        response => {
          const { errorMessages } = response.exportLessonPlan
          if (errorMessages) {
            alert(`An error occurred: ${errorMessages}`)
            setExporting(false)
          }
        },
        error => {
          setExporting(false)
          alert(`An error occured: ${error?.messages}`)
        }
      )
      .catch(error => {
        setExporting(false)
        alert(`An error occurred: ${error?.messages}`)
      })
  }

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <div className="row">
          <div className="col-6">
            <EditableDisplay
              displayValue={data.lessonPlan.name}
              save={({ value }) => runMutation({ id, name: value })}
            />
          </div>
          <div className="col-6">
            <ModalWithProvidedBody
              modalTitle="Remove Lesson Plan"
              buttonText="Remove Lesson Plan"
              buttonClassName="btn btn-danger"
            >
              {({ closeModal }) => (
                <>
                  <div className="modal-body">
                    Are you sure? This is a VERY destructive, irreversible
                    action! This action will fail if any students are currently
                    assigned to this plan or have previously completed
                    assignments.
                  </div>
                  <div className="modal-footer">
                    <div className="flex w-full justify-between">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          removeLessonPlan({ id })
                            .then(r => {
                              window.location.href =
                                r.removeLessonPlan.redirectTo
                            })
                            .catch(() => alert("Something went wrong"))
                        }}
                      >
                        Yes, remove it and all associated data
                      </button>
                    </div>
                  </div>
                </>
              )}
            </ModalWithProvidedBody>
            <button
              className="btn btn-success"
              onClick={csvExport}
              disabled={exporting}
            >
              Export to CSV
              {exporting && (
                <div
                  css={css`
                    display: inline-block;
                    margin-left: 15px;
                  `}
                >
                  <Loading />
                </div>
              )}
            </button>
            <a
              ref={csvExportDownloadLink}
              href={csvExportUrl}
              download={`${data.lessonPlan.name} export.csv`}
              css={css`
                display: none;
              `}
            >
              csv
            </a>
          </div>

          <div className="col-12">
            <div className="d-flex align-items-center">
              <NewTopic lessonPlanId={id} />
            </div>
            {data.lessonPlan.lessonTopics.map(topic => (
              <ul className="list-unstyled" key={topic.id}>
                <LessonTopic
                  topic={topic}
                  first={topic === data.lessonPlan.lessonTopics[0]}
                  last={
                    topic ===
                    data.lessonPlan.lessonTopics[
                      data.lessonPlan.lessonTopics.length - 1
                    ]
                  }
                />
              </ul>
            ))}
          </div>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const lessonPlanQuery = compress`
  ${lessonTopicsFragment}
  query($id: ID!) {
    lessonPlan(id: $id) {
      name
      lessonTopics {
        ...LessonTopicsFields
      }
    }
  }
`

const exportLessonPlanMutation = compress`
  mutation($id: ID!) {
    exportLessonPlan(lessonPlanId: $id) {
      errorMessages
    }
  }
`

const updateLessonPlanMutation = compress`
  mutation($id: ID!, $name: String!) {
    updateLessonPlan(id: $id, name: $name) {
      lessonPlan {
        name
      }
    }
  }
`

const removeLessonPlanMutation = compress`
  mutation($id: ID!) {
    removeLessonPlan(id: $id) {
      redirectTo
    }
  }
`

export default LessonPlanShow
