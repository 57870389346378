import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"

export type PastSessionsProps = {
  studentId: string
}

const PastSessions: React.FC<PastSessionsProps> = ({ studentId }) => {
  const [page, setPage] = useState(1)

  const [result] = useQuery({
    query: pastSessionsQuery,
    variables: { studentId: studentId, page: page },
  })

  return (
    <div className="row">
      <WithLoadingIndicator
        result={result}
        error="An error occurred while loading the past sessions"
      >
        {({ data: { viewer } }) => (
          <Table
            title="Past Sessions"
            data={viewer.sessions}
            changePage={setPage}
            headers={["Subject", "Date", "Time", "Actions"]}
            valueMapper={session => [
              <span key={session.id} className="flex items-center">
                {session.directory ? (
                  <DirectoryIcon recordType={"session"} />
                ) : (
                  <AirtutorsIcon recordType={"session"} />
                )}{" "}
                {session.subject.name}
              </span>,
              <LocalTime
                key={session.id}
                format="ddd MMM D"
                timestamp={session.startsAt}
              />,
              <span key={session.id}>
                <LocalTime omitDate timestamp={session.startsAt} />
                &mdash;
                <LocalTime omitDate timestamp={session.endsAt} />
              </span>,
              <div key={session.id}>
                <Link
                  to={path("sessionDetails", {
                    id: session.id,
                  })}
                  button
                  size={Size.Small}
                >
                  View
                </Link>
              </div>,
            ]}
          />
        )}
      </WithLoadingIndicator>
    </div>
  )
}

const pastSessionsQuery = gql`
  query PastSessionsQuery($studentId: ID!, $page: Int!) {
    viewer {
      sessions(
        filters: { studentId: $studentId, past: true, statusState: scheduled }
        page: $page
      ) {
        totalPages
        data {
          id
          directory
          airtutors
          type
          startsAt
          endsAt
          subject {
            name
          }
        }
      }
    }
  }
`

export default PastSessions
