import React from "react"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox } from "~tailwindui/Basics"

export type ConsistentTutorWarningProps = {
  minTutorConsistencyRate: number
  maxSubsEstimate: number
}

const ConsistentTutorWarning: React.FC<ConsistentTutorWarningProps> = ({
  minTutorConsistencyRate,
  maxSubsEstimate,
}) => {
  return (
    <AlertMessageBox level={AlertLevel.Warning}>
      WARNING: This group requires a commitment to attend{" "}
      {minTutorConsistencyRate}% of all sessions. If you accept this group, you
      will only be allowed to request a substitute approximately{" "}
      {maxSubsEstimate} time(s). (Note that this is an estimate. You will be
      given the exact number after registration.) Please think carefully before
      accepting this group.
    </AlertMessageBox>
  )
}

export default ConsistentTutorWarning
