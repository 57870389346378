import React, { useState } from "react"

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/core"

import { AlertMessageBox } from "~tailwindui/Basics"

import classNames from "src/classNames"

import { gql, useClient, useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type EndSessionProps = {
  sessionId: string
  userType: "Student" | "Tutor" | "Teacher" | "Admin"
}

const EndSession: React.FC<EndSessionProps> = ({ sessionId, userType }) => {
  const client = useClient()
  const [mutationState, runMutation]: any[] = useMutation(
    endSessionMutation,
    client
  )

  const [open, setOpen] = useState(false)

  const closeCloudroom = () => {
    runMutation({ sessionId }).then(response => {
      const { redirectTo, failures } = response.data.sessionCloseCloudroom
      if (failures.length > 0) {
        alert(failures[0].message)
      } else {
        window.location = redirectTo
      }
    })
  }

  return userType === "Admin" ? (
    <ModalWithProvidedBody
      modalTitle="EMERGENCY END SESSION"
      buttonText="EMERGENCY! END SESSION FOR ALL USERS"
      buttonClassName="btn btn-danger"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <AlertMessageBox>
              THIS WILL END THE CLOUDROOM SESSION FOR ALL USERS.
            </AlertMessageBox>

            <p className="lead">
              If you just want to leave the room, simply close your browser tab.
            </p>
          </div>

          <div className="modal-footer">
            <div className="grid w-full grid-cols-2 gap-x-4">
              <button
                className="btn btn-outline-info whitespace-normal"
                type="button"
                onClick={closeModal}
              >
                No, do not end this session.
              </button>
              <button
                className={classNames(
                  "btn btn-danger whitespace-normal",
                  mutationState.fetching && "disabled"
                )}
                type="button"
                onClick={closeCloudroom}
                disabled={mutationState.fetching}
              >
                YES. Close this cloudroom immediately.
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  ) : userType === "Teacher" ? (
    <>
      <button
        className="rounded bg-brand-700 px-3 py-1 font-semibold"
        onClick={() => setOpen(true)}
      >
        EMERGENCY! END SESSION FOR ALL USERS
      </button>
      <Modal isOpen={open}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>EMERGENCY END SESSION</ModalHeader>
          <ModalBody>
            <p className="mb-3 rounded bg-brand-700 p-2 text-center text-white">
              THIS WILL END THE CLOUDROOM SESSION FOR ALL USERS.
            </p>
            <p className="mb-3">
              If you just want to leave the room, simply close your browser tab.
            </p>
            <div className="flex">
              <button
                className="mr-4 rounded bg-info-700 px-3 py-1 font-semibold text-white"
                onClick={() => setOpen(false)}
              >
                No, do not end this session.
              </button>
              <button
                className={classNames(
                  "rounded bg-brand-700 px-3 py-1 font-semibold text-white",
                  mutationState.fetching && "opacity-50"
                )}
                type="button"
                onClick={closeCloudroom}
                disabled={mutationState.fetching}
              >
                YES. Close this cloudroom immediately.
              </button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  ) : null
}

const endSessionMutation = gql`
  mutation ($sessionId: ID!) {
    sessionCloseCloudroom(sessionId: $sessionId) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default EndSession
