import React, { useState } from "react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Button } from "~tailwindui/Basics"

import classNames from "src/classNames"

export type StudentAnswerProps = {
  studentAnswer: any
}

const StudentAnswer: React.FC<StudentAnswerProps> = ({ studentAnswer }) => {
  const [showGroupPassages, setShowGroupPassages] = useState<boolean>(false)
  return (
    <div>
      {studentAnswer.question.groupPassages.length > 0 && (
        <>
          <Button
            onClick={() => setShowGroupPassages(!showGroupPassages)}
            className="mb-4"
          >
            {showGroupPassages ? "Hide" : "Show"} Full Passage
          </Button>
          {showGroupPassages && (
            <div>
              {studentAnswer.question.groupPassages.map(groupPassage => (
                <img key={groupPassage.url} src={groupPassage.url} alt="" />
              ))}
            </div>
          )}
        </>
      )}

      <div className="mb-8 flex justify-start border-x-0 border-t-0 border-solid border-slate-200">
        <div>
          {studentAnswer.question.multipleChoice ? (
            <>
              {studentAnswer.question.number}{" "}
              {studentAnswer.correct ? (
                <FontAwesomeIcon icon={faCheck} className="text-emerald-400" />
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="mr-2 text-red-400"
                  />
                  {studentAnswer.skipped && "(skipped)"}
                </>
              )}
              <div className="italic">
                <span className="font-semibold">Subscores:</span>{" "}
                {studentAnswer.question.subscores.map(
                  subscore => subscore.name
                )}
              </div>
              {studentAnswer.question.crossScore && (
                <div className="italic">
                  <span className="font-semibold">Cross Score:</span>{" "}
                  {studentAnswer.question.crossScore.name}
                </div>
              )}
              <ul>
                {studentAnswer.question.answers.map(answer => (
                  <div key={answer.id} className="form-group w-48">
                    <div
                      className={classNames(
                        "form-check pl-1",
                        answer.correct && "bg-emerald-100",
                        !answer.correct &&
                          answer.id === studentAnswer.answer?.id &&
                          "bg-red-100"
                      )}
                    >
                      <label className="form-check-label hover:cursor-default">
                        {answer.stringValue}
                        <input
                          type="radio"
                          disabled
                          className="form-check-input"
                          checked={answer.id === studentAnswer.answer?.id}
                        />
                        <span className="form-check-sign" />
                      </label>
                    </div>
                  </div>
                ))}
              </ul>
            </>
          ) : (
            <>
              {studentAnswer.question.number}{" "}
              {studentAnswer.correct ? (
                <FontAwesomeIcon icon={faCheck} className="text-emerald-400" />
              ) : (
                <FontAwesomeIcon icon={faTimes} className="text-red-400" />
              )}
              <div className="italic">
                <span className="font-semibold">Subscores:</span>{" "}
                {studentAnswer.question.subscores.map(
                  subscore => subscore.name
                )}
              </div>
              {studentAnswer.question.crossScore && (
                <div className="italic">
                  <span className="font-semibold">Cross Score:</span>{" "}
                  {studentAnswer.question.crossScore.name}
                </div>
              )}
              <div className="bg-emerald-100 pl-1">
                Correct Answer:{" "}
                {studentAnswer.question.correctAnswer.numericValue}
              </div>
              <div className="pl-1">
                Your Answer:{" "}
                {Object.values(studentAnswer.numericValueHash).join("")}
              </div>
            </>
          )}
        </div>
        <div>
          <img src={studentAnswer.question.image.url} alt="" />
          <img src={studentAnswer.question.passage.url} alt="" />
        </div>
      </div>
    </div>
  )
}

export default StudentAnswer
