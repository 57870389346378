import React from "react"

import PropTypes from "prop-types"

import { AlertMessageBox } from "~tailwindui/Basics"

import LoadingIndicator from "components/LoadingIndicator"

const WithLoadingIndicator = props => {
  const {
    loadingState: { loading, loaded, data, error: loadingError },
    error,
    heading,
    children,
  } = props

  if (loading)
    return (
      <React.Fragment>
        {heading}
        <LoadingIndicator />
      </React.Fragment>
    )

  if (loadingError)
    return (
      <React.Fragment>
        {heading}
        <AlertMessageBox>{error}</AlertMessageBox>
      </React.Fragment>
    )
  if (loaded && data) {
    return (
      <React.Fragment>
        {heading}
        {children({ data })}
      </React.Fragment>
    )
  }
  return null
}

WithLoadingIndicator.propTypes = {
  loadingState: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    data: PropTypes.object,
  }).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  heading: PropTypes.element,
  children: PropTypes.func.isRequired,
}

WithLoadingIndicator.defaultProps = {
  error: "An error occurred",
}

export default WithLoadingIndicator
