import React, { ReactNode } from "react"

import { Link } from "react-router-dom"

import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid"

type Breadcrumb = {
  breadcrumb: ReactNode
  match: {
    url: string
    params: Record<string, string>
  }
}

export type BreadcrumbProps = {
  breadcrumbs: Breadcrumb[]
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[]
}) => {
  return (
    <nav className="mb-4 mt-2 flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <a href="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {breadcrumbs.map(({ breadcrumb, match }) => (
          <li key={match.url}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <Link
                to={match.url || ""}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {breadcrumb}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
