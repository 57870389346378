import React, { useState } from "react"

import dayjs from "dayjs"
import { gql, useQuery } from "urql"

import Tabs from "~tailwindui/Basics/Tabs"
import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"
import DataDateRange from "components/OrganizationAdmin/components/DataDateRange"
import Header from "components/OrganizationAdmin/components/Form/Header"
import { sessionPresenceIcon } from "components/OrganizationAdmin/components/presenceIcon"

const SessionsPage = () => {
  const defaultStartingAt = dayjs().startOf("year")
  const defaultEndingAt = dayjs()
  const [startingAt, setStartingAt] = useState(defaultStartingAt)
  const [endingAt, setEndingAt] = useState(defaultEndingAt)

  const tabs = ["Past", "Future", "All"]
  const [selected, setSelected] = useState("Past")
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: sessionsQuery,
    variables: {
      page,
      future: selected === "Future",
      past: selected === "Past",
      startingAt,
      endingAt,
    },
  })

  const tableHeaders = [
    "Subject",
    "Date",
    "Students",
    "Tutor",
    "Present?",
    "Status",
  ]

  if (result.data?.viewer.canOrganizationAdminAccessFinances)
    tableHeaders.push("Billed")

  return (
    <>
      <Header title="Sessions" />
      <DataDateRange
        startDate={startingAt}
        setStartDate={setStartingAt}
        endDate={endingAt}
        setEndDate={setEndingAt}
      />
      <WithLoadingIndicator result={result}>
        {({ data }) => (
          <>
            <Table
              title={
                <>
                  <Tabs
                    tabs={tabs}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </>
              }
              data={data.viewer.organization.airtutorsSessions}
              changePage={setPage}
              headers={tableHeaders}
              valueMapper={session => [
                session.subject.name,
                <LocalTime key="startsAt" timestamp={session.startsAt} />,
                <ul key="studentsList" className="list-none">
                  {session.students.map(student => (
                    <li key={student.id}>{student?.fullName}</li>
                  ))}
                </ul>,
                session.tutor?.fullName,
                sessionPresenceIcon(session),
                <span
                  key="status"
                  className={
                    ["canceled", "declined"].includes(session.statusState)
                      ? "text-red-500"
                      : ""
                  }
                >
                  {session.statusState}
                </span>,
                data.viewer.canOrganizationAdminAccessFinances &&
                  session.billState,
              ]}
              rowCallback={session =>
                (window.location.href = `/organization_admin/sessions/${session.id}`)
              }
            />
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const sessionFields = gql`
  fragment SessionFields on AirtutorsSession {
    id
    startsAt
    endsAt
    noShow
    statusState
    billState
    formattedActualDuration
    tutor {
      id
      fullName
    }
    students {
      id
      fullName
    }
    subject {
      id
      name
    }
  }
`

const sessionsQuery = gql`
  ${sessionFields}
  query sessions(
    $page: Int!
    $future: Boolean
    $past: Boolean
    $startingAt: DateTime!
    $endingAt: DateTime!
  ) {
    viewer {
      canOrganizationAdminAccessFinances
      organization {
        airtutorsSessions(
          page: $page
          future: $future
          past: $past
          startDate: $startingAt
          endDate: $endingAt
        ) {
          currentPage
          totalPages
          data {
            ...SessionFields
          }
        }
      }
    }
  }
`

export default SessionsPage
