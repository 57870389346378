import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import {
  Failures,
  SubmitButton,
  TextAreaInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"

export type EditDetailsProps = {
  isOpen: boolean
  closeModal: () => void
  studyGroup: {
    id: string
    name: string
    details: string
  }
}

const EditDetails: React.FC<EditDetailsProps> = ({
  isOpen,
  closeModal,
  studyGroup,
}) => {
  const initialFormState = {
    studyGroupId: studyGroup.id,
    details: studyGroup.details || "",
  }

  const [, runMutation]: [any, RunMutation] = useMutation(editDetailsMutation)

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          const { failures } = result.data.editStudyGroupDetails
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.success("Details updated successfully")
            closeModal()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Modal.Dialog hideButton isOpen={isOpen} closeModal={closeModal}>
      <>
        <Modal.Header>Edit Group Details</Modal.Header>
        <Formik initialValues={initialFormState} onSubmit={handleSubmit}>
          <Form className="flex max-h-[calc(70vh-90px)] flex-col">
            <Modal.Body>
              <div className="h-52">
                <TextAreaInput
                  label="Group Details"
                  name="details"
                  description="These notes will not be seen by students."
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Failures />
              <div className="flex justify-end space-x-2">
                <Button color={Color.Red} onClick={closeModal}>
                  Cancel
                </Button>
                <SubmitButton>Update Details</SubmitButton>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </>
    </Modal.Dialog>
  )
}

const editDetailsMutation = gql`
  mutation EditStudyGroupDetails($studyGroupId: ID!, $details: String!) {
    editStudyGroupDetails(studyGroupId: $studyGroupId, details: $details) {
      failures {
        message
      }
      studyGroup {
        id
      }
    }
  }
`

export default EditDetails
