import React from "react"

import { Color } from "~tailwindui/types/enums"

import { buttonClassNames as tailwindButtonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

export type GoogleDocButtonProps = {
  googledocUrl: string
  userType: "Student" | "Tutor" | "Teacher" | "Admin"
  tailwind?: boolean
}

const GoogleDocButton: React.FC<GoogleDocButtonProps> = ({
  googledocUrl,
  userType,
  tailwind = false,
}) => {
  const buttonClassNames = classNames(
    "btn solid green mb-1",
    tailwind && tailwindButtonClassNames({ color: Color.Green }),
    tailwind && "w-full",
    userType === "Admin" && "btn-success",
    userType === "Teacher" && "bg-success-500 px-3 py-1 rounded font-semibold"
  )

  return googledocUrl ? (
    <a
      className={buttonClassNames}
      href={googledocUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      Google Doc
    </a>
  ) : null
}

export default GoogleDocButton
