import React from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import { path } from "~Tutor/TutorRoutes"
import { H2, Link, WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types/MatchParams"

import StudentAnswer from "./StudentAnswer"

export type SectionInstanceDetailsProps = {
  sectionInstanceId: string
  studentId: string
}

const SectionInstanceDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { studentId, id: sectionInstanceId } = match.params
  const [result] = useQuery({
    query,
    variables: { sectionInstanceId, studentId },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({
        data: {
          student: { sectionInstance },
        },
      }) => (
        <>
          <H2>{sectionInstance.section.subject}</H2>
          {sectionInstance.studentAnswers.map(studentAnswer => (
            <StudentAnswer
              key={studentAnswer.id}
              studentAnswer={studentAnswer}
            />
          ))}

          <Link
            to={path("studentTestPrepExamInstanceDetails", {
              studentId,
              id: sectionInstance.examInstance.id,
            })}
            button
          >
            Back to All Sections
          </Link>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query SectionInstanceDetails($sectionInstanceId: ID!, $studentId: ID!) {
    student(id: $studentId) {
      id
      fullName
      sectionInstance(id: $sectionInstanceId) {
        id
        section {
          id
          subject
        }
        examInstance {
          id
        }
        studentAnswers {
          id
          correct
          skipped
          numericValueHash
          answer {
            id
          }
          question {
            id
            number
            multipleChoice
            numeric
            correctAnswer {
              id
              stringValue
              numericValue
            }
            answers {
              id
              stringValue
              numericValue
              correct
              sortOrder
            }
            crossScore {
              id
              name
            }
            subscores {
              id
              name
            }
            image {
              url
            }
            passage {
              url
            }
            groupPassages {
              url
            }
          }
        }
      }
    }
  }
`

export default SectionInstanceDetails
