import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import TutorSubject from "components/Admin/TutorSubjects/TutorSubject"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const PendingTutorSubjects = () => {
  const loadingState = useQuery(
    buildQuery(
      pendingTutorSubjectsQuery,
      {},
      {
        onMutation: {
          when: /(approve|reject)TutorSubject/,
          run: ({ softReset, currentResults }, response) => {
            let updatedTutorSubject
            if (response.approveTutorSubject) {
              updatedTutorSubject = response.approveTutorSubject.tutorSubject
            } else {
              updatedTutorSubject = response.rejectTutorSubject.tutorSubject
            }
            currentResults.tutorSubjects = currentResults.tutorSubjects.filter(
              ts => ts.id !== updatedTutorSubject.id
            )
            softReset(currentResults)
          },
        },
      }
    )
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading pending tutor subjects"
    >
      {({ data }) =>
        data.tutorSubjects.length === 0 ? (
          <p className="lead">No pending tutor subjects</p>
        ) : (
          <>
            <div className="flex items-center [&>div]:basis-1/5">
              <div>Tutor</div>
              <div>Grade Range</div>
              <div>Subject Bucket</div>
            </div>
            {data.tutorSubjects.map(tutorSubject => (
              <div
                key={tutorSubject.id}
                className="flex items-center [&>div]:basis-1/5"
              >
                <div>
                  <a href={`/admin/tutors/${tutorSubject.tutor.id}`}>
                    {tutorSubject.tutor.fullName}
                  </a>
                </div>
                <div>{tutorSubject.subjectBucket.tier.gradeRange}</div>
                <TutorSubject
                  tutorSubject={tutorSubject}
                  canAdminTutorApplications={
                    data.viewer.canAdminTutorApplications
                  }
                />
              </div>
            ))}
          </>
        )
      }
    </WithLoadingIndicator>
  )
}

const pendingTutorSubjectsQuery = compress`
  query {
    viewer {
      canAdminTutorApplications
    }
    tutorSubjects(approvalState: submitted) {
      id
      approvalType
      approvalState
      tutor {
        id
        fullName
      }
      subjectBucket {
        name
        tier {
          gradeRange
        }
      }
    }
  }
`

export default PendingTutorSubjects
