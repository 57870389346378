import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { path } from "~Tutor/TutorRoutes"
import { Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"
import ConfirmSessionButton from "components/Tutor/components/Sessions/ConfirmSessionButton"
import DeclineSessionButton from "components/Tutor/components/Sessions/DeclineSessionButton"

import AirtutorsIcon from "../AirtutorsIcon"
import DirectoryIcon from "../DirectoryIcon"

export type RequestedSessionsProps = {
  hideIfEmpty?: boolean
}

const RequestedSessions: React.FC<RequestedSessionsProps> = ({
  hideIfEmpty = false,
}) => {
  const [page, setPage] = useState(1)
  const [result, refetchQuery] = useQuery({
    query: requestedSessionsQuery,
    variables: { page },
  })

  if (
    hideIfEmpty &&
    result.data &&
    result.data.viewer.requestedSessions.data.length === 0
  )
    return null

  return (
    <WithLoadingIndicator
      result={result}
      error="An error occurred while loading your requested sessions"
    >
      {({ data: { viewer } }) => (
        <Table
          title="Requested Sessions"
          changePage={setPage}
          data={viewer.requestedSessions}
          headers={[
            "Study Group",
            "Subject",
            "Students",
            "Date",
            "Notes",
            "Actions",
          ]}
          valueMapper={session => [
            session.studyGroup && (
              <Link
                key={session.studyGroup.id}
                to={path("studyGroupDetails", {
                  id: session.studyGroup.id,
                })}
              >
                <span className="flex items-center">
                  {session.directory ? (
                    <DirectoryIcon recordType={"session"} />
                  ) : (
                    <AirtutorsIcon recordType={"session"} />
                  )}{" "}
                  {session.studyGroup.name}
                </span>
              </Link>
            ),
            session.subject.name,
            <ul key={session.id}>
              {session.students.map(student => (
                <li key={student.id}>
                  <Link
                    to={path("studentDetails", {
                      id: student.id,
                    })}
                  >
                    {student.fullName}
                  </Link>
                </li>
              ))}
            </ul>,
            <div key={session.id} className="min-w-[80px]">
              <LocalTime format="ddd MMM D" timestamp={session.startsAt} />
              <br />
              <LocalTime omitDate timestamp={session.startsAt} />
              &mdash;
              <LocalTime omitDate timestamp={session.endsAt} />
            </div>,
            session.formattedSubstituteNotes,
            <div key={session.id} className="flex items-center space-x-1">
              <ConfirmSessionButton
                id={session.id}
                refetchQuery={refetchQuery}
              />
              <DeclineSessionButton
                id={session.id}
                refetchQuery={refetchQuery}
              />
            </div>,
          ]}
        />
      )}
    </WithLoadingIndicator>
  )
}

const requestedSessionsQuery = gql`
  query requestedSessionsQuery($page: Int!) {
    viewer {
      requestedSessions: sessions(
        filters: { statusState: requested }
        page: $page
      ) {
        currentPage
        totalPages
        data {
          id
          type
          directory
          airtutors
          startsAt
          endsAt
          formattedSubstituteNotes
          studyGroup {
            id
            name
          }
          students {
            id
            fullName
          }
          subject {
            id
            name
          }
        }
      }
    }
  }
`

export default RequestedSessions
