import React from "react"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import { VoidReturn } from "src/types/VoidReturn"

export type ConfirmDeleteCourseProps = {
  registrationCount: number
  publishedAt: string
  handleSubmit: VoidReturn
  closeModal: VoidReturn
}

const ConfirmDeleteCourse: React.FC<ConfirmDeleteCourseProps> = ({
  registrationCount,
  publishedAt,
  handleSubmit,
  closeModal,
}) => {
  return (
    <Modal.Dialog isOpen={true} hideButton closeModal={closeModal}>
      <>
        <Modal.Header>Delete Directory Course</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete your course?</p>
          {publishedAt && (
            <p className="mt-4">
              {registrationCount} students have registered.{" "}
              {registrationCount > 0
                ? "Refunds will be issued to all students."
                : "No refunds are necessary."}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end space-x-2">
            <Button onClick={closeModal}>Nevermind</Button>
            <Button
              color={Color.Red}
              onClick={() => {
                closeModal()
                handleSubmit()
              }}
            >
              Yes, {registrationCount > 0 ? "Issue Refunds" : "Delete Course"}
            </Button>
          </div>
        </Modal.Footer>
      </>
    </Modal.Dialog>
  )
}

export default ConfirmDeleteCourse
