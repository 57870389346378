import React, { useState } from "react"

import { NotificationContainer, NotificationManager } from "react-notifications"

import { AlertLevel } from "~tailwindui/types/enums"

import {
  AlertMessageBox,
  Spinner,
  WithLoadingIndicator,
} from "~tailwindui/Basics"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import { SessionFields } from "components/Admin/Session/Details/SessionFields"
import ChangeTutor from "components/Admin/Session/components/ChangeTutor"
import ConfirmSessionButton from "components/Admin/Session/components/ConfirmSessionButton"
import RequestSubstitute from "components/Admin/Session/components/RequestSubstitute"
import StudentPresenceStatus from "components/Admin/Session/components/StudentPresenceStatus"
import UpdateNoShow from "components/Admin/Session/components/UpdateNoShow"
import BasicDisplay from "components/BasicDisplay"
import ClipBoardButton from "components/ClipboardButton"
import LocalTime from "components/LocalTime"

import CloudroomAvLogs from "./CloudroomAvLogs"
import SessionButtons from "./SessionButtons"
import SessionPresences from "./SessionPresences"
import TwilioParticipation from "./TwilioParticipation"

import "react-notifications/lib/notifications.css"

export type AdminSessionDetailsProps = {
  id: string
}

const AdminSessionDetails: React.FC<AdminSessionDetailsProps> = ({ id }) => {
  const client = useClient()
  const [result] = useQuery({ query, variables: { id }, client })
  const [, runMutation]: any[] = useMutation(resendBoardInvitesMutation, client)

  const [loading, setLoading] = useState(false)

  const resendBoardInvites = () => {
    setLoading(true)
    runMutation({ sessionId: id })
      .then(
        result => {
          const { failures } = result.data.resendBoardInvites
          if (failures.length > 0) {
            NotificationManager.error(`Cannot send: ${failures[0].message}`)
          } else {
            NotificationManager.success("Board invites re-sent")
          }
        },
        () => NotificationManager.error("Cannot send")
      )
      .catch(() => NotificationManager.error("Cannot send: "))
      .finally(() => setLoading(false))
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { session } }) => (
        <div>
          <NotificationContainer />

          {session.airtutors && (
            <>
              <SessionButtons session={session} />
              <hr />
            </>
          )}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <BasicDisplay
                label="Session Type"
                value={session.directory ? "Directory" : "Air Tutors"}
              />
              <BasicDisplay label="Date">
                <LocalTime timestamp={session.startsAt} omitTime />
              </BasicDisplay>
              <BasicDisplay label="Time">
                <LocalTime timestamp={session.startsAt} omitDate /> -{" "}
                <LocalTime timestamp={session.endsAt} omitDate />
              </BasicDisplay>
              <BasicDisplay label="Subject" value={session.subject.name} />
              <BasicDisplay label="Study Group">
                <a href={session.studyGroup.showPath}>
                  {session.studyGroup.name}
                </a>
              </BasicDisplay>

              {session.cloudroomActive && (
                <BasicDisplay label="Student Cloudroom Url">
                  <ClipBoardButton
                    content={session.studentCloudroomPath}
                    className="btn btn-sm btn-info"
                  >
                    Click to copy URL
                  </ClipBoardButton>
                </BasicDisplay>
              )}

              {session.topicsCovered && (
                <div className="rounded-md bg-sky-100 p-2">
                  <BasicDisplay label="Topics Covered">
                    {session.topicsCovered}
                  </BasicDisplay>
                </div>
              )}

              {session.tutorFeedback && (
                <div className="mt-2 rounded-md bg-sky-100 p-2">
                  <BasicDisplay label="Tutor Feedback">
                    {session.tutorFeedback}
                  </BasicDisplay>
                </div>
              )}

              <BasicDisplay label="State">{session.statusState}</BasicDisplay>

              {session.formattedSubstituteNotes && (
                <BasicDisplay label="Substitute Notes">
                  {session.formattedSubstituteNotes}
                </BasicDisplay>
              )}

              {session.substituteReason && (
                <div className="rounded-md bg-red-100 p-2">
                  <BasicDisplay label="Substitute Reason">
                    {session.substituteReason}
                  </BasicDisplay>
                </div>
              )}

              {session.canceledBy && (
                <>
                  <BasicDisplay label="Canceled By">
                    {session.canceledBy}
                  </BasicDisplay>
                  <div className="rounded-md bg-red-100 p-2">
                    <BasicDisplay label="Cancellation Reason">
                      {session.cancellationReason}
                    </BasicDisplay>
                  </div>
                </>
              )}

              {session.compedReason && (
                <div className="rounded-md bg-red-100 p-2">
                  <BasicDisplay label="Comped Reason">
                    {session.compedReason}
                  </BasicDisplay>
                </div>
              )}

              {session.formattedActualDuration && (
                <BasicDisplay label="Actual Duration">
                  {session.formattedActualDuration}
                </BasicDisplay>
              )}

              {session.airtutors && (
                <>
                  <BasicDisplay
                    label="On Demand"
                    boolean
                    value={session.onDemand}
                  />
                  <BasicDisplay
                    label="Split Bill?"
                    boolean
                    value={session.splitBill}
                  />
                </>
              )}

              <BasicDisplay label="Bill State">
                {session.billState}
              </BasicDisplay>

              <div className="flex items-center space-x-4">
                <BasicDisplay
                  label="Billed as No Show?"
                  boolean
                  value={session.noShow}
                />
                {session.airtutors && (
                  <UpdateNoShow
                    airtutorsSessionId={session.id}
                    noShow={session.noShow}
                  />
                )}
              </div>

              {session.earlyAcknowledgement && (
                <BasicDisplay label="Ended Early Reason">
                  <span className="rounded-md bg-red-100 p-1">
                    {session.earlyAcknowledgement}
                  </span>
                </BasicDisplay>
              )}

              {session.formattedBilledDuration && (
                <BasicDisplay label="Billed Duration">
                  {session.formattedBilledDuration}
                </BasicDisplay>
              )}

              {session.durationChangeState !== "unchanged" && (
                <BasicDisplay label="Duration Change State">
                  {session.durationChangeState}
                  {session.durationChangeState === "approval_needed" && (
                    <a
                      href={session.changeDurationPath}
                      className="btn btn-sm btn-info ml-4"
                    >
                      View
                    </a>
                  )}
                </BasicDisplay>
              )}

              {session.invoices.length > 0 && (
                <BasicDisplay label="Invoices">
                  <ul>
                    {session.invoices.map(invoice => (
                      <li key={invoice.id}>
                        <a href={invoice.payerShowPath}>{invoice.payerName}</a>
                        <div>{invoice.aasmState}</div>
                        <div>{invoice.formattedBilledPrice}</div>
                        {invoice.stripeIntentId && (
                          <a
                            href={`https://dashboard.stripe.com/payments/${invoice.stripeIntentId}`}
                          >
                            {invoice.stripeIntentId}
                          </a>
                        )}
                        {invoice.prepaidHoursUsed && (
                          <div>
                            {invoice.prepaidHoursUsed} prepaid hours used
                          </div>
                        )}
                        <div>
                          Invoice Created:{" "}
                          <LocalTime timestamp={invoice.createdAt} omitTime />
                        </div>
                      </li>
                    ))}
                  </ul>
                </BasicDisplay>
              )}

              {session.tutorPaidAt && (
                <BasicDisplay label="Tutor Paid At">
                  <LocalTime timestamp={session.tutorPaidAt} omitTime />
                </BasicDisplay>
              )}

              {session.attachments.length > 0 && (
                <BasicDisplay label="Attachments">
                  <ul>
                    {session.attachments.map(({ filename, downloadUrl }) => (
                      <li key={downloadUrl}>
                        <a href={downloadUrl}>{filename}</a>
                      </li>
                    ))}
                  </ul>
                </BasicDisplay>
              )}

              {session.cloudroomRecordings.length > 0 && (
                <BasicDisplay label="Cloudroom Recordings">
                  <ul>
                    {session.cloudroomRecordings.map(recording => (
                      <li key={recording.url}>
                        <a href={recording.url}>
                          {recording.name} (
                          <LocalTime timestamp={recording.createdAt} />
                          {recording.duration &&
                            `, Duration: ${recording.duration}`}
                          )
                        </a>
                      </li>
                    ))}
                  </ul>
                </BasicDisplay>
              )}

              {session.cloudroomEventLogs.length > 0 && (
                <BasicDisplay label="Chime Event Logs">
                  <ul>
                    {session.cloudroomEventLogs.map(logFile => (
                      <li key={logFile.downloadUrl}>
                        <a href={logFile.downloadUrl}>{logFile.filename}</a>
                      </li>
                    ))}
                  </ul>
                </BasicDisplay>
              )}
            </div>

            <div>
              <BasicDisplay label="Tutor">
                <>
                  <div className="flex flex-col">
                    <a href={session.tutor.showPath}>
                      {session.tutor.fullName}
                    </a>
                    <a href={`mailto:${session.tutor.email}`}>
                      {session.tutor.email}
                    </a>
                    <a href={`sms:${session.tutor.phoneNumber}`}>
                      {session.tutor.phoneNumber}
                    </a>
                  </div>

                  {session.airtutors &&
                    session.statusState === "scheduled" &&
                    session.future && (
                      <div className="mt-4">
                        <ChangeTutor id={session.id} />
                        <AlertMessageBox level={AlertLevel.Info}>
                          Note: manually changing tutor will not mark this as a
                          substitute session for tracking purposes.
                        </AlertMessageBox>
                      </div>
                    )}

                  {session.airtutors && session.statusState === "scheduled" && (
                    <>
                      {session.mayRequestSubstitute ? (
                        <RequestSubstitute id={session.id} />
                      ) : (
                        session.future && (
                          <AlertMessageBox>
                            Session is scheduled for less than 1 hour from now.
                            Substitute requests cannot be sent. Use the change
                            tutor feature above.
                          </AlertMessageBox>
                        )
                      )}
                    </>
                  )}
                </>
              </BasicDisplay>

              {session.originalTutor && (
                <BasicDisplay label="Original Tutor">
                  <div className="flex flex-col">
                    <a href={session.originalTutor.showPath}>
                      {session.originalTutor.fullName}
                    </a>
                    <a href={`mailto:${session.originalTutor.email}`}>
                      {session.originalTutor.email}
                    </a>
                    <a href={`sms:${session.originalTutor.phoneNumber}`}>
                      {session.originalTutor.phoneNumber}
                    </a>
                  </div>
                </BasicDisplay>
              )}

              {session.requestedTutors.length > 0 && (
                <BasicDisplay label="Requested Tutors">
                  <ul>
                    {session.requestedTutors.map(tutor => (
                      <li key={tutor.id} className="flex flex-col">
                        <a href={session.tutor.showPath}>
                          {session.tutor.fullName}
                        </a>
                        <a href={`mailto:${session.tutor.email}`}>
                          {session.tutor.email}
                        </a>
                        <a href={`sms:${session.tutor.phoneNumber}`}>
                          {session.tutor.phoneNumber}
                        </a>
                        <div>
                          <ConfirmSessionButton
                            airtutorsSessionId={session.id}
                            tutorId={tutor.id}
                            tutorName={tutor.fullName}
                            buttonText="Accept Substitute Request"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </BasicDisplay>
              )}

              <hr />

              <BasicDisplay label="Students">
                <ul className="list-unstyled">
                  {session.students.map(student => (
                    <li key={student.id}>
                      <div className="grid grid-cols-2">
                        <div>
                          <a href={student.showPath}>{student.fullName}</a>
                          <br />
                          <a href={`mailto:${student.email}`}>
                            {student.email}
                          </a>
                          <br />
                          <a href={`sms:${student.phoneNumber}`}>
                            {student.phoneNumber}
                          </a>
                          <br />
                          <br />
                          <a href={student.parent?.showPath}>
                            {student.parentName}
                          </a>
                          <br />
                          <a href={`mailto:${student.parentEmail}`}>
                            {student.parentEmail}
                          </a>
                          <br />
                          <a href={`sms:${student.parentPhoneNumber}`}>
                            {student.parentPhoneNumber}
                          </a>
                          <br />
                          Participation Level:{" "}
                          {student.studentSession.participationLevel}
                        </div>
                        <div>
                          <StudentPresenceStatus
                            studentSessionId={student.studentSession.id}
                            present={student.studentSession.present}
                            noShow={student.studentSession.noShow}
                            excusedAbsence={
                              student.studentSession.excusedAbsence
                            }
                            canExcuseAbsences={session.canExcuseAbsences}
                          />
                        </div>
                      </div>
                      <hr />
                    </li>
                  ))}
                </ul>
              </BasicDisplay>
            </div>
          </div>

          {session.statusState !== "canceled" && (
            <BasicDisplay label="Resources">
              <>
                <a href={session.cloudroomPath} className="btn btn-info">
                  Cloud Room
                </a>
                <a href={session.ghostCloudroomPath} className="btn">
                  Ghost Cloud Room
                </a>
                {session.realtimeboards.map(board => (
                  <a
                    key={board.id}
                    href={board.url}
                    className="btn btn-success"
                  >
                    {board.displayName}
                  </a>
                ))}
                {session.realtimeboards.length > 0 && (
                  <button
                    disabled={loading}
                    type="button"
                    onClick={resendBoardInvites}
                    className="btn btn-warning"
                  >
                    <div className="flex space-x-2">
                      <span>Resend Board Invites</span>
                      {loading && <Spinner />}
                    </div>
                  </button>
                )}

                {session.googledocUrl && (
                  <a href={session.googledocUrl} className="btn btn-success">
                    Google Doc
                  </a>
                )}
                {session.googleSpreadsheetUrl && (
                  <a
                    href={session.googleSpreadsheetUrl}
                    className="btn btn-success"
                  >
                    Google Doc
                  </a>
                )}
              </>
            </BasicDisplay>
          )}

          {session.sessionPresences.length > 0 && (
            <SessionPresences
              activePresences={session.activeSessionPresences}
              allPresences={session.sessionPresences}
            />
          )}

          {session.createdTwilioCloudroom && (
            <TwilioParticipation
              sessionId={session.id}
              sessionParticipants={session.sessionParticipants}
              sessionParticipantSummaries={session.sessionParticipantSummaries}
            />
          )}

          <CloudroomAvLogs session={session} />
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query SessionQuery($id: ID!) {
    ${SessionFields}
    session(id: $id) {
      ...SessionFields
    }
  }
`

const resendBoardInvitesMutation = gql`
  mutation ($sessionId: ID!) {
    resendBoardInvites(sessionId: $sessionId) {
      failures {
        message
      }
    }
  }
`

export default AdminSessionDetails
