import React from "react"

import Select from "react-select"
import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import ordered from "src/ordered"

export type StudyGroupSubjectsProps = {
  studyGroupId: string
  subjectId: string
  onChange: (subject: { id: string }) => void
}

const StudyGroupSubjects: React.FC<StudyGroupSubjectsProps> = ({
  studyGroupId,
  subjectId,
  onChange,
}) => {
  const [result] = useQuery({
    query: subjectsQuery,
    variables: { id: studyGroupId },
  })

  return (
    <WithLoadingIndicator
      result={result}
      error="An error occurred while loading your subjects"
    >
      {({ data }) => (
        <Select
          options={ordered(data.studyGroup.subjects, "name")}
          onChange={onChange}
          defaultValue={
            subjectId
              ? data.studyGroup.subjects.find(s => s.id === subjectId)
              : null
          }
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          styles={{
            control: provided => ({
              ...provided,
              "*": {
                boxShadow: "none !important",
              },
            }),
          }}
        />
      )}
    </WithLoadingIndicator>
  )
}

const subjectsQuery = gql`
  query SubjectsQuery($id: ID!) {
    studyGroup(id: $id) {
      subjects {
        id
        name
      }
    }
  }
`

export default StudyGroupSubjects
