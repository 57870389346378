import React from "react"

import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"
import Nav from "~tailwindui/Nav"

import { path } from "./TutorRoutes"

export type TutorNavProps = {
  logout: () => void
}

const TutorNav: React.FC<TutorNavProps> = ({ logout }) => {
  const [result] = useQuery({ query })

  const navItems = [
    { name: "Home", path: path("dashboard") },
    { name: "Sessions", path: path("sessionsIndex") },
    { name: "Study Groups", path: path("studyGroupsIndex") },
    { name: "Students", path: path("studentsIndex") },
    { name: "Reporting", path: path("sessionReporting") },
    {
      name: "Directory",
      path: path("directory"),
    },
    {
      name: "Tutor Playbook",
      path: "https://sites.google.com/airtutors.org/tutorplaybook/home",
      absolutePath: true,
    },
  ]

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <Nav
          navItems={navItems}
          viewer={viewer}
          profilePath={path("profileDetails")}
          logout={logout}
        />
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query ViewerQuery {
    viewer {
      id
      firstName
      fullName
      email
      avatar {
        thumbUrl
      }
    }
  }
`

export default TutorNav
