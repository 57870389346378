import React from "react"

import { Dayjs } from "dayjs"
import { Form, Formik, FormikBag } from "formik"

import { AutocompleteSelect, SelectInput, SubmitButton } from "~tailwindui/Form"

import { billOptions, statusOptions } from "src/enums"

import DateFilters from "./DateFilters"

export type FormState = {
  dateType: "month" | "year" | "custom" | "all"
  monthOption: string
  yearOption: string
  startDate: Dayjs
  endDate: Dayjs
  statusState: string
  billState: string
  subjectIds: string[]
  studyGroupIds: string[]
  type: string
}

export type FiltersProps = {
  formState: FormState
  handleSubmit: (values: FormState, actions: FormikBag<any, FormState>) => void
}

const Filters: React.FC<FiltersProps> = ({ formState, handleSubmit }) => {
  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      <Form>
        <div className="sm:grid sm:grid-cols-2 sm:gap-5">
          <div className="sm:col-span1">
            <AutocompleteSelect
              multi
              clearable
              name="subjectIds"
              label="Subjects"
              resource="subjects"
              className="w-20"
            />

            <AutocompleteSelect
              multi
              clearable
              name="studyGroupIds"
              label="Study Groups"
              resource="studyGroups"
              className="w-20"
            />

            <SelectInput
              clearable
              name="statusState"
              label="Scheduling Status"
              options={statusOptions}
              className="w-20"
            />

            <SelectInput
              clearable
              name="billState"
              label="Billing Status"
              options={billOptions}
              className="w-20"
            />
          </div>

          <div className="sm:col-span1">
            <SelectInput
              clearable
              name="type"
              label="Session Type"
              options={[
                { label: "Airtutors", value: "AirtutorsSession" },
                { label: "Directory", value: "DirectorySession" },
              ]}
              placeholder="All"
              className="w-20"
            />

            <DateFilters />
          </div>
        </div>

        <SubmitButton text="Apply Filters" />
      </Form>
    </Formik>
  )
}

export default Filters
