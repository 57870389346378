import React, { useContext } from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import { H2, WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types"

import TutorContext from "components/Tutor/TutorContext"
import EditSubjectBuckets from "components/Tutor/components/TutorSubjects/EditSubjectBuckets"

const DirectorySubjectBucketEdit: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match }) => {
  const tierId = match.params.id
  const { viewer } = useContext(TutorContext)
  const tutorId = viewer.id

  const [result] = useQuery({
    query,
    variables: { tutorId, tierId },
    requestPolicy: "network-only",
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer, tier } }) => (
        <>
          <H2>Edit {tier.gradeRange} Subjects</H2>
          <EditSubjectBuckets
            tier={tier}
            approvalType={"directory"}
            viewer={viewer}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query SubjectBucketsQuery($tutorId: ID!, $tierId: ID!) {
    viewer {
      id
      subjectBuckets(tierId: $tierId, approvalType: directory) {
        id
        name
        approvalState(tutorId: $tutorId, approvalType: directory)
      }
    }
    tier(id: $tierId) {
      id
      gradeRange
      subjectBuckets {
        id
        name
      }
    }
  }
`

export default DirectorySubjectBucketEdit
