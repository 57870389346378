import React from "react"

import BilledSessions from "components/Tutor/components/Sessions/BilledSessions"
import RequestedSessions from "components/Tutor/components/Sessions/RequestedSessions"
import ScheduledSessions from "components/Tutor/components/Sessions/ScheduledSessions"
import UnbilledSessions from "components/Tutor/components/Sessions/UnbilledSessions"

export type SessionsProps = Record<never, any>

const Sessions: React.FC<SessionsProps> = () => {
  return (
    <>
      <RequestedSessions />
      <ScheduledSessions />
      <UnbilledSessions />
      <BilledSessions />
    </>
  )
}

export default Sessions
