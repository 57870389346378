import React from "react"

import { gql, useQuery } from "urql"

import { path } from "~Tutor/TutorRoutes"
import { WithLoadingIndicator } from "~tailwindui/Basics"

import TutorCloudRoom from "components/TwilioCloudRoom/Tutor"

export type TwilioProps = {
  sessionId: string
}

const Twilio: React.FC<TwilioProps> = ({ sessionId }) => {
  const [result] = useQuery({ query, variables: { sessionId } })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer, session } }) => (
        <TutorCloudRoom
          id={session.id}
          airtutors={session.airtutors}
          currentUserId={viewer.id}
          startsAt={session.startsAt}
          endsAt={session.endsAt}
          ssoEnabled={true}
          ssoEmail={viewer.ssoEmail}
          phoneNumber={viewer.phoneNumber}
          googledocUrl={session.googledocUrl}
          audioMuted={session.allAudioMuted}
          videoMuted={session.allVideoMuted}
          controlsLocked={session.allControlsLocked}
          controlsLockedUserIds={session.controlsLockedUserIds}
          studentScreenShareDisabled={session.studentScreenShareDisabled}
          boardId={session.realtimeboards[0]?.id}
          studyGroupPath={
            session.studyGroup &&
            path("studyGroupDetails", {
              id: session.studyGroup.id,
            })
          }
          studentPath={
            session.students[0] &&
            path("studentDetails", {
              id: session.students[0].id,
            })
          }
          studyGroupDetails={session.studyGroup?.details}
          studentNotes={
            session.students.length === 1 && session.students[0].notes
          }
          accessToken={session.twilioAccessToken}
        />
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query TwilioCloudroomQuery($sessionId: ID!) {
    viewer {
      id
      ssoEmail
      phoneNumber
    }
    session(id: $sessionId) {
      id
      airtutors
      chimeCloudroomEnabled
      startsAt
      endsAt
      googledocUrl
      allAudioMuted
      allVideoMuted
      allControlsLocked
      controlsLockedUserIds
      studentScreenShareDisabled
      twilioAccessToken
      realtimeboards {
        id
      }
      students {
        id
        notes
      }
      studyGroup {
        id
        details
      }
    }
  }
`

export default Twilio
