import React, { useState } from "react"

import { gql, useMutation } from "urql"

import { TrashIcon } from "@heroicons/react/24/outline"

import AlertMessageBox from "~tailwindui/Basics/AlertMessageBox"

import LocalTime from "components/LocalTime"

export type SemesterItemProps = { semester: { [key: string]: any } }

const SemesterItem: React.FC<SemesterItemProps> = ({
  semester: { id, name, startsOn, endsOn },
}) => {
  const [error, setError] = useState("")
  const [, removeSemester] = useMutation(removeSemesterMutation)
  const deleteSemester = (id: number) => {
    removeSemester({ id })
      .then(
        result => {
          const { errorMessages } = result.data.removeSemester
          if (errorMessages) {
            setError(errorMessages)
          }
        },
        () => setError("Something went wrong")
      )
      .catch(() => setError("Something went wrong"))
  }

  return (
    <li key={id} className="px-4">
      <div className="border-t border-gray-100 px-4 py-7 sm:col-span-1 sm:px-0">
        {error ? (
          <AlertMessageBox>{error}</AlertMessageBox>
        ) : (
          <>
            <dt className="text-md font-medium leading-6 text-gray-900">
              <span className="flex align-middle">
                {name}{" "}
                <TrashIcon
                  className="ml-2 inline h-5 w-5 cursor-pointer text-gray-500"
                  onClick={() => deleteSemester(id)}
                />
              </span>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              <LocalTime timestamp={startsOn} omitTime /> -{" "}
              <LocalTime timestamp={endsOn} omitTime />
            </dd>
          </>
        )}
      </div>
    </li>
  )
}

const removeSemesterMutation = gql`
  mutation ($id: ID!) {
    removeSemester(id: $id) {
      organization {
        semesters {
          id
          name
          startsOn
          endsOn
        }
      }
    }
  }
`

export default SemesterItem
