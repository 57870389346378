import React from "react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type BasicDisplayPropsType =
  | {
      label: string
      labelClassName?: string
      value?: never
      boolean?: never
      children: React.ReactNode
    }
  | {
      label: string
      labelClassName?: string
      value: any
      boolean?: boolean
      children?: never
    }

const BasicDisplay: React.FC<BasicDisplayPropsType> = ({
  label,
  value,
  children,
  labelClassName = "",
  boolean = false,
}) => (
  <div className={`my-3 ${labelClassName}`}>
    <div className="font-medium">{label}</div>
    <div>
      {boolean ? (
        <>
          {value ? (
            <FontAwesomeIcon icon={faCheck} className="text-emerald-600" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="text-red-500" />
          )}
        </>
      ) : (
        children || value
      )}
    </div>
  </div>
)

export default BasicDisplay
