import React, { useMemo, useState } from "react"

import { Form, Formik } from "formik"
import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/24/solid"

import { ValueFormat } from "~tailwindui/types/enums"

import { H3, WithLoadingIndicator } from "~tailwindui/Basics"
import {
  CheckboxInput,
  Failures,
  SubmitButton,
  TextInput,
  handleFailure,
} from "~tailwindui/Form"

import calculateTutorPayout from "src/calculateTutorPayout"
import formatValue from "src/formatValue"
import ordered from "src/ordered"
import { RunMutation } from "src/types"
import uniq from "src/uniq"

import useLocalStorage from "hooks/useLocalStorage"

import LinkStripeAccountModal from "components/Tutor/Pages/Profile/Details/LinkStripeAccountModal"

import ManageSchedules from "../TutorSchedules/ManageSchedules"

import StorageContext from "./StorageContext"
import StorageWrapper from "./StorageWrapper"

const ValidationSchema = Yup.object().shape({
  personalEmail: Yup.string().required("Personal email is required"),
  hourlyRate: Yup.number()
    .required()
    .min(25, "Hourly rate must be at least $25"),
  subjectBucketIds: Yup.array().min(1, "Please choose at least one subject"),
})

export type OnboardingFormProps = Record<string, never>

const OnboardingForm: React.FC<OnboardingFormProps> = () => {
  const [bankModalOpen, setBankModalOpen] = useState(false)
  const [store, setStore] = useLocalStorage("tutorOnboarding", {})

  const buildFormState = viewer => ({
    personalEmail: viewer.personalEmail || store.personalEmail || "",
    hourlyRate: viewer.hourlyRate || store.hourlyRate || "",
    subjectBucketIds: store.subjectBucketIds || [],
  })

  const [result] = useQuery({ query })

  const [, runMutation]: [any, RunMutation] = useMutation(
    directoryOnboardingMutation
  )

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          const { failures } = result.data.directoryOnboarding
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            setStore({})
            window.location.reload()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const subjectOptions = useMemo(() => {
    if (!result.data) return []

    const tutorSubjects = result.data.viewer.tutorSubjects
    const subjectBuckets = ordered(
      uniq(
        tutorSubjects.map(ts => ts.subjectBucket),
        "id"
      ),
      "name"
    )

    return subjectBuckets.map(bucket => ({
      label: bucket.name,
      value: bucket.id,
    }))
  }, [result.data])

  return (
    <WithLoadingIndicator result={result}>
      {({
        data: { viewer, directoryPercentFee, stripePercentFee, stripeFlatFee },
      }) => (
        <>
          <p className="my-4">
            We are thrilled to introduce a new initiative that offers you
            greater control and flexibility in your teaching career!
          </p>

          <H3 className="mt-8 border-b-[1px]">What’s New?</H3>

          <p className="my-4">
            In addition to the current model where you are paired with clients
            and paid an hourly rate, we are now offering a more flexible option
            where <span className="font-semibold">you</span> are in control:
          </p>

          <ul className="my-4 space-y-2">
            <li>
              <span className="font-semibold italic">
                Create Your Own Courses
              </span>
              : Design and develop courses that reflect your expertise and
              passion.
            </li>
            <li>
              <span className="font-semibold italic">
                Find Your Own Clients
              </span>
              : Market your courses directly to potential students, and build
              your own client base.
            </li>
            <li>
              <span className="font-semibold italic">Set Your Own Rates</span>:
              Determine the value of your time and skills by setting your own
              pricing for each course or session.
            </li>
          </ul>

          <H3 className="mt-8 border-b-[1px]">How Is This Different?</H3>

          <ul className="my-4 space-y-2">
            <li>
              <span className="font-semibold italic">
                Air Tutors (current) model
              </span>
              : We match you with students and study groups, and you are paid a
              fixed hourly rate by us.
            </li>
            <li>
              <span className="font-semibold italic">Directory model</span>: You
              have full autonomy to create courses, attract students, and set
              your rates.
            </li>
          </ul>

          <H3 className="mt-8 border-b-[1px]">Why Join the Tutor Directory?</H3>

          <ul className="my-4 space-y-2">
            <li>
              <span className="font-semibold italic">
                Increased Flexibility
              </span>
              : Work on your own terms, at your own pace.
            </li>
            <li>
              <span className="font-semibold italic">
                Unlimited Earning Potential
              </span>
              : Your income is directly tied to the value you create.
            </li>
            <li>
              <span className="font-semibold italic">Creative Freedom</span>:
              Teach the subjects you are most passionate about, in your own way.
            </li>
          </ul>

          <H3 className="mt-8 border-b-[1px]">Getting Started</H3>

          <StorageContext.Provider value={[store, setStore]}>
            <Formik
              initialValues={buildFormState(viewer)}
              onSubmit={handleSubmit}
              validationSchema={ValidationSchema}
            >
              {({ values }) => (
                <Form>
                  <ul className="m-4 list-decimal space-y-6">
                    <li>
                      <strong>
                        Provide an email, hourly rate, and updated education
                        history.
                      </strong>{" "}
                      <br />
                      Note that you will receive 75% of the hourly rate set,
                      minus Stripe fees.
                      <div className="mt-4">
                        <StorageWrapper name="personalEmail">
                          <TextInput
                            label="Personal Email"
                            name="personalEmail"
                            description="This email will be used for notifications regarding Directory sessions."
                          />
                        </StorageWrapper>

                        <StorageWrapper name="hourlyRate">
                          <TextInput
                            type="number"
                            label="Hourly Rate (Individual Sessions)"
                            name="hourlyRate"
                            description={
                              values.hourlyRate &&
                              `You receive: ${formatValue(
                                calculateTutorPayout({
                                  hourlyRate: values.hourlyRate,
                                  directoryPercentFee,
                                  stripePercentFee,
                                  stripeFlatFee,
                                }),
                                ValueFormat.Currency
                              )}`
                            }
                          />
                        </StorageWrapper>
                      </div>
                      <div className="text-red-500">
                        Updating education history will go here after #1540 is
                        merged
                      </div>
                    </li>

                    <li>
                      <strong>Create a Stripe account</strong> and link a bank
                      account to which your session payments will be deposited.
                      {viewer.stripeAccountId &&
                      viewer.stripeBankAccountLinked ? (
                        <div className="mt-2 flex items-center">
                          <span>You're all set! </span>
                          <CheckCircleIcon className="inline-block h-5 w-5 text-emerald-500" />
                        </div>
                      ) : (
                        <>
                          <span
                            className="ml-2 cursor-pointer text-sky-600 underline"
                            onClick={() => setBankModalOpen(true)}
                          >
                            Go to Stripe{" "}
                            <ArrowRightIcon className="inline-block h-5 w-5" />
                          </span>
                          {bankModalOpen && (
                            <LinkStripeAccountModal
                              closeModal={() => setBankModalOpen(false)}
                            />
                          )}
                        </>
                      )}
                    </li>

                    <li>
                      <p>
                        <strong>
                          Let students know when you're available!
                        </strong>{" "}
                        <br />
                        Students will only be able to request sessions that
                        occur during these times.
                      </p>

                      <p className="my-4">
                        Click on the calendar (tap and hold on mobile) to add a
                        new availability. You may add one-off, weekly, or
                        biweekly availabilities.
                      </p>

                      <ManageSchedules smallCalendar />
                    </li>

                    <li>
                      <strong>
                        Choose which subjects appear on your Directory profile.
                      </strong>
                      <br />
                      Choose from your existing subjects below - don't worry,
                      you can add more later!
                      <StorageWrapper name="subjectBucketIds">
                        <CheckboxInput
                          checkboxType="multi"
                          name="subjectBucketIds"
                          options={subjectOptions}
                        />
                      </StorageWrapper>
                    </li>
                  </ul>

                  <Failures />
                  <SubmitButton text="Join the Tutor Directory" />
                </Form>
              )}
            </Formik>
          </StorageContext.Provider>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query Viewer {
    viewer {
      id
      personalEmail
      hourlyRate
      stripeAccountId
      stripeBankAccountLinked
      tutorSubjects {
        subjectBucket {
          id
          name
        }
      }
    }
    directoryPercentFee
    stripePercentFee
    stripeFlatFee
  }
`

const directoryOnboardingMutation = gql`
  mutation DirectoryOnboarding(
    $personalEmail: String!
    $hourlyRate: Float!
    $subjectBucketIds: [ID!]!
  ) {
    directoryOnboarding(
      personalEmail: $personalEmail
      hourlyRate: $hourlyRate
      subjectBucketIds: $subjectBucketIds
    ) {
      failures {
        message
      }
    }
  }
`

export default OnboardingForm
