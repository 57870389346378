import React from "react"

import { useField, useFormikContext } from "formik"

import {
  ClipboardDocumentListIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid"

type File = {
  file: string
  filename?: string
}

export type FileInputProps = {
  label: string
  name: string
  description?: string
  photo?: boolean
  hideErrors?: boolean
}

const FileInput: React.FC<
  FileInputProps & React.HTMLProps<HTMLInputElement>
> = ({
  name,
  label,
  description = "",
  hideErrors = false,
  photo = false,
  ...props
}) => {
  const [field, meta] = useField({ name })
  const { setFieldValue } = useFormikContext()

  const error = meta.error ? meta.error : ""

  return (
    <div className="mb-3">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-1 flex items-center gap-x-3">
        {photo ? (
          <UserCircleIcon
            className="h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
        ) : (
          <ClipboardDocumentListIcon
            className="h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
        )}
        <label
          htmlFor="file-input"
          className="cursor-pointer rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Change
        </label>
        <input
          type="file"
          id="file-input"
          className="hidden"
          onChange={e => setFieldValue(name, e.currentTarget.files[0])}
          {...props}
        />
        <span>{field.value?.name}</span>
      </div>
      <div className="text-sm">
        {!hideErrors && error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </div>
  )
}

const readFile = field => {
  const fileExists = field?.name || field?.fileName
  const reader = new FileReader()
  const promise = new Promise<void | File>(resolve => {
    if (!fileExists) {
      resolve()
      return
    }

    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = (reader.result as string).replace(/^data:.+;base64,/, "")

      const fileObject: File = { file: b64 }
      const filename = field.name || field.fileName
      const extension = (field.name || field.fileName).split(".").pop()
      if (!filename) {
        fileObject.filename = `${filename}.${extension}`
      } else {
        fileObject.filename = filename
      }
      resolve(fileObject)
    }
  })
  if (fileExists) {
    reader.readAsDataURL(field)
  }
  return promise
}

export default FileInput

export { readFile }
