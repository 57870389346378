import React, { useContext } from "react"

import { RouteComponentProps } from "react-router"
import { gql, useQuery } from "urql"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import TutorContext from "~Tutor/TutorContext"
import { path } from "~Tutor/TutorRoutes"
import ProxyNumber from "~Tutor/components/ProxyNumber"
import TutorNote from "~Tutor/components/TutorNote"
import {
  AlertMessageBox,
  H2,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import DescriptionItem from "~tailwindui/Basics/DescriptionItem"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import { MatchParams } from "src/types/MatchParams"

import NotFound from "components/Tutor/Pages/NotFound"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"

import CreateDirectoryGroupModal from "./CreateDirectoryGroupModal"
import CreateOrganizationGroupModal from "./CreateOrganizationGroupModal"
import ScheduledSessions from "./FutureSessions"
import PastSessions from "./PastSessions"

const StudentDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const studentId = match.params.id
  const { viewer } = useContext(TutorContext)
  const tutorId = viewer.id

  const [result] = useQuery({
    query,
    variables: { studentId, tutorId },
  })

  if (result.data && !result.data.student) return <NotFound />

  return (
    <WithLoadingIndicator result={result}>
      {({
        data: {
          student,
          viewer: { directorySubjects },
        },
      }) => (
        <>
          <H2 className="flex justify-between">
            <span>{student.fullName}</span>
          </H2>

          <AlertMessageBox level={AlertLevel.Info}>
            All 1-1 sessions will now take place via 1-1 groups. To schedule a
            session, go to the appropriate group and click the green "Schedule
            Session".
          </AlertMessageBox>

          <div className="mb-4 flex space-x-4">
            {viewer.directory &&
              (student.directoryOneOnOneGroup ? (
                <Link
                  to={path("studyGroupDetails", {
                    id: student.directoryOneOnOneGroup.id,
                  })}
                  button
                  color={Color.Purple}
                >
                  Go to Private 1-1 Group
                </Link>
              ) : (
                <CreateDirectoryGroupModal
                  student={student}
                  subjects={directorySubjects}
                />
              ))}

            {student.organization &&
              (student.organizationOneOnOneGroup ? (
                <Link
                  to={path("studyGroupDetails", {
                    id: student.organizationOneOnOneGroup.id,
                  })}
                  className={buttonClassNames({ color: Color.Orange })}
                >
                  Go to Organization 1-1 Group
                </Link>
              ) : (
                <CreateOrganizationGroupModal studentName={student.firstName} />
              ))}
          </div>

          <div className="flex flex-wrap justify-between">
            <div>
              <TutorNote title="Notes" content={student.notes} />
              <ul className="flex">
                {student.tags.map(tag => (
                  <li
                    className="mr-4 rounded bg-sky-400 p-2 text-white"
                    key={tag.name}
                  >
                    {tag.name}
                  </li>
                ))}
              </ul>

              <DescriptionItem
                label="Phone Number"
                value={<ProxyNumber tutorId={tutorId} studentId={studentId} />}
              />
              <DescriptionItem label="Email" value={student.email} />
              {student.parentName && (
                <>
                  <DescriptionItem
                    label="Parent Name"
                    value={student.parentName}
                  />
                  <DescriptionItem
                    label="Parent Phone Number"
                    value={
                      <ProxyNumber
                        tutorId={tutorId}
                        studentId={studentId}
                        forParent={true}
                      />
                    }
                  />
                </>
              )}
              <DescriptionItem label="Grade" value={student.grade?.name} />
              {student.organization && (
                <>
                  <DescriptionItem
                    label="Organization"
                    value={student.organization.name}
                  />
                  {student.organization.details.length > 0 && (
                    <DescriptionItem
                      label="Organization Details"
                      value={student.organization.details}
                    />
                  )}
                </>
              )}
              <DescriptionItem label="School" value={student.school?.name} />
              <DescriptionItem
                label="Study Groups"
                value={
                  <ul>
                    {student.studyGroups.map(group => (
                      <li key={group.id}>
                        <Link
                          to={path("studyGroupDetails", { id: group.id })}
                          className={
                            group.directory
                              ? "text-purple-500"
                              : "!text-red-500"
                          }
                        >
                          {group.directory ? (
                            <DirectoryIcon recordType="studyGroup" />
                          ) : (
                            <AirtutorsIcon recordType="studyGroup" />
                          )}
                          {group.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                }
              />

              <ul className="list-unstyled my-2">
                <li>
                  <Link
                    to={path("studentTailoredAssignmentsIndex", {
                      id: studentId,
                    })}
                    button
                    color={Color.Blue}
                    className="mb-4 inline-block"
                  >
                    View Tailored Assignments
                  </Link>
                </li>

                <li>
                  <Link
                    to={path("studentLessonPlansIndex", {
                      id: studentId,
                    })}
                    button
                    color={Color.Blue}
                    className="mb-4 inline-block"
                  >
                    View Lesson Plans
                  </Link>
                </li>

                {student.hasTestPrepAccess && (
                  <li>
                    <Link
                      to={path("studentTestPrepExams", {
                        id: student.id,
                      })}
                      className="mb-4 inline-block"
                      button
                      color={Color.Orange}
                    >
                      View Test Prep
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            {student.organization?.calendarEmbedUrl && (
              <iframe
                title={`${student.organization.name} Calendar`}
                className="my-4 max-w-full border-none"
                src={student.organization.calendarEmbedUrl}
                width="600"
                height="400"
              />
            )}
          </div>

          <ScheduledSessions studentId={student.id} />
          <PastSessions studentId={student.id} />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query StudentDetailsQuery($studentId: ID!, $tutorId: ID!) {
    viewer {
      directorySubjects: subjects(approvalType: directory, approved: true) {
        id
        name
      }
    }
    student(id: $studentId) {
      id
      directory
      fullName
      firstName
      notes
      email
      tutorToStudentPhoneNumber(tutorId: $tutorId)
      parentName
      tutorToParentPhoneNumber(tutorId: $tutorId)
      organizationOneOnOneGroup(tutorId: $tutorId) {
        id
      }
      directoryOneOnOneGroup(tutorId: $tutorId) {
        id
      }
      hasTestPrepAccess
      tags {
        name
      }
      grade {
        id
        name
      }
      emailTimeZone
      organization {
        id
        name
        details
        calendarEmbedUrl
      }
      school {
        id
        name
      }
      studyGroups(tutorId: $tutorId) {
        id
        name
        directory
      }
    }
  }
`

export default StudentDetails
