import React from "react"

import dayjs from "src/dayjs"

import BillSessionButton from "components/Admin/Session/components/BillSessionButton"
import CancelSessionButton from "components/Admin/Session/components/CancelSessionButton"
import CompSessionButton from "components/Admin/Session/components/CompSessionButton"
import ReassignStudentsButton from "components/Admin/Session/components/ReassignStudentsButton"
import RescheduleSessionButton from "components/Admin/Session/components/RescheduleSessionButton"

export type SessionButtonsProps = {
  session: {
    id: string
    endsAt: string
    startsAt: string
    statusState: string
    billState: string
    mayCancelByAdmin: boolean
    directory: boolean
    scheduledDurationMinutes: number
    mayComp: boolean
    tutor: {
      type: "Tutor" | "Teacher"
    }
  }
}

const SessionButtons: React.FC<SessionButtonsProps> = ({ session }) => {
  if (session.directory) return null

  return (
    <>
      {dayjs(session.endsAt).isAfter(dayjs()) && (
        <>
          <RescheduleSessionButton
            id={session.id}
            startsAt={session.startsAt}
            durationMinutes={session.scheduledDurationMinutes}
          />
          {session.statusState !== "canceled" && (
            <ReassignStudentsButton airtutorsSessionId={session.id} />
          )}
        </>
      )}

      {session.billState === "unbilled" && (
        <>
          {session.tutor.type === "Tutor" &&
            session.statusState !== "canceled" && (
              <BillSessionButton id={session.id} />
            )}
          {session.mayCancelByAdmin && <CancelSessionButton id={session.id} />}
          {session.mayComp && (
            <CompSessionButton size="normal" id={session.id} />
          )}
        </>
      )}
    </>
  )
}

export default SessionButtons
