export default (arr: any[], attribute?: string) => {
  if (attribute) {
    const uniqAttributes = Array.from(
      new Set(arr.map(item => item?.[attribute]))
    )
    return uniqAttributes.map(value =>
      arr.find(group => group?.[attribute] === value)
    )
  } else return Array.from(new Set(arr))
}
