import React, { useContext } from "react"

import { Color } from "~tailwindui/types/enums"

import TutorContext from "~Tutor/TutorContext"
import { path } from "~Tutor/TutorRoutes"
import { H1, Link } from "~tailwindui/Basics"

import ApprovedExpenses from "./ApprovedExpenses"
import ChangesRequestedExpenses from "./ChangesRequestedExpenses"
import DeniedExpenses from "./DeniedExpenses"
import SubmittedExpenses from "./SubmittedExpenses"

const TutorExpensesList = () => {
  const { viewer } = useContext(TutorContext)

  return (
    <>
      <H1>Your Expenses</H1>
      {viewer.mayCreateExpense && (
        <div>
          <Link to={path("expenseNew")} button color={Color.Orange}>
            New Expense
          </Link>
        </div>
      )}
      <ChangesRequestedExpenses />
      <SubmittedExpenses />
      <ApprovedExpenses />
      <DeniedExpenses />
    </>
  )
}

export default TutorExpensesList
