import React from "react"

import { H3 } from "~tailwindui/Basics"
import { CheckboxInput, Error } from "~tailwindui/Form"

import { tutorAssessmentOptions } from "src/enums"
import ordered from "src/ordered"

type Student = {
  id: string
  fullName: string
}

type StudentSession = {
  student: Student
}

type SessionPresence = {
  user: Student
  duration: number
}

export type TutorAssessmentProps = {
  studentSessions: StudentSession[]
  sessionPresences: SessionPresence[]
}

const TutorAssessment: React.FC<TutorAssessmentProps> = ({
  studentSessions,
  sessionPresences,
}) => {
  const students = studentSessions.map(studentSession => studentSession.student)

  const totalDuration = student =>
    sessionPresences
      .filter(presence => presence.user.id === student.id)
      .map(presence => presence.duration)
      .reduce((prev, curr) => prev + curr, 0)
      .toFixed(0)

  return (
    <div>
      <div className="mb-5 text-center">
        <H3>Student Assessment</H3>
        <div className="py-7 text-left">
          <div className="italic"> Evaluation Criteria Guide</div>
          <div className="grid grid-cols-4 gap-3">
            <div className="font-semibold">Fails to meet expectations</div>
            <div className="col-span-3">
              Learning goals are not currently met, or student needs additional
              support in order to meet learning goals
            </div>

            <div className="font-semibold">Partially meets expectations</div>
            <div className="col-span-3">
              Shows progress in meeting learning goals with appropriate levels
              of support
            </div>

            <div className="font-semibold">Meets expectations</div>
            <div className="col-span-3">
              Completes task independently, exhibits an understanding of the
              concept
            </div>

            <div className="font-semibold">Exceeds expectations</div>
            <div className="col-span-3">
              Completes tasks beyond current material independently, displays
              ability to grasp future concepts
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
        {ordered(students, "lastName").map((student: Student) => (
          <div key={student.id} className="mb-3">
            <CheckboxInput
              name={`tutorAssessment[${student.id}].tutorAssessment`}
              checkboxType="radio"
              label={`${student.fullName} (Duration: ${totalDuration(
                student
              )} minutes)`}
              options={tutorAssessmentOptions}
            />
          </div>
        ))}
      </div>

      <Error name="tutorAssessment" />
    </div>
  )
}

export default TutorAssessment
