import React from "react"

import { RouteComponentProps } from "react-router"
import { gql, useQuery } from "urql"

import { H1, WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types/MatchParams"

import ExamCategory from "./ExamCategory"

const ExamInstances: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const studentId = match.params.id
  const [result] = useQuery({
    query,
    variables: { studentId },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { student } }) => (
        <>
          <H1>Test Prep Exams</H1>

          <ExamCategory
            title="ACT"
            examInstances={student.actInstances}
            overallExamData={student.actOverallExamData}
            subjectExamData={student.actSubjectExamData}
            studentId={studentId}
          />
          <ExamCategory
            title="SAT"
            examInstances={student.satInstances}
            overallExamData={student.satOverallExamData}
            subjectExamData={student.satSubjectExamData}
            studentId={studentId}
          />
          <ExamCategory
            title="ISEE"
            examInstances={student.iseeInstances}
            overallExamData={student.iseeOverallExamData}
            subjectExamData={student.iseeSubjectExamData}
            studentId={studentId}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const examInstanceFields = gql`
  fragment examInstanceFields on ExamInstance {
    id
    exam {
      id
      name
    }
    sections {
      id
      subject
      number
    }
    sectionInstances {
      exam {
        name
      }
      section {
        id
        subject
      }
    }
  }
`

const dataFields = gql`
  fragment dataFields on ChartData {
    labels
    correctCounts
    skippedCounts
    incorrectCounts
    totalCounts
  }
`

const query = gql`
  ${examInstanceFields}
  ${dataFields}
  query ExamInstancesQuery($studentId: ID!) {
    student(id: $studentId) {
      id
      fullName
      actInstances: examInstances(examType: "act") {
        ...examInstanceFields
      }
      actOverallExamData: overallExamData(examType: "act") {
        ...dataFields
      }
      actSubjectExamData: subjectExamData(examType: "act") {
        name
        data {
          ...dataFields
        }
      }

      satInstances: examInstances(examType: "sat") {
        ...examInstanceFields
      }
      satOverallExamData: overallExamData(examType: "sat") {
        ...dataFields
      }
      satSubjectExamData: subjectExamData(examType: "sat") {
        name
        data {
          ...dataFields
        }
      }

      iseeInstances: examInstances(examType: "isee") {
        ...examInstanceFields
      }
      iseeOverallExamData: overallExamData(examType: "act") {
        ...dataFields
      }
      iseeSubjectExamData: subjectExamData(examType: "act") {
        name
        data {
          ...dataFields
        }
      }
    }
  }
`

export default ExamInstances
