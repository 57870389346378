import React from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { DateObject } from "components/Forms/DatePicker"
import {
  AutocompleteField,
  CheckboxField,
  DateTimeField,
  FormStatus,
  SubmitButton,
  TextAreaField,
} from "components/Forms/Formik/hookComponents"

import AddRecipients from "./AddRecipients"
import RecipientsList from "./RecipientsList"

const ValidationSchema = Yup.object().shape({
  message: Yup.string().required(),
  sendAt: Yup.number().required("Please specify a time to send the batch text"),
})

const NewBatchText = () => {
  const formState = {
    message: "",
    sendAt: new DateObject().setHour(12).setMinute(0).setSecond(0),
    notes: "",
    tagList: [],
    skipExistingUsers: false,
    recipients: [],
  }

  const { runMutation } = useMutation(buildMutation(createMutation))

  const handleSubmit = (values, actions) => {
    const { sendAt, recipients, ...rest } = values

    runMutation({
      sendAt: sendAt.toDate(),
      recipients: recipients.map(recipient => ({
        phoneNumber: recipient.phoneNumber,
        userId: recipient.user?.id,
      })),
      ...rest,
    })
      .then(
        response => {
          const { redirectTo, errorMessages } = response.batchTextCreate
          if (redirectTo) {
            window.location.href = redirectTo
          } else {
            actions.setStatus(errorMessages)
          }
        },
        () => actions.setStatus("An error occurred")
      )
      .catch(() => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <>
      <Formik
        validationSchema={ValidationSchema}
        initialValues={formState}
        onSubmit={handleSubmit}
      >
        <Form>
          <h4 className="mt-0">Configuration</h4>
          <TextAreaField
            name="message"
            label="Message"
            className="form-control w-50"
          />
          <CheckboxField
            name="skipExistingUsers"
            label="Skip Existing Users (this will only work for numbers provided in the free-form number input)"
          />
          <DateTimeField name="sendAt" label="Send At" />
          <TextAreaField
            name="notes"
            label="Notes"
            className="form-control w-50"
          />
          <AutocompleteField
            name="tagList"
            label="Tags"
            valueAttribute="label"
            api="/api/admins/batch_texts/autocomplete_tags"
            isMulti
            creatable
          />

          <h4>Recipients</h4>
          <AddRecipients />
          <RecipientsList />

          <FormStatus />
          <SubmitButton />
        </Form>
      </Formik>
    </>
  )
}

const createMutation = compress`
  mutation(
    $message: String!
    $sendAt: DateTime!
    $notes: String!
    $tagList: [String!]
    $skipExistingUsers: Boolean
    $recipients: [BatchTextRecipientInputObject!]!
  ) {
    batchTextCreate(
      message: $message
      sendAt: $sendAt
      notes: $notes
      tagList: $tagList
      skipExistingUsers: $skipExistingUsers
      recipients: $recipients
    ) {
      errorMessages
      redirectTo
    }
  }
`

export default NewBatchText
