import React, { useState } from "react"

import { gql, useQuery } from "urql"

import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"
import Table from "~tailwindui/Table/Table"

import Form from "components/OrganizationAdmin/components/Form"
import Header from "components/OrganizationAdmin/components/Form/Header"

const UsersPage = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: organizationAdminsQuery,
    variables: { page },
  })

  if (
    result.data &&
    !result.data.viewer.canOrganizationAdminAccessUserManagement
  ) {
    return <span>Sorry, you don't have access to this page.</span>
  }
  return (
    <WithLoadingIndicator result={result} header={<Header title="Users" />}>
      {({ data }) => (
        <>
          <Table
            title={
              <>
                <Form.Button
                  text="New User"
                  className="ml-4"
                  onClick={() =>
                    (window.location.href = "/organization_admin/users/new")
                  }
                />
              </>
            }
            data={data.viewer.organization.organizationAdmins}
            changePage={setPage}
            headers={["Name", "Email", "Username", "Phone"]}
            valueMapper={user => [
              user.fullName,
              user.email,
              user.username,
              user.phoneNumber,
            ]}
            rowCallback={user =>
              (window.location.href = `/organization_admin/users/${user.id}`)
            }
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const organizationAdminsQuery = gql`
  query organizationAdmins($page: Int!) {
    viewer {
      canOrganizationAdminAccessUserManagement
      organization {
        organizationAdmins: paginatedOrganizationAdmins(page: $page) {
          currentPage
          totalPages
          data {
            id
            fullName
            username
            email
            phoneNumber
          }
        }
      }
    }
  }
`

export default UsersPage
