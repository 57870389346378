import React from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import Select from "react-select"
import * as Yup from "yup"

import { useGradeOptions } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage, File, SubmitButton } from "components/Forms/Formik"

const ValidationSchema = Yup.object().shape({
  gradeId: Yup.string().required("Please select a grade"),
  organizationId: Yup.string(),
  file: Yup.mixed().test("file", "File must be present", file => {
    return file.name || file.fileName
  }),
})

const StudentBulkUploadForm = props => {
  const gradeOptions = useGradeOptions()
  const { runMutation } = useMutation(
    buildMutation(createStudentBulkUploadMutation)
  )
  const formState = {
    gradeId: "",
    organizationId: "",
    file: {},
  }

  const handleSubmit = (values, actions) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      const b64 = reader.result.replace(/^data:.+;base64,/, "")

      runMutation({
        file: b64,
        filename: values.file.name || values.file.fileName,
        gradeId: values.gradeId,
        organizationId: values.organizationId,
      }).then(response => {
        const { success, redirectTo, errorMessage } =
          response.createStudentBulkUpload
        if (success) {
          window.location.href = redirectTo
        } else {
          actions.setStatus(errorMessage)
        }
      })
    }
    reader.readAsDataURL(values.file)
  }

  return (
    <Formik
      initialValues={formState}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {({ status, isSubmitting, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="form-group">
                <label className="form-label w-100">
                  CSV File
                  <File
                    name="file"
                    className="form-control"
                    accept=".csv"
                    setFieldValue={setFieldValue}
                  />
                </label>
                <ErrorMessage name="file" />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label w-100">
                  Grade
                  <Field type="text" name="gradeId" className="form-control">
                    {({ field: { name, value } }) => (
                      <Select
                        options={gradeOptions}
                        defaultValue={value}
                        onChange={selectedOption =>
                          setFieldValue(
                            name,
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        styles={{
                          container: provided => ({
                            ...provided,
                            maxWidth: 250,
                          }),
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="gradeId" />
                </label>
              </div>
            </div>

            <div className="form-group col-12">
              <div className="form-group">
                <label className="form-label w-100">
                  Organization
                  <Field
                    name="organizationId"
                    type="text"
                    className="form-control"
                  >
                    {({ field: { name, value } }) => (
                      <AutocompleteSelect
                        api="/api/admins/organizations/autocomplete_name"
                        selected={value}
                        onChange={selectedOption =>
                          setFieldValue(
                            name,
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        placeholder="Search for organization"
                      />
                    )}
                  </Field>
                  <ErrorMessage name="organizationId" />
                </label>
              </div>
            </div>

            <div className="col-12">
              {status && (
                <div>
                  <div className="alert alert-danger d-inline-block">
                    {status}
                  </div>
                </div>
              )}

              <SubmitButton isSubmitting={isSubmitting} text="Upload" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const createStudentBulkUploadMutation = compress`
  mutation(
    $file: String!,
    $filename: String!,
    $gradeId: ID!,
    $organizationId: ID,
  ) {
    createStudentBulkUpload(
      file: $file
      filename: $filename
      gradeId: $gradeId
      organizationId: $organizationId
    ) {
      success
      redirectTo
      errorMessage
      errors {
        field
        messages
      }
    }
  }
`

export default StudentBulkUploadForm
