import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql, useMutation } from "hooks/urql"

import {
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  durationWeeks: Yup.number()
    .required("Duration is required")
    .min(1, "Duration must be at least 1 week"),
  sessionsPerWeek: Yup.number()
    .required("Session per week is required")
    .min(1, "Must have at least 1 session per week"),
  minimumStudyGroupSize: Yup.number()
    .required("Please specify minimum students per group")
    .min(1, "Minimum size must be at least 1"),
  maximumStudyGroupSize: Yup.number()
    .required("Please specify maximum students per group")
    .min(1, "Maximum size must be at least 1")
    .test(
      "maximumStudyGroupSize",
      "Maximum group size must be greater or equal to minimum group size",
      function () {
        if (
          this.parent.maximumStudyGroupSize < this.parent.minimumStudyGroupSize
        ) {
          return false
        } else {
          return true
        }
      }
    ),
})

const NewCourse = ({ courseCluster }) => {
  const formState = {
    courseClusterId: courseCluster.id,
    durationWeeks: 2,
    sessionsPerWeek: 2,
    minimumStudyGroupSize: 3,
    maximumStudyGroupSize: 5,
  }
  if (courseCluster.prepaid) {
    formState.prepaidPrice = ""
  }

  const [, createCourse] = useMutation(createMutation)

  const handleSubmit = (values, actions) => {
    createCourse(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { redirectTo, failures } = result.data.createCourse
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.href = redirectTo
          }
        },
        e => handleFailure(actions, [e])
      )
      .catch(e => handleFailure(actions, [e]))
  }

  return (
    <ModalWithProvidedBody
      buttonText="New Course"
      buttonClassName="btn btn-success"
      modalTitle="New Course"
    >
      {({ closeModal }) => (
        <Formik
          validationSchema={ValidationSchema}
          initialValues={formState}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="modal-body">
              <Field
                name="durationWeeks"
                type="number"
                label="Duration Weeks"
              />

              <Field
                name="sessionsPerWeek"
                type="number"
                label="Sessions per Week"
              />

              <Field
                name="minimumStudyGroupSize"
                type="number"
                label="Minimum Group Size"
              />

              <Field
                name="maximumStudyGroupSize"
                type="number"
                label="Maximum Group Size"
              />

              {courseCluster.prepaid && (
                <Field
                  name="prepaidPrice"
                  type="number"
                  step={0.01}
                  label="Prepaid Price"
                />
              )}
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="flex w-full justify-between">
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <SubmitButton text="Create Course" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createMutation = gql`
  mutation (
    $courseClusterId: ID!
    $durationWeeks: Int!
    $sessionsPerWeek: Int!
    $minimumStudyGroupSize: Int!
    $maximumStudyGroupSize: Int!
    $prepaidPrice: Float
  ) {
    createCourse(
      courseClusterId: $courseClusterId
      durationWeeks: $durationWeeks
      sessionsPerWeek: $sessionsPerWeek
      minimumStudyGroupSize: $minimumStudyGroupSize
      maximumStudyGroupSize: $maximumStudyGroupSize
      prepaidPrice: $prepaidPrice
    ) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default NewCourse
