import React, { Dispatch, SetStateAction } from "react"

import { Button } from "~tailwindui/Basics"

export type CloseButtonProps = {
  DatePicker?: any
  position?: string
  setClosed: Dispatch<SetStateAction<boolean>>
}

const CloseButton: React.FC<CloseButtonProps> = ({ DatePicker, setClosed }) => {
  return (
    <div className="p-3">
      <Button
        text="Done"
        type="button"
        onClick={() => {
          setClosed(true)
          setTimeout(() => DatePicker.closeCalendar(), 0)
        }}
      />
    </div>
  )
}

export default CloseButton
