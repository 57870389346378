import React from "react"

import { useLocation } from "react-router-dom"
import { gql, useQuery } from "urql"

import {
  BeakerIcon,
  CurrencyDollarIcon,
  UserIcon,
} from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { H2, WithLoadingIndicator } from "~tailwindui/Basics"

import ActionList from "components/Tutor/components/ActionList"

import LinkStripeAccountModal from "./LinkStripeAccountModal"

export type ProfileProps = Record<string, never>

const Profile: React.FC<ProfileProps> = () => {
  const { search } = useLocation()
  const bankModalOpen = new URLSearchParams(search).get("bank_instructions")

  const [result] = useQuery({
    query: tutorQuery,
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <>
          {viewer.avatar && (
            <img
              src={viewer.avatar.thumbUrl}
              alt="Your avatar"
              className="w-80 object-contain"
            />
          )}
          <H2>Hi, {viewer.firstName}</H2>

          {bankModalOpen && (
            <LinkStripeAccountModal
              closeModal={() => window.location.reload()}
            />
          )}

          <ActionList
            title="Your Account"
            color={Color.Blue}
            emptyText="You're all caught up!"
            listItems={[
              {
                icon: (
                  <UserIcon className="mr-3 inline-block h-8 w-8 text-blue-600" />
                ),
                path: path("profileEdit"),
                text: "Edit Profile",
              },
              {
                icon: (
                  <BeakerIcon className="mr-3 inline-block h-8 w-8 text-blue-600" />
                ),
                path: path("subjectBucketsIndex"),
                text: "Manage Air Tutors Subjects",
              },
              (viewer.mayCreateExpense || viewer.expenses.length > 0) && {
                icon: (
                  <CurrencyDollarIcon className="mr-3 inline-block h-8 w-8 text-blue-600" />
                ),
                path: path("expensesIndex"),
                text: "View Expenses",
              },
            ]}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const tutorQuery = gql`
  query TutorQuery {
    viewer {
      id
      firstName
      calendarId
      mayCreateExpense
      avatar {
        thumbUrl
      }
      expenses {
        id
      }
    }
  }
`

export default Profile
