import React from "react"

import { Field, Form, Formik } from "formik"
import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"
import * as Yup from "yup"

import axios from "src/axios"

import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const GenerateTailoredAssignmentForm = props => {
  const { studentId, sourceTailoredAssignmentId } = props
  const initialFormState = {
    totalQuestions: "",
    tagScoreThreshold: "",
    name: "",
  }

  const loadingState = useQuery(
    buildQuery(tagScoresQuery, { studentId, sourceTailoredAssignmentId })
  )

  const handleSubmit = (values, actions) => {
    actions.setStatus({ msg: "Generating..." })

    const formData = {
      tailored_assignment: {
        total_questions: values.totalQuestions,
        tag_score_threshold: values.tagScoreThreshold,
        name: values.name,
        student_id: studentId,
      },
    }

    axios
      .post(
        `/api/admins/tailored_assignments/${sourceTailoredAssignmentId}/generate`,
        formData
      )
      .then(response => {
        const url = response.data.redirectTo
        window.location.href = url
      })
  }

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading the source assignment data"
    >
      {({ data }) => (
        <React.Fragment>
          <Formik
            initialValues={initialFormState}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
          >
            {({ status, isSubmitting }) => (
              <Form>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="form-label w-100">
                      Name
                      <div className="d-flex align-items-center">
                        <Field
                          type="text"
                          name="name"
                          className="form-control col-2"
                        />
                        <div className="ml-3">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                    </label>
                  </div>

                  <div className="form-group col-12">
                    <label className="form-label w-100">
                      Total Questions
                      <div className="d-flex align-items-center">
                        <Field
                          type="number"
                          name="totalQuestions"
                          className="form-control col-2"
                        />
                        <div className="ml-3">
                          <ErrorMessage name="totalQuestions" />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12">
                    <label className="form-label w-100">
                      Tag Score Threshold
                      <div className="d-flex align-items-center">
                        <Field
                          type="number"
                          name="tagScoreThreshold"
                          className="form-control col-2"
                        />
                        <div className="ml-3">
                          <ErrorMessage name="tagScoreThreshold" />
                        </div>
                      </div>
                    </label>
                    <div className="font-italic">
                      This is the minimum grade the student would have to
                      achieve to not receive questions for a given tag
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      text="Generate Assignment"
                    />
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    {status && status.msg && <div>{status.msg}</div>}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <h4>Score Summary</h4>
          <dl>
            {data.student.tailoredAssignmentSummary.tagScores.map(tagScore => (
              <React.Fragment key={tagScore.tag.id}>
                <dt>{tagScore.tag.name}</dt>
                <dd>{Math.round(tagScore.score * 100)}%</dd>
              </React.Fragment>
            ))}
          </dl>
        </React.Fragment>
      )}
    </WithLoadingIndicator>
  )
}

const tagScoresQuery = compress`
  query($studentId: ID!, $sourceTailoredAssignmentId: ID!) {
    student(id: $studentId) {
      tailoredAssignmentSummary(tailoredAssignmentId: $sourceTailoredAssignmentId) {
        tagScores {
          score
          tag {
            id
            name
          }
        }
      }
    }
  }
`

const ValidationSchema = Yup.object().shape({
  totalQuestions: Yup.number()
    .required(
      "Please enter the number of questions for the generated assignment"
    )
    .min(1, "Generated assignment must have at least 1 question"),
  tagScoreThreshold: Yup.number()
    .required("Please enter a score threshold between 0 and 100")
    .min(0, "Score threshold must be at least 0")
    .max(100, "Score threshold cannot be higher than 100"),
  name: Yup.string().required("Please enter a name"),
})

GenerateTailoredAssignmentForm.propTypes = {
  studentId: PropTypes.number.isRequired,
  sourceTailoredAssignmentId: PropTypes.number.isRequired,
}
export default GenerateTailoredAssignmentForm
