import React, { ReactNode } from "react"

export type ModalBodyProps = {
  children: ReactNode
  title: string
  Icon?: React.FC<any>
  iconClassNames?: string
}

const ModalBody = ({ children }) => {
  return (
    <div className="max-h-[calc(70vh-150px)] overflow-y-scroll bg-white px-8 py-4 sm:px-14">
      {children}
    </div>
  )
}

export default ModalBody
