import React, { ReactNode } from "react"

import classNames from "src/classNames"

export type H3Props = {
  children: ReactNode
  className?: string
}

const H3: React.FC<H3Props> = ({ children, className = "" }) => {
  return (
    <h3 className={classNames("mt-0 mb-3 text-lg font-medium", className)}>
      {children}
    </h3>
  )
}

export default H3
