import React, { useContext, useState } from "react"

import { gql, useMutation } from "urql"

import { AlertMessageBox, Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"
import { VoidReturn } from "src/types/VoidReturn"

import TutorContext from "components/Tutor/TutorContext"

export type LinkStripeAccountModalProps = {
  closeModal: VoidReturn
}

const LinkStripeAccountModal: React.FC<LinkStripeAccountModalProps> = ({
  closeModal,
}) => {
  const { viewer } = useContext(TutorContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>()

  const [, runMutation]: [any, RunMutation] = useMutation(
    linkStripeAccountMutation
  )

  const initiateStripeConnect = () => {
    setLoading(true)
    runMutation({ userId: viewer.id })
      .then(
        result => {
          const { stripeUrl, failures } = result.data.linkStripeAccount
          setLoading(false)
          if (failures.length > 0) {
            setError(failures[0].message)
          } else {
            window.location.href = stripeUrl
          }
        },
        () => setError("An error occurred")
      )
      .catch(() => setError("An error occurred"))
  }

  return (
    <div>
      {error && <AlertMessageBox>{error}</AlertMessageBox>}
      <Modal.Dialog hideButton isOpen={true} closeModal={closeModal}>
        <>
          <Modal.Header>
            {viewer.stripeAccountId && viewer.stripeBankAccountLinked
              ? "View Linked Bank Account(s)"
              : "Link Bank Account"}
          </Modal.Header>

          <Modal.Body>
            <div className="flex flex-col space-y-4">
              {!viewer.stripeAccountId ? (
                <>
                  <p>
                    In order to use the Tutor Directory, you must create an
                    account with Stripe and{" "}
                    <span className="font-bold">link a valid bank account</span>{" "}
                    to receive your students' payments.
                  </p>

                  <div>
                    When creating your account, select the following options:
                    <ul>
                      <li>Type of Business: Individual</li>
                      <li>Industry: Other Education</li>
                      <li>Your Website: www.airtutors.org</li>
                    </ul>
                  </div>
                </>
              ) : !viewer.stripeBankAccountLinked ? (
                <p>
                  In order to use the Tutor Directory, you must link a valid
                  bank account to receive your students' payments.
                </p>
              ) : null}

              <p>
                Clicking "Continue to Stripe" below will redirect you to
                Stripe's platform, where you can
                {viewer.stripeBankAccountLinked
                  ? " log in to your account and view or change your linked bank account(s)."
                  : viewer.stripeAccountId
                  ? " add a bank account."
                  : " create an account."}
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {error && <AlertMessageBox>{error}</AlertMessageBox>}
            <div className="flex justify-end">
              {viewer.stripeBankAccountLinked ? (
                <Button
                  onClick={() =>
                    (window.location.href =
                      "https://dashboard.stripe.com/login")
                  }
                >
                  Continue to Stripe
                </Button>
              ) : (
                <Button onClick={initiateStripeConnect} loading={loading}>
                  Continue to Stripe
                </Button>
              )}
            </div>
          </Modal.Footer>
        </>
      </Modal.Dialog>
    </div>
  )
}

const linkStripeAccountMutation = gql`
  mutation LinkStripeAccount($userId: ID!) {
    linkStripeAccount(userId: $userId) {
      stripeUrl
      failures {
        message
      }
    }
  }
`

export default LinkStripeAccountModal
