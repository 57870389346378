import React from "react"

import { CloudIcon } from "@heroicons/react/24/outline"

import TimeProtectedLink from "components/TimeProtectedLink"

export type CloudroomButtonProps = {
  href: string
  startsAt: string
  className?: string
  tailwind?: boolean
}

const CloudroomButton: React.FC<CloudroomButtonProps> = ({ href, ...rest }) => {
  return (
    <TimeProtectedLink target="_blank" href={href} {...rest}>
      <div className="mb-2 flex h-28 cursor-pointer flex-col rounded-md border-2 border-solid border-sky-600 p-3 text-center text-sm text-sky-600 hover:opacity-50 sm:text-base md:text-lg">
        <span className="flex w-full items-center justify-center">
          <CloudIcon className="h-14 w-14" />
        </span>
        <div>Cloud Room</div>
      </div>
    </TimeProtectedLink>
  )
}

export default CloudroomButton
