import dayjs from "dayjs"

import { months } from "src/dateHelpers"

export const dateRangeHelper = ({
  dateType,
  monthOption,
  yearOption,
  startDate,
  endDate,
}) => {
  let startDateValue, endDateValue
  switch (dateType) {
    case "all":
      startDateValue = undefined
      endDateValue = undefined
      break
    case "custom":
      startDateValue = dayjs(startDate).startOf("day")
      endDateValue = dayjs(endDate).endOf("day")
      break
    case "month":
      if (monthOption === "current") {
        startDateValue = dayjs().startOf("month")
        endDateValue = dayjs()
      } else {
        const monthIndex = months.indexOf(monthOption)
        startDateValue = dayjs().month(monthIndex).startOf("month")
        endDateValue = dayjs().month(monthIndex).endOf("month")
      }
      break
    case "year":
      if (yearOption === "current") {
        startDateValue = dayjs().startOf("year")
        endDateValue = dayjs()
      } else {
        startDateValue = dayjs().year(yearOption).startOf("year")
        endDateValue = dayjs().year(yearOption).endOf("year")
      }
      break
  }
  return [startDateValue, endDateValue]
}
