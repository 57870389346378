import React, { ReactNode, useRef, useState } from "react"

import moment from "moment-timezone"

import TimeProtectedModal from "./TimeProtectedModal"

export type TimeProtectedLinkProps = {
  text?: string
  children?: ReactNode
  href: string
  startsAt: string
  method?: string
  target: string
  tailwind?: boolean
}

const TimeProtectedLink: React.FC<
  TimeProtectedLinkProps & React.HTMLProps<HTMLAnchorElement>
> = ({ children, text, href, startsAt, method, tailwind, ...rest }) => {
  const hiddenLink = useRef<HTMLAnchorElement>()
  const [showModal, setShowModal] = useState(false)
  const [timeString, setTimeString] = useState<string | null>()

  const onClick = () => {
    const timeDiff = moment(startsAt).unix() - moment().unix()
    const timeDiffHours = timeDiff / 60 / 60

    if (timeDiffHours <= 1) {
      hiddenLink.current.click()
    } else {
      const hours = Math.floor(timeDiffHours)
      const minutes = Math.floor((timeDiffHours - hours) * 60)
      if (hours > 24) {
        const days = Math.floor(hours / 24)
        setTimeString(`${days} ${days === 1 ? "day" : "days"}`)
      } else {
        setTimeString(`${hours} hours and ${minutes} minutes`)
      }
      setShowModal(true)
    }
  }

  return (
    <>
      <a
        ref={hiddenLink}
        style={{ display: "none" }}
        href={href}
        data-method={method === "get" ? null : method}
        {...rest}
      >
        Hidden
      </a>
      <a onClick={onClick} {...rest}>
        {children ? children : text}
      </a>
      <TimeProtectedModal
        isOpen={showModal}
        setOpen={setShowModal}
        timeString={timeString}
        tailwind={tailwind}
        onSubmit={() => hiddenLink.current.click()}
      />
    </>
  )
}

export default TimeProtectedLink
