import React, { useState } from "react"

import { Form, Formik } from "formik"
import { gql, useQuery } from "urql"

import { AlertLevel, Color, ValueFormat } from "~tailwindui/types/enums"

import {
  AlertMessageBox,
  Button,
  H2,
  H3,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import { TextInput } from "~tailwindui/Form"
import Table from "~tailwindui/Table/Table"

import calculateTutorPayout from "src/calculateTutorPayout"
import classNames from "src/classNames"
import emptyFunction from "src/emptyFunction"
import formatValue from "src/formatValue"
import titlecase from "src/titlecase"

import LocalTime from "components/LocalTime"
import LinkStripeAccountModal from "components/Tutor/Pages/Profile/Details/LinkStripeAccountModal"
import { path } from "components/Tutor/TutorRoutes"

import UpdateRateConfirmationModal from "./UpdateRateConfirmationModal"

export type DirectoryBillingProps = Record<string, never>

const DirectoryBilling: React.FC<DirectoryBillingProps> = () => {
  const [bankModalOpen, setBankModalOpen] = useState(false)

  const [result] = useQuery({ query })

  return (
    <div>
      <H2>Billing</H2>

      <WithLoadingIndicator result={result}>
        {({
          data: {
            viewer,
            directoryPercentFee,
            stripePercentFee,
            stripeFlatFee,
          },
        }) => (
          <>
            <AlertMessageBox level={AlertLevel.Info}>
              Payment for courses or individual sessions will be deposited
              directly to your bank account after each registration or session.
            </AlertMessageBox>
            {bankModalOpen && (
              <LinkStripeAccountModal
                closeModal={() => setBankModalOpen(false)}
              />
            )}

            <H3>Payment Settings</H3>
            <div className="mb-4 flex flex-col items-start rounded-md bg-gray-50 px-4 py-6 sm:flex-row sm:space-x-8">
              <Formik
                initialValues={{ hourlyRate: viewer.hourlyRate }}
                onSubmit={emptyFunction}
              >
                {({ values }) => (
                  <Form>
                    <TextInput
                      type="number"
                      label="Hourly Rate (Individual Sessions)"
                      name="hourlyRate"
                      description={
                        values.hourlyRate &&
                        `You receive: ${formatValue(
                          calculateTutorPayout({
                            hourlyRate: values.hourlyRate,
                            directoryPercentFee,
                            stripePercentFee,
                            stripeFlatFee,
                          }),
                          ValueFormat.Currency
                        )}`
                      }
                    />
                    {values.hourlyRate !== viewer.hourlyRate && (
                      <UpdateRateConfirmationModal
                        oldRate={viewer.hourlyRate}
                        newRate={values.hourlyRate}
                      />
                    )}
                  </Form>
                )}
              </Formik>

              <Button
                onClick={() => setBankModalOpen(true)}
                color={Color.Purple}
                className="mt-6"
              >
                Update Bank Account
              </Button>
            </div>

            <H3>Session Invoices</H3>
            <Table
              data={viewer.sessionInvoices}
              headers={["Session Date", "Amount", "Status", "Student"]}
              valueMapper={invoice => [
                <Link
                  key={invoice.id}
                  to={path("sessionDetails", { id: invoice.session.id })}
                >
                  <LocalTime omitTime timestamp={invoice.session.startsAt} />
                </Link>,
                invoice.formattedBilledPrice,
                <span
                  key={invoice.id}
                  className={classNames(
                    invoice.aasmState === "refunded" && "text-red-500",
                    invoice.aasmState === "submitted" && "text-emerald-500"
                  )}
                >
                  {titlecase(invoice.aasmState)}
                </span>,
                <Link
                  key={invoice.id}
                  to={path("studentDetails", { id: invoice.student.id })}
                >
                  {invoice.student.fullName}
                </Link>,
              ]}
            />

            <H3>Course Invoices</H3>
            <Table
              data={viewer.courseInvoices}
              headers={["Course", "Amount", "Status", "Student"]}
              valueMapper={invoice => [
                <Link
                  key={invoice.id}
                  to={path("directory.courseClustersIndex", {
                    id: invoice.course.id,
                  })}
                >
                  <LocalTime omitTime timestamp={invoice.course.name} />
                </Link>,
                invoice.formattedBilledPrice,
                titlecase(invoice.aasmState),
                <Link
                  key={invoice.id}
                  to={path("studentDetails", { id: invoice.student.id })}
                >
                  {invoice.student.fullName}
                </Link>,
              ]}
            />
          </>
        )}
      </WithLoadingIndicator>
    </div>
  )
}

const query = gql`
  query ViewerQuery {
    directoryPercentFee
    stripePercentFee
    stripeFlatFee
    viewer {
      id
      hourlyRate
      sessionInvoices {
        currentPage
        totalPages
        data {
          id
          aasmState
          formattedBilledPrice
          session {
            id
            startsAt
          }
          student {
            id
            fullName
          }
        }
      }
      courseInvoices {
        currentPage
        totalPages
        data {
          id
          aasmState
          formattedBilledPrice
          course {
            id
            name
          }
          student {
            id
            fullName
          }
        }
      }
    }
  }
`

export default DirectoryBilling
