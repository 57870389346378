import React, { ReactNode, useState } from "react"

import { gql, useClient } from "urql"
import { pipe, subscribe } from "wonka"

import { AlertLevel } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import {
  AlertMessageBox,
  Button,
  DescriptionItem,
  Link,
} from "~tailwindui/Basics"

import ordered from "src/ordered"
import titlecase from "src/titlecase"

import ConsistentTutorWarning from "components/ConsistentTutorWarning"
import LocalTime from "components/LocalTime"

type Subject = {
  id: string
  name: string
}

type ForeignLanguage = {
  id: string
  name: string
}

type StudyGroupGeneration = {
  id: string
  startsOn: Date
  endsOn: Date
}

type Availability = {
  id: string
  startsAt: Date
  endsAt: Date
  weekday: string
  weekdayNumber: number
}

type StudyGroup = {
  id: string
  name: string
  formattedDetails: string
  minTutorConsistencyRate: number
  maxSubsEstimate: number
  foreignLanguage: ForeignLanguage
  subjects: Subject[]
  studyGroupGeneration: StudyGroupGeneration
  availabilities: Availability
}

export type StudyGroupProps = {
  studyGroup: StudyGroup
  invitationId: string
}

const StudyGroupDetails: React.FC<StudyGroupProps> = ({
  studyGroup,
  invitationId,
}) => {
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState<string | null>()
  const [info, setInfo] = useState<ReactNode | null>()
  const [inFlight, setInFlight] = useState(false)
  const client = useClient()

  const register = () => {
    setInFlight(true)
    const { unsubscribe } = pipe(
      client.subscription(registerSubscription, {
        studyGroupId: studyGroup.id,
        studyGroupTutorInvitationId: invitationId,
      }),
      subscribe(response => {
        const { results, studyGroup } =
          response.data.studyGroupInvitationRegister

        const status = results[0].status
        setErrors(results.filter(result => !result.success))

        if (status === "failure" || status === "success") {
          setInFlight(false)
          unsubscribe()
          setStatus(null)
        } else {
          setStatus(titlecase(status))
        }

        if (status === "success") {
          setInfo(
            <>
              Registration successful!{" "}
              <Link
                to={path("studyGroupDetails", {
                  id: studyGroup.id,
                })}
              >
                Click here to view your new group
              </Link>
            </>
          )
        }
      })
    )
  }

  return (
    <div>
      <DescriptionItem label="Name" value={studyGroup.name} />
      <DescriptionItem
        label="Details"
        value={
          <span
            dangerouslySetInnerHTML={{ __html: studyGroup.formattedDetails }}
          />
        }
      />
      <DescriptionItem
        label="Subject"
        value={studyGroup.subjects.map(subject => subject.name).join(", ")}
      />
      <DescriptionItem
        label="Foreign Language"
        value={studyGroup.foreignLanguage?.name}
      />

      <DescriptionItem
        label="Session Dates"
        value={
          <span className="text-brand-600">
            <LocalTime
              timestamp={studyGroup.studyGroupGeneration.startsOn}
              omitTime
            />{" "}
            &ndash;{" "}
            <LocalTime
              timestamp={studyGroup.studyGroupGeneration.endsOn}
              omitTime
            />
          </span>
        }
      />

      <DescriptionItem
        label="Localized Session Times"
        value={
          <ul className="m-0">
            {ordered(studyGroup.availabilities, "weekdayNumber").map(
              availability => (
                <li className="text-brand-600" key={availability.id}>
                  {availability.weekday}{" "}
                  <LocalTime
                    timestamp={availability.startsAt}
                    format="h:mm A"
                  />
                  <span> &ndash; </span>
                  <LocalTime
                    timestamp={availability.endsAt}
                    format="h:mm A z"
                  />
                </li>
              )
            )}
          </ul>
        }
      />

      {(studyGroup.maxSubsEstimate || studyGroup.maxSubsEstimate === 0) && (
        <ConsistentTutorWarning
          minTutorConsistencyRate={studyGroup.minTutorConsistencyRate}
          maxSubsEstimate={studyGroup.maxSubsEstimate}
        />
      )}

      <div className="my-3">
        {errors.length > 0 && (
          <AlertMessageBox>
            <div className="flex list-disc flex-col space-y-1 pl-5">
              {errors.map((error, index) => (
                <div key={index}>
                  {error.message}
                  {error.conflictingSessions && (
                    <ul>
                      {error.conflictingSessions.map(session => (
                        <li key={session.id}>
                          <Link
                            to={path("sessionDetails", {
                              id: session.id,
                            })}
                            className="underline"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <LocalTime timestamp={session.startsAt} />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </AlertMessageBox>
        )}

        <div className="flex items-center">
          {status ? (
            <AlertMessageBox level={AlertLevel.Info}>{status}</AlertMessageBox>
          ) : info ? (
            <AlertMessageBox
              level={AlertLevel.Success}
              className="inline-block min-w-[20%]"
            >
              {info}
            </AlertMessageBox>
          ) : (
            <Button disabled={inFlight} onClick={register}>
              Register
            </Button>
          )}
        </div>
      </div>
      <hr />
    </div>
  )
}

const registerSubscription = gql`
  subscription ($studyGroupId: ID!, $studyGroupTutorInvitationId: ID!) {
    studyGroupInvitationRegister(
      studyGroupId: $studyGroupId
      studyGroupTutorInvitationId: $studyGroupTutorInvitationId
    ) {
      results {
        success
        status
        message
        conflictingSessions {
          id
          startsAt
        }
      }
      studyGroup {
        id
        name
      }
    }
  }
`

export default StudyGroupDetails
