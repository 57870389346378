import React from "react"

import { Color } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link } from "~tailwindui/Basics"

import CloudroomButton from "components/CloudroomButton"
import CompSessionButton from "components/Tutor/components/CompSessionButton"
import CancelSessionButton from "components/Tutor/components/Sessions/CancelSessionButton"
import RequestSubstitute from "components/Tutor/components/Sessions/RequestSubstituteButton"
import RescheduleSessionButton from "components/Tutor/components/Sessions/RescheduleSessionButton"

import Resources from "./Resources"

type Session = {
  id: string
  directory: boolean
  airtutors: boolean
  cloudroomActive: boolean
  startsAt: string
  statusState: string
  billState: string
  future: boolean
  immovable: boolean
  mayCancelByTutor: boolean
  subRequestsLeft?: number
}

export type SessionButtonsProps = {
  session: Session
}

const SessionButtons: React.FC<SessionButtonsProps> = ({ session }) => {
  const completable =
    !session.future &&
    session.statusState === "scheduled" &&
    session.billState === "unbilled"

  const reschedulable =
    session.statusState === "scheduled" && session.future && !session.immovable

  const cancelable = session.mayCancelByTutor && session.future

  const substitutable =
    session.airtutors &&
    session.statusState === "scheduled" &&
    session.future &&
    session.subRequestsLeft !== 0

  return (
    <div className="flex flex-col justify-center space-y-2">
      {session.cloudroomActive && (
        <CloudroomButton
          tailwind
          href={`/tutor${path("sessionCloudroom", {
            id: session.id,
          })}`}
          startsAt={session.startsAt}
          className="flex justify-center"
        />
      )}

      <Resources id={session.id} />

      {completable && (
        <Link
          to={path("sessionBill", { id: session.id })}
          button
          color={Color.Orange}
        >
          Complete
        </Link>
      )}

      {reschedulable && (
        <RescheduleSessionButton id={session.id} size="normal" />
      )}

      {cancelable && <CancelSessionButton id={session.id} />}

      {session.billState === "billed" && (
        <Link
          to={path("sessionDurationChange", {
            id: session.id,
          })}
          button
          color={Color.Green}
        >
          Request Duration Update
        </Link>
      )}

      {session.directory && session.billState === "billed" && (
        <CompSessionButton id={session.id} />
      )}

      {substitutable && (
        <RequestSubstitute
          id={session.id}
          startsAt={session.startsAt}
          subRequestsLeft={session.subRequestsLeft}
        />
      )}
    </div>
  )
}

export default SessionButtons
