import React from "react"

import { gql, useClient, useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import RequestProxyNumber from "./RequestProxyNumber"

export type ProxyNumberProps = {
  tutorId: string
  studentId: string
  forParent?: boolean
}

const ProxyNumber: React.FC<ProxyNumberProps> = ({
  tutorId,
  studentId,
  forParent = false,
}) => {
  const client = useClient()
  const [result] = useQuery({
    query,
    variables: { studentId, tutorId },
    client,
  })

  if (!studentId) return <>Please add a student first</>

  const parentProxyComponent = tutor => {
    if (!tutor.tutorToParentPhoneNumber)
      return <RequestProxyNumber studentId={studentId} tutorId={tutorId} />

    return (
      <a href={`sms:${tutor.tutorToParentPhoneNumber}`} className="">
        {tutor.tutorToParentPhoneNumber}
      </a>
    )
  }

  const studentProxyComponent = tutor => {
    if (!tutor.tutorToStudentPhoneNumber)
      return <RequestProxyNumber studentId={studentId} tutorId={tutorId} />

    return (
      <a href={`sms:${tutor.tutorToStudentPhoneNumber}`} className="">
        {tutor.tutorToStudentPhoneNumber}
      </a>
    )
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { tutor } }) => (
        <>
          {forParent
            ? parentProxyComponent(tutor)
            : studentProxyComponent(tutor)}
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const query = gql`
  query ($studentId: ID!, $tutorId: ID!) {
    tutor(id: $tutorId) {
      tutorToStudentPhoneNumber(studentId: $studentId)
      tutorToParentPhoneNumber(studentId: $studentId)
    }
  }
`

export default ProxyNumber
