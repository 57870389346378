import React, { Dispatch, SetStateAction } from "react"

import { Dayjs } from "dayjs"
import { useHistory } from "react-router-dom"

import { DescriptionItem } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"
import { path } from "components/Tutor/TutorRoutes"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"

type Session = {
  id: string
  startsAt: string
  statusState: string
  billState: string
  formattedBilledDuration: string
  formattedActualDuration: string
  formattedScheduledDuration: string
  studyGroup: { id: string; name: string }
  subject: { id: string; name: string }
  students: { id: string; fullName: string }[]
}

export type SessionResultsProps = {
  data: {
    sessions: Session[]
    sessionReportingStats: {
      totalSessions: number
      totalBilledHours: number
      grossPay: number
      netPay: number
    }
  }
  aggregate: boolean
  setPage: Dispatch<SetStateAction<number>>
  filters: {
    startDate: Dayjs
    endDate: Dayjs
    statusState: string
    billState: string
    subjectIds: string[]
    studyGroupIds: string[]
    type: string
  }
}

const SessionResults: React.FC<SessionResultsProps> = ({
  data,
  aggregate,
  setPage,
  filters,
}) => {
  const history = useHistory()

  return (
    <>
      {aggregate ? (
        <div className="mt-8 space-y-2">
          <DescriptionItem
            label="Total Sessions"
            value={(
              Math.round(data.sessionReportingStats.totalSessions * 100) / 100
            ).toLocaleString()}
          />
          <DescriptionItem
            label="Total Billed Hours"
            value={(
              Math.round(data.sessionReportingStats.totalBilledHours * 100) /
              100
            ).toLocaleString()}
          />
          <DescriptionItem
            label="Gross Pay"
            description="Total amount billed for your sessions."
            value={data.sessionReportingStats.grossPay.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          />

          <DescriptionItem
            label="Net Pay"
            description="Gross pay minus Directory fees and refunded transactions."
            value={data.sessionReportingStats.netPay.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          />
        </div>
      ) : (
        <div>
          {filters.startDate && filters.endDate && (
            <span className="pl-2 italic">
              <LocalTime timestamp={filters.startDate.toString()} omitTime /> -{" "}
              <LocalTime timestamp={filters.endDate.toString()} omitTime />
            </span>
          )}

          <Table
            data={data.sessions}
            changePage={setPage}
            headers={[
              null,
              "Date",
              "Subject",
              "Duration",
              "Status",
              "Students",
              "Study Group",
            ]}
            valueMapper={session => [
              <>
                {session.directory ? (
                  <DirectoryIcon recordType={"session"} />
                ) : (
                  <AirtutorsIcon recordType={"session"} />
                )}
              </>,
              <LocalTime key={session.id} timestamp={session.startsAt} />,
              <span key={session.id}>{session.subject?.name}</span>,
              session.formattedBilledDuration ||
                session.formattedActualDuration ||
                session.formattedScheduledDuration,
              `${session.statusState} | ${session.billState}`,
              <ul key={session.id}>
                {session.students.map(student => (
                  <li key={student.id}>{student.fullName}</li>
                ))}
              </ul>,
              session.studyGroup?.name,
            ]}
            rowCallback={session =>
              history.push(path("sessionDetails", { id: session.id }))
            }
          />
        </div>
      )}
    </>
  )
}

export default SessionResults
