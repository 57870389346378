import React, { useState } from "react"

import { Field, Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import { css } from "@emotion/core"

import { studentClient } from "src/graphql-config"

import { DateObject, DateTimePicker } from "components/Forms/DatePicker"
import { ErrorMessage, SubmitButton } from "components/Forms/Formik"
import LocalTime from "components/LocalTime"

import Subjects from "./Subjects"
import Tutors from "./Tutors"

const ValidationSchema = Yup.object().shape({
  subjectId: Yup.string().required("Please select a subject"),
  tutorId: Yup.string().required("Please select a tutor"),
  sessionDate: Yup.number().required("Please specify a session date"),
  durationMinutes: Yup.number().required("Please specify a session duration"),
})

const ScheduleSession = props => {
  const [errors, setErrors] = useState([])
  const formState = {
    subjectId: (props.subject && props.subject.id) || "",
    tutorId: (props.tutor && props.tutor.id) || "",
    sessionDate: new DateObject().setHour(12).setMinute(0).setSecond(0),
    durationMinutes: 60,
  }
  const { runMutation } = useMutation(
    buildMutation(scheduleSessionMutation, { client: studentClient })
  )

  const handleSubmit = (values, actions) => {
    const { sessionDate, ...rest } = values

    runMutation({ sessionDate: sessionDate.toDate().toString(), ...rest })
      .then(
        response => {
          const { session, failures } = response.scheduleSession
          if (failures.length > 0) {
            actions.setSubmitting(false)
            setErrors(failures)
          } else {
            window.location.href = session.showPath
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  const title = props.tutor
    ? `Schedule Session with ${props.tutor.fullName}`
    : `Schedule ${props.subject.name} Session`

  return (
    <div className="form-wrapper">
      <h1>{title}</h1>
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        {({ status, isSubmitting, values, setFieldValue }) => (
          <Form>
            {!props.subject && (
              <div>
                <label>Subject</label>
                <Subjects
                  onChange={selected => setFieldValue("subjectId", selected.id)}
                />
                <ErrorMessage name="subjectId" />
              </div>
            )}
            {!props.tutor && (
              <div>
                <label>Tutor</label>
                <Tutors
                  subjectId={props.subject.id}
                  onChange={selected => setFieldValue("tutorId", selected.id)}
                />
                <ErrorMessage name="tutorId" />
              </div>
            )}
            <div
              css={css`
                margin: 30px 0;
              `}
            >
              <label>Session Date</label>
              <DateTimePicker
                value={values.sessionDate}
                onChange={date => setFieldValue("sessionDate", date)}
              />
              <ErrorMessage name="sessionDate" />
            </div>

            <div>
              <label>
                Duration in minutes
                <Field
                  name="durationMinutes"
                  type="number"
                  min={15}
                  step={15}
                />
              </label>
              <ErrorMessage name="durationMinutes" />
            </div>

            {status && (
              <div
                className="alert"
                css={css`
                  margin-top: 15px;
                `}
              >
                {status}
              </div>
            )}

            {errors.map((error, index) => (
              <div key={index} className="alert mt-3 p-2">
                <p>{error.message}</p>
                {error.conflictingSessions && (
                  <ul>
                    {error.conflictingSessions.map(session => (
                      <li key={session.id}>
                        <a
                          href={session.showPath}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <LocalTime timestamp={session.startsAt} />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

            <SubmitButton isSubmitting={isSubmitting} text="Schedule" />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const scheduleSessionMutation = compress`
  mutation($tutorId: ID!, $subjectId: ID!, $sessionDate: DateTime!, $durationMinutes: Int!) {
    scheduleSession(tutorId: $tutorId, subjectId: $subjectId, sessionDate: $sessionDate, durationMinutes: $durationMinutes) {
      session {
        showPath
      }
      failures {
        message
        conflictingSessions {
          id
          startsAt
          showPath
        }
      }
    }
  }
`

export default ScheduleSession
