import React, { useState } from "react"

import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import { gql, useMutation, useQuery } from "hooks/urql"

import BasicDisplay from "components/BasicDisplay"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Edit from "./Edit"

const StatementConfigurationDetails = ({ statementConfigurationId }) => {
  const [result] = useQuery({
    query: statementConfigurationQuery,
    variables: { statementConfigurationId },
  })
  const [_, deleteConfiguration] = useMutation(
    deleteStatementConfigurationMutation
  )
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState("")

  const handleDelete = () => {
    deleteConfiguration({ id: statementConfigurationId })
      .then(
        result => {
          const { results, redirectTo } =
            result.data.deleteStatementConfiguration
          if (results) {
            setError(results[0].message)
          } else {
            window.location.href = redirectTo
          }
        },
        () => setError(["Something went wrong"])
      )
      .catch(() => setError(["Something went wrong"]))
  }

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { statementConfiguration } }) =>
        editing ? (
          <Edit
            statementConfiguration={statementConfiguration}
            stopEditing={() => setEditing(false)}
          />
        ) : (
          <>
            <button
              type="button"
              className="btn"
              onClick={() => setEditing(true)}
            >
              Edit
            </button>
            <BasicDisplay label="Name">
              {statementConfiguration.name}
            </BasicDisplay>

            {statementConfiguration.scheduledGeneration && (
              <>
                <BasicDisplay
                  label={
                    <>
                      Scheduled Generation
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="ml-3 text-emerald-600"
                      />
                    </>
                  }
                />

                <BasicDisplay label="Generation Frequency">
                  {statementConfiguration.generationFrequency === "monthly" &&
                    "2nd of month"}
                  {statementConfiguration.generationFrequency === "bimonthly" &&
                    "2nd and 16th of month"}
                </BasicDisplay>

                <BasicDisplay label="Generation Start Date">
                  {statementConfiguration.startDate}
                </BasicDisplay>

                <BasicDisplay label="Invoice Number Prefix">
                  {statementConfiguration.invoiceNumberPrefix}
                </BasicDisplay>
              </>
            )}

            {statementConfiguration.studyGroupTags.length > 0 && (
              <BasicDisplay label="Only Including Groups Tagged With One of">
                <ul className="list-unstyled">
                  {ordered(statementConfiguration.studyGroupTags).map(tag => (
                    <li key={tag}>{tag}</li>
                  ))}
                </ul>
              </BasicDisplay>
            )}

            {statementConfiguration.includeAllGroups ? (
              <BasicDisplay
                label={
                  <>
                    All Schools Included
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="ml-3 text-emerald-600"
                    />
                  </>
                }
              />
            ) : (
              <BasicDisplay label="Included Schools">
                <ul className="list-unstyled">
                  {ordered(statementConfiguration.schools, "name").map(
                    school => (
                      <li key={school.id}>
                        <a href={school.showPath}>{school.name}</a>
                      </li>
                    )
                  )}
                  {statementConfiguration.includeSchoollessGroups && (
                    <li key="schoolless">Sessions with No School Assigned</li>
                  )}
                </ul>
              </BasicDisplay>
            )}

            <BasicDisplay label="Autogenerated Breakdowns">
              {statementConfiguration.autogeneratesBreakdownsByCategory
                ? `By ${statementConfiguration.autogeneratesBreakdownsByCategory.replace(
                    /[A-Z]/g,
                    letter => ` and ${letter.toLowerCase()}`
                  )}`
                : "None"}
            </BasicDisplay>

            {statementConfiguration.subjectGroupings.length > 0 && (
              <BasicDisplay label="Subject Line Items">
                <ul className="list-unstyled">
                  {statementConfiguration.subjectGroupings.map(grouping => (
                    <li key={grouping.label}>
                      <span className="italic">
                        <span className="font-bold">"{grouping.label}" </span>(
                        {ordered(grouping.subjects)
                          .map(subject => subject.name)
                          .join(", ")}
                        )
                      </span>
                    </li>
                  ))}
                </ul>
                <span className="italic">
                  All other subjects each have their own line item.
                </span>
              </BasicDisplay>
            )}

            <BasicDisplay label="Budget">
              <a href={statementConfiguration.budget.showPath}>
                {statementConfiguration.budget.name}
              </a>
            </BasicDisplay>

            <BasicDisplay label="Miscellaneous Content">
              {statementConfiguration.miscellaneousContent}
            </BasicDisplay>

            <hr />

            <BasicDisplay label="Statements Generated">
              {statementConfiguration.statements.length === 0 ? (
                <span className="italic">
                  No statements have been generated using this configuration.
                </span>
              ) : (
                <ul className="list-unstyled">
                  {statementConfiguration.statements.map(statement => (
                    <li key={statement.id}>
                      <a href={statement.showPath}>
                        {statement.title ||
                          `No Title: Invoice ${statement.invoiceNumber}`}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </BasicDisplay>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
            >
              Delete Configuration
            </button>
            {error && (
              <div className="alert alert-danger my-3 !mr-0 p-3">{error}</div>
            )}
          </>
        )
      }
    </UrqlLoadingIndicator>
  )
}

const statementConfigurationQuery = gql`
  query statementConfiguration($statementConfigurationId: ID!) {
    statementConfiguration(id: $statementConfigurationId) {
      id
      name
      includeAllGroups
      includeSchoollessGroups
      miscellaneousContent
      studyGroupTags
      autogeneratesBreakdownsByCategory
      scheduledGeneration
      generationFrequency
      startDate
      invoiceNumberPrefix
      subjectGroupings {
        id
        label
        subjects {
          id
          name
        }
      }
      schools {
        id
        name
        showPath
      }
      budget {
        id
        name
        prepaid
        balance
        formattedBalance
        startsOn
        endsOn
        showPath
      }
      statements {
        id
        title
        showPath
        invoiceNumber
      }
      organization {
        id
        slug
        schools {
          id
          name
        }
        budgets: activeBudgets(per: 100) {
          data {
            id
            name
            poNumber
            active
            formattedOriginalAmount
            formattedBalance
            startsOn
            endsOn
          }
        }
      }
    }
  }
`

const deleteStatementConfigurationMutation = gql`
  mutation ($id: ID!) {
    deleteStatementConfiguration(id: $id) {
      results {
        message
      }
      redirectTo
    }
  }
`

export default StatementConfigurationDetails
