import React from "react"

import { gql, useQuery } from "urql"

import { Link, WithLoadingIndicator } from "~tailwindui/Basics"

import RequestProxyNumber from "./RequestProxyNumber"

export type ProxyNumberProps = {
  tutorId: string
  studentId: string
  forParent?: boolean
}

const ProxyNumber: React.FC<ProxyNumberProps> = ({
  tutorId,
  studentId,
  forParent = false,
}) => {
  const [result] = useQuery({
    query,
    variables: { studentId, tutorId },
  })

  const parentProxyComponent = student => {
    if (!student.parentPhoneNumber) return "No number present"

    if (!student.tutorToParentPhoneNumber)
      return <RequestProxyNumber studentId={studentId} />

    return (
      <Link to={`sms:${student.tutorToParentPhoneNumber}`}>
        {student.tutorToParentPhoneNumber}
      </Link>
    )
  }

  const studentProxyComponent = student => {
    if (!student.phoneNumber) return "No number present"

    if (!student.tutorToStudentPhoneNumber)
      return <RequestProxyNumber studentId={studentId} />

    return (
      <Link to={`sms:${student.tutorToStudentPhoneNumber}`}>
        {student.tutorToStudentPhoneNumber}
      </Link>
    )
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer, student } }) => (
        <>
          {viewer.twilioProxyDisabled
            ? "Proxy Disabled"
            : forParent
            ? parentProxyComponent(student)
            : studentProxyComponent(student)}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query StudentProxyNumberQuery($studentId: ID!, $tutorId: ID!) {
    viewer {
      id
      twilioProxyDisabled
    }
    student(id: $studentId) {
      phoneNumber
      parentPhoneNumber
      tutorToStudentPhoneNumber(tutorId: $tutorId)
      tutorToParentPhoneNumber(tutorId: $tutorId)
    }
  }
`

export default ProxyNumber
