import React, { useContext } from "react"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import airbrake from "src/airbrake-notifier"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"

export type ScreenShareRequestedModalProps = Record<any, never>

const ScreenShareRequestedModal: React.FC<
  ScreenShareRequestedModalProps
> = () => {
  const { channel, screenShareRequestedBy, setScreenShareRequestedBy } =
    useContext(CloudroomContext)

  const approveRequest = () => {
    if (!screenShareRequestedBy.userId) {
      airbrake.notify({
        error: "Not submitting screen share response, no userId is present",
        name: "ChimeCloudRoom/Tutor",
        params: { screenShareRequestedBy },
      })
    } else {
      channel.perform("screen_share_response", {
        approved: true,
        userId: screenShareRequestedBy.userId,
      })
    }
    setScreenShareRequestedBy()
  }

  const denyRequest = () => {
    channel.perform("screen_share_response", {
      approved: false,
      userId: screenShareRequestedBy.userId,
    })
    setScreenShareRequestedBy()
  }

  return (
    <Modal.Dialog
      hideButton
      isOpen={screenShareRequestedBy}
      closeModal={() => setScreenShareRequestedBy()}
    >
      <>
        <Modal.Header>Screen Share Request</Modal.Header>
        <Modal.Body>
          {screenShareRequestedBy?.name} has requested to share their screen
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end space-x-2">
            <Button onClick={approveRequest}>Allow</Button>
            <Button color={Color.Red} onClick={denyRequest}>
              Reject
            </Button>
          </div>
        </Modal.Footer>
      </>
    </Modal.Dialog>
  )
}

export default ScreenShareRequestedModal
