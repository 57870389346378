import React, { useEffect, useRef, useState } from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useClient, useQuery } from "urql"
import { pipe, subscribe } from "wonka"

import { AlertLevel, ValueFormat } from "~tailwindui/types/enums"

import { AlertMessage, AlertMessageBox } from "~tailwindui/Basics"
import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"
import ChipDetails from "~tailwindui/ChipDetails"

import createBlob from "src/createBlob"
import { surveyOptionsWithIcons } from "src/enums"
import titlecase from "src/titlecase"

import DonutChart from "components/OrganizationAdmin/components/DonutChart"
import SubHeader from "components/OrganizationAdmin/components/Form/SubHeader"

interface MatchParams {
  id: string
}

const SurveyDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const client = useClient()
  const [csvUrl, setCsvUrl] = useState<string>()
  const [exportMessages, setExportMessages] = useState<AlertMessage[]>([])
  const csvDownloadLink = useRef<HTMLAnchorElement>()
  const [result] = useQuery({ query: surveyQuery, variables: { id } })

  const requestExport = () => {
    const { unsubscribe } = pipe(
      client.subscription(surveyResultsSubscription, { id }),
      subscribe((result: any) => {
        if (result.error) {
          const errors: AlertMessage[] = []
          if (
            process.env.NODE_ENV === "development" &&
            result.error?.networkError?.error
          ) {
            errors.push({
              message: "Error type: backend",
              type: AlertLevel.Error,
            })
            errors.push({
              message: result.error.networkError.error,
              type: AlertLevel.Error,
            })
          }
          setExportMessages(errors.concat([result.error]))
          return
        }

        const { status, errorMessages, csv } = result.data.surveyResults

        if (errorMessages.length) {
          setExportMessages(errorMessages.map(message => ({ message })))
          unsubscribe()
        } else {
          if (status === "done") {
            const blobUrl = createBlob(csv, "text/csv")
            setCsvUrl(blobUrl)
            setExportMessages([
              {
                message:
                  "Export completed. Please check your downloads folder.",
                type: AlertLevel.Info,
              },
            ])
            unsubscribe()
          } else if (status === "enqueued") {
            setExportMessages([
              {
                message:
                  "Export enqueued. Please bear with us as this process can take a while. Leave this window open and your file will be downloaded once it's ready.",
                type: AlertLevel.Info,
              },
            ])
          } else {
            setExportMessages([
              { message: titlecase(status), type: AlertLevel.Info },
            ])
          }
        }
      })
    )
  }

  const buildChartData = question => [
    {
      name: "Don't Agree",
      value: question.results.find(result => result.value === 0)?.count,
    },
    {
      name: "Kinda Agree",
      value: question.results.find(result => result.value === 1)?.count,
    },
    {
      name: "Completely Agree",
      value: question.results.find(result => result.value === 2)?.count,
    },
  ]

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl(null)
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  return (
    <WithLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <a
            ref={csvDownloadLink}
            href={csvUrl}
            download="Survey Results.csv"
            className="hidden"
          >
            csv
          </a>
          <ChipDetails
            details={data.survey.questions.map(question => ({
              label: question.text,
              stats: surveyOptionsWithIcons.map(answer => {
                const result = question.results.find(
                  result => result.value === answer.value
                )
                return {
                  key: answer.value,
                  label: answer.label,
                  value: result?.percent,
                  valueFormat: ValueFormat.Percent,
                }
              }),
            }))}
          />

          <div className="mt-5">
            <button
              type="button"
              onClick={requestExport}
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Export Results
            </button>

            {exportMessages.length > 0 && (
              <div className="mt-3 md:w-2/3 lg:w-1/2">
                <AlertMessageBox level={exportMessages[0].type}>
                  <ul className="ml-5">
                    {exportMessages.map(message => (
                      <li key={message.message} className="list-disc">
                        {message.message}
                      </li>
                    ))}
                  </ul>
                </AlertMessageBox>
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2">
            {data.survey.questions.map(question => (
              <div key={question.id}>
                <SubHeader title={question.text} />
                <DonutChart
                  color={["#ee6666", "#fac858", "#91cc75"]}
                  title=""
                  data={buildChartData(question)}
                  height="medium"
                />
              </div>
            ))}
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const surveyQuery = gql`
  query Survey($id: ID!) {
    survey(id: $id) {
      id
      name
      startsOn
      questions {
        id
        text
        results(surveyId: $id) {
          value
          count
          percent
        }
      }
    }
  }
`

const surveyResultsSubscription = gql`
  subscription RequestSurveyResults($id: ID!) {
    surveyResults(surveyId: $id) {
      status
      csv
      errorMessages
    }
  }
`

export default SurveyDetails
