import React from "react"

import Form from "components/OrganizationAdmin/components/Form"

export type PasswordInputProps = {
  label?: string
  name?: string
  description?: string
}

const PasswordInput: React.FC<
  PasswordInputProps & React.HTMLProps<HTMLInputElement>
> = ({ label = "Password", name = "password", description = "", ...props }) => {
  return (
    <Form.TextInput
      label={label}
      name={name}
      description={description}
      type="password"
      autoComplete=""
      {...props}
    />
  )
}

export default PasswordInput
