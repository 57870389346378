import React from "react"

import dayjs from "src/dayjs"

import TimlineItem from "./TimlineItem"

const Timeline = ({ items, format = "hh:mm a" }) => {
  const activities = { Today: [] }
  items.forEach(({ timestamp, component }, index) => {
    const date = dayjs(timestamp)
    const dateStr = date.isToday() ? "Today" : date.format("ddd DD MMM")
    const list = activities[dateStr] || []
    list.push({
      time: date.format(format),
      component,
      key: index,
    })
    activities[dateStr] = list
    if (activities["Today"].length === 0) {
      activities["Today"].push({
        component: <span className="italic">No sessions scheduled</span>,
        key: "today",
      })
    }
  })

  const dates = Object.keys(activities)

  return (
    <div>
      {dates.map(date => (
        <ul
          key={date}
          className="before:context[''] relative list-none before:absolute before:-bottom-10 before:left-8 before:top-0 before:z-0 before:w-0.5 before:bg-sky-600"
        >
          <li className="relative z-10 my-2 inline-block rounded-sm bg-emerald-500 px-2 py-1 font-bold text-white">
            {date}
          </li>
          {activities[date].map(({ time, component, key }) => (
            <TimlineItem time={time} component={component} key={key} />
          ))}
        </ul>
      ))}
    </div>
  )
}

export default Timeline
