import React from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import { path } from "~Tutor/TutorRoutes"
import { H1, H2, Link, WithLoadingIndicator } from "~tailwindui/Basics"

import ordered from "src/ordered"
import { MatchParams } from "src/types/MatchParams"

import LocalTime from "components/LocalTime"
import BarChart from "components/Tutor/Pages/Students/TestPrep/components/BarChart"
import PieChart from "components/Tutor/Pages/Students/TestPrep/components/PieChart"

const ExamInstanceDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { id: examInstanceId, studentId } = match.params
  const [result] = useQuery({
    query,
    variables: { studentId, examInstanceId },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({
        data: {
          student: { examInstance },
        },
      }) => (
        <>
          <H1>{examInstance.exam.name}</H1>

          {ordered(examInstance.sectionInstances, "section.number").map(
            sectionInstance => (
              <React.Fragment key={sectionInstance.id}>
                <H2>
                  {sectionInstance.section.subject}:{" "}
                  {sectionInstance.totalCount} Questions,{" "}
                  {sectionInstance.section.formattedDuration}
                </H2>

                <div className="flex justify-between">
                  <div className="italic">
                    {sectionInstance.startedAt && (
                      <>
                        <p>
                          Started:{" "}
                          <LocalTime timestamp={sectionInstance.startedAt} />
                        </p>
                        {sectionInstance.completedAt && (
                          <>
                            <p>
                              Finished:{" "}
                              <LocalTime
                                timestamp={sectionInstance.completedAt}
                              />
                            </p>
                            {sectionInstance.completedQuestionsCount !==
                              undefined && (
                              <p>
                                Questions Completed in alotted time:{" "}
                                {sectionInstance.completedQuestionsCount}
                              </p>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <Link
                      to={path("studentTestPrepSectionInstanceDetails", {
                        studentId,
                        examInstanceId: examInstance.id,
                        id: sectionInstance.id,
                      })}
                      button
                      className="not-italic"
                    >
                      View Results
                    </Link>
                  </div>
                  <div>
                    <PieChart
                      title={sectionInstance.section.subject}
                      data={[
                        {
                          name: "Correct",
                          value: sectionInstance.correctCount,
                        },
                        {
                          name: "Skipped",
                          value: sectionInstance.skippedCount,
                        },
                        {
                          name: "Incorrect",
                          value: sectionInstance.incorrectCount,
                        },
                      ]}
                    />
                  </div>
                </div>
              </React.Fragment>
            )
          )}

          <H2>Exam Analysis</H2>
          {examInstance.subscoreData.totalCounts.length > 0 && (
            <BarChart title="Sub Scores" data={examInstance.subscoreData} />
          )}
          {examInstance.crossScoreData.totalCounts.length > 0 && (
            <BarChart title="Cross Scores" data={examInstance.crossScoreData} />
          )}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query ExamInstanceDetailsQuery($studentId: ID!, $examInstanceId: ID!) {
    student(id: $studentId) {
      examInstance(id: $examInstanceId) {
        id
        subscoreData {
          labels
          correctCounts
          incorrectCounts
          skippedCounts
          totalCounts
        }
        crossScoreData {
          labels
          correctCounts
          incorrectCounts
          skippedCounts
          totalCounts
        }
        exam {
          id
          name
        }
        sectionInstances {
          id
          correctCount
          incorrectCount
          skippedCount
          totalCount
          startedAt
          completedAt
          completedQuestionsCount
          exam {
            id
            name
          }
          section {
            id
            number
            subject
            formattedDuration
          }
        }
      }
    }
  }
`

export default ExamInstanceDetails
