import { Dayjs } from "dayjs"

import dayjs from "./dayjs"

export const formatDate = (date, opts) => {
  const mergedOptions = Object.assign({ usFormat: false, separator: "-" }, opts)
  const { usFormat, separator } = mergedOptions

  const dateComponents = usFormat
    ? [date.getMonth() + 1, date.getDate(), date.getFullYear()]
    : [date.getDate(), date.getMonth() + 1, date.getFullYear()]

  return dateComponents.join(separator)
}

export const to24HourTime = (hours: number, meridian: "am" | "pm"): number => {
  if ((meridian === "am" && hours < 12) || (meridian === "pm" && hours === 12))
    return hours
  else return hours + 12
}

export const to12HourTime = (hours: number): number => {
  return hours % 12 || 12
}

export const roundTime = (date: Dayjs, roundingIncrement: number): Dayjs => {
  const minutes = date.get("minute")
  const roundedMinutes =
    (Math.floor((minutes + roundingIncrement / 2) / roundingIncrement) *
      roundingIncrement) %
    60
  return date.set("minute", roundedMinutes)
}

export const isLocal = (timezone: string): boolean => {
  return dayjs().tz().format("z") === dayjs().tz(timezone).format("z")
}

export const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const weekdays: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]
