import React from "react"

import { useField } from "formik"

import classNames from "src/classNames"

import ErrorMessage from "./ErrorMessage"

const Field = props => {
  const [field, meta] = useField(props)
  const { labelClassName, ...rest } = props

  return (
    <div className="relative mb-1">
      <label className={classNames("mb-1 inline-block w-full", labelClassName)}>
        <span>{props.label}</span>
        <input
          className="form-control block w-full rounded border-solid border-neutral-300 bg-white p-2"
          {...field}
          {...rest}
        />
        <ErrorMessage {...meta} />
      </label>
    </div>
  )
}

export default Field
