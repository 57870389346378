import React from "react"

import dayjs from "dayjs"
import { RouteComponentProps, useLocation } from "react-router-dom"
import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types/MatchParams"

import ScheduleSessionForm from "./Form"

const ScheduleStudyGroupSession: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { search } = useLocation()
  const sessionId = new URLSearchParams(search).get("airtutors_session_id")
  const studyGroupId = match.params.id

  const [studyGroupResult] = useQuery({
    query: studyGroupQuery,
    variables: { studyGroupId },
  })

  const [sessionResult] = useQuery({
    query: sessionQuery,
    variables: { sessionId },
    pause: !sessionId,
  })

  return (
    <WithLoadingIndicator result={studyGroupResult}>
      {({ data: { studyGroup } }) => (
        <ScheduleSessionForm
          startsAt={
            sessionResult.data &&
            dayjs(sessionResult.data.previousSession.startsAt).add(1, "week")
          }
          subjectId={
            sessionResult.data && sessionResult.data.previousSession.subject.id
          }
          studyGroup={studyGroup}
        />
      )}
    </WithLoadingIndicator>
  )
}

const sessionQuery = gql`
  query PreviousSessionQuery($sessionId: ID!) {
    previousSession: airtutorsSession(id: $sessionId) {
      id
      startsAt
      subject {
        id
      }
    }
  }
`

const studyGroupQuery = gql`
  query StudyGroupQuery($studyGroupId: ID!) {
    studyGroup(id: $studyGroupId) {
      id
      name
      details
    }
  }
`

export default ScheduleStudyGroupSession
