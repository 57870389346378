import React from "react"

import { useField, useFormikContext } from "formik"

import ErrorMessage from "./ErrorMessage"

const CheckboxField = ({
  hideError = false,
  afterChange = undefined,
  isMulti = false,
  options = [],
  value = undefined,
  ...props
}) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()

  if (!isMulti) options.push({ label: props.label, value: true })
  if (isMulti && value) options.push({ label: props.label, value })

  return (
    <ul className="list-unstyled">
      {options.map(option => (
        <div key={option.value} className="form-group">
          <div className="form-check">
            <label
              className={`form-check-label ${props.disabled ? "disabled" : ""}`}
            >
              {option.label}
              <input
                {...field}
                onChange={e => {
                  if (isMulti) {
                    const newValues = [...field.value]
                    const index = field.value.indexOf(option.value)
                    const valueIsSelected = index >= 0
                    valueIsSelected
                      ? newValues.splice(index, 1)
                      : newValues.push(option.value)
                    setFieldValue(field.name, newValues)
                  } else {
                    setFieldValue(field.name, !field.value) // if isMulti is false, the field is a boolean
                  }
                  typeof afterChange === "function" && afterChange()
                }}
                className="form-check-input"
                type="checkbox"
                checked={
                  isMulti
                    ? field.value.includes(option.value)
                    : field.value === option.value
                }
                {...props}
              />
              <span className="form-check-sign" />
            </label>
          </div>
          {!hideError && <ErrorMessage {...meta} />}
        </div>
      ))}
    </ul>
  )
}

export default CheckboxField
