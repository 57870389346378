import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { gql, useClient } from "urql"

import { Button, H2 } from "~tailwindui/Basics"

import { reportGraphQLError } from "src/airbrake-notifier"
import ordered from "src/ordered"

import LessonTopic from "./LessonTopic"

export type LessonPlanProps = {
  sessionId: string
  lessonPlanId: string
  name: string
}

const LessonPlan: React.FC<LessonPlanProps> = ({
  sessionId,
  lessonPlanId,
  name,
}) => {
  const [topics, setTopics] = useState([])
  const [showTopics, setShowTopics] = useState(false)
  const client = useClient()

  const loadTopics = () => {
    client
      .query(loadTopicsQuery, { sessionId, lessonPlanId })
      .toPromise()
      .then(result => {
        if (!result.data) {
          if (result.error?.response?.status === 422) {
            window.location.reload()
          } else {
            NotificationManager.error("Unable to load topics")
            reportGraphQLError({
              result,
              component:
                "Tutor/AirtutorsSessions/Pages/LessonResources/LessonPlan",
              action: "loadTopics",
            })
          }
          return
        }
        setTopics(result.data.airtutorsSession.lessonPlan.lessonTopics)
        setShowTopics(true)
      })
  }

  const toggleTopics = () => {
    if (topics.length === 0) loadTopics()
    else setShowTopics(!showTopics)
  }

  return (
    <>
      <H2>{name}</H2>
      <Button outline onClick={toggleTopics} className="ml-2 mb-2">
        {showTopics ? "Hide Topics" : "Show Topics"}
      </Button>

      {showTopics &&
        ordered(topics, "sortOrder").map(topic => (
          <LessonTopic
            key={topic.id}
            sessionId={sessionId}
            lessonTopicId={topic.id}
            name={topic.name}
          />
        ))}
    </>
  )
}

const loadTopicsQuery = gql`
  query LessonTopicsQuery($sessionId: ID!, $lessonPlanId: ID!) {
    airtutorsSession(id: $sessionId) {
      lessonPlan(id: $lessonPlanId) {
        lessonTopics {
          id
          name
          sortOrder
        }
      }
    }
  }
`

export default LessonPlan
