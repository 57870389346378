import React from "react"

import { PencilSquareIcon } from "@heroicons/react/24/outline"

export type TutorNoteProps = {
  title: string
  content: string | React.ReactNode
  onEdit?: VoidFunction
}

const TutorNote: React.FC<TutorNoteProps> = ({
  title,
  content,
  onEdit = undefined,
}) => {
  if (!content) return null

  return (
    <div className="mb-4 rounded border-2 border-solid border-yellow-300 bg-yellow-100 p-4">
      <div className="flex items-center py-1 text-xl">
        {title}
        {onEdit && (
          <button onClick={onEdit}>
            <PencilSquareIcon className="inline-block h-8 w-8 pl-2" />
          </button>
        )}
      </div>
      <div className="whitespace-pre-wrap">{content}</div>
    </div>
  )
}

export default TutorNote
