import React, { useState } from "react"

import { Form, Formik } from "formik"
import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { Color } from "~tailwindui/types/enums"

import {
  AlertMessageBox,
  Button,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import { CheckboxInput, SubmitButton } from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"
import ordered from "src/ordered"

export type SendSmsButtonProps = {
  sessionId: string
}

const ValidationSchema = Yup.object().shape({
  studentIds: Yup.array().min(1, "Please select at least one student"),
})

const SendSmsButton: React.FC<SendSmsButtonProps> = ({ sessionId }) => {
  const formState = {
    studentIds: [],
  }

  const [submitted, setSubmitted] = useState(false)

  const [result] = useQuery({
    query: studentsQuery,
    variables: { sessionId },
  })

  const [_, runMutation]: any[] = useMutation(sendSmsMutation)

  const handleSubmit = (values, actions) => {
    runMutation({ id: sessionId, ...values })
      .then(
        response => {
          const { failures } = response.data.sessionSendLateSms
          if (failures.length > 0) {
            actions.setStatus(failures[0].message)
          } else {
            actions.setStatus()
            setSubmitted(true)
          }
        },
        err => actions.setStatus(`Rejected: ${err?.message}`)
      )
      .catch(err => actions.setStatus(`Caught: ${err?.message}`))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Modal.Dialog
      buttonText="Send Student SMS"
      buttonClassNames={classNames(
        "w-full",
        buttonClassNames({
          color: Color.Purple,
        })
      )}
    >
      {closeModal => (
        <>
          <Modal.Header>Send Student SMS</Modal.Header>
          {submitted ? (
            <>
              <Modal.Body>
                <div>Your SMS will be sent in ~1 minute!</div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={closeModal}>Okay</Button>
              </Modal.Footer>
            </>
          ) : (
            <WithLoadingIndicator result={result}>
              {({ data }) => (
                <Formik
                  initialValues={formState}
                  onSubmit={(values, actions) => handleSubmit(values, actions)}
                  validationSchema={ValidationSchema}
                >
                  {({ values, status }) => (
                    <Form className="flex max-h-[calc(70vh-90px)] flex-col">
                      <Modal.Body>
                        <p className="mt-0">
                          This will send the following SMS to the selected
                          students:
                        </p>
                        <p className="rounded-md border-solid border-black bg-[#eee] p-4">
                          Hey [Student Name]! It's [Tutor Name]{" "}
                          <span role="img" aria-label="happy">
                            🤗
                          </span>{" "}
                          Your session with me is happening right now! Any
                          chance you can make it?
                          <br />
                          <br />
                          Click this link to join the Cloudroom: [Cloudroom
                          Link]
                          <br />
                          <br />
                          BTW, if the arrow wheels keep spinning, click on the
                          camera icon in your URL address bar on the far right
                          and make sure airtutors.org is allowed. Lmk if you can
                          make it today!{" "}
                          <span role="img" aria-label="congratulations">
                            🥳
                          </span>
                        </p>
                        <div className="mt-2">
                          <CheckboxInput
                            name="studentIds"
                            checkboxType="multi"
                            options={ordered(
                              data.session.studentSessions,
                              "student",
                              "lastName"
                            ).map(studentSession => ({
                              value: studentSession.student.id,
                              label: `${studentSession.student.fullName} ${
                                !studentSession.student.phoneNumber
                                  ? "(has no number on file)"
                                  : ""
                              }${
                                studentSession.lateSmsSentAt
                                  ? "(you can only send each student one SMS per session)"
                                  : ""
                              }`,
                              disabled:
                                !studentSession.student.phoneNumber ||
                                studentSession.lateSmsSentAt,
                            }))}
                          />
                        </div>
                      </Modal.Body>

                      <Modal.Footer>
                        <div className="flex flex-col">
                          {status && (
                            <AlertMessageBox>{status}</AlertMessageBox>
                          )}
                          <div className="flex justify-end space-x-2">
                            <Button color={Color.Red} onClick={closeModal}>
                              Cancel
                            </Button>
                            <SubmitButton>Send SMS</SubmitButton>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Form>
                  )}
                </Formik>
              )}
            </WithLoadingIndicator>
          )}
        </>
      )}
    </Modal.Dialog>
  )
}

const studentsQuery = gql`
  query ($sessionId: ID!) {
    session(id: $sessionId) {
      studentSessions {
        id
        lateSmsSentAt
        student {
          id
          fullName
          lastName
          phoneNumber
        }
      }
    }
  }
`

const sendSmsMutation = gql`
  mutation ($id: ID!, $studentIds: [ID!]!) {
    sessionSendLateSms(id: $id, studentIds: $studentIds) {
      failures {
        message
      }
    }
  }
`

export default SendSmsButton
