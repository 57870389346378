import React from "react"

import { ErrorMessage } from "components/Forms/Formik"
import { CheckboxField } from "components/Forms/Formik/hookComponents"

const SubjectsCheckboxes = ({ label, subjects }) => (
  <section>
    <label>
      {label}: Which of the following do you feel absolutely confident
      supporting up to the highest level (AP, IB, Honors).
    </label>
    <CheckboxField
      isMulti
      name="subjectBucketIds"
      options={subjects.map(subject => ({
        value: subject.id,
        label: subject.name,
      }))}
    />
  </section>
)

const Subjects = ({
  mathSubjects,
  scienceSubjects,
  englishSubjects,
  socialStudiesSubjects,
  languagesSubjects,
  otherSubjects,
}) => {
  return (
    <>
      <h3>Subject Competencies</h3>
      <p>
        Please ONLY check a subject below if you are prepared and capable of
        teaching and tutoring in this subject at the highest level. Don't feel
        pressured to aim above your level of comfort - we strive for confidence
        and competence in your subject, not for you to check every box. (PLEASE
        NOTE: you will be tested in these areas during your interview)
      </p>

      <SubjectsCheckboxes label="Math" subjects={mathSubjects} />
      <SubjectsCheckboxes label="Science" subjects={scienceSubjects} />
      <SubjectsCheckboxes label="English" subjects={englishSubjects} />
      <SubjectsCheckboxes
        label="Social Studies"
        subjects={socialStudiesSubjects}
      />
      <SubjectsCheckboxes label="Languages" subjects={languagesSubjects} />
      <SubjectsCheckboxes label="Other" subjects={otherSubjects} />

      <ErrorMessage name="subjectBucketIds" />
    </>
  )
}

export default Subjects
