import React, { useState } from "react"

import { buildMutation, compress, useMutation } from "micro-graphql-react"

import { css } from "@emotion/core"

import { parentClient, studentClient } from "src/graphql-config"

import LocalTime from "components/LocalTime"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const Register = ({ courseSection, student, courseClusterName }) => {
  const [errors, setErrors] = useState()
  const client = student ? parentClient : studentClient
  const { runMutation, running } = useMutation(
    buildMutation(registerMutation, { client })
  )

  const register = () => {
    runMutation({ courseSectionId: courseSection.id, studentId: student?.id })
      .then(
        response => {
          const { errorMessages, billingPath } = response.courseRegistration
          if (errorMessages) {
            if (billingPath) {
              setErrors(
                <div>
                  {errorMessages}
                  <br />
                  <a href={billingPath}>Click here to view billing page</a>
                </div>
              )
            } else {
              setErrors(errorMessages)
            }
          } else {
            window.location.href = "/"
          }
        },
        () => setErrors("Something went wrong")
      )
      .catch(() => setErrors("Something went wrong"))
  }

  return (
    <ModalWithProvidedBody
      modalClassName="bootstrap-modal"
      modalTitle="Register"
      buttonText="Register"
      buttonClassName="btn solid green"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            This will register {student?.fullName} for {courseClusterName}{" "}
            starting on{" "}
            <LocalTime timestamp={courseSection.startsOn} format="dddd, LL" />{" "}
            at{" "}
            <LocalTime
              timestamp={courseSection.availabilities[0].startsAt}
              omitDate
            />
          </div>
          <div
            className="modal-footer"
            css={css`
              display: flex;
              flex-flow: column;
            `}
          >
            {errors && (
              <div className="alert mt-3 mb-3 p-3">
                <span
                  className="w-100"
                  dangerouslySetInnerHTML={{ __html: errors }}
                />
              </div>
            )}
            <div>
              <button onClick={closeModal} className="btn solid red">
                Cancel
              </button>
              <button
                onClick={register}
                className="btn solid green"
                disabled={running}
              >
                Register
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const registerMutation = compress`
  mutation($courseSectionId: ID!, $studentId: ID) {
    courseRegistration(courseSectionId: $courseSectionId, studentId: $studentId) {
      success
      errorMessages
      billingPath
    }
  }
`

export default Register
