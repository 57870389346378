import React from "react"

import RequestedSessions from "components/Tutor/components/Sessions/RequestedSessions"

export type RequestedSessionsPageProps = Record<string, never>

const RequestedSessionsPage: React.FC<RequestedSessionsPageProps> = () => {
  return <RequestedSessions />
}

export default RequestedSessionsPage
