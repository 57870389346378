import React from "react"

import dancing from "assets/dancing.gif"

import ordered from "src/ordered"

import Rating from "components/Student/Evaluation/Rating"

type Topic = {
  id: string
  name: string
}

type Student = {
  id: string
  fullName: string
}

type LessonResourceCompletion = {
  id: string
  completedLessonTopic: Topic
  student: Student
}

type Session = {
  id: string
  lessonResourceCompletions: LessonResourceCompletion[]
}

export type EvaluationProps = {
  airtutorsSession: Session
  values: Record<string, any>
  setFieldValue: (name: string, value: any) => void
}

const Evaluation: React.FC<EvaluationProps> = ({
  airtutorsSession,
  values,
  setFieldValue,
}) => {
  const resourcesWithCompletedLessonTopics = () => {
    if (!airtutorsSession) return []

    return airtutorsSession.lessonResourceCompletions.filter(
      c => c.completedLessonTopic
    )
  }

  return (
    <>
      {airtutorsSession.lessonResourceCompletions.length > 0 && (
        <div className="text-center">
          <div className="mb-5">
            <h3 className="my-0 font-medium">
              <span>Awesome job!</span>
              <span role="img" aria-label="kisses" className="ml-3">
                🥰
              </span>
              <br />
              <span>How confident are you about their abilities on...</span>
            </h3>
          </div>
          {ordered(
            ordered(
              ordered(
                airtutorsSession.lessonResourceCompletions,
                "lessonResource",
                "sortOrder"
              ),
              "lessonTopic",
              "sortOrder"
            ),
            "student",
            "fullName"
          ).map(resourceCompletion => (
            <Rating
              key={resourceCompletion.id}
              label={`${resourceCompletion.student.fullName}: ${resourceCompletion.lessonResource.name}`}
              name={`lessonResourceCompletions[${resourceCompletion.id}].tutorMastery`}
              value={
                values.lessonResourceCompletions[resourceCompletion.id]
                  .tutorMastery
              }
              onChange={e => {
                setFieldValue(
                  `lessonResourceCompletions[${resourceCompletion.id}].tutorMastery`,
                  e.currentTarget.value
                )
              }}
            />
          ))}
        </div>
      )}

      {resourcesWithCompletedLessonTopics().length > 0 && (
        <>
          <hr />
          <div className="text-center">
            <img src={dancing} alt="Dancing" />
            <div className="mb-5">
              <h3 className="my-0 font-medium">
                Woohoo! How do you feel about their overall chapter mastery?
              </h3>
            </div>
            {resourcesWithCompletedLessonTopics().map(resourceCompletion => (
              <Rating
                key={`${resourceCompletion.id}_topic`}
                label={`${resourceCompletion.student.fullName}: ${resourceCompletion.completedLessonTopic.name}`}
                name={`lessonResourceCompletions[${resourceCompletion.id}].tutorTopicMastery`}
                value={
                  values.lessonResourceCompletions[resourceCompletion.id]
                    .tutorTopicMastery
                }
                onChange={e => {
                  setFieldValue(
                    `lessonResourceCompletions[${resourceCompletion.id}].tutorTopicMastery`,
                    e.currentTarget.value
                  )
                }}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default Evaluation
