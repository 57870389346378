import React, { useState } from "react"

import { useHistory } from "react-router"
import { gql, useQuery } from "urql"

import { Combobox } from "@headlessui/react"
import { ChevronUpDownIcon } from "@heroicons/react/20/solid"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import classNames from "src/classNames"

import { path } from "components/Tutor/TutorRoutes"

export type AutocompleteNavigateProps = {
  navigateTo: string
  resource: string
  column?: string
  placeholder?: string
  className?: string
}

const AutocompleteSelect: React.FC<AutocompleteNavigateProps> = ({
  navigateTo,
  resource,
  column = "name",
  placeholder = "",
  className = "",
}) => {
  const [queryString, setQueryString] = useState("")

  const [result] = useQuery({
    query: autocompleteQuery,
    variables: { resource, column, match: queryString },
  })

  const history = useHistory()

  const onChange = selected => {
    if (!selected?.value) return
    history.push(path(navigateTo, { id: selected.value }))
  }

  return (
    <div className="mb-3">
      <Combobox as="div" onChange={onChange}>
        <div className={classNames("relative", className)}>
          <Combobox.Button className="w-full">
            {({ open }) => (
              <div>
                <Combobox.Input
                  placeholder={placeholder}
                  className="w-full truncate rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  onChange={event => setQueryString(event.target.value)}
                  onClick={e => {
                    if (open) e.stopPropagation()
                  }}
                />
                <span className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </div>
            )}
          </Combobox.Button>

          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <WithLoadingIndicator result={result}>
              {({ data: { options } }) =>
                options.length === 0 ? (
                  <span className="py-2 px-3 italic">No {resource} found</span>
                ) : (
                  options.map(option => (
                    <Combobox.Option
                      key={option.value}
                      value={option}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none py-2 px-3",
                          active ? "bg-sky-600 text-white" : "text-gray-900"
                        )
                      }
                    >
                      <span className="block truncate pr-4 font-normal">
                        {option.label}
                      </span>
                    </Combobox.Option>
                  ))
                )
              }
            </WithLoadingIndicator>
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  )
}

const autocompleteQuery = gql`
  query AutocompleteQuery(
    $resource: String!
    $column: AutocompleteColumnEnum!
    $match: String!
  ) {
    options: autocomplete(resource: $resource, column: $column, match: $match) {
      label
      value
    }
  }
`

export default AutocompleteSelect
