import React, { useContext, useEffect } from "react"

import { useField } from "formik"

import StorageContext from "./StorageContext"

export type StorageWrapperProps = {
  name: string
  children: React.ReactNode
}

const StorageWrapper: React.FC<StorageWrapperProps> = ({ name, children }) => {
  const [field] = useField(name)
  const [_, setStore] = useContext(StorageContext)

  useEffect(() => {
    setStore(oldStore => {
      const updatedStore = { ...oldStore }
      updatedStore[name] = field.value
      return updatedStore
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, name])

  return <>{children}</>
}

export default StorageWrapper
