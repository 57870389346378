import { compress } from "micro-graphql-react"

export default compress`
  query(
    $sourceUserId: ID!,
    $page: Int!,
  ) {
    tutor(id: $sourceUserId) {
      matchedUsers(page: $page) {
        totalPages
        currentPage
        data {
          id
          showPath
          fullName
          lastName
          tutorToStudentPhoneNumber(tutorId: $sourceUserId)
          tutorToParentPhoneNumber(tutorId: $sourceUserId)
          studentToTutorPhoneNumber(tutorId: $sourceUserId)
          parentToTutorPhoneNumber(tutorId: $sourceUserId)
        }
      }
    }
  }
`
