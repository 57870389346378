import React from "react"

import { Color } from "~tailwindui/types/enums"

import { Button, H3 } from "~tailwindui/Basics"

export type DirectoryInfoProps = Record<string, never>

const DirectoryInfo: React.FC<DirectoryInfoProps> = () => {
  return (
    <div>
      <p className="mb-4">
        With the Tutor Directory, you are in control! Share your public profile
        with anyone, invite students to create an account, and design your own
        courses which will be made available to clients browsing the directory.
      </p>

      <p className="my-4">
        In order to maximize your visibility and opportunities, please ensure
        your subjects and availability are always up to date.
      </p>

      <div className="text-red-500">
        Update this button to actually do something after Intercom is set up
      </div>
      <Button color={Color.Purple}>Take a Tour</Button>

      <H3 className="mt-8 border-b-[1px]">Help</H3>
      <p className="mt-4">
        For Directory-related support, please use the green chat bubble in the
        bottom right hand corner of the page.
      </p>
    </div>
  )
}

export default DirectoryInfo
