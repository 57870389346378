const calculateTutorPayout = ({
  hourlyRate,
  directoryPercentFee,
  stripePercentFee,
  stripeFlatFee,
}: {
  hourlyRate: number
  directoryPercentFee: number
  stripePercentFee: number
  stripeFlatFee: number
}): number => {
  const tutorPercentage = (100 - stripePercentFee - directoryPercentFee) / 100
  return hourlyRate * tutorPercentage - stripeFlatFee / 100
}

export default calculateTutorPayout
