import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { H2, WithLoadingIndicator } from "~tailwindui/Basics"
import {
  Failures,
  SelectInput,
  SubmitButton,
  handleFailure,
} from "~tailwindui/Form"

import { RunMutation } from "src/types"
import { MatchParams } from "src/types/MatchParams"

import { path } from "components/Tutor/TutorRoutes"

import SessionInfo from "../components/SessionInfo"

const ValidationSchema = Yup.object().shape({
  updatedDurationHours: Yup.string().required("Hours is required"),
  updatedDurationMinutes: Yup.string().required("Minutes is required"),
})

const RequestDurationChange: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const id = match.params.id
  const history = useHistory()

  const [result] = useQuery({
    query,
    variables: { id },
  })

  const [, runMutation]: [any, RunMutation] = useMutation(requestDurationChange)

  const initialValues = {
    updatedDurationHours: "",
    updatedDurationMintues: "",
  }

  const handleSubmit = (values, actions) => {
    runMutation({
      id,
      updatedDuration: `${values.updatedDurationHours}:${values.updatedDurationMintues}`,
    })
      .then(
        result => {
          const { failures } = result.data.requestDurationChange
          if (failures.length > 0) handleFailure(actions, failures)
          else history.push(path("sessionDetails", { id }))
          NotificationManager.success("Your request has been submitted")
        },
        err => handleFailure(actions)
      )
      .catch(err => handleFailure(actions))
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { airtutorsSession } }) => (
        <>
          <H2>Request Duration Change</H2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <SessionInfo session={airtutorsSession} />
            </div>

            <div>
              {airtutorsSession.durationChangeState === "approval_needed" ? (
                <div className="text-xl">
                  You have already submitted a duration change request from{" "}
                  {airtutorsSession.formattedBilledDuration} to{" "}
                  {airtutorsSession.formattedUpdatedDuration}. An admin will
                  review your request shortly.
                </div>
              ) : (
                <>
                  <div className="mb-4 text-xl">
                    Update duration from{" "}
                    {airtutorsSession.formattedBilledDuration} to:
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={ValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className="flex space-x-2">
                        <SelectInput
                          name="updatedDurationHours"
                          label="Hours"
                          options={[
                            { label: "0 hours", value: "0" },
                            { label: "1 hour", value: "1" },
                            { label: "2 hours", value: "2" },
                            { label: "3 hours", value: "3" },
                          ]}
                        />
                        <SelectInput
                          name="updatedDurationMinutes"
                          label="Minutes"
                          options={[
                            { label: "0 minutes", value: "0" },
                            { label: "15 minutes", value: "15" },
                            { label: "30 minutes", value: "30" },
                            { label: "45 minutes", value: "45" },
                          ]}
                        />
                      </div>

                      <Failures />
                      <SubmitButton text="Submit" />
                    </Form>
                  </Formik>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query SessionDetailsQuery($id: ID!) {
    airtutorsSession(id: $id) {
      id
      billState
      canceledBy
      compedReason
      startsAt
      cancellationReason
      substituteNotes
      tutorFeedback
      topicsCovered
      formattedBilledDuration
      formattedUpdatedDuration
      durationChangeState
      studentSessions {
        id
        noShow
        excusedAbsence
        participationLevel
        student {
          id
          fullName
          lastName
          notes
        }
      }
      subject {
        id
        name
      }
      originalTutor {
        id
        fullName
        email
      }
      studyGroup {
        id
        name
        details
      }
      organization {
        id
        name
        details
      }
    }
  }
`

const requestDurationChange = gql`
  mutation requestDurationChange($id: ID!, $updatedDuration: String!) {
    requestDurationChange(id: $id, updatedDuration: $updatedDuration) {
      failures {
        message
      }
    }
  }
`

export default RequestDurationChange
