import React from "react"

import { path } from "~Tutor/TutorRoutes"
import { H1, Link } from "~tailwindui/Basics"

import Echo from "./Echo"

export type NotFoundProps = Record<string, never>

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <div className="flex h-[80vh] flex-col justify-around">
      <div className="relative flex items-center justify-center">
        <Echo className="z-10 h-[45vh] h-[45vh]" />
        <span className="absolute -top-6 z-0 text-[30vh] text-sky-100">
          404
        </span>
      </div>
      <div className="flex flex-col items-center justify-center">
        <H1>Hmmmm, that page seems to be missing...</H1>
        <Link to={path("dashboard")} className="text-xl">
          Take me home
        </Link>
      </div>
    </div>
  )
}

export default NotFound
