import React, { useState } from "react"

import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { SelectOption } from "~tailwindui/types/SelectOption"

import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"

import { timezoneOptions } from "src/enums"
import ordered from "src/ordered"

import DisplayableFormikForm from "components/OrganizationAdmin/components/DisplayableFormikForm"
import Form from "components/OrganizationAdmin/components/Form"

import ChangePasswordForm from "./ChangePasswordForm"

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string().required("Email is required."),
  emailTimeZone: Yup.string().required("Please select a time zone."),
  currentPassword: Yup.string().when("password", {
    is: (password: string) => !!password,
    then: Yup.string().required("Please enter your current password."),
  }),
  passwordConfirmation: Yup.string().when("password", {
    is: (password: string) => !!password,
    then: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),
  }),
})

const ProfilePage = () => {
  const [changePassword, setChangePassword] = useState(false)
  const buildFormState = viewer => {
    const { accessibleSchools, ...viewerProps } = viewer
    return {
      ...viewerProps,
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    }
  }

  const [result] = useQuery({ query: viewerQuery })
  const [, updateOrganizationAdmin] = useMutation(
    updateOrganizationAdminMutation
  )

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <DisplayableFormikForm.Wrapper
          editable={true}
          title="Your Profile"
          displayData={viewer}
          initialValues={buildFormState(viewer)}
          mutation={{
            name: "updateOrganizationAdmin",
            run: updateOrganizationAdmin,
          }}
          convertFormValues={vals => ({ input: vals })}
          validationSchema={validationSchema}
        >
          <>
            <Form.Section title="Account">
              <DisplayableFormikForm.TextInput
                label="Username"
                name="username"
              />

              <DisplayableFormikForm.EditOnly>
                {
                  <ChangePasswordForm
                    changePassword={changePassword}
                    setChangePassword={setChangePassword}
                  />
                }
              </DisplayableFormikForm.EditOnly>

              <DisplayableFormikForm.SingleSelect
                label="Time Zone"
                name="emailTimeZone"
                options={ordered(timezoneOptions, "label") as SelectOption[]}
                description="This is used to determine notification times."
              />
            </Form.Section>

            <Form.Section title="Personal Information">
              <DisplayableFormikForm.TextInput
                label="First Name"
                name="firstName"
              />
              <DisplayableFormikForm.TextInput
                label="Last Name"
                name="lastName"
              />
              <DisplayableFormikForm.TextInput label="Email" name="email" />
              <DisplayableFormikForm.TextInput
                label="Phone Number"
                name="phoneNumber"
              />
            </Form.Section>

            {viewer.canOrganizationAdminAccessUserManagement && (
              <Form.Section title="User Permissions">
                <DisplayableFormikForm.ToggleInput
                  label="Financial Access"
                  name="canOrganizationAdminAccessFinances"
                  description="Allows this user to view financial data."
                />
                <DisplayableFormikForm.ToggleInput
                  label="Semesters Access"
                  name="canOrganizationAdminAccessSemesters"
                  description="Allows this user to create and edit your organization's academic semesters."
                />
                <DisplayableFormikForm.ToggleInput
                  label="User Management Access"
                  name="canOrganizationAdminAccessUserManagement"
                  description="Allows this user to create other users and edit all permissions."
                />
                <DisplayableFormikForm.ToggleInput
                  label="All Schools Access"
                  name="canOrganizationAdminAccessAllSchools"
                  description="Allows this user to view data for all schools."
                />
                {!viewer.canOrganizationAdminAccessAllSchools && (
                  <DisplayableFormikForm.MultiSelect
                    label="Permitted School(s)"
                    name="schoolIds"
                    displayValue={viewer.accessibleSchools}
                    description="The schools this user has access to."
                    options={viewer.accessibleSchools.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                )}
              </Form.Section>
            )}
          </>
        </DisplayableFormikForm.Wrapper>
      )}
    </WithLoadingIndicator>
  )
}

const viewerQuery = gql`
  query {
    viewer {
      id
      firstName
      lastName
      username
      email
      phoneNumber
      emailTimeZone
      canOrganizationAdminAccessAllSchools
      canOrganizationAdminAccessFinances
      canOrganizationAdminAccessSemesters
      canOrganizationAdminAccessUserManagement
      accessibleSchools {
        id
        name
      }
    }
  }
`

const updateOrganizationAdminMutation = gql`
  mutation ($input: OrganizationAdminInputObject!) {
    updateOrganizationAdmin(input: $input) {
      redirectTo
      organizationAdmin {
        id
      }
      failures {
        message
      }
    }
  }
`
export default ProfilePage
