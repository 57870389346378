import React, { Fragment, ReactNode, useState } from "react"

import { Dialog, Transition } from "@headlessui/react"

import ModalContext from "./ModalContext"

export type ModalDialogProps =
  | {
      hideButton: true
      isOpen: boolean
      closeModal: () => void
      buttonText?: never
      buttonClassNames?: never
      children: ReactNode
      afterClose?: () => void
    }
  | {
      hideButton?: false
      isOpen?: never
      closeModal?: never
      buttonText: string
      buttonClassNames?: string
      children: ReactNode
      afterClose?: () => void
    }

const ModalDialog: React.FC<ModalDialogProps> = ({
  isOpen,
  closeModal,
  hideButton = false,
  buttonText,
  buttonClassNames,
  children,
  afterClose,
}) => {
  const [buttonOpen, setButtonOpen] = useState(false)

  const handleClose = () => {
    hideButton ? closeModal() : setButtonOpen(false)
    if (afterClose) afterClose()
  }

  return (
    <ModalContext.Provider value={{ handleClose }}>
      <button
        type="button"
        onClick={() => {
          setButtonOpen(true)
        }}
        className={
          hideButton
            ? "hidden"
            : buttonClassNames ||
              "inline-flex justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        }
      >
        {buttonText}
      </button>
      <Transition.Root
        show={!!(hideButton ? isOpen : buttonOpen)}
        as={Fragment}
      >
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative flex max-h-[70vh] transform flex-col overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:w-1/2 md:max-w-2xl">
                  {typeof children === "function"
                    ? children(handleClose)
                    : children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </ModalContext.Provider>
  )
}

export default ModalDialog
