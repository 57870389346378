import React from "react"

import { useHistory } from "react-router-dom"

import { Color, Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

export type RescheduleSessionButtonProps = {
  id: string
  size?: "normal" | "small"
  className?: string
}

const RescheduleSessionButton: React.FC<RescheduleSessionButtonProps> = ({
  id,
  size = "small",
  className = "",
}) => {
  const history = useHistory()

  return (
    <Modal.Dialog
      buttonText="Reschedule"
      buttonClassNames={classNames(
        buttonClassNames({
          color: Color.Orange,
          size: size === "small" ? Size.Small : Size.Medium,
        }),
        className
      )}
    >
      {closeModal => (
        <>
          <Modal.Header>Reschedule Session</Modal.Header>
          <Modal.Body>
            <p>
              Before rescheduling in the app you should reach out to the
              student(s) to let them know. Have you contacted them?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button
                onClick={() => {
                  closeModal()
                  history.push(path("sessionReschedule", { id }))
                }}
              >
                Yes, I reached out
              </Button>
              <Button color={Color.Red} onClick={closeModal}>
                No, I will reach out
              </Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal.Dialog>
  )
}

export default RescheduleSessionButton
