import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { H2, WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

export type DeniedExpensesProps = Record<never, any>

const DeniedExpenses: React.FC<DeniedExpensesProps> = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({
    query: expensesQuery,
    variables: { page },
  })

  if (result.data && result.data.expenses.data.length === 0) return null

  return (
    <WithLoadingIndicator result={result} header={<H2>Denied Expenses</H2>}>
      {({ data: { expenses } }) => (
        <Table
          data={expenses}
          changePage={setPage}
          headers={[
            "Description",
            "Type",
            "Amount",
            "Your Comments",
            "Admin Feedback",
          ]}
          valueMapper={expense => [
            expense.description,
            expense.expenseType,
            expense.formattedAmmount,
            expense.comments,
            expense.feedback,
          ]}
        />
      )}
    </WithLoadingIndicator>
  )
}

const expensesQuery = gql`
  query DeniedExpensesQuery($page: Int!) {
    expenses(page: $page, state: denied) {
      currentPage
      totalPages
      data {
        id
        expenseType
        description
        comments
        feedback
        formattedAmount
      }
    }
  }
`

export default DeniedExpenses
