import React from "react"

import { path } from "~Tutor/TutorRoutes"
import TutorNote from "~Tutor/components/TutorNote"
import { Link } from "~tailwindui/Basics"
import DescriptionItem from "~tailwindui/Basics/DescriptionItem"

import titlecase from "src/titlecase"

import LocalTime from "components/LocalTime"

type Organization = {
  id: string
  name: string
  details: string
}

type StudyGroup = {
  id: string
  name: string
  details: string
}

type Tutor = {
  id: string
  fullName: string
  email: string
}

type Student = {
  id: string
  fullName: string
  notes: string
}

type Subject = {
  id: string
  name: string
}

type StudentSession = {
  student: Student
}

export type Session = {
  id: string
  directory: boolean
  billState: string
  canceledBy: string
  cancellationReason: string
  compedReason: string
  startsAt: string
  studyGroup: StudyGroup
  studentSessions: StudentSession[]
  originalTutor: Tutor
  organization: Organization
  substituteNotes: string
  subject: Subject
  topicsCovered: string
  tutorFeedback: string
}

export type SessionInfoProps = {
  session: Session
}

const SessionInfo: React.FC<SessionInfoProps> = ({ session }) => {
  return (
    <>
      <TutorNote title="Group Details" content={session.studyGroup?.details} />

      {session.studentSessions.some(({ student: { notes } }) => notes) && (
        <TutorNote
          title="Student Notes"
          content={
            <ul className="m-0">
              {session.studentSessions.map(
                ({ student: { fullName, notes } }) =>
                  notes && (
                    <li key={fullName}>
                      {fullName}: {notes}
                    </li>
                  )
              )}
            </ul>
          }
        />
      )}

      {session.directory && (
        <>
          <DescriptionItem
            label="Bill State"
            value={titlecase(session.billState)}
          />

          {session.canceledBy && (
            <DescriptionItem label="Canceled By" value={session.canceledBy} />
          )}

          {session.compedReason && (
            <div className="rounded-md bg-red-100 p-2">
              <DescriptionItem
                label="Comped Reason"
                value={session.compedReason}
              />
            </div>
          )}
        </>
      )}

      <DescriptionItem label="Subject" value={session.subject?.name} />
      <DescriptionItem
        label="Date"
        value={<LocalTime omitTime timestamp={session.startsAt} />}
      />
      <DescriptionItem
        label="Scheduled Time"
        value={<LocalTime omitDate timestamp={session.startsAt} />}
      />
      <DescriptionItem
        label="Original Tutor"
        value={
          session.originalTutor && (
            <Link to={`mailto:${session.originalTutor.email}`}>
              {session.originalTutor.fullName}
            </Link>
          )
        }
      />
      <DescriptionItem
        label="Substitute Notes"
        value={session.substituteNotes}
      />
      <DescriptionItem
        label="Study Group"
        value={
          session.studyGroup && (
            <Link
              to={path("studyGroupDetails", {
                id: session.studyGroup.id,
              })}
            >
              {session.studyGroup.name}
            </Link>
          )
        }
      />
      <DescriptionItem
        label="Organization"
        value={session.organization?.name}
      />
      {session.organization?.details.length > 0 && (
        <DescriptionItem
          label="Organization Details"
          value={session.organization.details}
        />
      )}
      <DescriptionItem label="Your Feedback" value={session.tutorFeedback} />
      <DescriptionItem label="Topics Covered" value={session.topicsCovered} />
    </>
  )
}

export default SessionInfo
