import React from "react"

import { useFormikContext } from "formik"

import { AlertMessageBox } from "~tailwindui/Basics"

const ValidationNotice = () => {
  const { isValid, errors } = useFormikContext()

  const extractMessages = error => {
    if (typeof error === "string") {
      return [error]
    } else if (Array.isArray(error)) {
      return error
        .filter(Boolean)
        .map(e => Object.values(e))
        .flat()
    } else {
      return ""
    }
  }

  if (isValid) return null
  return (
    <AlertMessageBox>
      <div>
        Please correct the following validation errors
        <ul className="ml-5">
          {Object.values(errors).map(error =>
            extractMessages(error).map(message => (
              <li key={message} className="list-disc">
                {message}
              </li>
            ))
          )}
        </ul>
      </div>
    </AlertMessageBox>
  )
}

export default ValidationNotice
