import React, { useContext } from "react"

import {
  faDesktop,
  faLock,
  faLockOpen,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons"

import { Color } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link } from "~tailwindui/Basics"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import BaseButton from "components/ChimeCloudroom/Shared/BaseButton"
import GoogleDocButton from "components/ChimeCloudroom/Shared/GoogleDocButton"
import MiroButton from "components/ChimeCloudroom/Shared/MiroButton"

import CompleteSessionModal from "./CompleteSessionModal"
import CompleteWorksheetButtons from "./CompleteWorksheetButtons"
import SendSmsButton from "./SendSmsButton"

export type ControlsProps = Record<string, never>

const Controls: React.FC<ControlsProps> = () => {
  const {
    session,
    localUser,
    roster,
    channel,
    allAudioMuted,
    toggleAllAudio,
    allVideoMuted,
    toggleAllVideo,
    allControlsLocked,
    toggleAllControlsLocked,
    startLocalScreenShare,
    stopLocalScreenShare,
    surveyModalOnOpen,
  } = useContext(CloudroomContext)

  const screenSharing =
    roster[session.chimeJoinInfo.attendee.attendeeId]?.sharingContent

  return (
    <div className="grid grid-cols-2 gap-x-2 sm:grid-cols-1">
      <BaseButton
        active={allAudioMuted}
        onClick={toggleAllAudio}
        activeText="Unmute Students"
        inactiveText="Mute Students"
        activeIcon={faMicrophoneSlash}
        inactiveIcon={faMicrophone}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />
      <BaseButton
        active={allVideoMuted}
        onClick={toggleAllVideo}
        activeText="Enable Student Video"
        inactiveText="Disable Student Video"
        activeIcon={faVideoSlash}
        inactiveIcon={faVideo}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />
      <BaseButton
        active={allControlsLocked}
        onClick={toggleAllControlsLocked}
        activeText="Unlock Student Controls"
        inactiveText="Lock Student Controls"
        activeIcon={faLock}
        inactiveIcon={faLockOpen}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />
      <BaseButton
        active={screenSharing}
        onClick={screenSharing ? stopLocalScreenShare : startLocalScreenShare}
        activeText="Stop Sharing Your Screen"
        inactiveText="Share Your Screen"
        activeIcon={faDesktop}
        inactiveIcon={faDesktop}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />

      {session.airtutors && <CompleteWorksheetButtons sessionId={session.id} />}

      <MiroButton tailwind user={localUser} session={session} />

      <GoogleDocButton
        tailwind
        googledocUrl={session.googledocUrl}
        userType={localUser.type}
      />

      <Link
        to={
          session.studyGroup
            ? path("studyGroupDetails", {
                id: session.studyGroup.id,
              })
            : path("studentDetails", {
                id: session.students[0].id,
              })
        }
        className="mb-1 w-full"
        target="_blank"
        rel="noopener noreferrer"
        button
        color={Color.Red}
      >
        Previous Sessions
      </Link>

      {session.survey && (
        <button
          onClick={surveyModalOnOpen}
          className="btn solid blue w-100 mb-1"
        >
          Re-Open Survey Modal
        </button>
      )}

      <CompleteSessionModal
        id={session.id}
        endsAt={session.endsAt}
        channel={channel}
      />

      <SendSmsButton sessionId={session.id} />
    </div>
  )
}

export default Controls
