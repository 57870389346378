import React from "react"

import AirtutorsSessions from "./Airtutors"
import DirectorySessions from "./Directory"

export type NameProps = {
  type: "AirtutorsSession" | "DirectorySession"
  qualityControlPath: string
}

const Name: React.FC<NameProps> = ({ type, qualityControlPath }) => {
  return (
    <>
      {type === "DirectorySession" ? (
        <DirectorySessions />
      ) : (
        <AirtutorsSessions qualityControlPath={qualityControlPath} />
      )}
    </>
  )
}

export default Name
