import React, { useEffect } from "react"

import dayjs from "dayjs"
import { NotificationManager } from "react-notifications"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { gql, useQuery } from "urql"

import { H2, WithLoadingIndicator } from "~tailwindui/Basics"

import ordered from "src/ordered"
import { MatchParams } from "src/types/MatchParams"

import NotFound from "components/Tutor/Pages/NotFound"
import { path } from "components/Tutor/TutorRoutes"

import StudyGroup from "./StudyGroup"

const StudyGroupTutorInvitation: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const id = match.params.id
  const history = useHistory()

  const [result] = useQuery({
    query: invitationQuery,
    variables: { id },
  })

  useEffect(() => {
    if (!result.data?.studyGroupTutorInvitation) return
    const { studyGroupTutorInvitation, viewer } = result.data
    const isFuture = dayjs(studyGroupTutorInvitation.expiresAt).isAfter(dayjs())
    if (isFuture) {
      history.push(path("dashboard"))
      NotificationManager.error("This invitation has expired")
    }
    if (studyGroupTutorInvitation.availableStudyGroups.length === 0) {
      history.push(path("dashboard"))
      NotificationManager.error("No available study groups")
    }
    if (!viewer.canAcceptGroups) {
      history.push(path("dashboard"))
      NotificationManager.error(
        "You have given up a study group in the last 48 hours, so you are ineligible to accept new groups at this time"
      )
    }
  }, [result, history])

  if (result.data && !result.data.studyGroupTutorInvitation) return <NotFound />

  return (
    <>
      <WithLoadingIndicator result={result}>
        {({ data: { studyGroupTutorInvitation } }) => (
          <>
            <H2>
              Study Group Registration for{" "}
              {studyGroupTutorInvitation.organization.name}
            </H2>

            {studyGroupTutorInvitation.availableStudyGroups.length === 0 && (
              <p className="italic">
                Sorry, all available groups have been registered for at this
                time.
              </p>
            )}

            <ul className="mt-4 space-y-8">
              {ordered(
                studyGroupTutorInvitation.availableStudyGroups,
                "availabilities",
                "0",
                "startsAt"
              ).map(studyGroup => (
                <StudyGroup
                  key={studyGroup.id}
                  studyGroup={studyGroup}
                  invitationId={id}
                />
              ))}
            </ul>
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const invitationQuery = gql`
  query InvitationQuery($id: ID!) {
    viewer {
      canAcceptGroups
    }
    studyGroupTutorInvitation(id: $id) {
      organization {
        id
        name
      }
      availableStudyGroups {
        id
        name
        formattedDetails
        minTutorConsistencyRate
        maxSubsEstimate
        foreignLanguage {
          id
          name
        }
        subjects {
          id
          name
        }
        studyGroupGeneration {
          id
          startsOn
          endsOn
        }
        availabilities {
          id
          startsAt
          endsAt
          weekday
          weekdayNumber
        }
      }
    }
  }
`

export default StudyGroupTutorInvitation
