import React from "react"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

const ScreenShareRequestedModal = ({
  isOpen,
  closeModal,
  studentName,
  approveRequest,
  denyRequest,
}) => {
  return (
    <Modal.Dialog hideButton isOpen={isOpen} closeModal={closeModal}>
      <>
        <Modal.Header>Screen Share Request</Modal.Header>
        <Modal.Body>
          {studentName} has requested to share their screen
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end space-x-2">
            <Button onClick={approveRequest}>Allow</Button>
            <Button color={Color.Red} onClick={denyRequest}>
              Reject
            </Button>
          </div>
        </Modal.Footer>
      </>
    </Modal.Dialog>
  )
}

export default ScreenShareRequestedModal
