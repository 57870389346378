import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"

import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

import { Color, Size } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"

export type ConfirmSessionButtonProps = {
  id: string
  refetchQuery: (options: any) => void
  className?: string
}

const ConfirmSessionButton: React.FC<ConfirmSessionButtonProps> = ({
  id,
  refetchQuery,
  className = "",
}) => {
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const [mutationState, runMutation]: [any, RunMutation] = useMutation(
    confirmSessionMutation
  )

  const confirmSession = () => {
    runMutation({ id }).then(result => {
      const { failures } = result.data.confirmAirtutorsSession
      if (failures.length > 0) {
        setErrorMessage(failures[0].message)
        setOpen(true)
      } else {
        NotificationManager.success("The session has been confirmed")
        refetchQuery({ requestPolicy: "network-only" })
      }
    })
  }

  return (
    <>
      <Modal.Dialog hideButton isOpen={open} closeModal={() => setOpen(false)}>
        {closeModal => (
          <>
            <Modal.Header
              Icon={ExclamationCircleIcon}
              iconClassNames="text-red-500"
            >
              Confirmation Failed
            </Modal.Header>
            <Modal.Body>
              <p>{errorMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex justify-end">
                <Button onClick={closeModal}>Okay</Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal.Dialog>

      <Button
        size={Size.Small}
        color={Color.Green}
        className={className}
        onClick={confirmSession}
        disabled={mutationState.fetching}
      >
        Confirm
      </Button>
    </>
  )
}

const confirmSessionMutation = gql`
  mutation confirmSession($id: ID!) {
    confirmAirtutorsSession(id: $id) {
      failures {
        message
      }
    }
  }
`

export default ConfirmSessionButton
