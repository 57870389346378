import React, { useEffect } from "react"

import { useLocation } from "react-router"
import { gql, useQuery } from "urql"

import { AlertLevel } from "~tailwindui/types/enums"

import {
  AlertMessageBox,
  H3,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"

import LocalTime from "components/LocalTime"
import { path } from "components/Tutor/TutorRoutes"

import CalendarInfo from "./CalendarInfoModal"
import Schedule from "./Schedule"
import ToDo from "./ToDo"
import TutoringOpportunities from "./TutoringOpportunities"

export type TutorDashboardProps = Record<string, never>

const TutorDashboard: React.FC<TutorDashboardProps> = () => {
  const { search } = useLocation()
  const calendarModalOpen = new URLSearchParams(search).get(
    "calendar_instructions"
  )

  const [result, refetchQuery] = useQuery({
    query: tutorQuery,
    requestPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (!result.data) return

    const timerId = setTimeout(() => {
      refetchQuery({ requestPolicy: "network-only" })
    }, 15 * 60 * 1000)

    return () => clearTimeout(timerId)
  }, [result.data, refetchQuery])

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <div>
          {calendarModalOpen &&
            !viewer.dismissedCalendarInfo &&
            viewer.calendarId && (
              <CalendarInfo calendarId={viewer.calendarId} />
            )}

          {viewer.requiresNewSchedule && (
            <AlertMessageBox level={AlertLevel.Error}>
              Either you have no availabilities scheduled, or they will expire
              within the next 2 weeks. Please{" "}
              <Link to={path("directory.tutorSchedules")}>
                update or add new availabilities
              </Link>{" "}
              in order to be eligible to accept new sessions.
            </AlertMessageBox>
          )}

          <div className="mt-8 w-full md:grid md:grid-cols-5 md:gap-2">
            <div className="md:col-span-3">
              <ToDo viewer={viewer} />
              <TutoringOpportunities viewer={viewer} />
            </div>

            <div className="space-y-4 md:col-span-2">
              {viewer.upcomingCourseGroups.length > 0 && (
                <div>
                  <H3>Upcoming Course Sections</H3>
                  <ul className="ml-10 list-disc space-y-2">
                    {viewer.upcomingCourseGroups.map(studyGroup => (
                      <li key={studyGroup.id}>
                        <LocalTime
                          omitTime
                          timestamp={studyGroup.courseSection.startsOn}
                        />
                        :{" "}
                        <Link
                          to={path("studyGroupDetails", {
                            id: studyGroup.id,
                          })}
                        >
                          {" "}
                          {studyGroup.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div>
                <H3>Upcoming Sessions</H3>
                <Schedule sessions={viewer.scheduledSessions.data} />
              </div>
            </div>
          </div>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const tutorQuery = gql`
  query tutorQuery {
    viewer {
      id
      directory
      firstName
      lastName
      calendarId
      dismissedCalendarInfo
      requiresNewSchedule
      availableStudyGroupReplacements {
        id
      }
      unbilledSessions: sessions(
        filters: { past: true, billState: unbilled, statusState: scheduled }
      ) {
        data {
          id
        }
      }
      studyGroups(needingScheduling: true) {
        data {
          id
        }
      }
      openGenerationInvites {
        id
      }
      registerableCourseClusters {
        id
      }
      upcomingCourseGroups {
        id
        name
        courseSection {
          id
          startsOn
        }
      }
      scheduledSessions: sessions(
        filters: { future: true, statusState: scheduled, billState: unbilled }
        orderDirection: ascending
      ) {
        totalPages
        data {
          id
          directory
          airtutors
          type
          startsAt
          endsAt
          subject {
            name
          }
          studyGroup {
            id
            name
          }
          students {
            id
            fullName
          }
        }
      }
      requestedSessions: sessions(filters: { statusState: requested }) {
        data {
          id
        }
      }
    }
  }
`

export default TutorDashboard
