import React from "react"

export type NameProps = {
  tutor: {
    first_name: string
    directory: boolean
    airtutors: boolean
  }
}

const Name: React.FC<NameProps> = ({ tutor }) => {
  if (!tutor.directory) return null

  if (tutor.airtutors)
    return (
      <div className="my-1 w-full rounded bg-blue-200 p-3 font-medium text-blue-800">
        Directory-approved tutor
      </div>
    )
  else
    return (
      <div className="my-1 w-full rounded bg-purple-200 p-3 font-medium text-purple-800">
        Directory-only tutor
      </div>
    )
}

export default Name
