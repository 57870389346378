import React from "react"

import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import { tutorClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"

import AppBody from "./AppBody"
import TutorContext from "./TutorContext"
import { findRoute } from "./TutorRoutes"

const TutorApp: React.FC<Record<never, any>> = () => {
  const hiddenNavRoutes = [findRoute("sessionCloudroom").path]
  const hideNavRegexp = hiddenNavRoutes.map(
    str => new RegExp(str.replace(":id", "\\d+"))
  )

  const [result] = useQuery({ query, client })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <Provider value={client}>
          <TutorContext.Provider value={{ viewer }}>
            <Router basename="/tutor">
              <AppBody hideNavRegexp={hideNavRegexp} />
            </Router>
          </TutorContext.Provider>
        </Provider>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query ViewerQuery {
    viewer {
      id
      airtutors
      directory
      firstName
      fullName
      email
      createdAt
      mayCreateExpense
      calendarId
      requiresNewSchedule
      dismissedCalendarInfo
      stripeAccountId
      stripeBankAccountLinked
      avatar {
        thumbUrl
      }
    }
  }
`

export default TutorApp
