import React, { useState } from "react"

import { RouteComponentProps, withRouter } from "react-router-dom"
import { gql, useQuery } from "urql"

import {
  ChartBarIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid"

import { Color, LayoutDirection, ValueFormat } from "~tailwindui/types/enums"

import AlertMessageBox from "~tailwindui/Basics/AlertMessageBox"
import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"
import Chip from "~tailwindui/Chip"
import Table from "~tailwindui/Table/Table"

import { MatchParams } from "src/types/MatchParams"

import LocalTime from "components/LocalTime"
import DisplayableFormikForm from "components/OrganizationAdmin/components/DisplayableFormikForm"
import Form from "components/OrganizationAdmin/components/Form"
import Header from "components/OrganizationAdmin/components/Form/Header"
import { sessionPresenceIcon } from "components/OrganizationAdmin/components/presenceIcon"

const StudyGroupDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const [pastSessionsPage, setPastSessionsPage] = useState(1)
  const [futureSessionsPage, setFutureSessionsPage] = useState(1)

  const [result] = useQuery({
    query: studyGroupQuery,
    variables: { id },
  })
  const [pastSessionsResult] = useQuery({
    query: pastSessionsQuery,
    variables: { id, pastSessionsPage },
  })
  const [futureSessionsResult] = useQuery({
    query: futureSessionsQuery,
    variables: { id, futureSessionsPage },
  })

  return (
    <>
      <WithLoadingIndicator result={result}>
        {({ data: { studyGroup } }) =>
          studyGroup === null ? (
            <AlertMessageBox>
              You do not have access to this study group
            </AlertMessageBox>
          ) : (
            <>
              <Header title={studyGroup.name} />
              <div className="mx-auto w-full max-w-7xl grow-0 lg:flex xl:px-2">
                <Chip.List
                  heading=""
                  layoutDirection={LayoutDirection.Horizontal}
                >
                  <Chip.Item
                    label="Sessions Attended"
                    value={studyGroup.presenceCount}
                    valueFormat={ValueFormat.Number}
                    Icon={UserCircleIcon}
                    iconColor={Color.Green}
                  />
                  <Chip.Item
                    label="No Show Sessions"
                    value={studyGroup.noShowCount}
                    valueFormat={ValueFormat.Number}
                    Icon={XMarkIcon}
                    iconColor={Color.Red}
                  />
                  <Chip.Item
                    label="Attendance Rate"
                    value={studyGroup.attendanceRate}
                    valueFormat={ValueFormat.Percent}
                    Icon={ChartBarIcon}
                    iconColor={Color.Blue}
                  />
                </Chip.List>
              </div>
              <div className="flex flex-col"></div>

              <DisplayableFormikForm.Wrapper
                editable={false}
                initialValues={studyGroup}
                title=""
                displayData={studyGroup}
              >
                <Form.Section title="Group Information">
                  {studyGroup.school && (
                    <DisplayableFormikForm.TextInput
                      label="School"
                      name="school.name"
                    />
                  )}
                  {studyGroup.courseCluster && (
                    <DisplayableFormikForm.TextInput
                      label="Course"
                      name="courseCluster.name"
                    />
                  )}
                  {studyGroup.class && (
                    <DisplayableFormikForm.TextInput
                      label="Class"
                      name="class"
                    />
                  )}
                  <DisplayableFormikForm.TextInput
                    label="Meeting Times"
                    name="formattedAvailabilities"
                    displayValue={
                      <ul className="list-none">
                        {studyGroup.formattedAvailabilities.map(
                          availability => (
                            <li key={availability}>{availability}</li>
                          )
                        )}
                      </ul>
                    }
                  />
                  <DisplayableFormikForm.TextInput
                    label="Subjects"
                    name="subjects"
                  />
                  <DisplayableFormikForm.TextInput
                    label="Tutor"
                    name="tutor.fullName"
                  />
                  <DisplayableFormikForm.TextInput
                    label="Students"
                    name="students"
                    displayValue={
                      <ul className="list-none text-blue-500">
                        {studyGroup.students.map(student => (
                          <li key={student.id}>
                            <a
                              href={`/organization_admin/students/${student.id}`}
                            >
                              {student.fullName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    }
                  />
                </Form.Section>
              </DisplayableFormikForm.Wrapper>
            </>
          )
        }
      </WithLoadingIndicator>

      <WithLoadingIndicator result={futureSessionsResult}>
        {({ data: { studyGroup } }) =>
          studyGroup === null ? null : (
            <Table
              data={studyGroup.futureSessions}
              changePage={setFutureSessionsPage}
              title="Upcoming Sessions"
              headers={["Subject", "Starts At", "Ends At", "Status"]}
              valueMapper={session => [
                session.subject.name,
                <LocalTime key="startsAt" timestamp={session.startsAt} />,
                <LocalTime key="endsAt" timestamp={session.endsAt} />,
                <span
                  key="status"
                  className={
                    ["canceled", "declined"].includes(session.statusState)
                      ? "text-red-500"
                      : ""
                  }
                >
                  {session.statusState}
                </span>,
              ]}
              rowCallback={session =>
                (window.location.href = `/organization_admin/sessions/${session.id}`)
              }
            />
          )
        }
      </WithLoadingIndicator>

      <WithLoadingIndicator result={pastSessionsResult}>
        {({ data: { studyGroup } }) =>
          studyGroup === null ? null : (
            <Table
              data={studyGroup.pastSessions}
              changePage={setPastSessionsPage}
              title="Recent Sessions"
              headers={["Subject", "Date", "Duration", "Status", "Present?"]}
              valueMapper={session => [
                session.subject.name,
                <LocalTime key="startsAt" timestamp={session.startsAt} />,
                session.formattedActualDuration,
                <span
                  key="status"
                  className={
                    ["canceled", "declined"].includes(session.statusState)
                      ? "text-red-500"
                      : ""
                  }
                >
                  {session.statusState}
                </span>,
                sessionPresenceIcon(session),
              ]}
              rowCallback={session =>
                (window.location.href = `/organization_admin/sessions/${session.id}`)
              }
            />
          )
        }
      </WithLoadingIndicator>
    </>
  )
}

const sessionFields = gql`
  fragment SessionFields on AirtutorsSession {
    id
    startsAt
    endsAt
    statusState
    subject {
      id
      name
    }
  }
`

const studyGroupQuery = gql`
  query StudyGroupDetails($id: ID!) {
    studyGroup(id: $id) {
      id
      name
      presenceCount
      noShowCount
      attendanceRate
      formattedAvailabilities

      teacherSubject {
        id
        teacher {
          id
          fullName
        }
        subject {
          id
          name
        }
      }

      courseCluster {
        id
        name
      }

      school {
        id
        name
      }

      tutor {
        id
        fullName
      }

      subjects {
        id
        name
      }

      students {
        id
        fullName
      }
    }
  }
`

const pastSessionsQuery = gql`
  ${sessionFields}
  query pastStudentSessions($id: ID!, $pastSessionsPage: Int!) {
    studyGroup(id: $id) {
      pastSessions: airtutorsSessions(page: $pastSessionsPage, past: true) {
        totalPages
        currentPage
        data {
          ...SessionFields
          formattedActualDuration
          statusState
          billState
          noShow
        }
      }
    }
  }
`

const futureSessionsQuery = gql`
  ${sessionFields}
  query futureStudentSessions($id: ID!, $futureSessionsPage: Int!) {
    studyGroup(id: $id) {
      futureSessions: airtutorsSessions(
        page: $futureSessionsPage
        per: 5
        future: true
      ) {
        totalPages
        currentPage
        data {
          ...SessionFields
        }
      }
    }
  }
`

export default withRouter(StudyGroupDetails)
