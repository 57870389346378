import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildQuery, compress, useQuery } from "micro-graphql-react"

import { adminClient as client } from "src/graphql-config"
import ordered from "src/ordered"

import { ErrorMessage, requireInput } from "components/Forms/Formik"
import {
  AutocompleteField,
  Field,
  FormStatus,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import Results from "../Results"

const buildSubjectBucketOptions = tiers =>
  ordered(tiers, "order").map(tier => ({
    label: tier.gradeRange,
    options: ordered(tier.subjectBuckets, "name").map(subjectBucket => ({
      label: subjectBucket.name,
      value: subjectBucket.id,
    })),
  }))

const AddTutors = ({ addRecipients }) => {
  const formState = {
    tagList: [],
    subjectBucketIds: [],
    fullName: "",
  }

  const loadingState = useQuery(buildQuery(subjectBucketsQuery))
  const [tutors, setTutors] = useState()

  const performSearch = (values, actions) => {
    client
      .runQuery(tutorSearchQuery, values)
      .then(
        response => {
          setTutors(response.data.tutors)
        },
        () => {
          actions.setStatus("An error occurred")
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        actions.setStatus("An error occurred")
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Add Tutors"
      buttonClassName="btn btn-info"
      buttonText="Add Tutors"
      afterClose={() => {
        setTutors()
      }}
    >
      {({ closeModal }) => (
        <WithLoadingIndicator loadingState={loadingState}>
          {({ data }) =>
            tutors ? (
              <Results
                users={tutors}
                addRecipients={addRecipients}
                source="tutor"
                sourceOptions={[]}
                closeModal={closeModal}
              />
            ) : (
              <Formik
                initialValues={formState}
                onSubmit={performSearch}
                validate={requireInput}
              >
                <Form>
                  <div className="modal-body">
                    <AutocompleteField
                      name="tagList"
                      label="Tags"
                      api="/api/admins/tutors/autocomplete_tags"
                      isMulti
                      valueAttribute="label"
                    />
                    <SelectField
                      name="subjectBucketIds"
                      label={
                        <>
                          Subject Buckets
                          <br />
                          (will include tutors who are approved for ANY of the
                          provided buckets)
                        </>
                      }
                      options={buildSubjectBucketOptions(data.tiers)}
                      isMulti
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                    <Field name="fullName" label="Tutor Name" />
                  </div>

                  <div className="modal-footer flex-column">
                    <FormStatus />
                    <ErrorMessage name="base" />
                    <SubmitButton text="Search" />
                  </div>
                </Form>
              </Formik>
            )
          }
        </WithLoadingIndicator>
      )}
    </ModalWithProvidedBody>
  )
}

const subjectBucketsQuery = compress`
  query {
    tiers {
      id
      gradeRange
      order
      subjectBuckets {
        id
        name
      }
    }
  }
`

const tutorSearchQuery = compress`
  query($tagList: [String!], $subjectBucketIds: [ID!], $fullName: String) {
    tutors(tagList: $tagList, subjectBucketIds: $subjectBucketIds, fullName: $fullName) {
      id
      fullName
      lastName
      phoneNumber
    }
  }
`

export default AddTutors
