import React from "react"

import classNames from "src/classNames"
import { VoidReturn } from "src/types/VoidReturn"

import {
  FormFailures,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"

type ModalFooterProps = {
  submitText?: string
  cancelText?: string
  cancelAction: VoidReturn
  isDangerous?: boolean
  isCancelable?: boolean
}

const ModalFooter: React.FC<ModalFooterProps> = ({
  submitText,
  cancelAction,
  cancelText = "Cancel",
  isDangerous = false,
  isCancelable = true,
}) => {
  return (
    <div className="modal-footer flex-col">
      <div className="w-100">
        <FormFailures />
      </div>

      <div className="w-100 flex justify-between">
        {isCancelable && (
          <button
            type="button"
            className={classNames(
              "btn",
              isDangerous ? "solid blue btn-info" : "solid red btn-danger"
            )}
            onClick={cancelAction}
          >
            {cancelText}
          </button>
        )}
        <SubmitButton
          text={submitText}
          className={classNames(
            "btn",
            isDangerous ? "solid red btn-danger" : "solid blue btn-info"
          )}
        />
      </div>
    </div>
  )
}

export default ModalFooter
