import React from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"

import { MatchParams } from "src/types/MatchParams"

import DisplayableFormikForm from "components/OrganizationAdmin/components/DisplayableFormikForm"
import Form from "components/OrganizationAdmin/components/Form"

const validationSchema = Yup.object().shape({
  schoolIds: Yup.array().when("canOrganizationAdminAccessAllSchools", {
    is: (canAllSchools: boolean) => !canAllSchools,
    then: Yup.array().min(
      1,
      "Please choose the school(s) which this user can access."
    ),
  }),
})

const UserDetails: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const [result] = useQuery({
    query: organizationAdminQuery,
    variables: { id },
  })
  const [, updateOrganizationAdmin] = useMutation(
    updateOrganizationAdminMutation
  )

  const buildFormState = ({
    fullName,
    accessibleSchools,
    ...organizationAdmin
  }) => ({
    ...organizationAdmin,
    schoolIds: accessibleSchools.map(school => school.id),
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { organizationAdmin, viewer } }) => (
        <DisplayableFormikForm.Wrapper
          editable={
            viewer.id !== organizationAdmin.id &&
            viewer.canOrganizationAdminAccessUserManagement
          }
          title={organizationAdmin.fullName}
          displayData={organizationAdmin}
          initialValues={buildFormState(organizationAdmin)}
          mutation={{
            name: "updateOrganizationAdmin",
            run: updateOrganizationAdmin,
          }}
          convertFormValues={vals => ({ input: vals })}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <>
              <DisplayableFormikForm.DisplayOnly>
                <Form.Section title="User Information">
                  <DisplayableFormikForm.TextInput
                    label="First Name"
                    name="firstName"
                  />
                  <DisplayableFormikForm.TextInput
                    label="Last Name"
                    name="lastName"
                  />
                  <DisplayableFormikForm.TextInput
                    label="Username"
                    name="username"
                  />
                  <DisplayableFormikForm.TextInput label="Email" name="email" />
                  <DisplayableFormikForm.TextInput
                    label="Phone Number"
                    name="phoneNumber"
                  />
                </Form.Section>
              </DisplayableFormikForm.DisplayOnly>

              {viewer.canOrganizationAdminAccessUserManagement && (
                <Form.Section title="User Permissions">
                  <DisplayableFormikForm.ToggleInput
                    label="Financial Access"
                    name="canOrganizationAdminAccessFinances"
                    description="Allows this user to view financial data."
                  />
                  <DisplayableFormikForm.ToggleInput
                    label="Semesters Access"
                    name="canOrganizationAdminAccessSemesters"
                    description="Allows this user to create and edit your organization's academic semesters."
                  />
                  <DisplayableFormikForm.ToggleInput
                    label="User Management Access"
                    name="canOrganizationAdminAccessUserManagement"
                    description="Allows this user to create and edit other users."
                  />
                  <DisplayableFormikForm.ToggleInput
                    label="All Schools Access"
                    name="canOrganizationAdminAccessAllSchools"
                    description="Allows this user to view data for all schools."
                  />
                  {!values.canOrganizationAdminAccessAllSchools && (
                    <DisplayableFormikForm.MultiSelect
                      label="Permitted School(s)"
                      name="schoolIds"
                      displayValue={organizationAdmin.accessibleSchools}
                      description="The schools this user has access to."
                      options={viewer.organization.schools.map(
                        ({ id, name }) => ({
                          label: name,
                          value: id,
                        })
                      )}
                    />
                  )}
                </Form.Section>
              )}
            </>
          )}
        </DisplayableFormikForm.Wrapper>
      )}
    </WithLoadingIndicator>
  )
}

const organizationAdminQuery = gql`
  query organizationAdmin($id: ID!) {
    viewer {
      id
      canOrganizationAdminAccessUserManagement
      organization {
        primaryAdmin {
          id
        }
        schools {
          id
          name
        }
      }
    }
    organizationAdmin(id: $id) {
      id
      fullName
      firstName
      lastName
      username
      email
      phoneNumber
      canOrganizationAdminAccessFinances
      canOrganizationAdminAccessAllSchools
      canOrganizationAdminAccessSemesters
      canOrganizationAdminAccessUserManagement
      accessibleSchools {
        id
        name
      }
    }
  }
`

const updateOrganizationAdminMutation = gql`
  mutation ($input: OrganizationAdminInputObject!) {
    updateOrganizationAdmin(input: $input) {
      redirectTo
      organizationAdmin {
        id
      }
      failures {
        message
      }
    }
  }
`

export default UserDetails
