import React from "react"

import { AlertMessageBox } from "~tailwindui/Basics"

import { reportGraphQLError } from "src/airbrake-notifier"

import LoadingIndicator from "components/LoadingIndicator"

const UrqlLoadingIndicator = ({
  result,
  error = "An error occurred",
  heading = null,
  children,
}) => {
  if (result.fetching)
    return (
      <React.Fragment>
        {heading}
        <LoadingIndicator />
      </React.Fragment>
    )

  if (result.error) {
    reportGraphQLError({
      result,
      component: "UrqlLoadingIndicator",
    })
    return (
      <React.Fragment>
        {heading}
        <AlertMessageBox>
          {process.env.NODE_ENV === "development"
            ? result.error.message
            : error}
        </AlertMessageBox>
      </React.Fragment>
    )
  }

  if (result.data) {
    return (
      <React.Fragment>
        {heading}
        {typeof children === "function"
          ? children({ data: result.data })
          : children}
      </React.Fragment>
    )
  }

  return null
}

export default UrqlLoadingIndicator
