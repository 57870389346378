import React, { useEffect, useState } from "react"

import axios from "axios"
import { useMediaQuery } from "react-responsive"

import { css } from "@emotion/core"

import airbrake from "src/airbrake-notifier"

import RemoteAudioButton from "components/TwilioCloudRoom/AudioButton/Remote"
import RemoteLockButton from "components/TwilioCloudRoom/LockButton/Remote"
import RemoteStream from "components/TwilioCloudRoom/RemoteStream"
import RemoteVideoButton from "components/TwilioCloudRoom/VideoButton/Remote"

const RemoteParticipant = props => {
  const [userLoaded, setUserLoaded] = useState(false)
  const [userName, setUserName] = useState("")

  useEffect(() => {
    const loadStudent = () => {
      fetchUser(
        `/api/${props.api}/sessions/${props.id}/students/${props.user.id}`
      )
    }

    const loadTutor = () => {
      fetchUser(
        `/api/${props.api}/sessions/${props.id}/tutors/${props.user.id}`
      )
    }

    const fetchUser = apiEndpoint => {
      setUserLoaded(true)
      axios(apiEndpoint).then(
        response => {
          if (!props.qualityControl) setUserName(response.data.full_name)
        },
        error => {
          if (error.response?.status === 401) {
            window.location.reload()
          } else {
            airbrake.notify({
              error,
              name: "CloudRoom: Fetch User promise rejected",
            })
          }
        }
      )
    }

    const loadUser = () => {
      if (!props.id) return
      if (props.user.type === "student") loadStudent()
      if (props.user.type === "tutor") loadTutor()
      if (props.user.type === "admin") {
        setUserLoaded(true)
        setUserName("Admin")
      }
    }

    if (!userLoaded && props.user) loadUser()
  }, [props.api, props.id, props.user, props.qualityControl, userLoaded])

  const isMobileStudent =
    useMediaQuery({ query: "(max-width: 767px)" }) && props.api === "students"

  if (!props.user) return null

  const skipVideoStream =
    isMobileStudent && props.user.type !== "tutor" ? true : false

  let screenShareButton = null

  if (!props.studentScreenShareDisabled && props.user.videoTrack) {
    if (props.user.videoTrack.name === "screenshare") {
      screenShareButton = (
        <button
          css={css`
            display: inline-flex;
            align-items: center;
            justify-content: space-around;
            color: white;
            border: none;
            transition: all 0.5s;
            padding: 0.5rem;
            border-radius: 0.15rem;
            outline: none;
            background-color: rgba(236, 50, 85, 0.75);
            margin-left: 15px;
            line-height: 1em;
          `}
          onClick={() => {
            if (!props.user.id) {
              airbrake.notify({
                error: "Not submitting end_screen_share, no user.id is present",
                name: "TwilioCloudRoom/RemoteParticipant",
                params: {
                  props,
                },
              })
            } else {
              props.channel.perform("end_screen_share", {
                userId: props.user.id,
              })
            }
          }}
        >
          End Screen Share
        </button>
      )
    } else if (!props.compact) {
      screenShareButton = (
        <button
          css={css`
            display: inline-flex;
            align-items: center;
            justify-content: space-around;
            color: white;
            border: none;
            transition: all 0.5s;
            padding: 0.5rem;
            border-radius: 0.15rem;
            outline: none;
            background-color: rgba(53, 105, 192, 0.75);
            margin-left: 15px;
            line-height: 1em;
          `}
          onClick={() => {
            if (!props.user.id) {
              airbrake.notify({
                error:
                  "Not submitting screen_share_response, no user.id is present",
                name: "TwilioCloudRoom/RemoteParticipant",
                params: {
                  props,
                },
              })
            } else {
              props.channel.perform("screen_share_response", {
                approved: true,
                userId: props.user.id,
              })
            }
          }}
        >
          Request Screen Share
        </button>
      )
    }
  }

  return (
    <div
      css={css`
        ${props.compact && "position: relative;"}
      `}
    >
      {!skipVideoStream && (
        <RemoteStream
          track={props.user.videoTrack}
          key={props.user.videoTrack && props.user.videoTrack.name}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {!props.compact && (
              <span style={{ color: "#ddd", fontWeight: 100 }}>{userName}</span>
            )}
            {props.controlled && (
              <div
                css={css`
                  ${props.compact
                    ? `
                    position: absolute;
                    right: 3px;
                    bottom: -3px;
                    z-index: 10;
                    button {
                      display: flex;
                      margin-bottom: 6px;
                    }
                  `
                    : `
                    display: flex;
                  `}
                `}
              >
                <RemoteAudioButton
                  channel={props.channel}
                  userId={props.user.id}
                  muted={props.user.audioMuted}
                />
                {props.user.videoTrack &&
                  props.user.videoTrack.name !== "screenshare" && (
                    <RemoteVideoButton
                      channel={props.channel}
                      userId={props.user.id}
                      muted={props.user.videoMuted}
                    />
                  )}
                <RemoteLockButton
                  channel={props.channel}
                  userId={props.user.id}
                  muted={props.controlsLocked}
                />
                {screenShareButton}
              </div>
            )}
          </div>
        </RemoteStream>
      )}
      <div
        style={{
          display: "none",
        }}
      >
        <RemoteStream
          track={props.user.audioTrack}
          qualityControlMuted={
            !props.qualityControl ? false : props.qualityControlMuted
          }
        />
      </div>
    </div>
  )
}

export default RemoteParticipant
