import React from "react"

import { components } from "react-select"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.full_name}
    </components.SingleValue>
  )
}

const Search = props => {
  const { autocompletePath } = props

  return (
    <AutocompleteSelect
      api={autocompletePath}
      placeholder="Search Matched Users"
      onChange={selected => {
        if (!selected?.show_path) return
        window.location.href = selected.show_path
      }}
      components={{ SingleValue }}
      getOptionLabel={params => {
        return (
          <React.Fragment>
            <div>{params.full_name}</div>
            <div>
              Student should dial: {params.student_to_tutor_phone_number}
            </div>
            <div>Tutor should dial: {params.tutor_to_student_phone_number}</div>
          </React.Fragment>
        )
      }}
    />
  )
}

export default Search
