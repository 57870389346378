import React from "react"

import { ErrorMessage } from "components/Forms/Formik"
import { CheckboxField } from "components/Forms/Formik/hookComponents"
import LocalTime from "components/LocalTime"

const Availabilities = ({ availabilities }) => {
  return (
    <>
      <h3>Availabilities</h3>
      <CheckboxField
        isMulti
        name="availabilityIds"
        options={availabilities.map(availability => ({
          value: availability.id,
          label: (
            <>
              <LocalTime timestamp={availability.startsAt} format="dddd LT" />{" "}
              &mdash; <LocalTime timestamp={availability.endsAt} omitDate />
            </>
          ),
        }))}
      />
      <ErrorMessage name="availabilityIds" />
    </>
  )
}

export default Availabilities
