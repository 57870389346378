import React from "react"

import { RouteComponentProps } from "react-router-dom"

import { MatchParams } from "src/types/MatchParams"

import TailoredSectionReview from "components/TailoredSectionReview"

const TutorTailoredSectionReview: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match }) => {
  const { studentId, id: tailoredSectionId } = match.params
  return (
    <TailoredSectionReview
      studentId={studentId}
      tailoredSectionId={tailoredSectionId}
    />
  )
}

export default TutorTailoredSectionReview
