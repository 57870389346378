import React from "react"

import moment from "moment-timezone"

export type LocalTimeProps = {
  timestamp: Date | string
  format?: string
  omitTime?: boolean
  omitDate?: boolean
}

const LocalTime: React.FC<LocalTimeProps> = ({
  timestamp,
  format = "lll",
  omitTime,
  omitDate,
}) => {
  if (!timestamp) return null
  const formatString = omitTime ? "ll" : omitDate ? "h:mm A" : format
  return <>{moment(timestamp).tz(moment.tz.guess()).format(formatString)}</>
}

const zoneName = moment().tz(moment.tz.guess()).format("z")

export default LocalTime
export { zoneName }
