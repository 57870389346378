import React, { useRef } from "react"

import moment from "moment-timezone"
import Select from "react-select"
import ReactTable from "react-table"

import { billOptions, statusOptions } from "src/enums"
import ordered from "src/ordered"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"

const DirectorySessions = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/sessions",
    additionalProps: { directory: true },
  })
  const table = useRef(null)

  const columns = [
    {
      id: "subject",
      Header: "Subject",
      accessor: "subject.name",
      sortable: false,
      filterable: false,
    },
    {
      id: "starts_at",
      Header: "Date",
      accessor: "starts_at",
      Cell: props => (
        <LocalTime timestamp={props.value} format="M/D/YY h:mm A" />
      ),
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ startsAt: null, endsAt: null })
              return
            }

            const startMoment = moment(startDate.toDate()).startOf("day")
            const endMoment = moment(endDate?.toDate() || startMoment).endOf(
              "day"
            )

            onChange({
              startsAt: startMoment.format(),
              endsAt: endMoment.format(),
            })
          }}
        />
      ),
    },
    {
      id: "tutor",
      Header: "Tutor",
      accessor: "tutor",
      Cell: props =>
        props.value.show_path && (
          <a href={props.value.show_path}>{props.value.full_name}</a>
        ),
      Filter: ({ onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/tutors/autocomplete_full_name"
            searchOptions="include_teachers=true"
          />
        )
      },
    },
    {
      id: "students",
      Header: "Students",
      accessor: "students",
      sortable: false,
      filterable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(props.value, "last_name").map(student => (
            <li key={student.id}>
              <a href={student.show_path}>{student.full_name}</a>
            </li>
          ))}
        </ul>
      ),
    },
    {
      id: "billed_duration",
      Header: "Billed Duration",
      accessor: "formatted_billed_duration",
      filterable: false,
    },
    {
      id: "status_state",
      Header: "Scheduled",
      accessor: "status_state",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={eventArray =>
              onChange({ status_states: eventArray.map(event => event.value) })
            }
            setValue={filter ? filter.value : "all"}
            options={statusOptions}
            isMulti={true}
            isClearable
          />
        )
      },
    },
    {
      id: "bill_state",
      Header: "Billed",
      accessor: "bill_state",
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={eventArray =>
              onChange({ bill_states: eventArray.map(event => event.value) })
            }
            setValue={filter ? filter.value : "all"}
            options={billOptions}
            isMulti={true}
            isClearable
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      ref={table}
      manual
      filterable
    />
  )
}

export default DirectorySessions
