import React, { useState } from "react"

import { AlertMessageBox } from "~tailwindui/Basics"

import { useMutation } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"

const TagSelect = ({
  api,
  tagList,
  createMutation,
  addMutation,
  removeMutation,
  recordId,
  modelKey,
  recordKey = "userId",
  ...options
}) => {
  const [errors, setErrors] = useState([])
  const [, create] = useMutation(createMutation)
  const [, add] = useMutation(addMutation)
  const [, remove] = useMutation(removeMutation)

  const handleChange = (selectedList, action) => {
    if (action.action === "create-option") {
      handleCreate(action.option)
    } else if (action.action === "select-option") {
      handleAdd(action.option)
    } else if (action.action === "remove-value") {
      handleRemove(action.removedValue)
    }
  }

  const handleCreate = selected => {
    if (!selected?.value) return

    create({ name: selected.value, [recordKey]: recordId })
      .then(
        result => {
          const { failures } = Object.values(result.data)[0]
          if (failures) {
            setErrors(failures)
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
  }

  const handleAdd = selected => {
    if (!selected?.value) return

    add({ [modelKey]: selected.value, [recordKey]: recordId })
      .then(
        result => {
          const { failures } = Object.values(result.data)[0]
          if (failures) {
            setErrors(failures)
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
  }

  const handleRemove = selected => {
    if (!selected?.value) return

    remove({
      [modelKey]: selected.value,
      [recordKey]: recordId,
    })
      .then(
        result => {
          const { failures } = Object.values(result.data)[0]
          if (failures) {
            setErrors(failures)
          }
        },
        () => setErrors([{ message: "Something went wrong" }])
      )
      .catch(() => setErrors([{ message: "Something went wrong" }]))
  }

  return (
    <>
      <AutocompleteSelect
        api={api}
        isMulti
        creatable
        isClearable={false}
        value={tagList}
        onChange={handleChange}
        {...options}
      />

      <div className="mt-3">
        {errors.length > 0 && (
          <AlertMessageBox>
            <div className="flex list-disc flex-col space-y-1 pl-5">
              {errors.map(error => (
                <li key={error.message}>{error.message}</li>
              ))}
            </div>
          </AlertMessageBox>
        )}
      </div>
    </>
  )
}

export default TagSelect
