import React from "react"

import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import titlecase from "src/titlecase"

import LocalTime from "components/LocalTime"

import AcceptReplacementRequest from "../Dashboard/AcceptReplacementRequest"

export type AvailableStudyGroupReplacementsProps = Record<never, any>

const AvailableStudyGroupReplacements: React.FC<
  AvailableStudyGroupReplacementsProps
> = () => {
  const [result] = useQuery({
    query: availableStudyGroupReplacementsQuery,
  })

  if (
    result.data &&
    result.data.viewer.availableStudyGroupReplacements.length === 0
  )
    return (
      <span className="mb-8 text-lg italic">
        No study group replacements are available at this time.
      </span>
    )

  return (
    <div>
      <WithLoadingIndicator result={result}>
        {({ data: { viewer } }) => (
          <Table
            title="Available Study Group Replacements"
            data={viewer.availableStudyGroupReplacements}
            headers={[
              "Study Group",
              "Date",
              "Subject",
              "Foreign Language",
              "Session Times",
              "Actions",
            ]}
            valueMapper={replacement => [
              replacement.studyGroup.name,
              <div key={replacement.id}>
                <LocalTime timestamp={replacement.startsAt} omitTime />
                {replacement.courseSection && (
                  <span>
                    {" "}
                    -{" "}
                    <LocalTime
                      timestamp={replacement.courseSection?.endsOn}
                      omitTime
                    />
                  </span>
                )}
              </div>,
              replacement.studyGroup.subjects
                .map(subject => subject.name)
                .join(", "),
              replacement.foreignLanguage?.name,
              <ul key={replacement.id}>
                {replacement.studyGroup.allAvailabilities.map(availability => (
                  <li key={availability.id}>
                    {titlecase(availability.weekday)}{" "}
                    <LocalTime omitDate timestamp={availability.startsAt} /> -{" "}
                    <LocalTime omitDate timestamp={availability.endsAt} />
                  </li>
                ))}
              </ul>,
              <AcceptReplacementRequest
                key={replacement.id}
                studyGroupTutorReplacementId={replacement.id}
              />,
            ]}
          />
        )}
      </WithLoadingIndicator>
    </div>
  )
}

const availableStudyGroupReplacementsQuery = gql`
  query AvailableStudyGroupReplacementsQuery {
    viewer {
      availableStudyGroupReplacements {
        id
        startsAt
        studyGroup {
          id
          name
          subjects {
            id
            name
          }
          courseSection {
            id
            endsOn
          }
          foreignLanguage {
            id
            name
          }
          allAvailabilities {
            id
            startsAt
            endsAt
            weekday
          }
        }
      }
    }
  }
`

export default AvailableStudyGroupReplacements
