import React, { ReactNode } from "react"

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid"

import classNames from "src/classNames"
import ordered from "src/ordered"

export type DescriptionItemProps = {
  label: string
  value: string | number | boolean | JSX.Element | any[]
  description?: string | ReactNode
}

const DescriptionItem: React.FC<DescriptionItemProps> = ({
  label,
  value,
  description = "",
}) => {
  if ([undefined, null].includes(value)) return null

  const formatValue = value => {
    if (value === true)
      return (
        <CheckCircleIcon
          className="h-5 w-5 text-emerald-600"
          aria-hidden="true"
        />
      )
    if (value === false)
      return <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
    if (Array.isArray(value)) {
      return ordered(value.map(val => (val?.name ? val.name : val))).join(", ")
    } else return value
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-1">
      <label
        className={classNames(
          "text-md block font-medium leading-6 text-gray-900"
        )}
      >
        {label}
      </label>

      <div
        className={classNames(
          "text-md ml-2 leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
        )}
      >
        {formatValue(value)}
      </div>
      <div className="flex h-full items-center">
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </div>
  )
}

export default DescriptionItem
