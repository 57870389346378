import React, { useContext } from "react"

import { H2 } from "~tailwindui/Basics"

import TutorContext from "components/Tutor/TutorContext"

import DirectoryInfo from "./DirectoryInfo"
import OnboardingForm from "./OnboardingForm"

export type WelcomeProps = Record<string, never>

const Welcome: React.FC<WelcomeProps> = () => {
  const { viewer } = useContext(TutorContext)

  return (
    <>
      <H2>Welcome to the Tutor Directory</H2>

      <div className="mb-10 max-w-2xl py-1">
        {viewer.directory ? <DirectoryInfo /> : <OnboardingForm />}
      </div>
    </>
  )
}

export default Welcome
