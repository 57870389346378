import React from "react"

import { useFormikContext } from "formik"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox } from "~tailwindui/Basics"

const Failures = () => {
  const { status } = useFormikContext()
  return Array.isArray(status) ? (
    <AlertMessageBox level={AlertLevel.Error}>
      <div className="flex list-disc flex-col space-y-1 pl-5">
        {status.map(({ message }) => (
          <span key={message} dangerouslySetInnerHTML={{ __html: message }} />
        ))}
      </div>
    </AlertMessageBox>
  ) : null
}

export default Failures
