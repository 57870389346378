import React, { useRef, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { useField, useFormikContext } from "formik"
import DatePicker, { DateObject } from "react-multi-date-picker"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox } from "~tailwindui/Basics"

import emptyFunction from "src/emptyFunction"

import TextInput from "components/OrganizationAdmin/components/Form/TextInput"

import CloseButton from "./DateInputCloseButton"

export type DateInputProps = {
  name: string
  label: string
  displayWarnings: boolean
  minDate?: DateObject
  afterChange?: (date: Dayjs) => void
}

const DateInput: React.FC<DateInputProps> = ({
  name,
  label,
  displayWarnings,
  minDate,
  afterChange,
}) => {
  const [datePickerClosed, setDatePickerClosed] = useState(true)
  const [warning, setWarning] = useState<string | null>()
  const datePicker = useRef() as any
  const { setFieldValue } = useFormikContext()
  const [field] = useField({ name })

  const openCalendar = () => {
    datePicker.current.openCalendar()
  }

  const updateWarnings = date => {
    if (displayWarnings) {
      if (date.isBefore(dayjs()))
        setWarning("This date is in the past. Is that what you intended?")
      else if (date.diff(dayjs(), "hours") < 48)
        setWarning(
          "This date is less than 2 days away. Please choose a later date if possible."
        )
      else setWarning(null)
    }
  }

  return (
    <div>
      <div className="relative flex w-48">
        <TextInput
          name={`${name}-display`}
          label={label}
          value={(field.value && dayjs(field.value).format("MM/DD/YYYY")) || ""}
          onClick={openCalendar}
          onChange={emptyFunction}
        />
        <div className="absolute left-0 top-14 z-10 origin-top-left">
          <DatePicker
            ref={datePicker}
            format="MM/DD/YYYY"
            onChange={(date: DateObject) => {
              const dayjsDate = dayjs(date.toString())
              updateWarnings(dayjsDate)
              setFieldValue(name, dayjsDate)
              afterChange(dayjsDate)
            }}
            minDate={minDate}
            inputClass="hidden"
            editable={false}
            arrow={false}
            onOpen={() => setDatePickerClosed(false)}
            onClose={() => datePickerClosed}
            plugins={[
              <CloseButton
                key="close"
                position="bottom"
                setClosed={setDatePickerClosed}
              />,
            ]}
          />
        </div>
      </div>

      {displayWarnings && warning && (
        <AlertMessageBox className="w-60" level={AlertLevel.Warning}>
          {warning}
        </AlertMessageBox>
      )}
    </div>
  )
}

export default DateInput
