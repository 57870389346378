import React from "react"

import Formik from "components/OrganizationAdmin/components/DisplayableFormikForm"

export type PasswordInputProps = {
  label?: string
  name?: string
  description?: string
  subfield?: boolean
}

const PasswordInput: React.FC<
  PasswordInputProps & React.HTMLProps<HTMLInputElement>
> = ({ name = "password", label = "Password", ...props }) => {
  return (
    <Formik.TextInput
      label={label}
      name={name}
      type="password"
      autoComplete=""
      {...props}
    />
  )
}

export default PasswordInput
