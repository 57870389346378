import React from "react"

import { useField } from "formik"

import { ExclamationCircleIcon } from "@heroicons/react/20/solid"

export type ErrorProps = {
  name: string
}

const Error: React.FC<ErrorProps> = ({ name }) => {
  const [_field, meta] = useField({ name })
  const { error } = meta
  return error ? (
    <div className="flex py-1 text-sm">
      <ExclamationCircleIcon
        className="inline-block h-5 w-5 pr-1 text-red-500"
        aria-hidden="true"
      />
      <p className="text-red-600">{error}</p>
    </div>
  ) : null
}

export default Error
