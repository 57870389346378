import React from "react"

import {
  faCheck,
  faFrown,
  faMeh,
  faMinus,
  faSmile,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { H3 } from "~tailwindui/Basics"

import classNames from "src/classNames"
import ordered from "src/ordered"

import LocalTime from "components/LocalTime"

const LessonPlan = ({ lessonPlanAssignment }) => {
  const { lessonPlan } = lessonPlanAssignment

  const RatingIcon = ({ value }) => {
    let icon
    const iconClassNames = []
    if (value === "weak") {
      icon = faFrown
      iconClassNames.push("text-danger red-text")
    }
    if (value === "moderate") {
      icon = faMeh
      iconClassNames.push("text-warning orange-text")
    }
    if (value === "strong") {
      icon = faSmile
      iconClassNames.push("text-success green-text")
    }

    return (
      <FontAwesomeIcon
        icon={icon}
        className={classNames(iconClassNames.join(" "), "font-4xl")}
      />
    )
  }

  return (
    <div>
      <H3>{lessonPlan.name}</H3>
      <ol className="inline-block text-left">
        {lessonPlan.lessonTopics.map(lessonTopic => (
          <React.Fragment key={lessonTopic.id}>
            <li className="list-upper-alpha">
              <h2 className="font-semibol ml-3">{lessonTopic.name}</h2>
              <ol>
                {lessonTopic.lessonResources.map(lessonResource => (
                  <li
                    key={lessonResource.id}
                    className="ml-16 mb-3 list-decimal pl-3"
                  >
                    {lessonResource.completed ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success green-text fa-fw mr-3"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="text-warning orange-text fa-fw mr-3"
                      />
                    )}
                    <h3 className="d-inline-block m-0">
                      {lessonResource.name}
                    </h3>
                    {lessonResource.current && (
                      <button className="btn btn-sm btn-success solid small green ml-3">
                        Current
                      </button>
                    )}
                    <ul className="list-none">
                      {lessonResource.lessonResourceCompletions &&
                        ordered(
                          lessonResource.lessonResourceCompletions,
                          "session",
                          "startsAt"
                        ).map(completion => (
                          <li key={completion.id}>
                            <a href={completion.session.showPath}>
                              Session with {completion.session.tutor?.fullName}{" "}
                              on{" "}
                              <LocalTime
                                timestamp={completion.session.startsAt}
                                omitTime
                              />
                            </a>
                            <ul className="list-none">
                              {completion.studentMastery && (
                                <li className="mb-4 flex items-center justify-between">
                                  <span>Student</span>
                                  <RatingIcon
                                    value={completion.studentMastery}
                                  />
                                </li>
                              )}
                              {completion.studentTopicMasteryStart && (
                                <li className="mb-4 flex items-center justify-between">
                                  <span>Student Chapter Start</span>
                                  <RatingIcon
                                    value={completion.studentTopicMasteryStart}
                                  />
                                </li>
                              )}
                              {completion.studentTopicMasteryEnd && (
                                <li className="mb-4 flex items-center justify-between">
                                  <span>Student Chapter End</span>
                                  <RatingIcon
                                    value={completion.studentTopicMasteryEnd}
                                  />
                                </li>
                              )}
                              {completion.tutorMastery && (
                                <li className="mb-4 flex items-center justify-between">
                                  <span>Tutor</span>
                                  <RatingIcon value={completion.tutorMastery} />
                                </li>
                              )}
                              {completion.tutorTopicMastery && (
                                <li className="mb-4 flex items-center justify-between">
                                  <span>Tutor Chapter</span>
                                  <RatingIcon
                                    value={completion.tutorTopicMastery}
                                  />
                                </li>
                              )}
                            </ul>
                            <hr />
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
              </ol>
            </li>
          </React.Fragment>
        ))}
      </ol>
    </div>
  )
}

export default LessonPlan
