import React from "react"

import { FieldArray, useField } from "formik"

import { Button } from "~tailwindui/Basics"
import { Error, FileInput } from "~tailwindui/Form"

export type ReceiptsInputProps = Record<never, any>

const ReceiptsInput: React.FC<ReceiptsInputProps> = () => {
  const [field] = useField("receipts")
  const [expenseType] = useField("expenseType")

  if (expenseType.value === "intro_session") return null
  return (
    <FieldArray name="receipts">
      {arrayHelpers => (
        <>
          {field.value.map((_receipt, index) => (
            <FileInput
              key={index}
              name={`receipts[${index}]`}
              hideErrors
              label="Receipt"
            />
          ))}

          <Button
            outline
            className="mb-3"
            onClick={() => arrayHelpers.push({})}
          >
            Add Receipt
          </Button>

          <Error name="receipts" />
        </>
      )}
    </FieldArray>
  )
}

export default ReceiptsInput
