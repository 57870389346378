import React, { useRef, useState } from "react"

import { useField, useFormikContext } from "formik"
import { gql, useQuery } from "urql"

import { Combobox } from "@headlessui/react"
import { ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { XMarkIcon } from "@heroicons/react/24/outline"

import { SelectOption } from "~tailwindui/types/SelectOption"

import { ErrorIcon, WithLoadingIndicator } from "~tailwindui/Basics"

import classNames from "src/classNames"

export type AutocompleteSelectProps = {
  name: string
  label: string
  resource: string
  column?: string
  defaultValue?: Selected
  multi?: boolean
  clearable?: boolean
  subfield?: boolean
  className?: string
}

type Selected = SelectOption[] | SelectOption | undefined

const AutocompleteSelect: React.FC<AutocompleteSelectProps> = ({
  name,
  label,
  resource,
  column = "name",
  defaultValue,
  multi = false,
  clearable = false,
  subfield = false,
  className = "",
}) => {
  const [queryString, setQueryString] = useState("")
  const inputRef = useRef<HTMLInputElement>()

  const [result] = useQuery({
    query: autocompleteQuery,
    variables: { resource, column, match: queryString },
  })

  const [field, meta] = useField({ name })
  const { setFieldValue } = useFormikContext() as any
  const error = meta.error ? meta.error : ""

  const [selected, setSelected] = useState<Selected>(
    defaultValue || multi ? [] : undefined
  )

  const onChange = selected => {
    setSelected(selected)
    setFieldValue(
      name,
      multi
        ? (selected as SelectOption[]).map(selected => selected?.value)
        : selected?.value
    )
    if (!multi) setQueryString(selected?.label || "")
  }

  const removeItem = (optionToRemove: SelectOption) => {
    if (multi) {
      const newOpts = (selected as SelectOption[]).filter(
        opt => opt.value !== optionToRemove.value
      )
      onChange(newOpts)
    } else onChange(undefined)
  }

  return (
    <div className={classNames("mb-3", subfield && "ml-8")}>
      <Combobox
        as="div"
        multiple={multi || undefined}
        value={selected as any}
        onChange={onChange}
      >
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
        >
          {label}
        </label>

        {multi && (
          <div className="mb-1 flex flex-auto flex-wrap">
            {(selected as SelectOption[]).map(option => (
              <div
                key={option.value}
                className="m-[1px] mb-1 flex items-center justify-center rounded border border-sky-300 bg-sky-100 py-[.2rem] px-1 font-medium text-sky-700 "
              >
                <div className="max-w-full flex-initial text-xs font-normal leading-none">
                  {option.label}
                </div>
                <div
                  className="flex flex-auto flex-row-reverse"
                  onClick={() => removeItem(option)}
                >
                  <XMarkIcon
                    className="h-4 w-4 cursor-pointer hover:text-sky-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={classNames("relative min-w-[160px]", className)}>
          <Combobox.Button>
            {({ open }) => (
              <div className="relative">
                <Combobox.Input
                  ref={inputRef}
                  className="w-full truncate rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  onChange={event => {
                    if (event.target.value) setQueryString(event.target.value)
                  }}
                  onClick={e => {
                    if (open) e.stopPropagation()
                    e.target.value = queryString
                  }}
                  key={selected?.toString()}
                  displayValue={() =>
                    multi ? "" : (selected as SelectOption)?.label
                  }
                  value={queryString}
                  placeholder="Search..."
                />
                <span className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  {error ? (
                    <ErrorIcon />
                  ) : clearable &&
                    (multi ? queryString.length > 0 : selected) ? (
                    <span
                      onClick={e => {
                        setQueryString("")
                        inputRef.current.value = ""
                        e.stopPropagation()
                      }}
                    >
                      <XMarkIcon
                        className="h-5 w-5 cursor-pointer text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </div>
            )}
          </Combobox.Button>

          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <WithLoadingIndicator result={result}>
              {({ data: { options } }) =>
                options.length === 0 ? (
                  <span className="py-2 px-3 italic">No results found</span>
                ) : (
                  options.map(option => {
                    const isSelected =
                      (multi && field.value.includes(option.value)) ||
                      (!multi && field.value === option.value)
                    return (
                      <Combobox.Option
                        key={option.value}
                        value={option}
                        className={({ active }) =>
                          classNames(
                            "relative cursor-default select-none py-2 px-3",
                            isSelected
                              ? "bg-sky-600 text-white"
                              : active
                              ? "bg-sky-400 text-white"
                              : "text-gray-900"
                          )
                        }
                      >
                        <span className="block pr-4">{option.label}</span>
                      </Combobox.Option>
                    )
                  })
                )
              }
            </WithLoadingIndicator>
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  )
}

const autocompleteQuery = gql`
  query AutocompleteQuery(
    $resource: String!
    $column: AutocompleteColumnEnum!
    $match: String!
  ) {
    options: autocomplete(resource: $resource, column: $column, match: $match) {
      label
      value
    }
  }
`

export default AutocompleteSelect
