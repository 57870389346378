import React from "react"

import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"

import { Tooltip } from "@chakra-ui/core"
import {
  ArrowPathIcon,
  CheckIcon,
  ClockIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid"

import { Color, Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Button, Link } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import { VoidReturn } from "src/types/VoidReturn"

type Tier = {
  name: string
}

export type TutorSubjectsTableProps = {
  tiers: Tier[]
  approvalType: "directory" | "airtutors"
  refetchQuery: VoidReturn
}

const TutorSubjectsTable: React.FC<TutorSubjectsTableProps> = ({
  tiers,
  approvalType,
  refetchQuery,
}) => {
  const [, runRemoveMutation] = useMutation(removeTutorSubject)
  const [, runResubmitMutation] = useMutation(resubmitTutorSubject)

  const handleRemove = subjectBucketId => {
    runRemoveMutation({ subjectBucketId, approvalType })
      .then(
        response => {
          const { failures } = response.data.removeTutorSubject
          if (failures.length > 0)
            NotificationManager.error(failures[0].message)
          else {
            refetchQuery()
            NotificationManager.success("Subject removed")
          }
        },
        () => NotificationManager.error("Something went wrong")
      )
      .catch(() => NotificationManager.error("Something went wrong"))
  }

  const handleResubmit = subjectBucketId => {
    runResubmitMutation({ subjectBucketId, approvalType })
      .then(
        response => {
          const { failures } = response.data.resubmitTutorSubject
          if (failures.length > 0)
            NotificationManager.error(failures[0].message)
          else {
            refetchQuery()
            NotificationManager.success("Subject re-submitted for approval")
          }
        },
        () => NotificationManager.error("Something went wrong")
      )
      .catch(() => NotificationManager.error("Something went wrong"))
  }

  const statusIcon = approvalState => {
    switch (approvalState) {
      case "submitted":
        return <ClockIcon className="h-5 text-orange-400" />
      case "approved":
        return <CheckIcon className="h-5 text-emerald-400" />
      case "rejected":
        return <XMarkIcon className="h-5 text-red-400" />
    }
  }

  const tableData = tiers => {
    const rows = []
    tiers.forEach(tier => {
      rows.push({ type: "tier", id: tier.id, gradeRange: tier.gradeRange })
      tier.subjectBuckets.forEach(subjectBucket => {
        rows.push({
          type: "subjectBucket",
          id: subjectBucket.id,
          name: subjectBucket.name,
          approvalState: subjectBucket.approvalState,
          gradeRange: tier.gradeRange,
        })
      })
    })
    return rows
  }

  return (
    <>
      <Table
        data={tableData(tiers)}
        headers={["Subject", "Grade Range", "Status", null]}
        valueMapper={row =>
          row.type === "tier"
            ? [
                <Link
                  key={row.id}
                  to={path(
                    approvalType === "directory"
                      ? "directory.tutorSubjectsEdit"
                      : "tutorSubjectsEdit",
                    { id: row.id }
                  )}
                  button
                  size={Size.Small}
                >
                  Edit {row.gradeRange} Subjects
                </Link>,
                null,
                null,
                null,
              ]
            : [
                <span
                  key={row.id}
                  className={
                    row.approvalState === "submitted"
                      ? "italic text-gray-400"
                      : row.approvalState === "rejected"
                      ? "text-red-500"
                      : ""
                  }
                >
                  {row.name}
                </span>,
                row.gradeRange,
                <Tooltip
                  key={row.id}
                  label={row.approvalState}
                  className="bg-gray-200 p-1"
                  aria-label={row.approvalState}
                >
                  <div>{statusIcon(row.approvalState)}</div>
                </Tooltip>,
                <>
                  <div key={row.id} className="flex space-x-2">
                    <Button
                      soft
                      color={Color.Red}
                      onClick={() => handleRemove(row.id)}
                    >
                      <Tooltip
                        label="Remove"
                        className="bg-gray-200 p-1"
                        aria-label="Remove"
                      >
                        <TrashIcon className="h-5 text-red-400" />
                      </Tooltip>
                    </Button>

                    {row.approvalState === "rejected" && (
                      <Button soft onClick={() => handleResubmit(row.id)}>
                        <Tooltip
                          label="Re-Submit"
                          className="bg-gray-200 p-1"
                          aria-label="Re-Submit"
                        >
                          <ArrowPathIcon className="h-5 text-sky-400" />
                        </Tooltip>
                      </Button>
                    )}
                  </div>
                </>,
              ]
        }
      />
    </>
  )
}

const removeTutorSubject = gql`
  mutation RemoveTutorSubject(
    $subjectBucketId: ID!
    $approvalType: ApprovalTypeEnum!
  ) {
    removeTutorSubject(
      subjectBucketId: $subjectBucketId
      approvalType: $approvalType
    ) {
      failures {
        message
      }
    }
  }
`

const resubmitTutorSubject = gql`
  mutation ResubmitTutorSubject(
    $subjectBucketId: ID!
    $approvalType: ApprovalTypeEnum!
  ) {
    resubmitTutorSubject(
      subjectBucketId: $subjectBucketId
      approvalType: $approvalType
    ) {
      failures {
        message
      }
    }
  }
`

export default TutorSubjectsTable
