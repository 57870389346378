import React, { useEffect, useMemo, useState } from "react"

import EventEmitter from "src/EventEmitter"

import { gql, useClient, useMutation } from "hooks/urql"
import useWebsocket from "hooks/useWebsocket"

import { Loading } from "components/Icons"

export type RequestProxyNumberProps = {
  studentId: string
  tutorId: string
}

const RequestProxyNumber: React.FC<RequestProxyNumberProps> = ({
  studentId,
  tutorId,
}) => {
  const [mutationResult, runMutation]: any[] = useMutation(
    requestNumberMutation,
    useClient()
  )

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const channelProps = useMemo(() => {
    return {
      channelName: "TutorChannel",
      channelProps: { id: tutorId, studentId: studentId },
    }
  }, [studentId, tutorId])

  useWebsocket(channelProps)

  useEffect(() => {
    EventEmitter.subscribe("numberCreated", () => {
      window.location.reload()
    })
  }, [])

  const handleClick = () => {
    runMutation({ studentId, tutorId })
      .then(
        response => {
          const { failures } = response.data.requestProxyNumber
          if (failures.length > 0) setErrorMessage(failures[0].message)
          else setLoading(true)
        },
        err => setErrorMessage("Something went wrong")
      )
      .catch(err => setErrorMessage("Something went wrong"))
  }

  return (
    <>
      {errorMessage && <div className="alert mb-3">{errorMessage}</div>}
      <button
        onClick={handleClick}
        className="btn small orange d-flex align-items-center"
        disabled={loading || mutationResult.fetching}
      >
        <span>Request Number</span>
        {(loading || mutationResult.fetching) && <Loading className="ml-3" />}
      </button>
    </>
  )
}

const requestNumberMutation = gql`
  mutation ($studentId: ID!, $tutorId: ID!) {
    requestProxyNumber(studentId: $studentId, tutorId: $tutorId) {
      failures {
        message
      }
    }
  }
`

export default RequestProxyNumber
