import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { pipe, subscribe } from "wonka"

import { Spinner } from "~tailwindui/Basics"

import { gql, useClient } from "hooks/urql"

import LocalTime from "components/LocalTime"

export type TwilioParticipationProps = {
  sessionId: string
  sessionParticipants: {
    id: string
    startedAt: string
    endedAt: string
    formattedDuration: string
    roomSid: string
    user: {
      id: string
      fullName: string
    }
  }[]
  sessionParticipantSummaries: {
    name: string
    formattedDuration: string
  }[]
}

const TwilioParticipation: React.FC<TwilioParticipationProps> = ({
  sessionId,
  sessionParticipants,
  sessionParticipantSummaries,
}) => {
  const client = useClient()

  const [loading, setLoading] = useState(false)

  const refreshLogEntries = () => {
    setLoading(true)
    const { unsubscribe } = pipe(
      client.subscription(refreshParticipantsSubscription, {
        sessionId,
      }),
      subscribe(result => {
        // triggered by backend exception
        if (result.error) {
          NotificationManager.error("Cannot refresh participants")
          setLoading(false)
          return
        }

        const { status, failures } = result.data.refreshSessionParticipants

        if (status === "done") {
          NotificationManager.success("Session participants refreshed")
          setLoading(false)
          unsubscribe()
        } else if (status === "enqueued") {
          NotificationManager.info("Fetching participant logs from Twilio...")
        }

        if (failures.length > 0) {
          NotificationManager.error(`Cannot refresh: ${failures[0].message}`)
          setLoading(false)

          unsubscribe()
        }
      })
    )
  }

  return (
    <>
      <hr />
      <h5>Twilio Logs</h5>
      <div>
        <button
          type="button"
          disabled={loading}
          onClick={refreshLogEntries}
          className="btn btn-info"
        >
          <div className="flex space-x-2">
            <span>Refresh Log Entries</span>
            {loading && <Spinner />}
          </div>
        </button>
      </div>

      <h5>Summary</h5>
      <ul className="list-unstyled">
        {sessionParticipantSummaries.map(summary => (
          <dl key={summary.name}>
            <dt>{summary.name}</dt>
            <dd>{summary.formattedDuration}</dd>
          </dl>
        ))}
      </ul>

      <h5>Details</h5>
      <table className="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Joined</th>
            <th>Exited</th>
            <th>Duration</th>
            <th>Room</th>
          </tr>
        </thead>
        <tbody>
          {sessionParticipants.map(participant => (
            <tr key={participant.id}>
              <td>{participant.user?.fullName}</td>
              <td>
                <LocalTime timestamp={participant.startedAt} />
              </td>
              <td>
                <LocalTime timestamp={participant.endedAt} />
              </td>
              <td>{participant.formattedDuration}</td>
              <td>{participant.roomSid}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const refreshParticipantsSubscription = gql`
  subscription ($sessionId: ID!) {
    refreshSessionParticipants(sessionId: $sessionId) {
      status
      session {
        id
      }
      failures {
        message
      }
    }
  }
`

export default TwilioParticipation
