import React, { useContext } from "react"

import { useField, useFormikContext } from "formik"

import { SelectOption } from "~tailwindui/types/SelectOption"

import dig from "src/dig"

import Form from "components/OrganizationAdmin/components/Form"

import PersistedValuesContext from "./PersistedValuesContext"

export type MultiSelectProps = {
  name: string
  label: string
  options: SelectOption[]
  description?: string
  valueAttribute?: string
  subfield?: boolean
  portal?: boolean
  inputWrapperClassNames?: string
  displayValue?: any
}

const MultiSelect: React.FC<
  MultiSelectProps & React.SelectHTMLAttributes<HTMLSelectElement>
> = ({
  name,
  label,
  options,
  description = "",
  valueAttribute = "value",
  portal = false,
  inputWrapperClassNames = "",
  displayValue,
  ...props
}) => {
  const [field, meta] = useField({ name })
  const persistedValues = useContext(PersistedValuesContext)
  const { values, setFieldValue } = useFormikContext() as any
  const error = meta.error ? meta.error : ""
  return values.editing ? (
    <Form.MultiSelect
      horizontal
      label={label}
      options={options}
      error={error}
      description={description}
      portal={portal}
      inputWrapperClassNames={inputWrapperClassNames}
      {...field}
      {...props}
      onChange={selectedOptions => {
        setFieldValue(
          field.name,
          selectedOptions.map(selected => selected?.[valueAttribute])
        )
      }}
    />
  ) : (
    <Form.ValueDisplay
      label={label}
      description={description}
      {...field}
      {...props}
      value={displayValue || dig(persistedValues, name.split("."))}
    />
  )
}

export default MultiSelect
