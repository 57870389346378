import React from "react"

import { useField, useFormikContext } from "formik"

type CheckboxOption = {
  label: string
  value: any
  disabled?: boolean
}

export type CheckboxInputProps =
  | {
      name: string
      checkboxType: "single"
      label: string
      options?: never
      description?: string
    }
  | {
      name: string
      label?: string
      checkboxType: "multi" | "radio"
      options: CheckboxOption[]
      description?: string
    }

const CheckboxInput: React.FC<
  CheckboxInputProps & React.HTMLProps<HTMLInputElement>
> = ({
  label,
  name,
  checkboxType,
  description = "",
  options = [],
  ...props
}) => {
  const [field, meta] = useField({ name })

  const { setFieldValue } = useFormikContext()

  if (checkboxType === "single") options.push({ label: label, value: true })

  const error = meta.error ? meta.error : ""

  return (
    <fieldset className="mb-3">
      {checkboxType !== "single" && (
        <legend className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
          {label}
        </legend>
      )}
      <ul className="space-y-2">
        {options.map(option => (
          <div key={option.value} className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                id={`${name}-${option.value}`}
                name={name}
                type="checkbox"
                value={option.value}
                disabled={option.disabled}
                checked={
                  checkboxType === "multi"
                    ? field.value.includes(option.value)
                    : field.value === option.value
                }
                onChange={e => {
                  if (checkboxType === "single")
                    setFieldValue(field.name, !field.value)
                  else if (checkboxType === "radio")
                    setFieldValue(field.name, option.value)
                  else if (checkboxType === "multi") {
                    const newValues = [...field.value]
                    const index = field.value.indexOf(option.value)
                    if (index >= 0) {
                      newValues.splice(index, 1)
                    } else {
                      newValues.push(option.value)
                    }
                    setFieldValue(field.name, newValues)
                  }
                }}
                aria-describedby={option.label}
                {...props}
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor={`${name}-${option.value}`}
                className={
                  option.disabled
                    ? "text-gray-400"
                    : "font-medium text-gray-900"
                }
              >
                {option.label}
              </label>
            </div>
          </div>
        ))}
      </ul>
      <div className="text-sm">
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </fieldset>
  )
}

export default CheckboxInput
