import React from "react"

import { Field, getIn } from "formik"

import styles from "./styles.module.scss"

const ErrorMessage = ({ name, fieldName = undefined, className = "" }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name)
      if (!error || typeof error !== "string") return null
      return (
        <div className={`${styles.container} ${className} d-inline-block`}>
          <span>{error}</span>
        </div>
      )
    }}
  </Field>
)

export default ErrorMessage
