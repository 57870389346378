import React, { useState } from "react"

import { gql, useQuery } from "urql"

import WithLoadingIndicator from "~tailwindui/Basics/WithLoadingIndicator"
import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"
import Header from "components/OrganizationAdmin/components/Form/Header"

export type SurveysPageProps = Record<string, never>

const SurveysPage: React.FC<SurveysPageProps> = () => {
  const [page, setPage] = useState(1)
  const [result] = useQuery({ query: surveysQuery, variables: { page } })
  return (
    <>
      <Header title="Surveys" />
      <WithLoadingIndicator result={result}>
        {({ data }) => (
          <Table
            data={data.viewer.organization.surveys}
            changePage={setPage}
            headers={["Name", "Start Date", "Total Responses"]}
            valueMapper={survey => [
              survey.name,
              <LocalTime key="startsOn" timestamp={survey.startsOn} omitTime />,
              survey.totalResponses,
            ]}
            rowCallback={survey =>
              (window.location.href = `/organization_admin/surveys/${survey.id}`)
            }
          />
        )}
      </WithLoadingIndicator>
    </>
  )
}

const surveysQuery = gql`
  query Surveys($page: Int!) {
    viewer {
      organization {
        surveys(page: $page) {
          currentPage
          totalPages
          data {
            id
            name
            startsOn
            totalResponses
          }
        }
      }
    }
  }
`

export default SurveysPage
