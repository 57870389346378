import React from "react"

import { useField } from "formik"

import ordered from "src/ordered"

import { CheckboxField } from "components/Forms/Formik/hookComponents"

const StatementSchools = ({ schools }) => {
  const [includeAll] = useField("includeAllGroups")

  return (
    <>
      <CheckboxField name="includeAllGroups" label="Include all groups" />

      {includeAll.value ? null : (
        <ul className="list-unstyled ml-4">
          <CheckboxField
            name="includeSchoollessGroups"
            label="Standalone groups (may include students from multiple schools)"
          />
          <CheckboxField
            isMulti
            name="schoolIds"
            options={ordered(schools, "name").map(school => ({
              label: school.name,
              value: school.id,
            }))}
          />
        </ul>
      )}
    </>
  )
}

export default StatementSchools
