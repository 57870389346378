import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { OperationContext, gql, useMutation } from "urql"

import { Menu } from "@headlessui/react"
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid"

import { Link } from "~tailwindui/Basics"

import classNames from "src/classNames"
import ordered from "src/ordered"
import { RunMutation } from "src/types"

import LocalTime from "components/LocalTime"
import { path } from "components/Tutor/TutorRoutes"

import ConfirmDeleteCourse from "./ConfirmDeleteCourse"

export type CourseClusterCardProps = {
  courseCluster: {
    id: string
    name: string
    publishedAt: string
    registrationCount: number
    description: string
    grades: {
      id: string
      name: string
    }[]
    subject: {
      id: string
      name: string
    }
    foreignLanguage: {
      id: string
      name: string
    }
    courses: {
      formattedPrepaidPrice: number
      durationWeeks: number
      sessionsPerWeek: number
      minimumStudyGroupSize: number
      maximumStudyGroupSize: number
      courseSections: {
        id: string
        startsOn: string
        endsOn: string
        availabilities: {
          id: string
          weekday: string
          startsAt: string
          endsAt: string
        }[]
        studyGroups: {
          id: string
          students: {
            id: string
          }[]
        }[]
      }[]
    }[]
  }
  refetchQuery: (options: Partial<OperationContext>) => void
}

const CourseClusterCard: React.FC<CourseClusterCardProps> = ({
  courseCluster,
  refetchQuery,
}) => {
  const [deleteCourseId, setDeleteCourseId] = useState<null | string>()

  const [, runPublishMutation]: [any, RunMutation] = useMutation(
    publishDirectoryCourseClusterMutation
  )

  const [, runRemoveMutation]: [any, RunMutation] = useMutation(
    removeDirectoryCourseClusterMutation
  )

  const handleUpdatePublish = (id, publish) => {
    runPublishMutation({ id, publish })
      .then(
        result => {
          const { failures } = result.data.publishDirectoryCourseCluster
          if (failures.length > 0) {
            NotificationManager.error(failures[0].message)
          } else {
            NotificationManager.success(
              `Your course has been ${publish ? "published" : "unpublished"}`
            )
            refetchQuery({ requestPolicy: "network-only" })
          }
        },
        () => NotificationManager.error("Something went wrong")
      )
      .catch(() => NotificationManager.error("Something went wrong"))
  }

  const handleRemove = id => {
    runRemoveMutation({ id })
      .then(
        result => {
          const { failures } = result.data.removeDirectoryCourseCluster
          if (failures.length > 0) {
            NotificationManager.error(failures[0].message)
          } else {
            NotificationManager.success("Your course has been deleted")
            refetchQuery({ requestPolicy: "network-only" })
          }
        },
        () => NotificationManager.error("Something went wrong")
      )
      .catch(() => NotificationManager.error("Something went wrong"))
  }

  return (
    <li
      key={courseCluster.id}
      className="divide-y-[1px] divide-gray-300 overflow-hidden rounded-xl border border-gray-300"
    >
      {deleteCourseId && (
        <ConfirmDeleteCourse
          registrationCount={courseCluster.registrationCount}
          publishedAt={courseCluster.publishedAt}
          handleSubmit={() => handleRemove(deleteCourseId)}
          closeModal={() => setDeleteCourseId(null)}
        />
      )}
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex max-w-[calc(100%-40px)] items-center space-x-3">
            <div className="truncate text-lg font-medium leading-6 text-gray-900">
              {courseCluster.foreignLanguage &&
                `[${courseCluster.foreignLanguage?.name}] `}
              {courseCluster.name}
            </div>

            <span
              className={classNames(
                courseCluster.publishedAt
                  ? "bg-emerald-50 text-emerald-700 ring-emerald-600/20"
                  : "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
                "inline-flex flex-shrink-0 items-center rounded-full  px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset "
              )}
            >
              {courseCluster.publishedAt ? "Published" : "Unpublished"}
            </span>
          </div>

          <Menu as="div" className="relative ml-auto">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-600">
              <span className="sr-only">Open options</span>
              <EllipsisHorizontalIcon aria-hidden="true" className="h-7 w-7" />
            </Menu.Button>
            <Menu.Items className="data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in absolute right-0 z-10 mt-0.5 w-36 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition hover:outline-none">
              {(!courseCluster.publishedAt ||
                courseCluster.registrationCount === 0) && (
                <Menu.Item>
                  <button
                    type="button"
                    onClick={() =>
                      handleUpdatePublish(
                        courseCluster.id,
                        !courseCluster.publishedAt
                      )
                    }
                    className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 hover:bg-gray-100"
                  >
                    {courseCluster.publishedAt ? "Unpublish" : "Publish"}
                    <span className="sr-only">, {courseCluster.name}</span>
                  </button>
                </Menu.Item>
              )}
              <Menu.Item>
                <Link
                  to={path("directory.courseClusterEdit", {
                    id: courseCluster.id,
                  })}
                  className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 hover:bg-gray-100 hover:no-underline"
                >
                  Edit Course
                  <span className="sr-only">, {courseCluster.name}</span>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <button className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 hover:bg-gray-100">
                  Customize Page
                  <span className="sr-only">, {courseCluster.name}</span>
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  type="button"
                  onClick={() => setDeleteCourseId(courseCluster.id)}
                  className="block w-full px-3 py-1 text-left text-sm leading-6 text-red-600 hover:bg-gray-100"
                >
                  Delete<span className="sr-only">, {courseCluster.name}</span>
                </button>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>

        <div className="pt-4 text-gray-700">{courseCluster.description}</div>
      </div>

      <dl className="-my-3 divide-y divide-gray-200 px-6 py-4 text-sm leading-6">
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Subject</dt>
          <dd className="text-gray-700">{courseCluster.subject.name}</dd>
        </div>

        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Grades</dt>
          <dd className="text-gray-700">
            {courseCluster.grades.map(grade => grade.name).join(", ")}
          </dd>
        </div>

        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Price</dt>
          <dd className="text-gray-700">
            {courseCluster.courses[0].formattedPrepaidPrice}
          </dd>
        </div>

        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Duration</dt>
          <dd className="text-gray-700">
            {courseCluster.courses[0].durationWeeks} Weeks
          </dd>
        </div>

        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Group Size</dt>
          <dd className="text-gray-900">
            {courseCluster.courses[0].minimumStudyGroupSize} -{" "}
            {courseCluster.courses[0].maximumStudyGroupSize}
          </dd>
        </div>
      </dl>

      <div className="p-6">
        <div className="mb-2 font-medium leading-6 text-gray-900">
          Course Sections
        </div>
        <ul className="divide-y text-gray-700">
          {ordered(courseCluster.courses[0].courseSections, "startsOn").map(
            section => (
              <li key={section.id} className="py-2">
                <div className="sm:flex sm:justify-between">
                  <div>
                    {section.studyGroups[0] ? (
                      <Link
                        to={path("studyGroupDetails", {
                          id: section.studyGroups[0].id,
                        })}
                      >
                        <LocalTime timestamp={section.startsOn} omitTime />
                        {" - "}
                        <LocalTime timestamp={section.endsOn} omitTime />
                      </Link>
                    ) : (
                      <>
                        <LocalTime timestamp={section.startsOn} omitTime />
                        {" - "}
                        <LocalTime timestamp={section.endsOn} omitTime />
                      </>
                    )}
                  </div>
                  {courseCluster.publishedAt && (
                    <div className="text-gray-500">
                      {section.studyGroups[0]?.students?.length || 0} registered
                    </div>
                  )}
                </div>
                <ul className="ml-4">
                  {section.availabilities.map(availability => (
                    <li key={availability.id}>
                      {availability.weekday}s{" "}
                      <LocalTime timestamp={availability.startsAt} omitDate />
                      {" - "}
                      <LocalTime timestamp={availability.endsAt} omitDate />
                    </li>
                  ))}
                </ul>
              </li>
            )
          )}
        </ul>
      </div>
    </li>
  )
}

const publishDirectoryCourseClusterMutation = gql`
  mutation publishDirectoryCourseCluster($id: ID!, $publish: Boolean!) {
    publishDirectoryCourseCluster(id: $id, publish: $publish) {
      failures {
        message
      }
      courseCluster {
        id
      }
    }
  }
`

const removeDirectoryCourseClusterMutation = gql`
  mutation removeDirectoryCourseCluster($id: ID!) {
    removeDirectoryCourseCluster(id: $id) {
      failures {
        message
      }
      courseCluster {
        id
      }
    }
  }
`

export default CourseClusterCard
