import React, { useEffect, useRef, useState } from "react"

import { gql, useMutation } from "urql"

import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"

import { Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button, Spinner } from "~tailwindui/Basics"

import { RunMutation } from "src/types"

import LessonResourceCompletionDetails from "./LessonResourceCompletionDetails"

import "tippy.js/dist/tippy.css"

type LessonResourceCompletion = {
  id: string
  studentMastery: string
  createdAt: Date
  student: {
    id: string
    fullName: string
  }
}

type LessonResource = {
  id: string
  name: string
  file: {
    previewUrl
    thumbUrl
  }
  lessonResourceCompletions: LessonResourceCompletion[]
  sortOrder: number
}

export type LessonResourceDetailsProps = {
  airtutorsSessionId: string
  lessonResource: LessonResource
  isCurrent: boolean
}

const LessonResourceDetails: React.FC<LessonResourceDetailsProps> = ({
  airtutorsSessionId,
  lessonResource,
  isCurrent,
}) => {
  const lessonResourceDownloadLink = useRef<HTMLAnchorElement>()
  const [collapsed, setCollapsed] = useState(true)
  const [lessonResourceUrl, setLessonResourceUrl] = useState("")
  const [error, setError] = useState<string | null>()
  const [completeResourceState, completeResource]: [any, RunMutation] =
    useMutation(completeResourceMutation)

  const [downloadResourceState, downloadResource]: [any, RunMutation] =
    useMutation(downloadResourceMutation)

  useEffect(() => {
    if (lessonResourceUrl === "") return
    lessonResourceDownloadLink.current.click()
  }, [lessonResourceUrl])

  return (
    <li key={lessonResource.id} className="my-2 ml-16 pl-3">
      <div className="flex items-center justify-between">
        <div>
          <span className="mr-2">#{lessonResource.sortOrder}</span>
          <a
            ref={lessonResourceDownloadLink}
            href={lessonResourceUrl}
            download
            className="hidden"
          >
            download resource
          </a>
          <button
            onClick={() =>
              !downloadResourceState.fetching &&
              downloadResource({
                airtutorsSessionId,
                lessonResourceId: lessonResource.id,
              }).then(result => {
                setError(null)
                const { failures, lessonResource } =
                  result.data.downloadLessonResource
                if (failures.length > 0) {
                  setError(failures[0].message)
                } else if (lessonResource) {
                  setLessonResourceUrl(lessonResource.file.downloadUrl)
                  setTimeout(() => setLessonResourceUrl(""), 1000)
                }
              })
            }
            disabled={downloadResourceState.fetching}
            className="inline-flex items-center border-0 bg-transparent text-brand-600 hover:text-brand-800 disabled:cursor-not-allowed disabled:opacity-75"
          >
            <div className="mr-4">
              <h3 className="block">
                {lessonResource.name}
                <span className="ml-1">
                  {downloadResourceState.fetching ? (
                    <Spinner />
                  ) : (
                    <FontAwesomeIcon icon={faCloudDownloadAlt} />
                  )}
                </span>
              </h3>
            </div>
            <Tippy
              content={
                <img
                  src={lessonResource.file.previewUrl}
                  alt={lessonResource.name}
                  className="mh-[80vh]"
                />
              }
              placement="auto"
              maxWidth="90vw"
            >
              <div>
                <img
                  src={lessonResource.file.thumbUrl}
                  alt={lessonResource.name}
                />
              </div>
            </Tippy>
          </button>
        </div>

        {isCurrent && (
          <div className="ml-3 mb-2">
            <Button
              color={Color.Orange}
              onClick={() =>
                completeResource({
                  airtutorsSessionId,
                  lessonResourceId: lessonResource.id,
                }).then(result => {
                  const { failures } = result.data.completeLessonResource
                  if (failures.length > 0) {
                    setError(failures[0].message)
                  }
                })
              }
              disabled={completeResourceState.fetching}
            >
              <div className="flex justify-center">
                Mark Worksheet Completed
                {completeResourceState.fetching && (
                  <div className="ml-3 inline-block">
                    <Spinner />
                  </div>
                )}
              </div>
            </Button>
            {error && <AlertMessageBox>{error}</AlertMessageBox>}
          </div>
        )}
      </div>

      {lessonResource.lessonResourceCompletions.length > 0 && (
        <div className="mt-3">
          <Button
            onClick={() => setCollapsed(c => !c)}
            className="mb-2"
            outline
          >
            {collapsed
              ? "View Student Completions"
              : "Hide Student Completions"}
          </Button>
          {!collapsed && (
            <div className="mt-8">
              {lessonResource.lessonResourceCompletions.map(completion => (
                <LessonResourceCompletionDetails
                  key={completion.id}
                  airtutorsSessionId={airtutorsSessionId}
                  lessonResourceCompletion={completion}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <hr />
    </li>
  )
}

const downloadResourceMutation = gql`
  mutation downloadLessonResource(
    $lessonResourceId: ID!
    $airtutorsSessionId: ID!
  ) {
    downloadLessonResource(
      lessonResourceId: $lessonResourceId
      airtutorsSessionId: $airtutorsSessionId
    ) {
      failures {
        message
      }
      lessonResource {
        file {
          downloadUrl
        }
      }
    }
  }
`

const completeResourceMutation = gql`
  mutation completeLessonResource(
    $airtutorsSessionId: ID!
    $lessonResourceId: ID!
  ) {
    completeLessonResource(
      airtutorsSessionId: $airtutorsSessionId
      lessonResourceId: $lessonResourceId
    ) {
      failures {
        message
      }
      airtutorsSession {
        lessonPlans {
          id
          name
        }
        currentLessonResources {
          id
          name
          sortOrder
          file {
            thumbUrl
            previewUrl
          }
          lessonTopic {
            id
            name
            sortOrder
          }
          lessonPlan {
            id
            name
          }
          lessonResourceCompletions(sessionId: $airtutorsSessionId) {
            id
            createdAt
            studentMastery
            student {
              id
              fullName
            }
          }
        }
      }
    }
  }
`

export default LessonResourceDetails
