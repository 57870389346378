import React, { useEffect, useRef, useState } from "react"

import { Form, Formik, useFormikContext } from "formik"
import moment from "moment-timezone"
import { pipe, subscribe } from "wonka"

import { gql } from "@urql/core"

import createBlob from "src/createBlob"
import client from "src/urql-client"

import {
  CheckboxField,
  FormFailures,
  SubmitButton,
  TimeZoneField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

type SessionsExportProps = {
  canAdminBudgets: boolean
  sessionType: "AirtutorsSession" | "DirectorySession"
}

const SessionsExport: React.FC<SessionsExportProps> = ({
  canAdminBudgets,
  sessionType,
}) => {
  const [status, setStatus] = useState()
  const [csvUrl, setCsvUrl] = useState<string | undefined>()
  const csvDownloadLink = useRef<HTMLAnchorElement>()
  const { values: searchValues } = useFormikContext()
  const variables = { ...(searchValues as Record<string, any>) }
  if (variables.startsAt) {
    variables.startsAt = moment(variables.startsAt.toDate())
      .startOf("day")
      .format()
  }
  if (variables.endsAt) {
    variables.endsAt = moment(variables.endsAt.toDate()).endOf("day").format()
  }

  const handleExport = ({ timezone, useBillableName }, actions, closeModal) => {
    const { unsubscribe } = pipe(
      client.subscription(exportSubscription, {
        sessionType,
        searchInput: variables,
        timezone,
        useBillableName,
      }),
      subscribe(result => {
        // triggered by backend exception
        if (result.error) {
          handleFailure(actions, [result.error])
          actions.setSubmitting(false)
          return
        }

        const { status, failures, csv } = result.data.adminSessionExport

        if (status === "done") {
          const blobUrl = createBlob(csv, "text/csv")
          setCsvUrl(blobUrl)
          actions.setSubmitting(false)
          unsubscribe()
          closeModal()
        } else if (status) {
          setStatus(status)
        }

        if (failures.length > 0) {
          handleFailure(actions, failures)
          unsubscribe()
        }
      })
    )
  }

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl(undefined)
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  return (
    <>
      <a
        ref={csvDownloadLink}
        href={csvUrl}
        download={`${sessionType} sessions export.csv`}
        className="hidden"
      >
        csv
      </a>

      <ModalWithProvidedBody
        modalTitle="Export"
        buttonText="Export"
        buttonClassName="btn btn-success"
        afterClose={() => {
          setStatus(undefined)
        }}
      >
        {({ closeModal }) => (
          <Formik
            initialValues={{ timezone: "Pacific", useBillableName: false }}
            onSubmit={(values, actions) =>
              handleExport(values, actions, closeModal)
            }
          >
            <Form>
              <div className="modal-body">
                <TimeZoneField
                  name="timezone"
                  menuPortalTarget={document.body}
                  styles={{
                    container: provided => ({
                      ...provided,
                      maxWidth: 250,
                    }),
                  }}
                />

                {canAdminBudgets && (
                  <CheckboxField
                    name="useBillableName"
                    label="Use Billable Name"
                  />
                )}
              </div>

              <div className="modal-footer flex-col">
                <FormFailures />
                {status && <p>Export State: {status}</p>}
                <SubmitButton text="Export" className="btn btn-info" />
              </div>
            </Form>
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const exportSubscription = gql`
  subscription (
    $sessionType: SessionTypeEnum!
    $searchInput: AdminSessionSearchInputObject!
    $timezone: TimeZoneEnum!
    $useBillableName: Boolean
  ) {
    adminSessionExport(
      sessionType: $sessionType
      searchInput: $searchInput
      timezone: $timezone
      useBillableName: $useBillableName
    ) {
      status
      csv
      failures {
        message
      }
    }
  }
`

export default SessionsExport
