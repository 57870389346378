import React from "react"

import { Form, Formik } from "formik"
import StepWizard from "react-step-wizard"

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"

import { gql, useClient, useMutation } from "hooks/urql"

import {
  FormFailures,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

import DurationSelection from "./DurationSelection"
import Review from "./Review"
import ScheduleSelection from "./ScheduleSelection"
import SubjectSelection from "./SubjectSelection"

const GroupTutoring = ({ courses, viewer, student }) => {
  const formState = {
    subjectId: "",
    durationWeeks: "",
    courseSectionId: "",
    stripeToken: "",
  }

  const client = useClient()
  const [, register] = useMutation(registerMutation, client)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = (values, actions) => {
    actions.setStatus()

    const cardElement = elements.getElement(CardElement)
    if (cardElement) {
      stripe
        .createSource(cardElement, { type: "card" })
        .then(
          ({ error, source }) => {
            if (error) {
              actions.setStatus([error])
              actions.setSubmitting(false)
              return
            }

            values.stripeToken = source.id
            submitRegistration(values, actions)
          },
          e => handleFailure(actions, [e])
        )
        .catch(e => handleFailure(actions, [e]))
    } else {
      submitRegistration(values, actions)
    }
  }

  const submitRegistration = (values, actions) => {
    register({
      studentId: student.id,
      courseSectionId: values.courseSectionId,
      stripeToken: values.stripeToken,
    })
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { failures, redirectTo } = result.data.publicCourseRegistration
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.href = redirectTo
          }
        },
        e => handleFailure(actions, [e])
      )
      .catch(e => handleFailure(actions, [e]))
  }

  const filteredCourses = courses.filter(
    course => course.studentRegisterableCourseSections.length > 0
  )

  if (filteredCourses.length === 0) {
    return <h3>No courses of the selected type available at this time</h3>
  }

  return (
    <Formik initialValues={formState} onSubmit={handleSubmit}>
      <Form>
        <StepWizard isLazyMount>
          <SubjectSelection courses={filteredCourses} />
          <DurationSelection courses={filteredCourses} />
          <ScheduleSelection courses={filteredCourses} />
          <Review courses={filteredCourses} viewer={viewer} />
        </StepWizard>

        <FormFailures />
      </Form>
    </Formik>
  )
}

const registerMutation = gql`
  mutation ($studentId: ID!, $courseSectionId: ID!, $stripeToken: String) {
    publicCourseRegistration(
      courseSectionId: $courseSectionId
      studentId: $studentId
      stripeToken: $stripeToken
    ) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default GroupTutoring
