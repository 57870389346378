import React from "react"

import { Link, useParams } from "react-router-dom"
import { gql, useQuery } from "urql"

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/core"

import ChimeCloudroom from "components/ChimeCloudroom"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import TeacherTwilioCloudroom from "./TwilioCloudRoom"

const TeacherSessionCloudroom = () => {
  let params = useParams()

  const [result] = useQuery({
    query: cloudroomQuery,
    variables: { id: params.id },
  })

  if (result.error) {
    return (
      <Modal isOpen>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>An Error Occurred</ModalHeader>
          <ModalBody>
            <p className="mb-3">
              Unable to load Cloudroom. This usually occurs when attempting to
              access a session you do not have permission to access.
            </p>
            <div>
              <Link to="/" className="btn">
                Back to dashboard
              </Link>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <>
      <UrqlLoadingIndicator result={result}>
        {({ data }) =>
          data.airtutorsSession.chimeCloudroomEnabled ? (
            <ChimeCloudroom sessionId={data.airtutorsSession.id} />
          ) : (
            <TeacherTwilioCloudroom
              airtutorsSession={data.airtutorsSession}
              viewer={data.viewer}
            />
          )
        }
      </UrqlLoadingIndicator>
    </>
  )
}

const cloudroomQuery = gql`
  query SessionCloudroomQuery($id: ID!) {
    viewer {
      id
      ssoEmail
      hasRealtimeboardLicense
    }
    airtutorsSession(id: $id) {
      id
      chimeCloudroomEnabled
      startsAt
      twilioAccessToken
      allAudioMuted
      allVideoMuted
      allControlsLocked
      controlsLockedUserIds
      googledocUrl
      studentScreenShareDisabled
      realtimeboards {
        id
        url
        displayName
      }
    }
  }
`

export default TeacherSessionCloudroom
