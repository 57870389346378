import React, { useContext } from "react"

import { useField, useFormikContext } from "formik"

import dig from "src/dig"

import Form from "components/OrganizationAdmin/components/Form"

import PersistedValuesContext from "./PersistedValuesContext"

export type ToggleInputProps = {
  label: string
  name: string
  description?: string
  subfield?: boolean
  displayValue?: any
}

const ToggleInput: React.FC<
  ToggleInputProps & React.HTMLProps<HTMLInputElement>
> = ({ name, displayValue, ...props }) => {
  const [field, meta] = useField({ name })
  const persistedValues = useContext(PersistedValuesContext)
  const { values, setFieldValue } = useFormikContext() as any
  const error = meta.error ? meta.error : ""
  return values.editing ? (
    <Form.ToggleInput
      horizontal
      error={error}
      {...field}
      {...props}
      onChange={newVal => {
        setFieldValue(field.name, newVal)
      }}
    />
  ) : (
    <Form.ValueDisplay
      {...field}
      {...props}
      value={displayValue || dig(persistedValues, name.split("."))}
    />
  )
}

export default ToggleInput
