import React from "react"

import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button } from "~tailwindui/Basics"
import { Failures, SubmitButton, TextAreaInput } from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"
import { RunMutation } from "src/types"

import { path } from "components/Tutor/TutorRoutes"

const ValidationSchema = Yup.object().shape({
  reason: Yup.string().required(
    "Please specify a reason (this will only be visible to admins)"
  ),
  substituteNotes: Yup.string().required(
    "Please leave a note for the tutor who will pick up the session"
  ),
})

export type RequestSubstituteProps = {
  id: string
  startsAt: string
  subRequestsLeft: number
}

const RequestSubstitute: React.FC<RequestSubstituteProps> = ({
  id,
  startsAt,
  subRequestsLeft,
}) => {
  const history = useHistory()

  const [, runMutation]: [any, RunMutation] = useMutation(
    requestSubstituteMutation
  )

  if (dayjs(startsAt).isBefore(dayjs().add(1, "hour")))
    return (
      <div className="my-3 mx-1 rounded bg-sky-600 p-3 text-center text-white">
        If you need a substitute, you must reach out to your slack admin channel
      </div>
    )

  const formState = {
    id: id,
    reason: "",
    substituteNotes: "",
  }

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          const { failures } = result.data.airtutorsSessionRequestSubstitute
          if (failures.length > 0) {
            actions.setStatus(failures[0].message)
          } else {
            history.push(path("dashboard"))
            NotificationManager.success(
              "A substitute request will be sent to matching tutors momentarily"
            )
          }
        },
        () => actions.setStatus("Something went wrong")
      )
      .catch(() => actions.setStatus("Something went wrong"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Modal.Dialog
      buttonText="Request Substitute Tutor"
      buttonClassNames={classNames(
        "mb-2",
        buttonClassNames({ color: Color.Red })
      )}
    >
      {closeModal => (
        <>
          <Modal.Header>Request Substitute Tutor</Modal.Header>
          <Formik
            initialValues={formState}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex max-h-[calc(70vh-90px)] flex-col">
              <Modal.Body>
                <p>
                  Once this form is submitted, this session will no longer be
                  assigned to you.
                </p>
                <p>
                  If no substitute can be found, the session will be canceled 1
                  hour prior to the scheduled start time.
                </p>

                <TextAreaInput name="reason" label="Reason" autoFocus />

                <div className="mt-3">
                  <TextAreaInput
                    name="substituteNotes"
                    label="Notes"
                    description="These will be provided to the tutor who picks up your session"
                  />
                </div>
              </Modal.Body>

              <Modal.Footer>
                {subRequestsLeft && (
                  <AlertMessageBox level={AlertLevel.Warning}>
                    WARNING:{" "}
                    {subRequestsLeft === 1
                      ? "This is your last substitute request for this group. If you cannot attend a future session, you will be forced to give up the group."
                      : `You currently have ${subRequestsLeft} substitute requests left for this group. After this, you will have ${
                          subRequestsLeft - 1
                        }.`}{" "}
                    Please think carefully before submitting this request.
                  </AlertMessageBox>
                )}

                <Failures />

                <div className="flex justify-end space-x-2">
                  <Button color={Color.Red} onClick={closeModal}>
                    Nevermind
                  </Button>
                  <SubmitButton text="Submit Request" />
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </>
      )}
    </Modal.Dialog>
  )
}

const requestSubstituteMutation = gql`
  mutation requestSubstitute(
    $id: ID!
    $reason: String!
    $substituteNotes: String!
  ) {
    airtutorsSessionRequestSubstitute(
      airtutorsSessionId: $id
      reason: $reason
      substituteNotes: $substituteNotes
    ) {
      failures {
        message
      }
    }
  }
`

export default RequestSubstitute
