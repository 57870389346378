import React from "react"

import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid"

import classNames from "src/classNames"

export type PaginatorProps = {
  currentPage: number
  totalPages: number
  changePage: (page: number) => void
}

const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  changePage,
}) => {
  if (totalPages === 1) return null
  let offset = 0
  if (currentPage > 3) offset = currentPage - 3

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        {currentPage > 3 && (
          <button
            key={1}
            onClick={() => changePage(1)}
            disabled={currentPage <= 3}
            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            First
          </button>
        )}
      </div>

      <div className="hidden md:-mt-px md:flex">
        {currentPage > 1 && (
          <button
            onClick={() => changePage(currentPage - 1)}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ChevronDoubleLeftIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        )}
        {new Array(Math.min(5, totalPages)).fill(0).map(
          (_, index) =>
            index + 1 + offset <= totalPages && (
              <button
                key={index}
                onClick={() => changePage(index + 1 + offset)}
                className={classNames(
                  currentPage === index + 1 + offset &&
                    "border-sky-500 text-sky-600",
                  "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                )}
              >
                {index + 1 + offset}
              </button>
            )
        )}
        {currentPage < totalPages && (
          <button
            onClick={() => changePage(currentPage + 1)}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ChevronDoubleRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        )}
      </div>

      <div className="-mt-px flex w-0 flex-1 justify-end">
        {currentPage < totalPages - 2 && (
          <button
            key={totalPages}
            onClick={() => changePage(totalPages)}
            disabled={currentPage >= totalPages - 2}
            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Last
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    </nav>
  )
}

export default Paginator
