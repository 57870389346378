import React, { useEffect, useState } from "react"

import { Form, Formik } from "formik"
import { DateObject } from "react-multi-date-picker"
import { NotificationManager } from "react-notifications"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { path } from "~Tutor/TutorRoutes"
import {
  AlertMessageBox,
  H1,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import { DateTimeInput, SubmitButton, TextInput } from "~tailwindui/Form"

import airbrake from "src/airbrake-notifier"
import { RunMutation } from "src/types"
import { MatchParams } from "src/types/MatchParams"

import LocalTime from "components/LocalTime"

const ValidationSchema = Yup.object().shape({
  sessionDate: Yup.string().required("Please specify a date"),
  durationMinutes: Yup.number()
    .required("Please specify a duration")
    .min(15, "Sessions must be at least 15 minutes long"),
})

const RescheduleSession: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const id = match.params.id
  const history = useHistory()
  const [errors, setErrors] = useState([])
  const buildFormState = session => ({
    id: session.id,
    sessionDate: new DateObject(new Date(session.startsAt)),
    durationMinutes: session.scheduledDurationMinutes,
  })

  const [result] = useQuery({ query, variables: { id } })

  const [, runMutation]: [any, RunMutation] = useMutation(
    rescheduleSessionMutation
  )

  useEffect(() => {
    if (!result.data) return

    const { session } = result.data
    if (session.started) {
      history.push(path("dashboard"))
      NotificationManager.error("Unable to schedule past session")
    }
    if (session.immovable) {
      history.push(path("dashboard"))
      NotificationManager.error(
        "This session is not reschedulable. Please slack your admin channel or submit a substitute request."
      )
    }
  }, [result, history])

  const handleSubmit = (values, actions) => {
    const { sessionDate, ...rest } = values

    runMutation({ sessionDate: sessionDate.toDate(), ...rest })
      .then(
        response => {
          const { session, failures } = response.data.rescheduleSession
          if (failures.length > 0) {
            actions.setSubmitting(false)
            actions.setStatus(
              "Failed to reschedule session. Please correct validation errors."
            )
            setErrors(failures)
          } else {
            NotificationManager.success("Session rescheduled")
            history.push(path("sessionDetails", { id: session.id }))
          }
        },
        error => {
          actions.setStatus("Something went wrong")
          airbrake.notify({ error, name: "RescheduleSession" })
        }
      )
      .catch(error => {
        actions.setStatus("Something went wrong")
        airbrake.notify({ error, name: "RescheduleSession" })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { session } }) => (
        <div className="form-wrapper">
          <H1>Reschedule Session</H1>
          <Formik
            initialValues={buildFormState(session)}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
          >
            {({ status }) => (
              <Form>
                <div className="flex flex-col sm:flex-row sm:space-x-10">
                  <DateTimeInput
                    name="sessionDate"
                    label="Session Date"
                    displayWarnings
                  />

                  <div className="w-48">
                    <TextInput
                      name="durationMinutes"
                      label="Duration (in minutes)"
                      type="number"
                      min="15"
                      step="15"
                    />
                  </div>
                </div>

                {status && <AlertMessageBox>{status}</AlertMessageBox>}

                {errors.length > 0 && (
                  <AlertMessageBox>
                    <div className="flex list-disc flex-col space-y-1 pl-5">
                      {errors.map(error => (
                        <>
                          <p>{error.message}</p>
                          {error.conflictingSessions && (
                            <ul>
                              {error.conflictingSessions.map(session => (
                                <li key={session.id}>
                                  <Link
                                    to={path("sessionDetails", {
                                      id: session.id,
                                    })}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="underline"
                                  >
                                    <LocalTime timestamp={session.startsAt} />
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                      ))}
                    </div>
                  </AlertMessageBox>
                )}

                <SubmitButton text="Reschedule" />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query RescheduleSessionQuery($id: ID!) {
    session(id: $id) {
      id
      started
      immovable
      startsAt
      scheduledDurationMinutes
    }
  }
`

const rescheduleSessionMutation = gql`
  mutation RescheduleSession(
    $id: ID!
    $sessionDate: DateTime!
    $durationMinutes: Int!
  ) {
    rescheduleSession(
      id: $id
      sessionDate: $sessionDate
      durationMinutes: $durationMinutes
    ) {
      session {
        id
      }
      failures {
        message
        conflictingSessions {
          id
          startsAt
        }
      }
    }
  }
`

export default RescheduleSession
