import React from "react"

import { useFormikContext } from "formik"

import { Color, Size } from "~tailwindui/types/enums"

import Button from "~tailwindui/Basics/Button"

export type SubmitButtonProps = {
  text?: string
  submitting?: boolean
  className?: string
  color?: Color
  size?: Size
  outline?: boolean
  soft?: boolean
}

const SubmitButton = ({ text = "Save", submitting = undefined, ...props }) => {
  let { isSubmitting } = useFormikContext()
  if (submitting !== undefined) isSubmitting = submitting

  return (
    <div>
      <Button
        type="submit"
        text={text}
        loading={isSubmitting}
        disabled={isSubmitting}
        {...props}
      />
    </div>
  )
}

export default SubmitButton
