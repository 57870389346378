import React from "react"

import { Form, Formik } from "formik"
import { gql } from "urql"
import * as Yup from "yup"

import { useMutation } from "hooks/urql"

import {
  AutocompleteField,
  CheckboxField,
  Field,
  FileField,
  FormFailures,
  SubmitButton,
  handleFailure,
  readFile,
} from "components/Forms/Formik/hookComponents"

const ValidationSchema = Yup.object().shape({
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  organizationId: Yup.string().required("Organization is required"),
})

const TeacherForm = ({ formState, organization }) => {
  const [, saveTeacher] = useMutation(teacherMutation)

  const handleSubmit = (values, actions) => {
    readFile(values.avatar).then(file => {
      values.avatar = file
      saveTeacher({ input: values })
        .then(
          result => {
            const { redirectTo, failures } = result.data.saveTeacher
            if (failures) {
              handleFailure(actions, failures)
            } else if (redirectTo) {
              window.location.href = redirectTo
            }
          },
          () => handleFailure(actions)
        )
        .catch(() => handleFailure(actions))
    })
  }

  if (!formState) return null
  return (
    <div className="w-full md:w-2/3 lg:w-1/2">
      <Formik
        initialValues={formState}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field name="firstName" label="First Name" />
          <Field name="lastName" label="Last Name" />
          <Field name="email" label="Email" type="email" />
          <Field name="phoneNumber" label="Phone Number" />
          <AutocompleteField
            name="organizationId"
            label="Organization"
            api="/api/admins/organizations/autocomplete_name"
            defaultValue={
              organization
                ? {
                    value: organization?.id,
                    label: organization?.name,
                  }
                : null
            }
            isClearable={false}
          />
          <CheckboxField
            name="hasRealtimeboardLicense"
            label="Has Miro License"
          />
          <CheckboxField
            name="teacherDigestsEnabled"
            label="Send teacher digest emails (ie: daily scheduled sessions notification)"
          />
          <FileField name="avatar" label="Avatar" accept="image/*" />

          <FormFailures />
          <SubmitButton />
        </Form>
      </Formik>
    </div>
  )
}

const teacherMutation = gql`
  mutation ($input: TeacherInputObject!) {
    saveTeacher(input: $input) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default TeacherForm
