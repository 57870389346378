import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import * as Yup from "yup"

import { surveyOptionsWithIcons } from "src/enums"
import { VoidReturn } from "src/types/VoidReturn"

import { gql, useClient, useMutation, useQuery } from "hooks/urql"

import {
  RadioField,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody, { Footer } from "components/ModalWithProvidedBody"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

type SurveyModalProps = {
  sessionId: number
  isOpen: boolean
  onClose: VoidReturn
  observationMode?: boolean
}

const ValidationSchema = Yup.object().shape({
  answers: Yup.array().of(
    Yup.object().shape({
      value: Yup.number().required(
        "Please select a response for each question"
      ),
    })
  ),
})

const SurveyModal: React.FC<SurveyModalProps> = ({
  sessionId,
  isOpen,
  onClose,
  observationMode = false,
}) => {
  const client = useClient()
  const [result] = useQuery({
    query: surveyQuery,
    variables: { sessionId },
    client,
  })
  const [, submitSurvey]: any[] = useMutation(submitSurveyMutation, client)
  const formState = {
    sessionId,
    answers: [],
  }

  if (result.data) {
    result.data.airtutorsSession.survey.questions.forEach((question, index) => {
      formState.answers[index] = { questionId: question.id }
    })
  }

  const handleSubmit = (values, actions) => {
    if (observationMode) {
      onClose()
      return
    }

    submitSurvey(values)
      .then(
        result => {
          const { failures } = result.data.submitSurvey
          if (failures.length > 1) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.info(
              "Thank you for submitting your survey answers!"
            )
            onClose()
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      modalTitle="Student Survey"
      modalClassName="bootstrap-modal"
      closeModal={onClose}
      hideCloseButton={!observationMode}
    >
      <UrqlLoadingIndicator result={result}>
        {({ data }) => (
          <Formik
            initialValues={formState}
            onSubmit={handleSubmit}
            validationSchema={!observationMode && ValidationSchema}
            validateOnChange={false}
            enableReinitialize
          >
            <Form>
              <div className="modal-body">
                {observationMode && (
                  <div className="alert rounded bg-yellow-400 p-3 text-black">
                    This survey is currently being presented to your students.
                    Please ensure they complete the form. You may read the
                    questions aloud to them if necessary.
                  </div>
                )}
                {data.airtutorsSession.survey.questions.map(
                  (question, index) => (
                    <div
                      key={question.id}
                      className="mb-4 border-0 border-b-2 border-solid border-b-slate-200"
                    >
                      <label className="mb-4 block text-xl">
                        {question.text}
                      </label>
                      <RadioField
                        name={`answers[${index}][value]`}
                        options={surveyOptionsWithIcons}
                        numeric
                        disabled={observationMode}
                      />
                    </div>
                  )
                )}
              </div>

              <Footer
                cancelAction={onClose}
                submitText={
                  observationMode ? "Close this window" : "Submit Survey"
                }
                isCancelable={false}
              />
            </Form>
          </Formik>
        )}
      </UrqlLoadingIndicator>
    </ModalWithProvidedBody>
  )
}

const surveyQuery = gql`
  query SessionSurvey($sessionId: ID!) {
    airtutorsSession(id: $sessionId) {
      id
      survey {
        id
        questions {
          id
          text
        }
      }
    }
  }
`

const submitSurveyMutation = gql`
  mutation SubmitSurvey(
    $sessionId: ID!
    $answers: [SurveyAnswerInputObject!]!
  ) {
    submitSurvey(sessionId: $sessionId, answers: $answers) {
      failures {
        message
      }
    }
  }
`

export default SurveyModal
