import React, { ReactElement } from "react"

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid"

import { AlertLevel, Color, Size } from "~tailwindui/types/enums"

import classNames from "src/classNames"

export const chipIconColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "bg-sky-500"
    case Color.Yellow:
      return "bg-yellow-500"
    case Color.Red:
      return "bg-red-500"
    case Color.Green:
      return "bg-emerald-500"
  }
}

export const buttonClassNames = ({
  size = Size.Medium,
  color = Color.Blue,
  outline = false,
  soft = false,
}: {
  size?: Size
  color?: Color
  outline?: boolean
  soft?: boolean
}): string => {
  return classNames(
    "font-medium tracking-wide shadow-sm text-center cursor-pointer",
    buttonSizeClassNames(size),
    outline
      ? "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      : soft
      ? softButtonColorClassNames(color)
      : buttonColorClassNames(color)
  )
}

export const buttonSizeClassNames = (size: Size) => {
  switch (size) {
    case Size.Small:
      return "rounded px-2 py-1 text-md"
    case Size.Medium:
      return "rounded-md px-2.5 py-1.5 text-lg"
    case Size.Large:
      return "rounded-md px-3.5 py-2.5 text-lg"
  }
}

export const buttonColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "bg-sky-600 text-white hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
    case Color.Red:
      return "bg-red-600 text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
    case Color.Green:
      return "bg-emerald-600 text-white hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
    case Color.Orange:
      return "bg-orange-500 text-white hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
    case Color.Yellow:
      return "bg-yellow-500 text-white hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
    case Color.Gray:
      return "bg-gray-600 text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
    case Color.Purple:
      return "bg-purple-600 text-white hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
  }
}

export const softButtonColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "bg-sky-50 text-sky-600 hover:bg-sky-100"
    case Color.Red:
      return "bg-red-50 text-red-600 hover:bg-red-100"
    case Color.Green:
      return "bg-emerald-50 text-emerald-600 hover:bg-emerald-100"
    case Color.Orange:
      return "bg-orange-50 text-orange-600 hover:bg-orange-100"
    case Color.Yellow:
      return "bg-yellow-50 text-yellow-600 hover:bg-yellow-100"
    case Color.Gray:
      return "bg-gray-50 text-gray-600 hover:bg-gray-100"
    case Color.Purple:
      return "bg-purple-50 text-purple-600 hover:bg-purple-100"
  }
}

export const backgroundColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Red:
      return "bg-red-100"
    case Color.Yellow:
      return "bg-yellow-100"
    case Color.Blue:
      return "bg-sky-100"
    case Color.Green:
      return "bg-emerald-100"
    case Color.Purple:
      return "bg-purple-100"
    case Color.Orange:
      return "bg-orange-100"
  }
}

export const textColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Red:
      return "text-red-900"
    case Color.Yellow:
      return "text-yellow-900"
    case Color.Blue:
      return "text-sky-900"
    case Color.Green:
      return "text-emerald-900"
    case Color.Purple:
      return "text-purple-900"
    case Color.Orange:
      return "text-orange-900"
  }
}

export const divideColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Red:
      return "divide-red-500"
    case Color.Yellow:
      return "divide-yellow-500"
    case Color.Blue:
      return "divide-sky-500"
    case Color.Green:
      return "divide-emerald-500"
    case Color.Purple:
      return "divide-purple-500"
    case Color.Orange:
      return "divide-orange-500"
  }
}

export const borderColorClassNames = (color: Color) => {
  switch (color) {
    case Color.Red:
      return "border-red-500"
    case Color.Yellow:
      return "border-yellow-500"
    case Color.Blue:
      return "border-sky-500"
    case Color.Green:
      return "border-emerald-500"
    case Color.Purple:
      return "border-purple-500"
    case Color.Orange:
      return "border-orange-500"
  }
}

export const alertBackgroundClassNames = (level: AlertLevel) => {
  switch (level) {
    case AlertLevel.Error:
      return backgroundColorClassNames(Color.Red)
    case AlertLevel.Warning:
      return backgroundColorClassNames(Color.Orange)
    case AlertLevel.Info:
      return backgroundColorClassNames(Color.Blue)
    case AlertLevel.Success:
      return backgroundColorClassNames(Color.Green)
  }
}

export const alertTextClassNames = (level: AlertLevel) => {
  switch (level) {
    case AlertLevel.Error:
      return textColorClassNames(Color.Red)
    case AlertLevel.Warning:
      return textColorClassNames(Color.Orange)
    case AlertLevel.Info:
      return textColorClassNames(Color.Blue)
    case AlertLevel.Success:
      return textColorClassNames(Color.Green)
  }
}

export const alertIcon = (level: AlertLevel): ReactElement<any, any> => {
  switch (level) {
    case AlertLevel.Error:
      return <XCircleIcon className="h5 w-5 text-red-400" aria-hidden="true" />
    case AlertLevel.Warning:
      return (
        <ExclamationTriangleIcon
          className="h5 w-5 text-orange-400"
          aria-hidden="true"
        />
      )
    case AlertLevel.Info:
      return (
        <InformationCircleIcon
          className="h5 w-5 text-sky-400"
          aria-hidden="true"
        />
      )
    case AlertLevel.Success:
      return (
        <CheckCircleIcon
          className="h5 w-5 text-emerald-400"
          aria-hidden="true"
        />
      )
  }
}
