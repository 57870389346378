import React, { useRef, useState } from "react"

import moment from "moment-timezone"

import TimeProtectedModal from "./TimeProtectedModal"

type TimeProtectedMiroLinkProps = {
  email: string
  boardId: string
  startsAt: string
  buttonClassName?: string
  buttonText?: string
  tailwind?: boolean
}

const TimeProtectedMiroLink: React.FC<
  TimeProtectedMiroLinkProps & React.HTMLProps<HTMLAnchorElement>
> = ({
  email,
  boardId,
  startsAt,
  buttonClassName = "btn solid green",
  buttonText = "Whiteboard",
  tailwind,
}) => {
  const form = useRef<HTMLFormElement>()
  const [showModal, setShowModal] = useState(false)
  const [timeString, setTimeString] = useState("")

  const onClick = () => {
    const timeDiff = moment(startsAt).unix() - moment().unix()
    const timeDiffHours = timeDiff / 60 / 60

    if (timeDiffHours <= 1) {
      form.current.submit()
    } else {
      const hours = Math.floor(timeDiffHours)
      const minutes = Math.floor((timeDiffHours - hours) * 60)
      if (hours > 24) {
        const days = Math.floor(hours / 24)
        setTimeString(`${days} ${days === 1 ? "day" : "days"}`)
      } else {
        setTimeString(`${hours} hours and ${minutes} minutes`)
      }
      setShowModal(true)
    }
  }

  return (
    <>
      <form
        ref={form}
        method="POST"
        action="https://miro.com/sso/api/process-email"
        target="_blank"
      >
        <input name="email" type="hidden" value={email} />
        <input name="openBoardId" type="hidden" value={boardId} />
        <button type="button" onClick={onClick} className={buttonClassName}>
          {buttonText}
        </button>
      </form>
      <TimeProtectedModal
        isOpen={showModal}
        setOpen={setShowModal}
        timeString={timeString}
        tailwind={tailwind}
        onSubmit={() => form.current.submit()}
      />
    </>
  )
}

export default TimeProtectedMiroLink
