import React, { useEffect, useRef, useState } from "react"

import dayjs from "dayjs"
import { useField, useFormikContext } from "formik"
import DatePicker, { DateObject } from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox } from "~tailwindui/Basics"
import { TextInput } from "~tailwindui/Form"

import { roundTime } from "src/dateHelpers"
import emptyFunction from "src/emptyFunction"

import CloseButton from "./DateInputCloseButton"

export type DateTimeInputProps = {
  name: string
  label: string
  displayWarnings: boolean
  description?: string
}

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  name,
  label,
  displayWarnings,
  description,
}) => {
  const [datePickerClosed, setDatePickerClosed] = useState(true)
  const [warning, setWarning] = useState<string | null>()
  const datePicker = useRef() as any
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField({ name })

  const error = meta.error || ""

  useEffect(() => {
    const date = dayjs(field.value)
    if (date.minute() % 5 !== 0) {
      setFieldValue(field.name, roundTime(date, 5))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value])

  const openCalendar = () => {
    datePicker.current.openCalendar()
  }

  const updateWarnings = date => {
    if (displayWarnings) {
      if (date.isBefore(dayjs()))
        setWarning("This date is in the past. Is that what you intended?")
      else if (date.diff(dayjs(), "hours") < 48)
        setWarning(
          "This date is less than 2 days away. Please choose a later date if possible."
        )
      else setWarning(null)
    }
  }

  return (
    <div>
      <div className="relative flex w-60">
        <TextInput
          name={`${name}-display`}
          label={label}
          value={field.value?.format("MM/DD/YYYY hh:mm a") || ""}
          onClick={openCalendar}
          onChange={emptyFunction}
        />

        <div className="absolute left-0 top-14 z-10 origin-top-left">
          <DatePicker
            value={
              field.value ? new DateObject(dayjs(field.value).toDate()) : null
            }
            ref={datePicker}
            onChange={(date: DateObject) => {
              const dayjsDate = dayjs(date.toString())
                .set("hour", date.hour)
                .set("minute", date.minute)
                .set("second", 0)
              updateWarnings(dayjsDate)
              setFieldValue(name, dayjsDate)
            }}
            inputClass="hidden"
            editable={false}
            format="M/D/YYYY h:mm a"
            arrow={false}
            onOpen={() => setDatePickerClosed(false)}
            onClose={() => datePickerClosed}
            plugins={[
              <TimePicker key="time" position="right" hideSeconds mStep={5} />,
              <CloseButton
                key="close"
                position="bottom"
                setClosed={setDatePickerClosed}
              />,
            ]}
          />
        </div>
      </div>

      <div className="-mt-3 text-sm">
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>

      {displayWarnings && warning && (
        <AlertMessageBox className="w-60" level={AlertLevel.Warning}>
          {warning}
        </AlertMessageBox>
      )}
    </div>
  )
}

export default DateTimeInput
