import React, { Dispatch, SetStateAction, useEffect } from "react"

import { FormikValues, useFormikContext } from "formik"

import DisplayableFormikForm from "components/OrganizationAdmin/components/DisplayableFormikForm"
import Form from "components/OrganizationAdmin/components/Form"

export type ChangePasswordFormProps = {
  changePassword: boolean
  setChangePassword: Dispatch<SetStateAction<boolean>>
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  changePassword,
  setChangePassword,
}) => {
  const { setFieldValue, values } = useFormikContext<FormikValues>()
  const togglePasswordChange = () => {
    if (changePassword) {
      setChangePassword(false)
      setFieldValue("currentPassword", "")
      setFieldValue("password", "")
      setFieldValue("passwordConfirmation", "")
    } else {
      setChangePassword(true)
    }
  }

  useEffect(() => {
    if (changePassword) togglePasswordChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.editing])
  return (
    <>
      {changePassword ? (
        <>
          <DisplayableFormikForm.PasswordInput
            label="Current Password"
            name="currentPassword"
            autoComplete="current-password"
            subfield
          />
          <DisplayableFormikForm.PasswordInput
            label="New Password"
            name="password"
            autoComplete="new-password"
            subfield
          />
          <DisplayableFormikForm.PasswordInput
            label="Password Confirmation"
            name="passwordConfirmation"
            autoComplete="new-password"
            subfield
          />
          <div className="ml-8">
            <Form.Button text="Cancel" soft className="my-6" />
          </div>
        </>
      ) : (
        <div>
          <Form.Button
            text="Change Password"
            soft
            onClick={togglePasswordChange}
            className="my-6"
          />
        </div>
      )}
    </>
  )
}

export default ChangePasswordForm
