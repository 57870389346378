import React from "react"

import { Form, Formik } from "formik"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import {
  Failures,
  SelectInput,
  SubmitButton,
  TextInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"
import { participationLevelOptions } from "src/enums"
import { RunMutation } from "src/types"

const PresentValidationSchema = Yup.object().shape({
  participationLevel: Yup.string().required("Participation level is required"),
})

const AbsentValidationSchema = Yup.object().shape({
  cancellationReason: Yup.string().required("This field is required"),
})

export type StudentPresenceStatusProps = {
  studentSessionId: string
  noShow: boolean
  excusedAbsence: boolean
  participationLevel?: string
}

const StudentPresenceStatus: React.FC<StudentPresenceStatusProps> = ({
  studentSessionId,
  noShow,
  excusedAbsence,
  participationLevel,
}) => {
  const formState = { participationLevel: "" }

  const [, runPresentMutation]: [any, RunMutation] =
    useMutation(markPresentMutation)
  const [, runNoShowMutation]: [any, RunMutation] =
    useMutation(markNoShowMutation)

  const markPresent = (values, actions) => {
    runPresentMutation({
      studentSessionId,
      participationLevel: values.participationLevel,
    })
      .then(
        result => {
          const { failures } = result.data.studentSessionMarkPresent
          if (failures.length > 0) handleFailure(actions, failures)
          else window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const markNoShow = (values, actions) => {
    runNoShowMutation({
      studentSessionId,
      cancellationReason: values.cancellationReason,
    })
      .then(
        result => {
          const { failures } = result.data.studentSessionMarkNoShow
          if (failures.length > 0) handleFailure(actions, failures)
          else window.location.reload()
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <div>
      Attendance Status:{" "}
      <span className="font-semibold">
        {noShow ? "No Show" : excusedAbsence ? "Excused" : "Present"}
      </span>
      <br />
      Participation Level: {participationLevel}
      <br />
      {noShow ? (
        <Modal.Dialog
          buttonText="Mark as Present"
          buttonClassNames={classNames(
            "mt-2",
            buttonClassNames({
              color: Color.Green,
            })
          )}
        >
          {closeModal => (
            <>
              <Modal.Header
                Icon={CheckCircleIcon}
                iconClassNames="text-emerald-600"
              >
                Mark as Present
              </Modal.Header>
              <Formik
                initialValues={formState}
                validationSchema={PresentValidationSchema}
                onSubmit={markPresent}
              >
                <Form className="flex max-h-[calc(70vh-90px)] flex-col">
                  <Modal.Body>
                    <div className="h-60">
                      <SelectInput
                        name="participationLevel"
                        label="Participation Level"
                        options={participationLevelOptions}
                      />
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Failures />
                    <div className="flex justify-end space-x-1">
                      <Button color={Color.Red} onClick={closeModal}>
                        Cancel
                      </Button>
                      <SubmitButton text="Update" />
                    </div>
                  </Modal.Footer>
                </Form>
              </Formik>
            </>
          )}
        </Modal.Dialog>
      ) : (
        <Modal.Dialog
          buttonText="Mark as No Show"
          buttonClassNames={classNames(
            "mt-2",
            buttonClassNames({
              color: Color.Red,
            })
          )}
        >
          {closeModal => (
            <>
              <Modal.Header Icon={XCircleIcon} iconClassNames="text-red-500">
                Submit No Show
              </Modal.Header>
              <Formik
                initialValues={{ cancellationReason: "" }}
                validationSchema={AbsentValidationSchema}
                onSubmit={markNoShow}
              >
                <Form className="flex max-h-[calc(70vh-90px)] flex-col">
                  <Modal.Body>
                    <TextInput
                      name="cancellationReason"
                      label="Why are you updating this student's no show status?"
                    />
                  </Modal.Body>

                  <Modal.Footer>
                    <Failures />
                    <div className="flex justify-end space-x-1">
                      <Button onClick={closeModal}>Cancel</Button>
                      <SubmitButton text="Submit" color={Color.Red} />
                    </div>
                  </Modal.Footer>
                </Form>
              </Formik>
            </>
          )}
        </Modal.Dialog>
      )}
    </div>
  )
}

const markPresentMutation = gql`
  mutation markPresent(
    $studentSessionId: ID!
    $participationLevel: ParticipationLevelEnum!
  ) {
    studentSessionMarkPresent(
      studentSessionId: $studentSessionId
      participationLevel: $participationLevel
    ) {
      failures {
        message
      }
    }
  }
`

const markNoShowMutation = gql`
  mutation markNoShow($studentSessionId: ID!, $cancellationReason: String!) {
    studentSessionMarkNoShow(
      studentSessionId: $studentSessionId
      cancellationReason: $cancellationReason
    ) {
      failures {
        message
      }
    }
  }
`

export default StudentPresenceStatus
