import React, { useContext } from "react"

import axios from "axios"
import dayjs from "dayjs"
import withBreadcrumbs from "react-router-breadcrumbs-hoc"
import { Route, Switch, useLocation } from "react-router-dom"

import Intercom from "@intercom/messenger-js-sdk"

import Breadcrumbs from "~tailwindui/Breadcrumbs"

import NotificationContainer from "components/NotificationContainer"

import TutorContext from "./TutorContext"
import TutorNav from "./TutorNav"
import TutorRoutes from "./TutorRoutes"

const routesConfig = Object.values(TutorRoutes)
const TutorBreadcrumbs = withBreadcrumbs(routesConfig, {
  disableDefaults: true,
})(Breadcrumbs)
export type AppBodyProps = {
  hideNavRegexp: RegExp[]
}

const AppBody: React.FC<AppBodyProps> = ({ hideNavRegexp }) => {
  const location = useLocation()
  const hideNav = hideNavRegexp.some(path => location.pathname.match(path))
  const { viewer } = useContext(TutorContext)

  const logout = () => {
    axios.delete("/users/sign_out").then(() => (window.location.href = "/"))
  }

  Intercom({
    app_id: process.env.INTERCOM_APP_ID,
    user_id: viewer.id,
    name: viewer.fullName,
    email: viewer.email,
    created_at: dayjs(viewer.createdAt).unix(),
  })

  return (
    <>
      {!hideNav && <TutorNav logout={logout} />}
      <div className={hideNav ? "" : "my-0 mx-auto w-3/4 pb-10"}>
        {!hideNav && <TutorBreadcrumbs routesConfig={routesConfig} />}
        <Switch>
          {routesConfig.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </div>
      <NotificationContainer />
    </>
  )
}

export default AppBody
