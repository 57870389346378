import React, { useEffect, useState } from "react"

import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import dayjs from "src/dayjs"

import Calendar from "./Calendar"
import ScheduleModal from "./ScheduleModal"
import { TutorSchedule } from "./TutorScheduleType"

import "react-big-calendar/lib/css/react-big-calendar.css"

export type ManageSchedulesProps = {
  smallCalendar?: boolean
}

const ManageSchedules: React.FC<ManageSchedulesProps> = ({
  smallCalendar = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState<"edit" | "new" | undefined>()
  const [defaultStart, setDefaultStart] = useState<Date | undefined>()
  const [defaultEnd, setDefaultEnd] = useState<Date | undefined>()
  const [schedule, setSchedule] = useState<TutorSchedule | undefined>()

  useEffect(() => {
    if (modalOpen) return

    setSchedule(undefined)
    setDefaultStart(undefined)
    setDefaultEnd(undefined)
    setModalMode(undefined)
  }, [modalOpen])

  const [result, refetchSchedules] = useQuery({ query })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <>
          <ScheduleModal
            open={modalOpen}
            afterCreate={() =>
              refetchSchedules({ requestPolicy: "network-only" })
            }
            closeModal={() => setModalOpen(false)}
            mode={modalMode}
            defaultStart={dayjs(defaultStart)}
            defaultEnd={dayjs(defaultEnd)}
            schedule={schedule}
            viewerTimeZone={viewer.dayjsTimeZone}
          />

          <Calendar
            smallCalendar={smallCalendar}
            createEvent={({ start, end }) => {
              setDefaultStart(start)
              setDefaultEnd(end)
              setModalOpen(true)
              setModalMode("new")
            }}
            openEvent={event => {
              const schedule = viewer.schedules.find(
                schedule => schedule.id === event.id
              )
              setSchedule(schedule)
              setModalOpen(true)
              setModalMode("edit")
            }}
            schedules={viewer.schedules}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query TutorSchedulesQuery {
    viewer {
      id
      dayjsTimeZone
      schedules {
        id
        startHour
        startMinute
        endHour
        endMinute
        dayjsTimeZone
        weekday
        startDate
        endDate
        frequency
      }
    }
  }
`

export default React.memo(ManageSchedules)
