import React, { useEffect } from "react"

import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import { path } from "components/Tutor/TutorRoutes"

import ExpenseForm from "../components/ExpenseForm"

export type NewExpenseProps = Record<never, any>

const NewExpense: React.FC<NewExpenseProps> = () => {
  const history = useHistory()
  const formState = {
    expenseType: "",
    amount: "",
    description: "",
    comments: "",
    receipts: [],
  }
  const [result] = useQuery({ query: tutorQuery })

  useEffect(() => {
    if (!result.data) return

    const { viewer } = result.data
    if (!viewer.mayCreateExpense) {
      history.push(path("dashboard"))
      NotificationManager.error("You may not create new expenses")
    }
    if (!viewer.billDotComVendorId) {
      history.push(path("dashboard"))
      NotificationManager.error(
        "You have not accepted your Air Tutors bill.com invitation. If you believe you have, please contact an admin."
      )
    }
  }, [result, history])

  return (
    <WithLoadingIndicator result={result}>
      {({ data }) => <ExpenseForm formState={formState} tutor={data.viewer} />}
    </WithLoadingIndicator>
  )
}

const tutorQuery = gql`
  query TutorQuery {
    viewer {
      mayCreateExpense
      billDotComVendorId
      canSubmitGeneralExpenses
      hasSubmittedFingerprintExpense
      hasSubmittedIntroSessionExpense
    }
  }
`

export default NewExpense
