import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import {
  Failures,
  SelectInput,
  SubmitButton,
  TextAreaInput,
  TextInput,
  readFile,
} from "~tailwindui/Form"

import { expenseTypeOptions } from "src/enums"
import { RunMutation } from "src/types"

import { path } from "components/Tutor/TutorRoutes"

import ReceiptsInput from "./ReceiptsInput"

const ValidationSchema = Yup.object().shape({
  expenseType: Yup.string().required(),
  amount: Yup.number()
    .required()
    .test(
      "amount",
      "Intro Session may only be billed as $20",
      function (value) {
        const expenseType = this.parent.expenseType
        if (expenseType === "intro_session" && value !== 20) {
          return false
        }
        return true
      }
    ),
  description: Yup.string().required(),
  receipts: Yup.array().test("file", function (value) {
    if (
      this.parent.receiptsOptional ||
      this.parent.expenseType === "intro_session"
    )
      return true

    if (value.length === 0)
      return this.createError({
        message: "At least 1 receipt is required",
        path: "receipts",
      })

    if (value.some(file => !file.name))
      return this.createError({
        message: "Please select a file for all receipts",
        path: "receipts",
      })

    return true
  }),
})

type Tutor = {
  id: string
  canSubmitGeneralExpenses: boolean
  hasSubmittedFingerprintExpense: boolean
  hasSubmittedIntroSessionExpense: boolean
}

type FormState = {
  expenseType: string
  amount: string
  description: string
  comments: string
  receipts: string[]
}

export type ExpenseFormProps = {
  formState: FormState
  tutor: Tutor
}

const ExpenseForm: React.FC<ExpenseFormProps> = ({ formState, tutor }) => {
  const history = useHistory()
  const [, runMutation]: [any, RunMutation] = useMutation(expenseMutation)

  const handleSubmit = (values, actions) => {
    const { receipts, receiptsOptional, __typename, ...variables } = values
    const promises = receipts.map(receipt => readFile(receipt))
    Promise.allSettled(promises)
      .then(promiseResults => {
        variables.receipts = promiseResults.map((result: any) => result.value)

        runMutation({ input: variables })
          .then(
            result => {
              const { failures } = result.data.saveExpense
              if (failures.length > 0) actions.setStatus(failures)
              else {
                history.push(path("dashboard"))
                NotificationManager.success("Your expense has been submitted")
              }
            },
            () => actions.setStatus([{ message: "Something went wrong" }])
          )
          .catch(() => actions.setStatus([{ message: "Something went wrong" }]))
          .finally(() => actions.setSubmitting(false))
      })
      .catch(() =>
        actions.setStatus([
          { message: "One or more files failed to upload successfully" },
        ])
      )
  }

  let validExpenseTypeOptions = [...expenseTypeOptions]
  if (!tutor.canSubmitGeneralExpenses) {
    validExpenseTypeOptions = validExpenseTypeOptions.filter(
      o => o.value !== "other"
    )
  }
  if (tutor.hasSubmittedFingerprintExpense) {
    validExpenseTypeOptions = validExpenseTypeOptions.filter(
      o => o.value !== "fingerprinting"
    )
  }
  if (tutor.hasSubmittedIntroSessionExpense) {
    validExpenseTypeOptions = validExpenseTypeOptions.filter(
      o => o.value !== "intro_session"
    )
  }

  return (
    <Formik
      initialValues={formState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <SelectInput
          name="expenseType"
          label="Expense Type"
          options={validExpenseTypeOptions}
        />

        <TextInput name="amount" label="Amount" type="number" step={0.01} />
        <TextInput name="description" label="Description" />
        <TextAreaInput name="comments" label="Comments" />
        <ReceiptsInput />
        <Failures />
        <SubmitButton />
      </Form>
    </Formik>
  )
}

const expenseMutation = gql`
  mutation saveExpense($input: ExpenseInputObject!) {
    saveExpense(input: $input) {
      failures {
        message
      }
    }
  }
`

export default ExpenseForm
