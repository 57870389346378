import React, { useEffect } from "react"

import { NotificationManager } from "react-notifications"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

import { MatchParams } from "src/types/MatchParams"

import ChimeCloudroom from "components/ChimeCloudroom"
import { path } from "components/Tutor/TutorRoutes"

import Twilio from "./Twilio"

const TutorCloudroom: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const sessionId = match.params.id
  const history = useHistory()

  const [result] = useQuery({ query, variables: { sessionId } })

  useEffect(() => {
    if (!result.data) return
    if (!result.data.session.cloudroomActive) {
      history.push(path("dashboard"))
      NotificationManager.error(
        "This session's Cloud Room is not active at this time"
      )
    }
  }, [result, history])

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { session } }) => (
        <>
          {session.chimeCloudroomEnabled ? (
            <ChimeCloudroom sessionId={sessionId} />
          ) : (
            <Twilio sessionId={sessionId} />
          )}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query CloudroomQuery($sessionId: ID!) {
    session(id: $sessionId) {
      id
      cloudroomActive
      chimeCloudroomEnabled
    }
  }
`

export default TutorCloudroom
