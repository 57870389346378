import React from "react"

import { buildMutation, useMutation } from "micro-graphql-react"
import PropTypes from "prop-types"

import { Negative, Neutral, Positive } from "components/Icons"

import approveTutorSubjectMutation from "./approveTutorSubjectMutation"
import rejectTutorSubjectMutation from "./rejectTutorSubjectMutation"
import styles from "./styles.module.scss"

const TutorSubject = ({ tutorSubject, canAdminTutorApplications }) => {
  const { running: approveRunning, runMutation: runApproveMutation } =
    useMutation(buildMutation(approveTutorSubjectMutation))

  const { running: rejectRunning, runMutation: runRejectMutation } =
    useMutation(buildMutation(rejectTutorSubjectMutation))

  const approveTutorSubject = () => {
    runApproveMutation({ id: tutorSubject.id })
  }

  const rejectTutorSubject = () => {
    runRejectMutation({ id: tutorSubject.id })
  }

  const statusIcon = tutorSubject => {
    switch (tutorSubject.approvalState) {
      case "approved":
        return <Positive />
      case "rejected":
        return <Negative />
      default:
        return <Neutral />
    }
  }

  const spinner = <i className="nc-icon nc-refresh-69 spinner" />

  return (
    <React.Fragment>
      <div className={styles.subjectName}>
        {statusIcon(tutorSubject)}
        <span>
          {tutorSubject.subjectBucket.name}
          {tutorSubject.approvalType === "directory" && (
            <span className="font-semibold"> (Directory)</span>
          )}
        </span>
      </div>
      {tutorSubject.approvalState !== "approved" &&
        canAdminTutorApplications && (
          <React.Fragment>
            <button
              onClick={approveTutorSubject}
              className={`${styles.actionButton} btn btn-sm btn-success`}
              disabled={approveRunning}
            >
              Approve
              {approveRunning && spinner}
            </button>
          </React.Fragment>
        )}
      {tutorSubject.approvalState !== "rejected" &&
        canAdminTutorApplications && (
          <React.Fragment>
            <button
              onClick={rejectTutorSubject}
              className={`${styles.actionButton} btn btn-sm btn-danger`}
              disabled={rejectRunning}
            >
              Reject
              {rejectRunning && spinner}
            </button>
          </React.Fragment>
        )}
    </React.Fragment>
  )
}

TutorSubject.propTypes = {
  tutorSubject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    approvalState: PropTypes.string.isRequired,
    subjectBucket: PropTypes.object.isRequired,
  }).isRequired,
}

export default TutorSubject
