import React from "react"

import { Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { H2, Link } from "~tailwindui/Basics"

import BarChart from "components/Tutor/Pages/Students/TestPrep/components/BarChart"

type ChartData = {
  labels: string[]
  correctCounts: number[]
  incorrectCounts: number[]
  skippedCounts: number[]
  totalCounts: number[]
}

type SectionInstance = {
  correctCount: number
  incorrectCount: number
  skippedCount: number
  totalCount: number
  section: {
    subject: string
  }
  exam: {
    name: string
  }
}

type ExamInstance = {
  id: string
  correctCount: number
  incorrectCount: number
  skippedCount: number
  totalCount: number
  exam: {
    name: string
  }
  sections: {
    subject: string
  }
  sectionInstances: SectionInstance[]
}

export type ExamCategoryProps = {
  title: string
  examInstances: ExamInstance[]
  overallExamData: ChartData
  subjectExamData: {
    name: string
    data: ChartData
  }[]
  studentId: string
}

const ExamCategory: React.FC<ExamCategoryProps> = ({
  title,
  examInstances,
  overallExamData,
  subjectExamData,
  studentId,
}) => {
  return (
    <>
      <H2>{title}</H2>
      {examInstances.length === 0 ? (
        <span className="italic">No {title} exams</span>
      ) : (
        <>
          {examInstances.map(examInstance => (
            <div key={examInstance.id}>
              <h3>{examInstance.exam.name}</h3>
              <Link
                to={path("studentTestPrepExamInstanceDetails", {
                  studentId,
                  id: examInstance.id,
                })}
                button
                size={Size.Small}
              >
                View Results
              </Link>
            </div>
          ))}

          <h3>Test History</h3>
          <BarChart title="Overall" data={overallExamData} />
          {subjectExamData.map(subject => (
            <BarChart
              key={subject.name}
              title={subject.name}
              data={subject.data}
            />
          ))}
        </>
      )}
    </>
  )
}

export default ExamCategory
