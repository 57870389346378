import React, { useEffect, useState } from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import {
  AlertMessageBox,
  H2,
  H3,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import {
  CheckboxInput,
  Error,
  Failures,
  SelectInput,
  SubmitButton,
  TextAreaInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"
import { MatchParams } from "src/types/MatchParams"

import NotFound from "components/Tutor/Pages/NotFound"

import Evaluation from "../components/Evaluation"
import SessionInfo from "../components/SessionInfo"

import StudentParticipation from "./StudentParticipation"
import TutorAssessment from "./TutorAssessment"
import { durationValidator } from "./validators"

type StudentData = {
  participationLevel: string
  tutorAssessment: string
}

const BillSession: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const id = match.params.id
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false)
  const history = useHistory()

  const [result] = useQuery({
    query: sessionQuery,
    variables: { id },
  })

  const [mutationState, runMutation]: [any, RunMutation] =
    useMutation(billSessionMutation)

  useEffect(() => {
    if (!result.data?.session || mutationState.data) return
    const { session } = result.data
    if (session.statusState === "canceled") {
      history.push(path("dashboard"))
      NotificationManager.error("Unable to bill canceled session")
    }
    if (["pending", "billed", "comped"].includes(session.billState)) {
      history.push(path("dashboard"))
      NotificationManager.warning("Session has already been billed")
    }
  }, [result, history, mutationState])

  const buildFormState = session => {
    const {
      billedDurationMinutes,
      roundedSuggestedDurationMinutes,
      lessonResourceCompletions,
      tutorFeedback,
      topicsCovered,
      studentSessions,
      sessionPresences,
      scheduledDurationMinutes,
    } = session

    const resourceCompletions = {}
    lessonResourceCompletions.forEach(completion => {
      resourceCompletions[completion.id] = {
        id: completion.id,
        tutorMastery: completion.tutorMastery,
        tutorTopicMastery: completion.tutorTopicMastery,
      }
    })

    const studentParticipation = {}
    const tutorAssessment = {}
    studentSessions.forEach(({ student }) => {
      const presenceDuration = sessionPresences
        .filter(presence => presence.user.id === student.id)
        .map(presence => presence.duration)
        .reduce((prev, curr) => prev + curr, 0)
      const minimumDuration = scheduledDurationMinutes / 4

      studentParticipation[student.id] = {
        studentId: student.id,
        participationLevel: presenceDuration < minimumDuration ? "absent" : "",
      }
      tutorAssessment[student.id] = {
        studentId: student.id,
        tutorAssessment: presenceDuration < minimumDuration ? "absent" : "",
      }
    })

    const durationDefaultValue =
      billedDurationMinutes || roundedSuggestedDurationMinutes

    const formState = {
      participationLevel: [],
      confirmZeroHours: false,
      durationMinutes: durationDefaultValue % 60,
      durationHours: Math.floor(durationDefaultValue / 60),
      lessonResourceCompletions: resourceCompletions,
      tutorFeedback: tutorFeedback || "",
      topicsCovered: topicsCovered || "",
      studentParticipation,
      tutorAssessment,
    }

    return formState
  }

  const buildValidationSchema = session => {
    const { studentSessions } = session

    return Yup.object()
      .shape({
        tutorFeedback: Yup.string().required("Feedback is a required field"),
        topicsCovered: Yup.string().required(
          "Topics covered is a required field"
        ),
        studentParticipation: Yup.mixed().test(
          "studentParticipation",
          "You must specify a participation level for every student",
          studentParticipation => {
            const participationValues = Object.values(studentParticipation)
              .map((sp: StudentData) => sp.participationLevel)
              .filter(Boolean)

            return participationValues.length === studentSessions.length
          }
        ),
        tutorAssessment: Yup.mixed().test(
          "tutorAssessment",
          "You must specify an assessment level for every student",
          tutorAssessment => {
            const assessmentValues = Object.values(tutorAssessment)
              .map((ta: StudentData) => ta.tutorAssessment)
              .filter(Boolean)

            return assessmentValues.length === studentSessions.length
          }
        ),
        confirmZeroHours: Yup.boolean().when(
          ["durationHours", "durationMinutes"],
          {
            is: (durationHours, durationMinutes) =>
              durationHours === 0 && durationMinutes === 0,
            then: Yup.boolean().oneOf(
              [true],
              "Please check the box to confirm"
            ),
          }
        ),
      })
      .test("actualDuration", function (values) {
        const result = durationValidator(values, session)
        if (typeof result === "string")
          return this.createError({ message: result, path: "actualDuration" })
        else return true
      })
  }

  const handleSubmit = (values, actions) => {
    const {
      lessonResourceCompletions,
      studentParticipation,
      tutorAssessment,
      confirmZeroHours,
      durationMinutes,
      durationHours,
      ...rest
    } = values

    runMutation({
      id,
      actualDuration: `${durationHours}:${durationMinutes}`,
      lessonResourceCompletions: Object.values(lessonResourceCompletions),
      studentParticipation: Object.values(studentParticipation).filter(
        (pl: StudentData) => pl.participationLevel
      ),
      tutorAssessment: Object.values(tutorAssessment).filter(
        (ta: StudentData) => ta.tutorAssessment
      ),
      ...rest,
    })
      .then(
        response => {
          const { failures, session } = response.data.billSession
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            NotificationManager.success("Session billed")
            if (
              session.studyGroup.hasFutureSessions ||
              session.studyGroup.hasAvailabilities
            ) {
              history.push(path("dashboard"))
            } else {
              setFollowUpModalOpen(true)
            }
          }
        },
        error => handleFailure(actions)
      )
      .catch(error => handleFailure(actions))
      .finally(() => actions.setSubmitting(false))
  }

  if (result.data && !result.data.session) return <NotFound />

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { session } }) => (
        <>
          <H2 className="text-center">Bill Session</H2>
          <hr />
          <Modal.Dialog
            hideButton
            isOpen={followUpModalOpen}
            closeModal={() => setFollowUpModalOpen(false)}
          >
            <>
              <Modal.Header>Schedule Follow Up</Modal.Header>
              <Modal.Body>
                Would you like to schedule a follow-up session?
              </Modal.Body>
              <Modal.Footer>
                <div className="flex justify-end space-x-2">
                  <Link
                    to={path("studyGroupScheduleSession", {
                      id: session.studyGroup.id,
                    })}
                    button
                    className="w-16"
                  >
                    Yes
                  </Link>
                  <Link to="/" button color={Color.Red} className="w-16">
                    No
                  </Link>
                </div>
              </Modal.Footer>
            </>
          </Modal.Dialog>

          <SessionInfo session={session} />

          <Formik
            initialValues={buildFormState(session)}
            validationSchema={buildValidationSchema(session)}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <hr />
                <StudentParticipation
                  studentSessions={session.studentSessions}
                  sessionPresences={session.sessionPresences}
                />
                <hr />
                <TutorAssessment
                  studentSessions={session.studentSessions}
                  sessionPresences={session.sessionPresences}
                />

                {session.lessonResourceCompletions.length > 0 && <hr />}
                <Evaluation
                  airtutorsSession={session}
                  values={values}
                  setFieldValue={setFieldValue}
                />

                <hr />

                <div className="flex flex-col items-center">
                  <H3>Actual Duration</H3>

                  {session.sessionIncomplete && (
                    <AlertMessageBox level={AlertLevel.Warning}>
                      Note: Please double-check the actual duration as this
                      session was not ended properly. For all future sessions,
                      hit the "End Session" button before closing the browser.
                    </AlertMessageBox>
                  )}

                  <AlertMessageBox level={AlertLevel.Info}>
                    Select a duration, rounded to the nearest 15 minute
                    increment; ie, a 40 minute session should be billed for 45
                    minutes
                  </AlertMessageBox>

                  <div className="flex flex-col items-center sm:flex-row sm:gap-12">
                    <SelectInput
                      name="durationHours"
                      label="Hours"
                      options={[
                        { label: "0 hours", value: 0 },
                        { label: "1 hour", value: 1 },
                        { label: "2 hours", value: 2 },
                        { label: "3 hours", value: 3 },
                      ]}
                      defaultValue={
                        values.durationHours !== null && values.durationHours
                      }
                    />
                    <div className="mt-5 italic">and</div>
                    <SelectInput
                      name="durationMinutes"
                      label="Minutes"
                      options={[
                        { label: "0 minutes", value: 0 },
                        { label: "15 minutes", value: 15 },
                        { label: "30 minutes", value: 30 },
                        { label: "45 minutes", value: 45 },
                      ]}
                      defaultValue={
                        values.durationMinutes !== null &&
                        values.durationMinutes
                      }
                    />
                  </div>
                  <Error name="actualDuration" />
                </div>

                <hr />
                <div className="mx-auto flex w-1/2 flex-col items-center">
                  <H3>Session Feedback</H3>
                  <AlertMessageBox level={AlertLevel.Info}>
                    Note: If this session was scheduled through an organization
                    or district, your feedback may be be provided to school
                    administrators.
                  </AlertMessageBox>
                  <TextAreaInput
                    name="tutorFeedback"
                    label=""
                    description={`${
                      500 - values.tutorFeedback.length
                    }/500 characters remaining`}
                  />
                </div>

                <div className="mx-auto mt-7 flex w-1/2 flex-col items-center">
                  <H3>Topics Covered</H3>
                  <TextAreaInput
                    name="topicsCovered"
                    label=""
                    description={`${
                      500 - values.topicsCovered.length
                    }/500 characters remaining`}
                  />
                </div>

                <hr />
                <div className="my-4 mx-auto mb-4 w-1/2 text-center">
                  <div className="italic text-red-500">
                    {values.durationHours === 0 &&
                      values.durationMinutes === 0 && (
                        <CheckboxInput
                          checkboxType="single"
                          label="Please confirm that you intend to bill for zero hours."
                          name="confirmZeroHours"
                        />
                      )}
                  </div>

                  <Failures />

                  <SubmitButton text="Complete" />
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const sessionQuery = gql`
  query BillSessionQuery($id: ID!) {
    session(id: $id) {
      id
      statusState
      billState
      canceledBy
      cancellationReason
      compedReason
      startsAt
      scheduledDurationMinutes
      roundedScheduledDurationMinutes
      billedDurationMinutes
      roundedSuggestedDurationMinutes
      sessionIncomplete
      canBillLongSessions
      canExceedScheduledDuration
      substituteNotes
      topicsCovered
      tutorFeedback
      studyGroup {
        id
        name
        details
        showPath
      }
      originalTutor {
        id
        fullName
        email
      }
      subject {
        id
        name
      }
      studentSessions {
        student {
          id
          fullName
          lastName
          notes
        }
      }
      organization {
        id
        name
        details
        allowLongNoShows
      }
      sessionPresences {
        id
        duration
        user {
          id
        }
      }
      lessonResourceCompletions {
        id
        tutorMastery
        tutorTopicMastery
        lessonResource {
          name
          sortOrder
        }
        lessonTopic {
          sortOrder
        }
        student {
          fullName
        }
        completedLessonTopic {
          id
          name
          sortOrder
        }
      }
    }
  }
`

const billSessionMutation = gql`
  mutation billSession(
    $id: ID!
    $actualDuration: String!
    $tutorFeedback: String!
    $topicsCovered: String!
    $lessonResourceCompletions: [LessonResourceCompletionInputObject!]
    $studentParticipation: [StudentParticipationInputObject!]!
    $tutorAssessment: [TutorAssessmentInputObject!]!
  ) {
    billSession(
      id: $id
      actualDuration: $actualDuration
      tutorFeedback: $tutorFeedback
      topicsCovered: $topicsCovered
      lessonResourceCompletions: $lessonResourceCompletions
      studentParticipation: $studentParticipation
      tutorAssessment: $tutorAssessment
    ) {
      session {
        id
        studyGroup {
          hasFutureSessions
          hasAvailabilities
        }
      }
      failures {
        message
      }
    }
  }
`

export default BillSession
