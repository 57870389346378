import React from "react"

import {
  BanknotesIcon,
  CalendarIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline"

import { Color } from "~tailwindui/types/enums"

import { path } from "components/Tutor/TutorRoutes"
import ActionList from "components/Tutor/components/ActionList"

export type ToDoProps = {
  viewer: any
}

const ToDo: React.FC<ToDoProps> = ({ viewer }) => {
  return (
    <ActionList
      title="To Do"
      color={Color.Yellow}
      emptyText="You're all caught up!"
      listItems={[
        viewer.unbilledSessions.data.length > 0 && {
          icon: (
            <BanknotesIcon className="mr-3 inline-block h-8 w-8 text-yellow-600" />
          ),
          path: path("unbilledSessionsIndex"),
          text: `Bill Recent Sessions (${viewer.unbilledSessions.data.length})`,
        },
        viewer.studyGroups.data.length > 0 && {
          icon: (
            <UserGroupIcon className="mr-3 inline-block h-8 w-8 text-yellow-600" />
          ),
          path: path("studyGroupsIndexNeedingScheduling"),
          text: `Schedule Sessions for new Study Groups (${viewer.studyGroups.data.length})`,
        },
        !viewer.dismissedCalendarInfo &&
          viewer.calendarId && {
            icon: (
              <CalendarIcon className="mr-3 inline-block h-8 w-8 text-yellow-600" />
            ),
            path: `${path("dashboard")}?calendar_instructions=true`,
            text: "Link Your Google Calendar",
          },
      ]}
    />
  )
}

export default ToDo
