import React, { createRef, useEffect, useRef } from "react"

import { BarChart as ApacheBarChart } from "echarts/charts"
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import * as echarts from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"

import titlecase from "src/titlecase"

type DataGroup = Record<string, { value: number; order: number }>

type StackedBarChartProps = {
  groupedData: Record<string, DataGroup>
  hideLegend?: boolean
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({
  groupedData,
  hideLegend = false,
}) => {
  const sortedKeys = Object.keys(groupedData[Object.keys(groupedData)[0]]).sort(
    (a, b) =>
      groupedData[Object.keys(groupedData)[0]][a].order -
      groupedData[Object.keys(groupedData)[0]][b].order
  )

  const series = Object.keys(groupedData).map(groupName => ({
    data: sortedKeys.map(key => groupedData[groupName][key].value),
    type: "bar",
    stack: "a",
    name: titlecase(groupName),
  }))

  // start logic from echarts
  const stackInfo: {
    [key: string]: { stackStart: number[]; stackEnd: number[] }
  } = {}
  for (let i = 0; i < series[0].data.length; ++i) {
    for (let j = 0; j < series.length; ++j) {
      const stackName = series[j].stack
      if (!stackName) {
        continue
      }
      if (!stackInfo[stackName]) {
        stackInfo[stackName] = {
          stackStart: [],
          stackEnd: [],
        }
      }
      const info = stackInfo[stackName]
      const data = series[j].data[i]
      if (data) {
        if (info.stackStart[i] == null) {
          info.stackStart[i] = j
        }
        info.stackEnd[i] = j
      }
    }
  }

  for (let i = 0; i < series.length; ++i) {
    const data = series[i].data as number[] | { value: number }[]
    for (let j = 0; j < series[i].data.length; ++j) {
      data[j] = {
        value: data[j] as number,
      }
    }
  }
  // end logic from echarts

  const chartOpts = {
    title: {
      show: true,
      text: "Sessions By Subject",
      left: "center",
    },
    legend: { show: !hideLegend, top: "bottom" },
    tooltip: {},
    xAxis: {
      type: "category",
      data: sortedKeys,
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      max: value => (value.max < 3 ? 3 : value.max),
    },
    series: series as any,
  }

  const chartContainer = createRef<HTMLDivElement>()
  const chartObject = useRef<echarts.ECharts>()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    echarts.use([
      ApacheBarChart,
      CanvasRenderer,
      GridComponent,
      TitleComponent,
      TooltipComponent,
      LegendComponent,
    ])
    chartObject.current = echarts.init(chartContainer.current)
    chartObject.current.setOption(chartOpts)

    return () => {
      if (chartContainer.current) {
        echarts.dispose(chartContainer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (chartOpts) chartObject.current.setOption(chartOpts)
  }, [groupedData])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const resize = () => {
      if (chartObject.current) chartObject.current.resize()
    }
    window.addEventListener("resize", resize)

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  return <div className="h-[50vh] w-full" ref={chartContainer} />
}

export default StackedBarChart
