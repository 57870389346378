import React from "react"

import { NotificationManager } from "react-notifications"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"

import { H2, WithLoadingIndicator } from "~tailwindui/Basics"
import { handleFailure } from "~tailwindui/Form"

import { MatchParams, RunMutation } from "src/types"

import NotFound from "components/Tutor/Pages/NotFound"
import { findRoute } from "components/Tutor/TutorRoutes"

import CourseForm from "../Form"

export type EditCourseClusterProps = Record<string, never>

const EditCourseCluster: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const history = useHistory()
  const id = match.params.id
  const [result] = useQuery({
    query,
    variables: { id },
    requestPolicy: "network-only",
  })

  const [, runMutation]: [any, RunMutation] = useMutation(
    updateCourseClusterMutation
  )

  const handleSubmit = (values, actions) => {
    const { prepaidPrice, courseSections, ...rest } = values
    runMutation({
      input: {
        ...rest,
        prepaidPriceCents: values.prepaidPrice * 100,
        courseSections: courseSections.map(
          ({ editable, ...section }) => section
        ),
      },
    })
      .then(
        result => {
          const { failures } = result.data.updateDirectoryCourseCluster
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            history.push(findRoute("directory.courseClustersIndex").path)
            NotificationManager.success("Your course has been updated")
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  if (result.data && !result.data.courseCluster) return <NotFound />

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { courseCluster } }) => (
        <div className="pb-10 lg:w-2/3">
          <H2>Edit {courseCluster.name}</H2>
          <CourseForm onSubmit={handleSubmit} courseCluster={courseCluster} />
        </div>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query DirectoryDashboardQuery($id: ID!) {
    courseCluster(id: $id) {
      id
      name
      description
      foreignLanguage {
        id
        name
      }
      subject {
        id
        name
      }
      grades {
        id
        name
      }
      courses {
        id
        prepaidPrice
        durationWeeks
        sessionsPerWeek
        maximumStudyGroupSize
        minimumStudyGroupSize
        courseSections {
          id
          startsOn
          endsOn
          availabilities {
            id
            weekday
            startsAt
            endsAt
          }
          studyGroups {
            id
          }
        }
      }
    }
  }
`

const updateCourseClusterMutation = gql`
  mutation updateDirectoryCourseCluster($input: DirectoryCourseInputObject!) {
    updateDirectoryCourseCluster(input: $input) {
      failures {
        message
      }
      courseCluster {
        id
      }
    }
  }
`
export default EditCourseCluster
