import React from "react"

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid"

type StudentSession = {
  noShow: boolean
  excusedAbsence: boolean
}

type Session = {
  noShow: boolean
  statusState: string
  billState: string
}

export const studentPresenceIcon = (
  studentSession: StudentSession,
  session: Session
) => {
  if (studentSession.noShow)
    return <XCircleIcon className="h5 w-5 text-red-400" />
  if (studentSession.excusedAbsence)
    return <XCircleIcon className="h5 w-5 text-blue-400" />
  if (session.statusState !== "scheduled") return "N/A"
  if (session.billState !== "billed") return null
  else return <CheckCircleIcon className="h5 w-5 text-emerald-600" />
}

export const sessionPresenceIcon = (session: Session) => {
  if (session.noShow) return <XCircleIcon className="h5 w-5 text-red-400" />
  if (session.statusState !== "scheduled") return "N/A"
  if (session.billState !== "billed") return null
  else return <CheckCircleIcon className="h5 w-5 text-emerald-600" />
}
