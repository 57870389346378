import React from "react"

import { gql } from "@urql/core"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const EditTutor = ({ tutorId, canAdminExpenses, canAdminBudgets }) => {
  const [result] = useQuery({
    query: tutorQuery,
    variables: { tutorId },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <Form
          tutor={data.tutor}
          tiers={data.tiers}
          canAdminExpenses={canAdminExpenses}
          canAdminBudgets={canAdminBudgets}
        />
      )}
    </UrqlLoadingIndicator>
  )
}

const tutorQuery = gql`
  query ($tutorId: ID!) {
    tutor(id: $tutorId) {
      id
      airtutors
      directory
      firstName
      lastName
      email
      personalEmail
      phoneNumber
      education
      bio
      emailTimeZone
      realtimeboardAdmin
      disableTutorNotifications
      activeCourseStudyGroupLimit
      canSubmitGeneralExpenses
      hasSubmittedFingerprintExpense
      hasSubmittedIntroSessionExpense
      billDotComVendorId
      onDemandTutor
      billableName
      tiers {
        id
      }
      address {
        country
        region
      }
    }
    tiers {
      id
      gradeRange
      order
    }
  }
`

export default EditTutor
