import React from "react"

import SmallLogoImageSrc from "assets/airtutors-logo-square.png"
import LogoImageSrc from "assets/airtutors_banner.png"
import axios from "axios"
import { NotificationContainer } from "react-notifications"
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Link as RouterLink,
  Switch,
} from "react-router-dom"
import { Provider } from "urql"

import {
  Box,
  CSSReset,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Link,
  Stack,
  ThemeProvider,
  useDisclosure,
} from "@chakra-ui/core"
import { Global } from "@emotion/core"

import { useClient } from "hooks/urql"

import NavLink from "components/Pages/NavLink"
import EditPassword from "components/Pages/Teacher/EditPassword"
import EditProfile from "components/Pages/Teacher/EditProfile"
import ErrorBoundary from "components/Pages/Teacher/ErrorBoundary"
import SessionCloudroom from "components/Pages/Teacher/SessionCloudroom"
import SessionsIndex from "components/Pages/Teacher/SessionsIndex"
import StudyGroupsIndex from "components/Pages/Teacher/StudyGroupsIndex"
import theme from "components/Pages/theme"

import "react-notifications/lib/notifications.css"

const TeacherApp = () => {
  const client = useClient()
  const logout = () => {
    axios.delete("/users/sign_out").then(() => (window.location.href = "/"))
  }

  const { isOpen, onToggle, onClose } = useDisclosure()

  const navLinkProps = {
    p: 4,
    _hover: {
      bg: "blue.800",
      color: "white",
    },
    height: "full",
    display: "flex",
    alignItems: "center",
    onClick: e => e.currentTarget.blur(),
  }

  return (
    <ErrorBoundary name="topLevel">
      <Provider value={client}>
        <ThemeProvider theme={theme}>
          <CSSReset />
          <Global
            styles={{
              body: {
                backgroundColor: theme.colors.gray[400],
              },
            }}
          />
          <NotificationContainer />
          <Router basename="/teacher">
            <Switch>
              <Route path="/sessions/:id/cloudroom">
                <SessionCloudroom />
              </Route>
              <Route>
                <Box pb="16">
                  <Box
                    as="header"
                    position="fixed"
                    height="16"
                    top="0"
                    left="0"
                    zIndex="sticky"
                    bg="white"
                    w="full"
                  >
                    <Flex
                      display={{ sm: "none", md: "flex" }}
                      justify="space-between"
                      height="full"
                    >
                      <Stack isInline spacing="0" alignItems="center">
                        <Link
                          as={RouterLink}
                          to="/"
                          {...navLinkProps}
                          padding="0"
                          display="flex"
                          alignItems="center"
                          px="4"
                          _hover={{
                            bg: "inherit",
                          }}
                        >
                          <Image
                            src={LogoImageSrc}
                            alt="Airtutors Logo"
                            height="12"
                          />
                        </Link>
                        <Link as={NavLink} to="/sessions" {...navLinkProps}>
                          Sessions
                        </Link>
                        <Link as={NavLink} to="/study-groups" {...navLinkProps}>
                          Classes
                        </Link>
                      </Stack>
                      <Stack isInline spacing="0">
                        <Link as={NavLink} to="/profile" {...navLinkProps}>
                          Edit Profile
                        </Link>
                        <Link {...navLinkProps} onClick={logout}>
                          Logout
                        </Link>
                      </Stack>
                    </Flex>

                    <Flex
                      display={{ sm: "flex", md: "none" }}
                      justify="space-between"
                      height="full"
                    >
                      <Link
                        as={RouterLink}
                        to="/"
                        {...navLinkProps}
                        padding="0"
                        display="flex"
                        alignItems="center"
                        px="4"
                        _hover={{
                          bg: "inherit",
                        }}
                      >
                        <Image
                          src={SmallLogoImageSrc}
                          alt="Airtutors Logo"
                          height="12"
                        />
                      </Link>
                      <IconButton
                        aria-label="Navigation Menu"
                        icon="drag-handle"
                        variant="ghost"
                        onClick={onToggle}
                        height="full"
                      />
                      <Drawer
                        size="xs"
                        isOpen={isOpen}
                        placement="left"
                        onClose={onClose}
                      >
                        <DrawerOverlay />
                        <DrawerContent>
                          <DrawerBody p={0}>
                            <Box contentHeight="100vh" top="0">
                              <Stack>
                                <Link
                                  as={NavLink}
                                  to="/sessions"
                                  {...navLinkProps}
                                >
                                  Sessions
                                </Link>
                                <Link
                                  as={NavLink}
                                  to="/study-groups"
                                  {...navLinkProps}
                                >
                                  Classes
                                </Link>
                                <Link
                                  as={NavLink}
                                  to="/profile"
                                  {...navLinkProps}
                                >
                                  Edit Profile
                                </Link>
                                <Link {...navLinkProps} onClick={logout}>
                                  Logout
                                </Link>
                              </Stack>
                            </Box>
                          </DrawerBody>
                        </DrawerContent>
                      </Drawer>
                    </Flex>
                  </Box>

                  <Box mx={["8", "2", "8"]} mt="16" pt="4">
                    <Switch>
                      <Route exact path="/sessions">
                        <SessionsIndex />
                      </Route>
                      <Route path="/sessions/semesters/:semesterId">
                        <SessionsIndex />
                      </Route>
                      <Route path="/study-groups">
                        <StudyGroupsIndex />
                      </Route>
                      <Route path="/profile">
                        <EditProfile />
                      </Route>
                      <Route path="/password">
                        <EditPassword />
                      </Route>
                      <Route>
                        <Redirect to="/sessions" />
                      </Route>
                    </Switch>
                  </Box>
                </Box>
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default TeacherApp
