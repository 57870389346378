import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { gql, useClient } from "urql"

import { Button } from "~tailwindui/Basics"

import { reportGraphQLError } from "src/airbrake-notifier"
import ordered from "src/ordered"

import LessonResource from "./Details"

type LessonTopicProps = {
  sessionId: string
  lessonTopicId: string
  name: string
}

const LessonTopic: React.FC<LessonTopicProps> = ({
  sessionId,
  lessonTopicId,
  name,
}) => {
  const client = useClient()
  const [resources, setResources] = useState([])
  const [showResources, setShowResources] = useState(false)

  const loadResources = () => {
    client
      .query(loadResourcesQuery, { sessionId, lessonTopicId })
      .toPromise()
      .then(result => {
        if (!result.data) {
          if (result.error?.response?.status === 422) {
            window.location.reload()
          } else {
            NotificationManager.error("Unable to load resources")
            reportGraphQLError({
              result,
              component:
                "Tutor/AirtutorsSessions/Pages/LessonResources/LessonTopic",
              action: "loadResources",
            })
          }
          return
        }
        setResources(result.data.airtutorsSession.lessonTopic.lessonResources)
        setShowResources(true)
      })
  }

  const toggleResources = () => {
    if (resources.length === 0) loadResources()
    else setShowResources(!showResources)
  }

  return (
    <div>
      <div className="space-between my-4 flex items-center">
        <h3>{name}</h3>
        <Button outline onClick={toggleResources} className="ml-2">
          {showResources ? "Hide Resources" : "Show Resources"}
        </Button>
      </div>

      <ul>
        {showResources &&
          ordered(resources, "sortOrder").map(resource => (
            <LessonResource
              key={resource.id}
              airtutorsSessionId={sessionId}
              lessonResource={resource}
              isCurrent={false}
            />
          ))}
      </ul>
    </div>
  )
}

const loadResourcesQuery = gql`
  query LessonResourcesQuery($sessionId: ID!, $lessonTopicId: ID!) {
    airtutorsSession(id: $sessionId) {
      lessonTopic(id: $lessonTopicId) {
        lessonResources {
          id
          name
          sortOrder
          file {
            thumbUrl
            previewUrl
          }
          lessonTopic {
            id
            name
          }
          lessonPlan {
            id
            name
          }
          lessonResourceCompletions(sessionId: $sessionId) {
            id
            createdAt
            studentMastery
            student {
              id
              fullName
            }
          }
        }
      }
    }
  }
`

export default LessonTopic
