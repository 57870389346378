import React from "react"

import { gql, useQuery } from "urql"

import { WithLoadingIndicator } from "~tailwindui/Basics"

export const StudyGroupName = ({ id }) => {
  const [result] = useQuery({
    query: studyGroupNameQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { studyGroup } }) => <span>{studyGroup?.name}</span>}
    </WithLoadingIndicator>
  )
}

const studyGroupNameQuery = gql`
  query studyGroupBreadcrumbQuery($id: ID!) {
    studyGroup(id: $id) {
      name
    }
  }
`

export const StudentName = ({ id }) => {
  const [result] = useQuery({
    query: studentNameQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { student } }) => <span>{student?.fullName}</span>}
    </WithLoadingIndicator>
  )
}

const studentNameQuery = gql`
  query studentBreadcrumbQuery($id: ID!) {
    student(id: $id) {
      fullName
    }
  }
`

export const TierRange = ({ id }) => {
  const [result] = useQuery({
    query: tierRangeQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { tier } }) => <span>{tier?.gradeRange}</span>}
    </WithLoadingIndicator>
  )
}

const tierRangeQuery = gql`
  query tierBreadcrumbQuery($id: ID!) {
    tier(id: $id) {
      gradeRange
    }
  }
`

export const CourseClusterName = ({ id }) => {
  const [result] = useQuery({
    query: courseClusterName,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { courseCluster } }) => <span>{courseCluster?.name}</span>}
    </WithLoadingIndicator>
  )
}

const courseClusterName = gql`
  query courseClusterBreadcrumbQuery($id: ID!) {
    courseCluster(id: $id) {
      name
    }
  }
`

export const SessionTitle = ({ id }) => {
  const [result] = useQuery({
    query: sessionQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { session } }) => (
        <span>
          {session ? (
            <>
              {session?.subject?.name} with{" "}
              {session?.studyGroup?.name || session?.students[0]?.fullName}
            </>
          ) : (
            "Not Found"
          )}
        </span>
      )}
    </WithLoadingIndicator>
  )
}

const sessionQuery = gql`
  query sessionBreadcrumbQuery($id: ID!) {
    session(id: $id) {
      subject {
        name
      }
      studyGroup {
        name
      }
      students {
        fullName
      }
    }
  }
`

export const ExamInstanceName = ({ id }) => {
  const [result] = useQuery({
    query: examInstanceNameQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { examInstance } }) => <span>{examInstance?.exam?.name}</span>}
    </WithLoadingIndicator>
  )
}

const examInstanceNameQuery = gql`
  query examInstanceBreadcrumbQuery($id: ID!) {
    examInstance(id: $id) {
      exam {
        name
      }
    }
  }
`

export const SectionInstanceName = ({ id }) => {
  const [result] = useQuery({
    query: sectionInstanceNameQuery,
    variables: { id },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { sectionInstance } }) => (
        <span>{sectionInstance?.section?.subject}</span>
      )}
    </WithLoadingIndicator>
  )
}

const sectionInstanceNameQuery = gql`
  query sectionInstanceBreadcrumbQuery($id: ID!) {
    sectionInstance(id: $id) {
      section {
        subject
      }
    }
  }
`

export const StudyGroupInvitationName = ({ id }) => {
  const [result] = useQuery({
    query: studyGroupInvitationNameQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { studyGroupTutorInvitation } }) => (
        <span>
          {studyGroupTutorInvitation ? (
            <>Invitation from {studyGroupTutorInvitation?.organization?.name}</>
          ) : (
            "Not Found"
          )}
        </span>
      )}
    </WithLoadingIndicator>
  )
}

const studyGroupInvitationNameQuery = gql`
  query studyGroupTutorInvitationBreadcrumbQuery($id: ID!) {
    studyGroupTutorInvitation(id: $id) {
      organization {
        name
      }
    }
  }
`

export const TailoredAssignmentName = ({ id }) => {
  const [result] = useQuery({
    query: tailoredAssignmentNameQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { tailoredAssignment } }) => (
        <span>{tailoredAssignment?.name}</span>
      )}
    </WithLoadingIndicator>
  )
}

const tailoredAssignmentNameQuery = gql`
  query tailoredAssignmentBreadcrumbQuery($id: ID!) {
    tailoredAssignment(id: $id) {
      name
    }
  }
`

export const TailoredSectionName = ({ id }) => {
  const [result] = useQuery({
    query: tailoredSectionNameQuery,
    variables: { id },
  })
  return (
    <WithLoadingIndicator result={result}>
      {({ data: { tailoredSection } }) => <span>{tailoredSection?.name}</span>}
    </WithLoadingIndicator>
  )
}

const tailoredSectionNameQuery = gql`
  query tailoredSectionBreadcrumbQuery($id: ID!) {
    tailoredSection(id: $id) {
      name
    }
  }
`
