import React from "react"

import uniq from "src/uniq"

import LocalTime from "components/LocalTime"

type SessionPresence = {
  id: string
  joinedAt: string
  departedAt: string
  user: {
    id: string
    type: string
    fullName: string
  }
}

export type SessionPresencesProps = {
  allPresences: SessionPresence[]
  activePresences: SessionPresence[]
}

const SessionPresences: React.FC<SessionPresencesProps> = ({
  activePresences,
  allPresences,
}) => {
  const activeTutorPresences = activePresences.filter(
    presence => presence.user.type === "Tutor"
  )

  const activeStudentPresences = activePresences.filter(
    presence => presence.user.type === "Student"
  )

  const groupedPresences = {}
  const userIds = uniq(allPresences.map(presence => presence.user.id))
  userIds.forEach(id => {
    const presences = allPresences.filter(presence => presence.user.id === id)
    groupedPresences[id] = presences
  })

  const tutorIds = uniq(
    allPresences
      .filter(presence => presence.user.type === "Tutor")
      .map(presence => presence.user.id)
  )
  const studentIds = uniq(
    allPresences
      .filter(presence => presence.user.type === "Student")
      .map(presence => presence.user.id)
  )

  return (
    <>
      <hr />

      {activePresences.length > 0 && (
        <>
          <h3>Present Users</h3>
          {activeTutorPresences.length > 0 && (
            <>
              <h5>Tutors</h5>
              <table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Joined</th>
                  </tr>
                </thead>
                <tbody>
                  {activeTutorPresences.map(presence => (
                    <tr key={presence.id}>
                      <td>{presence.user.fullName}</td>
                      <td>
                        <LocalTime timestamp={presence.joinedAt} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {activeStudentPresences.length > 0 && (
            <>
              <h5>Students</h5>
              <table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Joined</th>
                  </tr>
                </thead>
                <tbody>
                  {activeStudentPresences.map(presence => (
                    <tr key={presence.id}>
                      <td>{presence.user.fullName}</td>
                      <td>
                        <LocalTime timestamp={presence.joinedAt} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </>
      )}

      <h3>Full Presence History</h3>
      {tutorIds.length > 0 && (
        <>
          <h5>Tutors</h5>
          <table className="table">
            <thead>
              <tr>
                <th>User</th>
                <th>Joined</th>
                <th>Exited</th>
              </tr>
            </thead>
            <tbody>
              {tutorIds.map(userId => (
                <tr key={userId}>
                  <th>{groupedPresences[userId][0].user.fullName}</th>

                  {groupedPresences[userId].map(presence => (
                    <tr key={presence.id}>
                      <td>
                        <LocalTime timestamp={presence.joinedAt} />
                      </td>
                      <td>
                        <LocalTime timestamp={presence.departedAt} />
                      </td>
                    </tr>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {studentIds.length > 0 && (
        <>
          <h5>Students</h5>
          <table className="table">
            <thead>
              <tr>
                <th>User</th>
                <th>Joined</th>
                <th>Exited</th>
              </tr>
            </thead>
            <tbody>
              {studentIds.map(userId => (
                <tr key={userId}>
                  <th>{groupedPresences[userId][0].user.fullName}</th>
                  {groupedPresences[userId].map(presence => (
                    <tr key={presence.id}>
                      <td>
                        <LocalTime timestamp={presence.joinedAt} />
                      </td>
                      <td>
                        <LocalTime timestamp={presence.departedAt} />
                      </td>
                    </tr>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  )
}

export default SessionPresences
