import React from "react"

import PropTypes from "prop-types"

import { gql, useClient, useMutation } from "hooks/urql"

import CancelSessionButton from "components/AirtutorsSession/CancelSessionButton"

const AdminCancelSessionButton = props => {
  const client = useClient()
  const [, runMutation] = useMutation(cancelSessionMutation, client)

  return (
    <CancelSessionButton
      viewerType="Admin"
      runMutation={runMutation}
      {...props}
    />
  )
}

const cancelSessionMutation = gql`
  mutation ($id: ID!, $cancellationReason: String!) {
    cancelSession(id: $id, cancellationReason: $cancellationReason) {
      failures {
        message
      }
    }
  }
`

AdminCancelSessionButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default AdminCancelSessionButton
