import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button } from "~tailwindui/Basics"
import {
  Failures,
  SubmitButton,
  TextAreaInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"
import { RunMutation } from "src/types"

import { path } from "../TutorRoutes"

const ValidationSchema = Yup.object().shape({
  compedReason: Yup.string().required(
    "Please specify a reason (this will only be visible to you and admins)"
  ),
})

export type CompSessionButtonProps = { id: string }

const CompSessionButton: React.FC<CompSessionButtonProps> = ({ id }) => {
  const history = useHistory()

  const [, runMutation]: [any, RunMutation] = useMutation(compSessionMutation)

  const formState = { id, compedReason: "" }

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        result => {
          const { failures } = result.data.compSession
          if (failures.length > 0) {
            handleFailure(actions, failures)
          } else {
            history.push(path("dashboard"))
            NotificationManager.success("Session successfully comped.")
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <Modal.Dialog
      buttonText="Comp Directory Session"
      buttonClassNames={classNames(
        "mb-2",
        buttonClassNames({ color: Color.Red })
      )}
    >
      {closeModal => (
        <>
          <Modal.Header>Comp Directory Session</Modal.Header>
          <Formik
            initialValues={formState}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex max-h-[calc(70vh-90px)] flex-col">
              <Modal.Body>
                <AlertMessageBox level={AlertLevel.Warning}>
                  Note: Stripe does not refund the inital processing fee, so you
                  will see a small negative balance for this transaction.
                </AlertMessageBox>
                <p className="mb-4">
                  Are you sure you want to comp this session? This will refund
                  the student for the cost of the session.
                </p>

                <TextAreaInput name="compedReason" label="Reason" autoFocus />
              </Modal.Body>

              <Modal.Footer>
                <Failures />

                <div className="flex justify-end space-x-2">
                  <Button color={Color.Red} onClick={closeModal}>
                    Nevermind
                  </Button>
                  <SubmitButton text="Submit Refund" />
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </>
      )}
    </Modal.Dialog>
  )
}

const compSessionMutation = gql`
  mutation ($id: ID!, $compedReason: String!) {
    compSession(id: $id, compedReason: $compedReason) {
      failures {
        message
      }
      session {
        id
      }
    }
  }
`

export default CompSessionButton
