import React, { ReactNode } from "react"

import classNames from "src/classNames"

export type H1Props = {
  children: ReactNode
  className?: string
}

const H1: React.FC<H1Props> = ({ children, className = "" }) => {
  return (
    <h1 className={classNames("my-6 text-3xl font-medium", className)}>
      {children}
    </h1>
  )
}

export default H1
