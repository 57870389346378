import React, { useState } from "react"

import { gql, useQuery } from "urql"

import { SelectOption } from "~tailwindui/types/SelectOption"
import { Color, Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { H1, Link, WithLoadingIndicator } from "~tailwindui/Basics"
import { AutocompleteNavigate, SimpleSelect } from "~tailwindui/Form"
import Table from "~tailwindui/Table/Table"

export type StudentsProps = Record<never, any>

const Students: React.FC<StudentsProps> = () => {
  const [page, setPage] = useState(1)
  const allSubjectsOption = { label: "All Subjects", value: undefined }
  const [subjectOption, setSubjectOption] = useState<SelectOption | undefined>(
    allSubjectsOption
  )

  const [result] = useQuery({
    query,
    variables: { subjectId: subjectOption.value, page },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <>
          <H1>Your Students</H1>
          <div className="flex w-full space-x-4">
            <AutocompleteNavigate
              resource="students"
              column="fullName"
              placeholder="Find by Name"
              navigateTo={"studentDetails"}
              className="w-64"
            />

            <SimpleSelect
              value={subjectOption}
              options={[allSubjectsOption].concat(
                viewer.subjects.map(subject => ({
                  label: subject.name,
                  value: subject.id,
                }))
              )}
              onChange={(selectedOption: SelectOption) =>
                setSubjectOption(selectedOption)
              }
            />
          </div>

          <Table
            data={viewer.students}
            changePage={setPage}
            headers={["Student (Parent)", "Email", "Subjects", "Actions"]}
            valueMapper={student => [
              <div key={student.id}>
                <Link
                  to={path("studentDetails", {
                    id: student.id,
                  })}
                >
                  {student.fullName}{" "}
                  {student.parent && `(${student.parent.fullName})`}
                </Link>
              </div>,
              <a
                className="tailwind"
                key={student.id}
                href={`mailto:${student.email}`}
              >
                {student.email}
              </a>,
              <ul key={student.id}>
                {student.subjects.map(subject => (
                  <li key={subject.id}>{subject.name}</li>
                ))}
              </ul>,
              <Link
                key={student.id}
                to={path("studentDetails", {
                  id: student.id,
                })}
                button
                color={Color.Blue}
                size={Size.Small}
              >
                View
              </Link>,
            ]}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query StudentsIndexQuery($subjectId: ID, $page: Int) {
    viewer {
      id
      subjects {
        id
        name
      }
      students(subjectId: $subjectId, page: $page) {
        currentPage
        totalPages
        data {
          id
          fullName
          email
          subjects {
            id
            name
          }
          parent {
            id
            fullName
          }
        }
      }
    }
  }
`

export default Students
