import React from "react"

import { useField } from "formik"

import { ErrorIcon } from "~tailwindui/Basics"

import classNames from "src/classNames"

export type TextInputProps = {
  label: string
  name: string
  description?: string
  subfield?: boolean
  errorMessage?: string
}

const TextInput: React.FC<
  TextInputProps & React.HTMLProps<HTMLInputElement>
> = ({
  name,
  label,
  subfield = false,
  description = "",
  placeholder = "",
  errorMessage = undefined,
  ...props
}) => {
  const [field, meta] = useField({ name })
  const error = errorMessage || meta.error || ""

  return (
    <div className="mb-3 w-full">
      <label
        htmlFor={name}
        className={classNames(
          "block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5",
          subfield && "ml-8"
        )}
      >
        {label}
      </label>

      <div
        className={classNames(
          props.type === "number" ? "w-24" : "w-full",
          subfield && "ml-8",
          error && "relative rounded-md shadow-sm"
        )}
      >
        <input
          type="text"
          id={name}
          {...field}
          className={classNames(
            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6",
            error ? "ring-red-300" : "ring-gray-300"
          )}
          placeholder={placeholder}
          aria-describedby={`${name}-description`}
          {...props}
        />
        {error && <ErrorIcon />}
      </div>

      <div className="text-sm">
        {error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <p className="text-gray-500">{description}</p>
        )}
      </div>
    </div>
  )
}

export default TextInput
