import React from "react"

import { Color } from "~tailwindui/types/enums"

import { buttonClassNames as tailwindButtonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

import RealtimeboardDirectLink from "components/RealtimeboardDirectLink"
import TimeProtectedMiroLink from "components/TimeProtectedMiroLink"

export type MiroButtonProps = {
  user: {
    type: "Student" | "Tutor" | "Teacher" | "Admin"
    ssoEnabled: boolean
    ssoEmail: string
    id: string
  }
  session: {
    realtimeboards: {
      id: string
    }
    startsAt: string
  }
  tailwind?: boolean
}

const MiroButton: React.FC<MiroButtonProps> = ({
  session,
  user,
  tailwind = false,
}) => {
  const buttonClassNames = classNames(
    "btn solid green mb-1 btn-success",
    tailwind && tailwindButtonClassNames({ color: Color.Green }),
    tailwind && "w-full",
    user.type === "Teacher" && "bg-success-500 px-3 py-1 rounded font-semibold"
  )

  return user.ssoEnabled ? (
    <TimeProtectedMiroLink
      tailwind
      email={user.ssoEmail}
      boardId={session.realtimeboards[0]?.id}
      startsAt={session.startsAt}
      buttonClassName={buttonClassNames}
    />
  ) : (
    <RealtimeboardDirectLink
      boardId={session.realtimeboards[0]?.id}
      className={buttonClassNames}
    />
  )
}

export default MiroButton
