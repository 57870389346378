import React, { useEffect, useMemo, useState } from "react"

import { gql, useMutation } from "urql"

import { Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button, Spinner } from "~tailwindui/Basics"

import EventEmitter from "src/EventEmitter"
import { RunMutation } from "src/types"

import useWebsocket from "hooks/useWebsocket"

export type RequestProxyNumberProps = {
  studentId: string
}

const RequestProxyNumber: React.FC<RequestProxyNumberProps> = ({
  studentId,
}) => {
  const [mutationState, runMutation]: [any, RunMutation] = useMutation(
    requestNumberMutation
  )

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const channelProps = useMemo(() => {
    return {
      channelName: "StudentChannel",
      channelProps: { id: studentId },
    }
  }, [studentId])

  useWebsocket(channelProps)

  useEffect(() => {
    EventEmitter.subscribe("numberCreated", () => {
      window.location.reload()
    })
  }, [])

  const handleClick = () => {
    runMutation({ studentId })
      .then(
        response => {
          const { failures } = response.data.requestProxyNumber
          if (failures.length > 0) setErrorMessage(failures[0].message)
          else setLoading(true)
        },
        err => setErrorMessage("Something went wrong")
      )
      .catch(err => setErrorMessage("Something went wrong"))
  }

  return (
    <>
      {errorMessage && <AlertMessageBox>{errorMessage}</AlertMessageBox>}

      <Button
        onClick={handleClick}
        className="flex items-center"
        color={Color.Orange}
        soft
        disabled={loading || mutationState.fetching}
      >
        <span>Request Number</span>
        {(loading || mutationState.fetching) && (
          <Spinner className="text-md ml-3" />
        )}
      </Button>
    </>
  )
}

const requestNumberMutation = gql`
  mutation requestProxyNumber($studentId: ID!) {
    requestProxyNumber(studentId: $studentId) {
      failures {
        message
      }
    }
  }
`

export default RequestProxyNumber
