import React, { ReactNode, useRef, useState } from "react"

import { gql, useClient } from "urql"
import { pipe, subscribe } from "wonka"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button, Link } from "~tailwindui/Basics"

import ConsistentTutorWarning from "components/ConsistentTutorWarning"
import LocalTime from "components/LocalTime"

type Availability = {
  id: string
  startsAt: Date
  endsAt: Date
  weekday: string
  duration: string
}

export type CourseSectionProps = {
  courseSection: {
    id: string
    startsOn: Date
    endsOn: Date
    maxSubsEstimate: number
    organization: {
      minTutorConsistencyRate: number
    }
    availabilities: Availability[]
  }
}

const CourseSection: React.FC<CourseSectionProps> = ({ courseSection }) => {
  const [status, setStatus] = useState<ReactNode | undefined>()
  const [errorMessages, setErrorMessages] = useState([])
  const [inFlight, setInFlight] = useState(false)
  const submitClicked = useRef(false)
  const client = useClient()

  const register = () => {
    if (submitClicked.current) {
      return
    }
    submitClicked.current = true
    setInFlight(true)
    setErrorMessages([])
    setStatus("starting")

    const { unsubscribe } = pipe(
      client.subscription(registerSubscription, {
        courseSectionId: courseSection.id,
      }),
      subscribe(result => {
        if (result.data) {
          const { status, errorMessages } = result.data.courseSectionRegister
          setStatus(status)
          setErrorMessages(errorMessages)
          if (errorMessages.length) {
            setErrorMessages(errorMessages)
            setInFlight(false)
            submitClicked.current = false
            unsubscribe()
          }
          if (status === "in-progress") {
            setStatus(
              <>
                In Progress <br />
                (Please be patient, this process can take some time)
              </>
            )
          }
          if (status === "success") {
            setErrorMessages([])
            setStatus(
              <>
                Registration Successful <br />
                <Link to="/">Click here to go back to your dashboard</Link>
              </>
            )
            unsubscribe()
          }
        } else if (result.error) {
          setErrorMessages([result.error?.message])
          setInFlight(false)
          submitClicked.current = false
          unsubscribe()
        }
      })
    )
  }

  return (
    <div key={courseSection.id}>
      <dl>
        <dt>Starts</dt>
        <dd>
          <LocalTime timestamp={courseSection.startsOn} omitTime />
        </dd>

        <dt>Ends</dt>
        <dd>
          <LocalTime timestamp={courseSection.endsOn} omitTime />
        </dd>

        <dt>Weekly Sessions</dt>
        <dd>
          {courseSection.availabilities.map(availability => (
            <div key={availability.id}>
              {availability.weekday}{" "}
              <LocalTime timestamp={availability.startsAt} omitDate /> to{" "}
              <LocalTime timestamp={availability.endsAt} omitDate />
            </div>
          ))}
        </dd>
      </dl>
      {(courseSection.maxSubsEstimate ||
        courseSection.maxSubsEstimate === 0) && (
        <ConsistentTutorWarning
          minTutorConsistencyRate={
            courseSection.organization.minTutorConsistencyRate
          }
          maxSubsEstimate={courseSection.maxSubsEstimate}
        />
      )}
      <Button color={Color.Green} onClick={register} disabled={inFlight}>
        Register
      </Button>

      {status && (
        <AlertMessageBox className="w-1/3" level={AlertLevel.Info}>
          Current Status: {status}
        </AlertMessageBox>
      )}

      {errorMessages.length > 0 && (
        <AlertMessageBox>
          <div className="flex list-disc flex-col space-y-1 pl-5">
            {errorMessages.map((msg, index) => (
              <div key={index} dangerouslySetInnerHTML={{ __html: msg }} />
            ))}
          </div>
        </AlertMessageBox>
      )}

      <hr />
    </div>
  )
}

const registerSubscription = gql`
  subscription ($courseSectionId: ID) {
    courseSectionRegister(courseSectionId: $courseSectionId) {
      status
      errorMessages
    }
  }
`

export default CourseSection
