import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"

import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"

import { Color, Size, ValueFormat } from "~tailwindui/types/enums"

import { AlertMessageBox, Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import formatValue from "src/formatValue"

export type UpdateRateConfirmationModalProps = {
  oldRate: number
  newRate: number
}

const UpdateRateConfirmationModal: React.FC<
  UpdateRateConfirmationModalProps
> = ({ oldRate, newRate }) => {
  const [error, setError] = useState<string | null>()
  const [, runMutation]: any[] = useMutation(updateTutorRateMutation)

  const handleSubmit = hourlyRate => {
    runMutation({ hourlyRate })
      .then(
        result => {
          const { failures } = result.data.updateHourlyRate
          if (failures.length > 0) {
            setError(failures[0].message)
          } else {
            NotificationManager.success("Hourly Rate Updated")
          }
        },
        () => setError("Something went wrong")
      )
      .catch(() => setError("Something went wrong"))
  }
  return (
    <Modal.Dialog
      buttonText="Update"
      buttonClassNames={buttonClassNames({
        color: Color.Blue,
        size: Size.Small,
      })}
    >
      <>
        <Modal.Header
          Icon={ExclamationTriangleIcon}
          iconClassNames="text-red-600"
        >
          Update Hourly Rate
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to update your rate from{" "}
            {formatValue(oldRate, ValueFormat.Currency)} to{" "}
            {formatValue(newRate, ValueFormat.Currency)}?
          </p>
          <br />
          <p>
            Once you continue, all sessions will now be billed at{" "}
            {formatValue(newRate, ValueFormat.Currency)}. If you have any
            outstanding sessions that need to be billed at{" "}
            {formatValue(oldRate, ValueFormat.Currency)}, please hit "Nevermind"
            and go bill them first.
          </p>
        </Modal.Body>
        <Modal.Footer>
          {error && <AlertMessageBox>{error}</AlertMessageBox>}
          <div className="flex justify-end space-x-2">
            <Button>Nevermind</Button>
            <Button color={Color.Red} onClick={() => handleSubmit(newRate)}>
              Yes, Update
            </Button>
          </div>
        </Modal.Footer>
      </>
    </Modal.Dialog>
  )
}

const updateTutorRateMutation = gql`
  mutation updateTutorRate($hourlyRate: Float!) {
    updateHourlyRate(hourlyRate: $hourlyRate) {
      failures {
        message
      }
      viewer {
        id
        hourlyRate
      }
    }
  }
`

export default UpdateRateConfirmationModal
