import { compress } from "micro-graphql-react"

export default compress`
  query($tutorId: ID!) {
    viewer {
      canAdminTutorApplications
    }
    tutor(id: $tutorId) {
      tutorSubjects {
        id
        approvalType
        approvalState
        subjectBucket {
          id
          name
          tier {
            id
          }
        }
      }
      tiers {
        id
        gradeRange
      }
    }
    tiers {
      id
      gradeRange
    }
  }
`
