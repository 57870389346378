import React, { useContext } from "react"

import dayjs from "dayjs"
import { useFormikContext } from "formik"

import { CheckboxInput, DateRangeInput, SelectInput } from "~tailwindui/Form"

import { months } from "src/dateHelpers"

import TutorContext from "components/Tutor/TutorContext"

import { FormState } from "./Filters"

export type DateFiltersProps = Record<string, never>

const DateFilters: React.FC<DateFiltersProps> = () => {
  const { values } = useFormikContext<FormState>()
  const { viewer } = useContext(TutorContext)

  const yearOptions = []
  const earliestYear = dayjs(viewer.createdAt).year()
  for (let year = dayjs().year(); year >= earliestYear; year--) {
    yearOptions.push({ label: year, value: year })
  }

  return (
    <>
      <CheckboxInput
        checkboxType="radio"
        name="dateType"
        label="Date Range"
        options={[
          { label: "All Time", value: "all" },
          { label: "Custom", value: "custom" },
          { label: "Month", value: "month" },
          { label: "Year", value: "year" },
        ]}
      />

      {values.dateType === "month" && (
        <SelectInput
          name="monthOption"
          label=""
          options={[
            { label: "Current Month", value: "current" },
            ...months.map(month => ({
              label: month,
              value: month,
            })),
          ]}
        />
      )}

      {values.dateType === "year" && (
        <SelectInput
          name="yearOption"
          label=""
          options={[
            { label: "Current Year", value: "current" },
            ...yearOptions,
          ]}
        />
      )}

      {values.dateType === "custom" && (
        <DateRangeInput
          label="Date Range"
          displayWarnings={false}
          rangeStartName="startDate"
          rangeEndName="endDate"
        />
      )}
    </>
  )
}

export default DateFilters
