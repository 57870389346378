import React, { ReactNode } from "react"

export type ModalFooterProps = {
  children: ReactNode
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  return <div className="bg-gray-50 px-4 py-3 sm:px-6">{children}</div>
}

export default ModalFooter
