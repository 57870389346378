import React, { useRef } from "react"

import LoadingIndicator from "components/LoadingIndicator"

const AvatarPreview = props => {
  const { thumbUrl, pendingAvatar, isUploading } = props
  const imgRef = useRef(null)

  if (!thumbUrl && !pendingAvatar) return null

  if (pendingAvatar) {
    const reader = new FileReader()
    reader.onload = e => (imgRef.current.src = e.target.result)
    reader.readAsDataURL(pendingAvatar)
  }

  return (
    <div className="flex items-center">
      <img
        src={thumbUrl}
        ref={imgRef}
        alt="Avatar Preview"
        className="mr-4 max-h-24"
      />
      <span className="mr-4">
        {isUploading && <LoadingIndicator size="small" />}
      </span>
    </div>
  )
}

export default AvatarPreview
