import React, { useEffect, useMemo, useRef, useState } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import { Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button, Spinner } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import EventEmitter from "src/EventEmitter"
import classNames from "src/classNames"
import { tutorClient } from "src/graphql-config"

import useWebsocket from "hooks/useWebsocket"

import WithLoadingIndicator from "components/WithLoadingIndicator"

const CompleteWorksheetButtons = ({ sessionId }) => {
  const resourceDownloadLink = useRef()
  const [downloadUrl, setDownloadUrl] = useState("")
  const [errors, setErrors] = useState()

  useEffect(() => {
    if (downloadUrl === "") return
    resourceDownloadLink.current.click()
  }, [downloadUrl])

  const { runMutation: completeResource, running: completeResourceRunning } =
    useMutation(
      buildMutation(completeLessonResourceMutation, { client: tutorClient })
    )
  const { runMutation: downloadResource, running: downloadResourceRunning } =
    useMutation(
      buildMutation(downloadLessonResourceMutation, { client: tutorClient })
    )

  const loadingState = useQuery(
    buildQuery(
      currentLessonResourcesQuery,
      { sessionId },
      {
        client: tutorClient,
        onMutation: [
          {
            when: "completeLessonResource",
            run: ({ softReset, currentResults }, response) => {
              if (response.completeLessonResource?.failures) {
                setErrors(response.completeLessonResource.failures[0].message)
              } else {
                setErrors()
              }

              if (response.completeLessonResource?.session) {
                currentResults.session = response.completeLessonResource.session
                softReset(currentResults)
              }
              if (response.completeLessonResource?.nextResource) {
                const nextResource =
                  response.completeLessonResource.nextResource
                downloadResource({
                  sessionId,
                  lessonResourceId: nextResource.id,
                }).then(response => {
                  setDownloadUrl(
                    response.downloadLessonResource.lessonResource.file
                      .downloadUrl
                  )
                })
              }
            },
          },
        ],
      }
    )
  )

  const channelProps = useMemo(() => {
    return { id: sessionId }
  }, [sessionId])

  useWebsocket({
    channelName: "SessionChannel",
    channelProps,
  })

  const reload = useRef()
  reload.current = loadingState.reload

  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      reload.current()
    })
  }, [])

  return (
    <>
      <a
        ref={resourceDownloadLink}
        href={downloadUrl}
        download
        className="hidden"
      >
        download
      </a>
      <WithLoadingIndicator loadingState={loadingState}>
        {({ data }) =>
          data.session.currentLessonResources.map(lessonResource => (
            <Modal.Dialog
              key={lessonResource.id}
              buttonText={`Complete ${lessonResource.name}`}
              buttonClassNames={classNames(
                "mb-1 w-full",
                buttonClassNames({
                  color: Color.Orange,
                })
              )}
            >
              {closeModal => (
                <>
                  <Modal.Header>Complete {lessonResource.name}</Modal.Header>
                  <Modal.Body>
                    <p>
                      This will complete {lessonResource.name} and automatically
                      download the next worksheet.
                    </p>
                    <p>
                      So make sure to let students know to complete the survey
                      at the end of this session!
                    </p>
                  </Modal.Body>

                  <Modal.Footer>
                    <div className="flex flex-col">
                      {errors && <AlertMessageBox>{errors}</AlertMessageBox>}
                      <div className="flex justify-end space-x-2">
                        <Button onClick={closeModal} color={Color.Red}>
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            completeResourceRunning || downloadResourceRunning
                          }
                          onClick={() => completeResource(lessonResource)}
                        >
                          <div className="flex space-x-2">
                            <span>Complete + Download</span>{" "}
                            {(completeResourceRunning ||
                              downloadResourceRunning) && <Spinner />}
                          </div>
                        </Button>
                      </div>
                    </div>
                  </Modal.Footer>
                </>
              )}
            </Modal.Dialog>
          ))
        }
      </WithLoadingIndicator>
    </>
  )
}

const currentLessonResourcesQuery = compress`
  query($sessionId: ID!) {
    session(id: $sessionId) {
      currentLessonResources {
        id
        name
      }
    }
  }
`

const completeLessonResourceMutation = compress`
  mutation($sessionId: ID!, $lessonResourceId: ID!) {
    completeLessonResource(sessionId: $sessionId, lessonResourceId: $lessonResourceId) {
      failures {
        message
      }
      session {
        currentLessonResources {
          id
          name
        }
      }
      nextResource {
        id
        name
        file {
          downloadUrl
          fileName
        }
      }
    }
  }
`

const downloadLessonResourceMutation = compress`
  mutation($sessionId: ID!, $lessonResourceId: ID!) {
    downloadLessonResource(sessionId: $sessionId, lessonResourceId: $lessonResourceId) {
      lessonResource {
        id
        file {
          downloadUrl
        }
      }
    }
  }
`

export default CompleteWorksheetButtons
