import React, { useContext, useState } from "react"

import { RouteComponentProps } from "react-router"
import { gql, useMutation, useQuery } from "urql"

import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import TutorContext from "~Tutor/TutorContext"
import { path } from "~Tutor/TutorRoutes"
import TutorNote from "~Tutor/components/TutorNote"
import {
  AlertMessageBox,
  Button,
  H2,
  Link,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import DescriptionItem from "~tailwindui/Basics/DescriptionItem"

import titlecase from "src/titlecase"
import { RunMutation } from "src/types"
import { MatchParams } from "src/types/MatchParams"

import LocalTime from "components/LocalTime"
import NotFound from "components/Tutor/Pages/NotFound"
import AirtutorsIcon from "components/Tutor/components/AirtutorsIcon"
import DirectoryIcon from "components/Tutor/components/DirectoryIcon"

import ReplacementRequest from "../components/ReplacementRequest"

import EditDetails from "./EditDetails"
import EditSubjects from "./EditSubjects"
import FutureSessions from "./FutureSessions"
import PastSessions from "./PastSessions"

const Details: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const studyGroupId = match.params.id
  const { viewer } = useContext(TutorContext)
  const tutorId = viewer.id

  const [editSubjectsOpen, setEditSubjectsOpen] = useState(false)
  const [editDetailsOpen, setEditDetailsOpen] = useState(false)
  const [error, setError] = useState<string | null>()
  const [message, setMessage] = useState<string | null>()
  const [result] = useQuery({
    query,
    variables: { studyGroupId, tutorId },
  })

  const [_, runMutation]: [any, RunMutation] = useMutation(
    enqueueIntroductoryTextMutation
  )

  const enqueueIntroductoryText = () => {
    runMutation({ studyGroupId }).then(result => {
      const { failures, success } = result.data.enqueueIntroductoryText
      if (failures.length > 0) setError(failures[0].message)
      else setMessage(success.message)
    })
  }

  if (result.data && !result.data.studyGroup) return <NotFound />

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { studyGroup, viewer } }) => (
        <>
          <EditSubjects
            isOpen={editSubjectsOpen}
            closeModal={() => setEditSubjectsOpen(false)}
            studyGroup={studyGroup}
            subjects={viewer.directorySubjects}
          />
          <EditDetails
            isOpen={editDetailsOpen}
            closeModal={() => setEditDetailsOpen(false)}
            studyGroup={studyGroup}
          />
          <H2 className="flex items-center">
            {studyGroup.directory ? (
              <DirectoryIcon recordType={"studyGroup"} />
            ) : (
              <AirtutorsIcon recordType={"studyGroup"} />
            )}
            {studyGroup.name}
          </H2>
          {studyGroup.minTutorConsistencyRate &&
            studyGroup.hasAvailabilities && (
              <AlertMessageBox level={AlertLevel.Warning}>
                <div>
                  This study group requires a tutor consistency rate of{" "}
                  {studyGroup.minTutorConsistencyRate}%.
                  {studyGroup.subRequestsLeft ? (
                    studyGroup.subRequestsLeft > 0 ? (
                      <div>
                        You have {studyGroup.subRequestsLeft} substitute
                        request(s) remaining.
                      </div>
                    ) : (
                      <div>
                        You are not allowed to request any more substitutes. If
                        you know that you are going to miss another session,
                        please give up the group as soon as possible.
                      </div>
                    )
                  ) : null}
                </div>
              </AlertMessageBox>
            )}

          {studyGroup.studyGroupGeneration &&
            !studyGroup.introductoryTextEnqueued && (
              <div>
                <Button onClick={enqueueIntroductoryText} color={Color.Orange}>
                  Send Introductory Text
                </Button>
                <br />
                {error && <AlertMessageBox>{error}</AlertMessageBox>}
                {message && (
                  <AlertMessageBox level={AlertLevel.Info}>
                    {message}
                  </AlertMessageBox>
                )}
                The introductory text button will send a message out to the
                students and parents in your group, letting them know your name
                and the day/time that the group will meet.
              </div>
            )}

          <div className="space-x-4">
            {studyGroup.canRequestReplacement && (
              <ReplacementRequest id={studyGroup.id} />
            )}

            {!studyGroup.hasAvailabilities && (
              <Link
                to={path("studyGroupScheduleSession", {
                  id: studyGroup.id,
                })}
                button
                color={Color.Green}
                className="my-2"
              >
                {studyGroup.oneOnOne
                  ? "Schedule 1-1 Session"
                  : "Schedule Group Session"}
              </Link>
            )}
          </div>

          <div className="flex flex-wrap justify-between">
            <div>
              {studyGroup.details ? (
                <TutorNote
                  title="Group Details"
                  content={
                    <span className="semibold">{studyGroup.details}</span>
                  }
                  onEdit={() => setEditDetailsOpen(true)}
                />
              ) : studyGroup.directory ? (
                <button
                  className="my-1 flex items-center border-none bg-none text-purple-600 shadow-none"
                  onClick={() => setEditDetailsOpen(true)}
                >
                  <span className="text-lg font-medium">Add Group Details</span>
                  <PlusIcon className="inline-block h-5 w-5 pl-1" />
                </button>
              ) : null}

              <div className="max-w-max">
                <DescriptionItem
                  label="Subjects"
                  value={
                    studyGroup.directory && studyGroup.oneOnOne ? (
                      <div
                        className="flex cursor-pointer items-center"
                        onClick={() => setEditSubjectsOpen(true)}
                      >
                        {studyGroup.subjects
                          .map(subject => subject.name)
                          .join(", ")}
                        <PencilSquareIcon className="inline-block h-6 w-6 pl-1 text-purple-600" />
                      </div>
                    ) : (
                      <>
                        {studyGroup.subjects
                          .map(subject => subject.name)
                          .join(", ")}
                      </>
                    )
                  }
                />

                <DescriptionItem
                  label="Students"
                  value={
                    <ul>
                      {studyGroup.students.map(student => (
                        <li key={student.id}>
                          <Link
                            to={path("studentDetails", {
                              id: student.id,
                            })}
                          >
                            {student.fullName}
                          </Link>
                          <br />
                          {!studyGroup.directory && student.school?.name}
                        </li>
                      ))}
                    </ul>
                  }
                />

                {studyGroup.availabilities.length > 0 && (
                  <DescriptionItem
                    label="Session Times"
                    value={
                      <ul>
                        {studyGroup.availabilities.map(availability => (
                          <li key={availability.id}>
                            {titlecase(availability.weekday)}{" "}
                            <LocalTime
                              omitDate
                              timestamp={availability.startsAt}
                            />{" "}
                            -{" "}
                            <LocalTime
                              omitDate
                              timestamp={availability.endsAt}
                            />
                          </li>
                        ))}
                      </ul>
                    }
                  />
                )}

                {studyGroup.courseSection && (
                  <>
                    <DescriptionItem
                      label="Dates"
                      value={
                        <span>
                          <LocalTime
                            timestamp={studyGroup.courseSection.startsOn}
                          />{" "}
                          -{" "}
                          <LocalTime
                            timestamp={studyGroup.courseSection.endsOn}
                          />
                        </span>
                      }
                    />
                    <DescriptionItem
                      label="Meets"
                      value={
                        <ul>
                          {studyGroup.courseSection.availabilities.map(
                            availability => (
                              <li key={availability.id}>
                                {titlecase(availability.weekday)}{" "}
                                <LocalTime
                                  omitDate
                                  timestamp={availability.startsAt}
                                />{" "}
                                -{" "}
                                <LocalTime
                                  omitDate
                                  timestamp={availability.endsAt}
                                />
                              </li>
                            )
                          )}
                        </ul>
                      }
                    />
                  </>
                )}

                {studyGroup.studyGroupTutorReplacements.length > 0 && (
                  <DescriptionItem
                    label="Previous Tutor's Notes"
                    value={
                      <ul>
                        {studyGroup.studyGroupTutorReplacements.map(
                          replacement => (
                            <li key={replacement.id}>{replacement.details}</li>
                          )
                        )}
                      </ul>
                    }
                  />
                )}

                {studyGroup.organization && (
                  <>
                    <DescriptionItem
                      label="Organization"
                      value={studyGroup.organization.name}
                    />
                    {studyGroup.organization.details.length > 0 && (
                      <DescriptionItem
                        label="Organization Details"
                        value={studyGroup.organization.details}
                      />
                    )}
                    <DescriptionItem
                      label="Organization Time Zone"
                      value={studyGroup.organization.emailTimeZone}
                    />
                  </>
                )}
              </div>
            </div>

            {studyGroup.organization?.calendarEmbedUrl && (
              <iframe
                title={`${studyGroup.organization.name} Calendar`}
                className="m-2 max-w-full border-none"
                src={studyGroup.organization.calendarEmbedUrl}
                width="600"
                height="400"
              />
            )}
          </div>

          <PastSessions studyGroupId={studyGroupId} />
          <FutureSessions studyGroupId={studyGroupId} />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query StudyGroupDetailsQuery($studyGroupId: ID!, $tutorId: ID!) {
    viewer {
      directorySubjects: subjects(approvalType: directory, approved: true) {
        id
        name
      }
    }
    studyGroup(id: $studyGroupId) {
      id
      name
      directory
      details
      minTutorConsistencyRate
      hasAvailabilities
      maxSubsEstimate
      introductoryTextEnqueued
      subRequestsLeft(tutorId: $tutorId)
      oneOnOne
      canRequestReplacement
      subjects {
        id
        name
      }
      organization {
        id
        name
        details
        emailTimeZone
        calendarEmbedUrl
      }
      studyGroupTutorReplacements {
        id
        details
      }
      studyGroupGeneration {
        id
      }
      availabilities {
        id
        weekday
        startsAt
        endsAt
      }
      courseSection {
        id
        startsOn
        endsOn
        availabilities {
          id
          weekday
          startsAt
          endsAt
        }
      }
      students {
        id
        fullName
        school {
          id
          name
        }
      }
    }
  }
`

const enqueueIntroductoryTextMutation = gql`
  mutation tutorEnqueueIntroductoryText($studyGroupId: ID!) {
    enqueueIntroductoryText(studyGroupId: $studyGroupId) {
      failures {
        message
      }
      success {
        message
      }
    }
  }
`

export default Details
