import React from "react"

import { Form, Formik } from "formik"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"

import { SelectOption } from "~tailwindui/types/SelectOption"
import { Color } from "~tailwindui/types/enums"

import { H2, WithLoadingIndicator } from "~tailwindui/Basics"
import {
  Failures,
  FileInput,
  SelectInput,
  SubmitButton,
  TextInput,
  ToggleInput,
  handleFailure,
  readFile,
} from "~tailwindui/Form"
import CheckboxInput from "~tailwindui/Form/CheckboxInput"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import { timezoneOptions } from "src/enums"
import { RunMutation } from "src/types"

import { path } from "components/Tutor/TutorRoutes"

export type EditProfileProps = Record<string, never>

const EditProfile: React.FC<EditProfileProps> = () => {
  const history = useHistory()
  const [result] = useQuery({ query })
  const [, runMutation]: [any, RunMutation] = useMutation(editProfileMutation)

  const buildFormState = viewer => ({
    id: viewer.id,
    firstName: viewer.firstName || "",
    lastName: viewer.lastName || "",
    bio: viewer.bio || "",
    phoneNumber: viewer.phoneNumber || "",
    emailTimeZone: viewer.emailTimeZone,
    smsNotificationsEnabled: viewer.smsNotificationsEnabled,
    avatar: {},
    tierIds: viewer.tiers?.map(tier => tier.id) || [],
  })

  const handleSubmit = (values, actions) => {
    readFile(values.avatar)
      .then(file => {
        values.avatar = file
        runMutation({ input: values })
          .then(response => {
            const { failures } = response.data.editTutorProfile
            if (failures.length > 0) {
              handleFailure(actions, failures)
            } else {
              history.push(path("profileDetails"))
              NotificationManager.success("Your profile has been updated")
            }
          })
          .catch(error => handleFailure(actions))
      })
      .catch(error => handleFailure(actions))
  }

  return (
    <div className="flex flex-col items-center">
      <H2>Profile</H2>
      <WithLoadingIndicator result={result}>
        {({ data: { viewer, tiers } }) => (
          <>
            <a
              href="/users/edit"
              className={buttonClassNames({
                color: Color.Orange,
              })}
            >
              Change Password
            </a>

            <div className="sm:w-2/3 lg:w-1/2">
              <Formik
                initialValues={buildFormState(viewer)}
                onSubmit={handleSubmit}
              >
                <Form>
                  <TextInput
                    name="firstName"
                    label="First Name"
                    autoFocus
                    autoComplete="given-name"
                  />
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    autoComplete="family-name"
                  />
                  <TextInput name="phoneNumber" label="Phone Number" />
                  <SelectInput
                    label="Time Zone"
                    name="emailTimeZone"
                    options={timezoneOptions as SelectOption[]}
                  />
                  <ToggleInput
                    name="smsNotificationsEnabled"
                    label="Disable Tutor Notifications"
                    description="This setting will not disable notifications for unbilled sessions."
                  />
                  <FileInput
                    photo
                    name="avatar"
                    label="Avatar"
                    accept="image/*"
                  />

                  <CheckboxInput
                    checkboxType="multi"
                    name="tierIds"
                    label="Grade Ranges"
                    options={tiers.map(tier => ({
                      label: tier.gradeRange,
                      value: tier.id,
                    }))}
                  />

                  <Failures />
                  <SubmitButton />
                </Form>
              </Formik>
            </div>
          </>
        )}
      </WithLoadingIndicator>
    </div>
  )
}

const query = gql`
  query ViewerQuery {
    viewer {
      id
      firstName
      lastName
      bio
      phoneNumber
      emailTimeZone
      smsNotificationsEnabled
      tiers {
        id
      }
    }
    tiers {
      id
      gradeRange
    }
  }
`

const editProfileMutation = gql`
  mutation editTutorProfile($input: TutorInputObject!) {
    editTutorProfile(input: $input) {
      failures {
        message
      }
      tutor {
        id
      }
    }
  }
`

export default EditProfile
