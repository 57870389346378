import React, { Dispatch, SetStateAction } from "react"

import { Switch } from "@headlessui/react"

import classNames from "src/classNames"

export type AggregateToggleProps = {
  aggregate: boolean
  setAggregate: Dispatch<SetStateAction<boolean>>
}

const AggregateToggle: React.FC<AggregateToggleProps> = ({
  aggregate,
  setAggregate,
}) => {
  return (
    <Switch
      checked={aggregate}
      onChange={setAggregate}
      className={classNames(
        "relative inline-flex h-9 w-48 flex-shrink-0 cursor-pointer rounded-full bg-gray-200 text-base transition-colors duration-200 ease-in-out focus:outline-none"
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          aggregate ? "translate-x-24" : "translate-x-0",
          "pointer-events-none inline-block flex h-full w-24 transform items-center justify-center rounded-full bg-sky-600 bg-white px-3 text-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      >
        {aggregate ? "Statistics" : "Sessions"}
      </span>
      <span
        className={classNames(
          "flex h-full items-center justify-center px-3",
          aggregate ? "-translate-x-24" : "translate-x-0"
        )}
      >
        {aggregate ? "Sessions" : "Statistics"}
      </span>
    </Switch>
  )
}

export default AggregateToggle
