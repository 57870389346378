import React, { useState } from "react"

import { RouteComponentProps } from "react-router-dom"
import { gql, useQuery } from "urql"

import { path } from "~Tutor/TutorRoutes"
import { H2, Link, WithLoadingIndicator } from "~tailwindui/Basics"
import Paginator from "~tailwindui/Table/Paginator"

import { MatchParams } from "src/types/MatchParams"

import NotFound from "components/Tutor/Pages/NotFound"

const TailoredAssignments: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const studentId = match.params.id
  const [page, setPage] = useState(1)

  const [result] = useQuery({
    query: loadAssignmentsQuery,
    variables: { studentId, page },
  })

  const spacer = <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

  if (result.data && !result.data.tailoredAssignmentSummaries)
    return <NotFound />

  return (
    <>
      <H2>Tailored Assignments</H2>
      <WithLoadingIndicator
        result={result}
        error="An error occurred while loading assignments"
      >
        {({ data: { tailoredAssignmentSummaries } }) =>
          tailoredAssignmentSummaries.data.length === 0 ? (
            <span className="italic">
              There are no tailored assignments for this student.
            </span>
          ) : (
            <>
              <ul>
                {tailoredAssignmentSummaries.data.map(summary => (
                  <li
                    className="my-2 text-lg"
                    key={summary.tailoredAssignment.id}
                  >
                    <span>{summary.tailoredAssignment.name}</span>
                    {spacer}
                    {summary.aasmState !== "initial" && (
                      <>
                        <Link
                          to={path("studentTailoredAssignmentDetails", {
                            studentId,
                            id: summary.tailoredAssignment.id,
                          })}
                        >
                          Section Results
                        </Link>
                        {spacer}
                      </>
                    )}

                    {summary.aasmState === "initial" && (
                      <span>Score: Not Started</span>
                    )}
                    {summary.aasmState === "in_progress" && (
                      <span>Score: In Progress</span>
                    )}
                    {summary.aasmState === "completed" && (
                      <span>Score: {(summary.score * 100).toFixed(2)}%</span>
                    )}
                  </li>
                ))}
              </ul>
              <Paginator
                currentPage={page}
                totalPages={tailoredAssignmentSummaries.totalPages}
                changePage={page => setPage(page)}
              />
            </>
          )
        }
      </WithLoadingIndicator>
    </>
  )
}

const loadAssignmentsQuery = gql`
  query TailoredAssignmentsQuery($studentId: ID!, $page: Int!) {
    tailoredAssignmentSummaries(studentId: $studentId, page: $page) {
      totalPages
      data {
        score
        aasmState
        tailoredAssignment {
          id
          name
        }
      }
    }
  }
`

export default TailoredAssignments
