import React, { useState } from "react"

import { Form, Formik } from "formik"
import { buildMutation, compress, useMutation } from "micro-graphql-react"
import * as Yup from "yup"

import {
  Field,
  FormStatus,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please specify a name"),
})

const NewAssessment = () => {
  const formState = { name: "" }
  const [errors, setErrors] = useState([])
  const { runMutation } = useMutation(buildMutation(createMutation))

  const handleSubmit = (values, actions) => {
    runMutation(values)
      .then(
        response => {
          const { failures, assessment } = response.assessmentCreate
          if (assessment) {
            window.location.href = assessment.showPath
          } else {
            setErrors(failures)
            actions.setSubmitting(false)
          }
        },
        () => {
          setErrors([{ message: "Something went wrong" }])
          actions.setSubmitting(false)
        }
      )
      .catch(() => {
        setErrors([{ message: "Something went wrong" }])
        actions.setSubmitting(false)
      })
  }

  return (
    <ModalWithProvidedBody
      modalTitle="New Assessment"
      buttonText="New Assessment"
      buttonClassName="btn btn-success"
    >
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="modal-body">
            <Field name="name" label="Test Name" autoFocus />
          </div>

          <div className="modal-footer flex-column">
            {errors.map((error, index) => (
              <div key={index} className="alert alert-danger">
                <div>{error.message}</div>
              </div>
            ))}
            <FormStatus />
            <SubmitButton />
          </div>
        </Form>
      </Formik>
    </ModalWithProvidedBody>
  )
}

const createMutation = compress`
  mutation($name: String!) {
    assessmentCreate(name: $name) {
      failures {
        message
      }
      assessment {
        id
        showPath
      }
    }
  }
`

export default NewAssessment
