import React from "react"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

const IncomingStudentsModal = ({ isOpen, onClose }) => {
  return (
    <Modal.Dialog hideButton isOpen={isOpen} closeModal={onClose}>
      {closeModal => (
        <>
          <Modal.Header>Incoming Students</Modal.Header>

          <Modal.Body>
            <p className="lead">
              Another tutor was unable to make their session. One or more
              students have been reassigned to your session and will be joining
              momentarily.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button onClick={closeModal}>Dismiss</Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal.Dialog>
  )
}

export default IncomingStudentsModal
