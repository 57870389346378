import React from "react"

import { gql, useQuery } from "urql"

import { H2, Link, WithLoadingIndicator } from "~tailwindui/Basics"

import ordered from "src/ordered"

import { findRoute } from "components/Tutor/TutorRoutes"

import CourseClusterCard from "./CourseClusterCard"

export type DirectoryCourseClustersIndexProps = Record<string, never>

const DirectoryCourseClustersIndex: React.FC<
  DirectoryCourseClustersIndexProps
> = () => {
  const [result, refetchQuery] = useQuery({ query })

  return (
    <>
      <H2>Your Courses</H2>

      <div className="mb-10 w-2/3 rounded-md bg-gray-50 p-6 pb-2">
        <p className="text-lg text-gray-600">
          These are your personal courses offered via the Tutor Directory. All
          Directory session bills will be paid directly to you.
        </p>

        <Link
          className="my-4"
          button
          to={findRoute("directory.courseClusterNew").path}
        >
          Create a course
        </Link>
      </div>

      <WithLoadingIndicator result={result}>
        {({ data: { viewer } }) => (
          <>
            {viewer.directoryCourseClusters.length > 0 ? (
              <ul className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 xl:gap-x-8">
                {ordered(viewer.directoryCourseClusters, "name").map(
                  cluster => (
                    <CourseClusterCard
                      key={cluster.id}
                      courseCluster={cluster}
                      refetchQuery={refetchQuery}
                    />
                  )
                )}
              </ul>
            ) : (
              <span className="px-4 italic text-gray-600">
                No courses found
              </span>
            )}
          </>
        )}
      </WithLoadingIndicator>
    </>
  )
}

const query = gql`
  query DirectoryCourseClustersIndexQuery {
    viewer {
      directoryCourseClusters {
        id
        publishedAt
        registrationCount
        description
        name
        foreignLanguage {
          id
          name
        }
        subject {
          id
          name
        }
        grades {
          id
          name
        }
        courses {
          id
          formattedPrepaidPrice
          durationWeeks
          minimumStudyGroupSize
          maximumStudyGroupSize
          courseSections {
            id
            startsOn
            endsOn
            availabilities {
              id
              weekday
              startsAt
              endsAt
            }
            studyGroups {
              id
              students {
                id
              }
            }
          }
        }
      }
    }
  }
`

export default DirectoryCourseClustersIndex
