import { SelectOption } from "~tailwindui/types/SelectOption"

const titlecase = (
  startNum: number,
  endNum: number,
  pad?: boolean
): SelectOption[] => {
  const options = []
  for (let num = startNum; num <= endNum; num++) {
    let label = num.toString()
    if (pad) label = label.padStart(2, "0")
    options.push({ label, value: num })
  }
  return options
}

export default titlecase
