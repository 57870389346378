import React from "react"

import { BuildingOffice2Icon } from "@heroicons/react/24/solid"

import { Color } from "~tailwindui/types/enums"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

export type CreateOrganizationGroupModalProps = {
  studentName: string
}

const CreateOrganizationGroupModal: React.FC<
  CreateOrganizationGroupModalProps
> = ({ studentName }) => {
  return (
    <Modal.Dialog
      buttonText="Create Organization 1-1 Group"
      buttonClassNames={buttonClassNames({
        color: Color.Orange,
      })}
    >
      {closeModal => (
        <>
          <Modal.Header
            Icon={BuildingOffice2Icon}
            iconClassNames="text-orange-600"
          >
            Create Organization 1-1 Group
          </Modal.Header>
          <Modal.Body>
            <p>
              To create a 1-1 group via {studentName}'s organization, please
              reach out to admin.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button onClick={closeModal}>Okay</Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal.Dialog>
  )
}

export default CreateOrganizationGroupModal
