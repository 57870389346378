import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { useDisclosure } from "@chakra-ui/core"

import { gql, useClient, useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .required("Please confirm your password")
    .equals([Yup.ref("password")], "Passwords do not match"),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the Air Tutors privacy policy"
  ),
})

const ParentForm = ({ forceOpen, includeNotice, redirectUrl }) => {
  const client = useClient()
  const [, createParent] = useMutation(createParentMutation, client)

  const formState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    passwordConfirmation: "",
    termsAccepted: false,
    redirectUrl,
  }

  const handleSubmit = (values, actions) => {
    createParent(values)
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { redirectTo, failures } = result.data.createParent
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.location.href = redirectTo
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  const { isOpen, onOpen, onClose } = useDisclosure(forceOpen)

  return (
    <ModalWithProvidedBody
      modalTitle="Create Parent Account"
      buttonText="Create Parent Account"
      buttonClassName="btn solid green"
      modalClassName="bootstrap-modal"
      modalDialogClassName="!max-w-[50vw]"
      isOpen={isOpen}
      closeModal={onClose}
      openModal={onOpen}
    >
      {({ closeModal }) => (
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <Form>
            <div className="modal-body">
              {includeNotice ? (
                <div className="alert mb-5 bg-blue-500 p-3">
                  In order to register for private programs, please have your
                  parent create an account below.
                </div>
              ) : null}
              <Field
                name="firstName"
                label="First Name"
                autoFocus
                autoComplete="given-name"
              />
              <Field
                name="lastName"
                label="Last Name"
                autoComplete="family-name"
              />
              <Field name="email" label="E-mail" autoComplete="email" />
              <Field name="phoneNumber" label="Phone Number" />
              <Field
                name="password"
                type="password"
                label="Password"
                autoComplete="new-password"
              />
              <Field
                name="passwordConfirmation"
                type="password"
                label="Password Confirmation"
                autoComplete="new-password"
              />
              <CheckboxField
                name="termsAccepted"
                label={
                  <>
                    I am giving Air Tutors permission to contact my family
                    regarding setting up tutoring services and agree to
                    the&nbsp;
                    <a href="/privacy-policy" target="_blank">
                      Air Tutors privacy policy
                    </a>
                  </>
                }
              />
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="flex w-full justify-between">
                <button
                  type="button"
                  onClick={onClose}
                  className="btn solid red"
                >
                  Cancel
                </button>
                <SubmitButton text="Create Parent Account" />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const createParentMutation = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $password: String!
    $passwordConfirmation: String!
    $termsAccepted: Boolean!
    $redirectUrl: String!
  ) {
    createParent(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      password: $password
      passwordConfirmation: $passwordConfirmation
      termsAccepted: $termsAccepted
      redirectUrl: $redirectUrl
    ) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default ParentForm
