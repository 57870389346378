import React from "react"

import { Form, Formik } from "formik"

import { gql, useMutation } from "hooks/urql"

import {
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

const RemoveBudget = ({ budget }) => {
  const formState = {}
  const [, removeBudget] = useMutation(deleteBudgetMutation)
  const handleSubmit = (values, actions) => {
    removeBudget({ budgetId: budget.id })
      .then(
        response => {
          const { failures, redirectTo } = response.data.deleteBudget
          if (redirectTo) {
            window.location.href = redirectTo
          } else {
            handleFailure(actions, failures)
          }
        },
        () => handleFailure(actions)
      )
      .catch(() => handleFailure(actions))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Remove Budget"
      buttonText="Remove Budget"
      buttonClassName="btn btn-danger"
    >
      {({ closeModal }) => (
        <Formik initialValues={formState} onSubmit={handleSubmit}>
          <Form>
            <div className="modal-body">
              <p className="alert alert-danger">
                Are you sure? This will permanently remove this budget!
              </p>
            </div>

            <div className="modal-footer flex-col">
              <FormFailures />
              <div className="w-100 flex justify-between">
                <SubmitButton text="Remove" className="btn btn-danger" />
                <button type="button" className="btn" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </ModalWithProvidedBody>
  )
}

const deleteBudgetMutation = gql`
  mutation ($budgetId: ID!) {
    deleteBudget(budgetId: $budgetId) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default RemoveBudget
