import React, { ReactNode, useContext } from "react"

import { Dialog } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import classNames from "src/classNames"

import ModalContext from "./ModalContext"

export type ModalHeaderProps = {
  children: ReactNode
  Icon?: React.FC<any>
  iconClassNames?: string
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  Icon = InformationCircleIcon,
  iconClassNames = "text-sky-500",
}) => {
  const { handleClose } = useContext(ModalContext)
  return (
    <div className="flex justify-between bg-gray-50 py-5 px-2">
      <div className="flex items-center">
        <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center">
          <Icon
            className={classNames(iconClassNames, "h-6 w-6")}
            aria-hidden="true"
          />
        </div>
        <div className="ml-2 text-left text-center">
          <Dialog.Title
            as="h3"
            className="text-lg font-semibold leading-6 text-gray-900"
          >
            {children}
          </Dialog.Title>
        </div>
      </div>
      <button
        onClick={handleClose}
        className="flex h-12 w-12 flex-shrink-0 items-center justify-center"
      >
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  )
}

export default ModalHeader
