import React from "react"

import { Size } from "~tailwindui/types/enums"

import { path } from "~Tutor/TutorRoutes"
import { Link } from "~tailwindui/Basics"
import Table from "~tailwindui/Table/Table"

import titlecase from "src/titlecase"

import LocalTime from "components/LocalTime"

import AirtutorsIcon from "./AirtutorsIcon"
import DirectoryIcon from "./DirectoryIcon"

type Availability = {
  id: string
  startsAt: string
  endsAt: string
  weekday: string
}

type Subject = {
  id: string
  name: string
}

type Student = {
  id: string
  fullName: string
}

type StudyGroup = {
  id: string
  name: string
  subjects: Subject[]
  students: Student[]
  allAvailabilities: Availability[]
}

export type StudyGroupsProps = {
  studyGroups: StudyGroup[]
}

const StudyGroups: React.FC<StudyGroupsProps> = ({ studyGroups }) => {
  if (studyGroups.length === 0)
    return <span className="italic">No groups to display</span>

  return (
    <Table
      data={studyGroups}
      headers={["Name", "Students", "Subject", "Meeting Times", "Actions"]}
      valueMapper={studyGroup => [
        <Link
          key={studyGroup.id}
          to={path("studyGroupDetails", {
            id: studyGroup.id,
          })}
        >
          <span className="flex items-center">
            {studyGroup.directory ? (
              <DirectoryIcon recordType={"session"} />
            ) : (
              <AirtutorsIcon recordType={"session"} />
            )}{" "}
            {studyGroup.name}
          </span>
        </Link>,
        <ul key={studyGroup.id}>
          {studyGroup.students.map(student => (
            <li key={student.id}>
              <Link
                to={path("studentDetails", {
                  id: student.id,
                })}
              >
                {student.fullName}
              </Link>
            </li>
          ))}
        </ul>,
        studyGroup.subjects.map(subject => subject.name).join(", "),
        <ul key={studyGroup.id}>
          {studyGroup.allAvailabilities.map(availability => (
            <li key={availability.id}>
              {titlecase(availability.weekday)}{" "}
              <LocalTime omitDate timestamp={availability.startsAt} /> -{" "}
              <LocalTime omitDate timestamp={availability.endsAt} />
            </li>
          ))}
        </ul>,
        <Link
          key={studyGroup.id}
          to={path("studyGroupDetails", {
            id: studyGroup.id,
          })}
          size={Size.Small}
          button
        >
          View
        </Link>,
      ]}
    />
  )
}

export default StudyGroups
