import React, { useRef, useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql } from "@urql/core"

import { BulkActionResourceType } from "~tailwindui/types/enums"

import { reportGraphQLError } from "src/airbrake-notifier"
import classNames from "src/classNames"

import { useGradeOptions } from "hooks/remoteTable"
import { useMutation } from "hooks/urql"

import { DateObject } from "components/Forms/DatePicker"
import {
  AutocompleteField,
  CheckboxField,
  SelectField,
  SubmitButton,
} from "components/Forms/Formik/hookComponents"
import HeaderTabs from "components/HeaderTabs"

import CourseClusterResults from "./CourseClusterResults"
import StudyGroupResults from "./StudyGroupResults"

const BulkActions = () => {
  const [resourceType, setResourceType] = useState(
    BulkActionResourceType.StudyGroup
  )

  const ResultsValidationSchema = Yup.object().shape({
    resourceIds: Yup.array().min(
      1,
      `Please select at least 1 ${
        resourceType === BulkActionResourceType.StudyGroup
          ? "group"
          : "course cluster"
      }`
    ),
    dateRange: Yup.array().when("action", {
      is: action => ["schedule", "cancel", "reschedule"].includes(action),
      then: Yup.array().min(2, "Please specify a start and end date"),
    }),
    action: Yup.string().required("Please select an action to perform"),
  })

  const searchFormState = {
    organizationIds: [],
    schoolIds: [],
    teacherIds: [],
    subjectIds: [],
    gradeId: "",
    budgetId: "",
    searchTags: [],
    excludeTags: false,
    requireFutureSessions: false,
    fromGeneration: false,
    fromCluster: false,
    includeArchivedGroups: false,
  }
  const resultsFormState = {
    performAction: true,
    resourceIds: [],
    dateRange: [],
    tags: [],
    newStartsAt: new DateObject().setHour(12).setMinute(0).setSecond(0),
    durationMinutes: 60,
    skipStatusNotifications: false,
    action: "",
  }

  const resultsFormik = useRef<any>({})
  const [queryVariables, setQueryVariables] = useState({
    submitted: false,
    ...searchFormState,
  })
  const [page, setPage] = useState(1)
  const [errors, setErrors] = useState([])
  const [warningMessage, setWarningMessage] = useState()
  const [, processBulkAction]: any[] = useMutation(processMutation)

  const selectRefs = useRef<any>({})

  const gradeOptions = useGradeOptions()

  const handleSearchSubmit = (values, actions) => {
    const { searchTags, ...rest } = values
    setQueryVariables({
      submitted: true,
      tagList: searchTags.map(t => t.label),
      ...rest,
    })
    resetResultsForm()
    actions.setSubmitting(false)
  }

  const resetResultsForm = () => {
    setPage(1)
    setWarningMessage(undefined)
    resultsFormik.current?.resetForm()
  }

  const clearSelectFields = () => {
    Object.values(selectRefs.current).forEach((ref: any) => {
      if (!ref) return
      const clearValue = ref.clearValue
        ? ref.clearValue
        : ref.select?.clearValue
      clearValue()
    })
  }

  const handleResultsSubmit = (values, actions) => {
    const {
      resourceIds,
      performAction,
      dateRange: [startDate, endDate],
      newStartsAt,
      tags,
      ...rest
    } = values

    processBulkAction({
      resourceIds,
      resourceType,
      performAction,
      arguments: {
        startsOn: startDate?.toString(),
        endsOn: endDate?.toString(),
        newStartsAt: newStartsAt?.toDate(),
        tagList: tags.map(t => t.label),
        ...rest,
      },
    })
      .then(result => {
        if (result.error) {
          actions.setStatus(`Something went wrong: ${result.error}`)
          reportGraphQLError({
            result,
            component: `Admin/BulkActions/New#${rest.action}`,
            values: values,
          })
        } else {
          const {
            failures,
            redirectTo,
            warningMessage: newWarningMessage,
          } = result.data.studyGroupBulkActionProcess
          actions.setStatus()

          if (failures) {
            setErrors(failures)
          } else if (newWarningMessage) {
            setWarningMessage(newWarningMessage)
            setErrors([])
          } else if (redirectTo) {
            window.location.href = redirectTo
          }
        }
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  const ResultsComponent =
    resourceType === BulkActionResourceType.StudyGroup
      ? StudyGroupResults
      : CourseClusterResults

  return (
    <>
      <Formik initialValues={searchFormState} onSubmit={handleSearchSubmit}>
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <h5>Choose Filters</h5>
            <HeaderTabs
              selected={resourceType}
              options={[
                {
                  label: "Study Groups",
                  value: "studyGroup",
                  onClick: () => {
                    resetForm()
                    clearSelectFields()
                    setQueryVariables({ ...queryVariables, submitted: false })
                    setResourceType(BulkActionResourceType.StudyGroup)
                  },
                },
                {
                  label: "Course Clusters",
                  value: "courseCluster",
                  onClick: () => {
                    resetForm()
                    clearSelectFields()
                    setQueryVariables({ ...queryVariables, submitted: false })
                    setResourceType(BulkActionResourceType.CourseCluster)
                  },
                },
              ]}
            />
            <div className="flex list-none justify-start">
              <div className="mr-3 w-48">
                <AutocompleteField
                  ref={ref => (selectRefs.current.organizationIds = ref)}
                  name="organizationIds"
                  label="Organizations"
                  api="/api/admins/organizations/autocomplete_name"
                  isMulti
                />
              </div>
              <div className="mr-3 w-48">
                <AutocompleteField
                  ref={ref => (selectRefs.current.schoolIds = ref)}
                  name="schoolIds"
                  label="Schools"
                  api="/api/admins/schools/autocomplete_name"
                  isMulti
                />
              </div>
              <div className="mr-3 w-48">
                <AutocompleteField
                  ref={ref => (selectRefs.current.subjectIds = ref)}
                  name="subjectIds"
                  label="Subjects"
                  api="/api/admins/subjects/autocomplete_name"
                  isMulti
                />
              </div>
              <div className="mr-3 w-48">
                <SelectField
                  ref={ref => (selectRefs.current.gradeIds = ref)}
                  name="gradeId"
                  label={`Grades${
                    resourceType === BulkActionResourceType.StudyGroup
                      ? " all match"
                      : ""
                  }`}
                  options={gradeOptions}
                  isClearable
                  menuPortalTarget={document.body}
                />
              </div>
              <div
                className={classNames(
                  "mr-3 w-48",
                  resourceType === BulkActionResourceType.CourseCluster &&
                    "hidden"
                )}
              >
                <AutocompleteField
                  ref={ref => (selectRefs.current.teacherIds = ref)}
                  name="teacherIds"
                  label="Teachers"
                  api="/api/admins/teachers/autocomplete_full_name"
                  isMulti
                />
              </div>
              <div
                className={classNames(
                  "mr-3 w-48",
                  resourceType === BulkActionResourceType.CourseCluster &&
                    "hidden"
                )}
              >
                <AutocompleteField
                  ref={ref => (selectRefs.current.budgetId = ref)}
                  name="budgetId"
                  label="Budget"
                  api="/api/admins/budgets/autocomplete_name"
                />
              </div>
            </div>

            <div className="flex list-none justify-start">
              <div
                className={classNames(
                  "mr-3 w-48",
                  resourceType === BulkActionResourceType.CourseCluster &&
                    "hidden"
                )}
              >
                <label className="form-label w-100">
                  <div className="flex justify-between">
                    <span>Tags</span>
                    <span className="bold cursor-pointer">
                      <input
                        name="excludeTags"
                        className="form-check-input"
                        type="checkbox"
                        checked={values.excludeTags}
                        onChange={() =>
                          setFieldValue("excludeTags", !values.excludeTags)
                        }
                      />
                      Exclude Selected
                    </span>
                  </div>
                </label>
                <AutocompleteField
                  ref={ref => (selectRefs.current.tags = ref)}
                  name="searchTags"
                  api="/api/admins/study_groups/autocomplete_tags"
                  value={values.tags}
                  onChange={selected => setFieldValue("searchTags", selected)}
                  isMulti
                />
              </div>
            </div>

            {resourceType === BulkActionResourceType.StudyGroup && (
              <>
                <CheckboxField
                  name="requireFutureSessions"
                  label="Groups with future sessions"
                />
                <CheckboxField
                  name="fromGeneration"
                  label="Groups made from a study group generation"
                />
                <CheckboxField
                  name="fromCluster"
                  label="Groups made from a course cluster"
                />
                <CheckboxField
                  name="includeArchivedGroups"
                  label="Include Archived Groups"
                />
              </>
            )}
            <SubmitButton
              text={`Find ${
                resourceType === BulkActionResourceType.StudyGroup
                  ? "Study Groups"
                  : "Course Clusters"
              }`}
            />
          </Form>
        )}
      </Formik>
      <br />
      {queryVariables.submitted && (
        <Formik
          initialValues={resultsFormState}
          onSubmit={handleResultsSubmit}
          validationSchema={ResultsValidationSchema}
          validateOnChange={false}
          innerRef={resultsFormik}
        >
          <Form>
            <ResultsComponent
              variables={queryVariables}
              page={page}
              setPage={setPage}
              warningMessage={warningMessage}
              setWarningMessage={setWarningMessage}
              errors={errors}
              resetForm={resetResultsForm}
            />
          </Form>
        </Formik>
      )}
    </>
  )
}

const processMutation = gql`
  mutation (
    $resourceIds: [ID!]!
    $resourceType: BulkActionResourceTypeEnum!
    $arguments: StudyGroupBulkActionInputObject!
    $performAction: Boolean!
  ) {
    studyGroupBulkActionProcess(
      resourceIds: $resourceIds
      resourceType: $resourceType
      arguments: $arguments
      performAction: $performAction
    ) {
      failures {
        message
      }
      warningMessage
      redirectTo
    }
  }
`

export default BulkActions
