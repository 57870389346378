import React from "react"

import Table from "~tailwindui/Table/Table"

import LocalTime from "components/LocalTime"

export type CloudroomAvLogsProps = {
  session: {
    id: string
    cloudroomAvLogs: {
      id: string
      interfaceType: string
      interfaceState: string
      createdAt: string
      event: string
      user: {
        fullName: string
      }
    }[]
  }
}

const CloudroomAvLogs: React.FC<CloudroomAvLogsProps> = ({ session }) => {
  if (session.cloudroomAvLogs.length === 0) return null
  return (
    <Table
      title="Tutor A/V Log"
      data={session.cloudroomAvLogs}
      headers={["User", "Type", "State", "Time", "Triggered By"]}
      valueMapper={avLog => [
        avLog.user.fullName,
        avLog.interfaceType,
        avLog.interfaceState,
        <LocalTime key={avLog.id} timestamp={avLog.createdAt} />,
        avLog.event,
      ]}
    />
  )
}

export default CloudroomAvLogs
