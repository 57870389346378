import React, { useState } from "react"

import { gql, useQuery } from "urql"

import Table from "~Tutor/components/StudyGroupsTable"
import { H1, H2, WithLoadingIndicator } from "~tailwindui/Basics"
import { AutocompleteNavigate } from "~tailwindui/Form"
import Paginator from "~tailwindui/Table/Paginator"

export type StudyGroupsIndexProps = Record<never, any>

const StudyGroupsIndex: React.FC<StudyGroupsIndexProps> = () => {
  const [futurePage, setFuturePage] = useState(1)
  const [assignedPage, setAssignedPage] = useState(1)

  const [result] = useQuery({
    query,
    variables: { futurePage, assignedPage },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <>
          <H1>Your Study Groups</H1>
          <AutocompleteNavigate
            resource="studyGroups"
            placeholder="Find Study Groups"
            navigateTo={"studyGroupDetails"}
            className="w-64"
          />

          {viewer.futureStudyGroups.data.length > 0 && (
            <>
              <H2>Future Study Groups</H2>
              <Table studyGroups={viewer.futureStudyGroups.data} />
              <Paginator
                currentPage={viewer.futureStudyGroups.currentPage}
                totalPages={viewer.futureStudyGroups.totalPages}
                changePage={setFuturePage}
              />
            </>
          )}

          <H2>Assigned Study Groups</H2>
          <Table studyGroups={viewer.assignedStudyGroups.data} />
          <Paginator
            currentPage={viewer.assignedStudyGroups.currentPage}
            totalPages={viewer.assignedStudyGroups.totalPages}
            changePage={setAssignedPage}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query ViewerQuery($futurePage: Int, $assignedPage: Int) {
    viewer {
      id
      subjects {
        id
        name
      }
      futureStudyGroups: studyGroups(future: true, page: $futurePage) {
        currentPage
        totalPages
        data {
          id
          directory
          name
          subjects {
            id
            name
          }
          students {
            id
            fullName
          }
          allAvailabilities {
            id
            startsAt
            endsAt
            weekday
          }
        }
      }
      assignedStudyGroups: studyGroups(page: $assignedPage) {
        currentPage
        totalPages
        data {
          id
          directory
          name
          subjects {
            id
            name
          }
          students {
            id
            fullName
          }
          allAvailabilities {
            id
            startsAt
            endsAt
            weekday
          }
        }
      }
    }
  }
`

export default StudyGroupsIndex
