import React, { useState } from "react"

import { NotificationManager } from "react-notifications"
import { gql, useMutation } from "urql"

import { faFrown, faMeh, faSmile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Color, Size } from "~tailwindui/types/enums"

import { AlertMessageBox, Button, Spinner } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"
import { RunMutation } from "src/types"

import LocalTime from "components/LocalTime"

type LessonResourceCompletion = {
  id: string
  studentMastery: string
  createdAt: Date
  student: {
    id: string
    fullName: string
  }
}

export type LessonResourceCompletionDetailsProps = {
  airtutorsSessionId: string
  lessonResourceCompletion: LessonResourceCompletion
}

const LessonResourceCompletionDetails: React.FC<
  LessonResourceCompletionDetailsProps
> = ({ airtutorsSessionId, lessonResourceCompletion }) => {
  const [removed, setRemoved] = useState(false)
  const [status, setStatus] = useState<undefined | string>()
  const [mutationState, runMutation]: [any, RunMutation] = useMutation(
    removeCompletionMutation
  )

  const studentMasteryIcon = studentMastery => {
    switch (studentMastery) {
      case "weak":
        return <FontAwesomeIcon icon={faFrown} className="text-red-400" />
      case "moderate":
        return <FontAwesomeIcon icon={faMeh} className="text-yellow-400" />
      case "strong":
        return <FontAwesomeIcon icon={faSmile} className="text-emerald-400" />
    }
  }

  const handleSubmit = closeModal => {
    runMutation({
      airtutorsSessionId,
      id: lessonResourceCompletion.id,
    })
      .then(
        result => {
          const { failures } = result.data.removeLessonResourceCompletion
          if (failures.length > 0) {
            setStatus(failures[0].message)
          } else {
            closeModal()
            NotificationManager.success("Successfully removed")
            setRemoved(true)
          }
        },
        () => setStatus("An error occurred. Please refresh and try again.")
      )
      .catch(() =>
        setStatus("An error occurred. Please refresh and try again.")
      )
  }

  return removed ? null : (
    <div className="mb-4 flex items-center">
      <div className="flex grow items-center">
        <span>
          {lessonResourceCompletion.student.fullName} completed on{" "}
          <LocalTime timestamp={lessonResourceCompletion.createdAt} />
        </span>
        {lessonResourceCompletion.studentMastery && (
          <span className="ml-4 text-3xl">
            {studentMasteryIcon(lessonResourceCompletion.studentMastery)}
          </span>
        )}
      </div>

      <Modal.Dialog
        buttonText="Remove"
        buttonClassNames={classNames(
          "ml-3",
          buttonClassNames({ color: Color.Red, size: Size.Small })
        )}
      >
        {closeModal => (
          <>
            <Modal.Header>Remove Worksheet Completion</Modal.Header>

            <Modal.Body>
              <p>
                Are you sure? This will remove the selected completion and
                adjust the current worksheet for this student.
              </p>
            </Modal.Body>

            <Modal.Footer>
              {status && <AlertMessageBox>{status}</AlertMessageBox>}

              <div className="flex justify-end space-x-2">
                <Button color={Color.Red} onClick={closeModal}>
                  Nevermind
                </Button>
                <Button
                  type="submit"
                  disabled={mutationState.fetching}
                  onClick={() => {
                    handleSubmit(closeModal)
                  }}
                >
                  <span>Remove It</span>
                  {mutationState.fetching && (
                    <div className="ml-4 inline-block">
                      <Spinner />
                    </div>
                  )}
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal.Dialog>
    </div>
  )
}

const removeCompletionMutation = gql`
  mutation removeLessonResourceCompletion($id: ID!, $airtutorsSessionId: ID!) {
    removeLessonResourceCompletion(
      id: $id
      airtutorsSessionId: $airtutorsSessionId
    ) {
      failures {
        message
      }
      airtutorsSession {
        id
      }
    }
  }
`

export default LessonResourceCompletionDetails
