import React from "react"

import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { DateObject } from "react-multi-date-picker"
import { NotificationManager } from "react-notifications"
import { useHistory } from "react-router-dom"
import { gql, useMutation } from "urql"
import * as Yup from "yup"

import { Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button } from "~tailwindui/Basics"
import {
  DateInput,
  Failures,
  SubmitButton,
  TextAreaInput,
  handleFailure,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"

import { RunMutation } from "src/types"
import { VoidReturn } from "src/types/VoidReturn"

import { path } from "components/Tutor/TutorRoutes"

const ValidationSchema = Yup.object().shape({
  startsOn: Yup.string().required(
    "Please provide a start date for the replacement tutor"
  ),
  details: Yup.string().required(
    "Please provide relevant details about the group"
  ),
  substituteNotes: Yup.string().when("substituteRequired", {
    is: substituteRequired => substituteRequired,
    then: Yup.string().required(
      "Please leave a note for the tutor who will pick up the session"
    ),
  }),
})

type StudyGroup = {
  id: string
  imminentSession: {
    id: string
    startsAt: Date
  }
}

export type ReplacementRequestFormProps = {
  studyGroup: StudyGroup
  closeModal: VoidReturn
}

const ReplacementRequestForm = ({ studyGroup, closeModal }) => {
  const history = useHistory()
  const formState = {
    substituteRequired: false,
    studyGroupId: studyGroup.id,
    startsOn: undefined,
    details: "",
    substituteNotes: "",
  }
  const [, submitRequest]: [any, RunMutation] = useMutation(
    submitRequestMutation
  )

  const afterChange = (selectedDate, setFieldValue) => {
    if (!studyGroup.imminentSession) return false
    const imminentSessionWithinRange = dayjs(selectedDate)
      .startOf("day")
      .isBefore(studyGroup.imminentSession.startsAt)
    setFieldValue("substituteRequired", imminentSessionWithinRange)
  }

  const handleSubmit = (values, actions) => {
    const { startsOn, substituteRequired, ...rest } = values
    const startsAt = dayjs(startsOn.toDate()).startOf("day")

    submitRequest({ startsAt: startsAt.format(), ...rest }).then(result => {
      if (result.error) {
        handleFailure(actions, [{ message: result.error.message }])
        return
      }

      const { failures } = result.data.requestStudyGroupTutorReplacement
      if (failures.length > 0) {
        handleFailure(actions, failures)
        history.push(path("dashboard"))
      } else {
        NotificationManager.success("Request submitted")
      }
    })
  }

  return (
    <Formik
      initialValues={formState}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="flex max-h-[calc(70vh-90px)] flex-col">
          <Modal.Body>
            <AlertMessageBox>
              <p>
                If you give up this group, you will be unable to accept new
                groups for <span className="font-semibold">48 hours!</span>
              </p>
              <p className="font-semibold italic">
                Think carefully before proceeding!
              </p>
            </AlertMessageBox>
            <DateInput
              name="startsOn"
              label="Start Date"
              displayWarnings
              minDate={new DateObject()}
              afterChange={date => {
                afterChange(date, setFieldValue)
              }}
            />
            <TextAreaInput
              name="details"
              label="Details"
              description="Provide relevant details about the group for your replacement tutor"
            />
            {values.substituteRequired && (
              <>
                <p>This group has a session within the next 12 hours.</p>

                <TextAreaInput
                  name="substituteNotes"
                  label="Session Notes"
                  description="These will be provided to the tutor who picks up your session"
                />
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Failures />
            <div className="flex justify-end space-x-2">
              <Button onClick={closeModal} color={Color.Red}>
                Cancel
              </Button>
              <SubmitButton text="Request Replacement" />
            </div>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

const submitRequestMutation = gql`
  mutation requestStudyGroupTutorReplacement(
    $studyGroupId: ID!
    $startsAt: DateTime!
    $details: String!
    $substituteNotes: String
  ) {
    requestStudyGroupTutorReplacement(
      studyGroupId: $studyGroupId
      startsAt: $startsAt
      details: $details
      substituteNotes: $substituteNotes
    ) {
      failures {
        message
      }
    }
  }
`

export default ReplacementRequestForm
