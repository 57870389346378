import React from "react"

import { gql } from "urql"

import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import { useQuery } from "hooks/urql"

import { CheckboxField } from "components/Forms/Formik/hookComponents"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const ClasslinkUsersTable = ({
  userType,
  organizationId,
  filters,
  page,
  setPage,
}) => {
  const chooseQuery = () => {
    if (userType === "Teacher") return classlinkTeachersQuery
    if (userType === "Student") return classlinkStudentsQuery
  }

  const [usersResult] = useQuery({
    query: chooseQuery(),
    variables: { organizationId, page, params: filters },
  })

  return (
    <UrqlLoadingIndicator result={usersResult}>
      {({ data }) => {
        return (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Import</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Schools</th>
                  <th>Courses</th>
                  <th>Imported?</th>
                </tr>
              </thead>

              <tbody>
                {data.organization.classlinkUsers.data.map(classlinkUser => (
                  <tr key={classlinkUser.id}>
                    <td>
                      <CheckboxField
                        isMulti
                        name={`${userType}.classlinkUserIds`}
                        value={classlinkUser.id}
                      />
                    </td>
                    <td>{classlinkUser.fullName}</td>
                    <td>{classlinkUser.email}</td>
                    <td>
                      <ul className="list-unstyled">
                        {ordered(classlinkUser.classlinkSchools, "name").map(
                          classlinkSchool => (
                            <li key={classlinkSchool.id}>
                              {classlinkSchool.name}
                            </li>
                          )
                        )}
                      </ul>
                    </td>
                    <td>
                      <ul className="list-unstyled">
                        {ordered(classlinkUser.classlinkCourses, "title").map(
                          classlinkCourse => (
                            <li key={classlinkCourse.id}>
                              {classlinkCourse.title}
                            </li>
                          )
                        )}
                      </ul>
                    </td>
                    <td>
                      {!classlinkUser.user ? (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />
                      ) : (
                        <a
                          className="btn btn-sm btn-outline-info"
                          href={classlinkUser.user.showPath}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          View {userType}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={page}
              changePage={setPage}
              totalPages={data.organization.classlinkUsers.totalPages}
            />
          </>
        )
      }}
    </UrqlLoadingIndicator>
  )
}

const classlinkStudentsQuery = gql`
  query (
    $organizationId: ID!
    $page: Int!
    $params: ClasslinkStudentSearchInputObject
  ) {
    organization(id: $organizationId) {
      classlinkUsers: classlinkStudents(page: $page, params: $params) {
        currentPage
        totalPages
        data {
          id
          fullName
          email
          classlinkSchools {
            id
            name
          }
          classlinkCourses {
            id
            title
          }
          user {
            ... on Student {
              id
              firstName
              lastName
              showPath
            }
          }
        }
      }
    }
  }
`

const classlinkTeachersQuery = gql`
  query (
    $organizationId: ID!
    $page: Int!
    $params: ClasslinkTeacherSearchInputObject
  ) {
    organization(id: $organizationId) {
      classlinkUsers: classlinkTeachers(page: $page, params: $params) {
        currentPage
        totalPages
        data {
          id
          fullName
          email
          classlinkSchools {
            id
            name
          }
          classlinkCourses {
            id
            title
          }
          user {
            ... on Teacher {
              id
              firstName
              lastName
              showPath
            }
          }
        }
      }
    }
  }
`

export default ClasslinkUsersTable
