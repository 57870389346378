import React, { useEffect, useMemo, useState } from "react"

import { gql, useMutation, useQuery } from "urql"

import { Color } from "~tailwindui/types/enums"

import { Button, WithLoadingIndicator } from "~tailwindui/Basics"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import EventEmitter from "src/EventEmitter"
import classNames from "src/classNames"
import { RunMutation } from "src/types"

import useWebsocket from "hooks/useWebsocket"

import { Loading } from "components/Icons"
import TimeProtectedLink from "components/TimeProtectedLink"
import TimeProtectedMiroLink from "components/TimeProtectedMiroLink"

const SessionResources = ({ id }) => {
  const [googledocPending, setGoogledocPending] = useState(false)
  const [result, refetchQuery] = useQuery({
    query: resourcesQuery,
    variables: { id },
  })

  const channelProps = useMemo(() => {
    return { id }
  }, [id])

  useWebsocket({
    channelName: "SessionChannel",
    channelProps,
  })

  useEffect(() => {
    EventEmitter.subscribe("update", data => {
      refetchQuery({ requestPolicy: "network-only" })
      setGoogledocPending(false)
    })
  }, [refetchQuery])

  const [, runMutation]: [any, RunMutation] = useMutation(
    createGoogledocMutation
  )

  return (
    <WithLoadingIndicator result={result}>
      {({ data }) => (
        <>
          {data.session.realtimeboards.map(realtimeboard => (
            <TimeProtectedMiroLink
              tailwind
              key={realtimeboard.id}
              startsAt={data.session.startsAt}
              email={data.viewer.email}
              boardId={realtimeboard.id}
              buttonText={realtimeboard.displayName}
              buttonClassName={classNames(
                "w-full",
                buttonClassNames({
                  color: Color.Green,
                })
              )}
            />
          ))}

          {data.session.realtimeboardPending && (
            <div>
              <Button color={Color.Gray} className="w-full">
                <span>Whiteboard Pending</span>
                <div className="ml-4 block">
                  <Loading />
                </div>
              </Button>
            </div>
          )}

          {data.session.googledocUrl && (
            <TimeProtectedLink
              tailwind
              text="Google Doc"
              href={data.session.googledocUrl}
              startsAt={data.session.startsAt}
              className={classNames(
                "w-full",
                buttonClassNames({
                  color: Color.Green,
                })
              )}
              target="_blank"
            />
          )}

          {data.session.googleSpreadsheetUrl && (
            <TimeProtectedLink
              tailwind
              text="Google Spreadsheet"
              href={data.session.googleSpreadsheetUrl}
              startsAt={data.session.startsAt}
              className={buttonClassNames({
                color: Color.Green,
              })}
              target="_blank"
            />
          )}

          {!data.session.googledocUrl &&
            (googledocPending ? (
              <Button
                className={classNames(
                  "w-full",
                  buttonClassNames({
                    color: Color.Gray,
                  })
                )}
              >
                <span>Google Doc Pending</span>
                <div className="ml-4 mt-2 block">
                  <Loading />
                </div>
              </Button>
            ) : (
              <Button
                onClick={() => {
                  runMutation({ id }).then(result => {
                    if (result.data.createGoogledoc.failures.length === 0)
                      setGoogledocPending(true)
                  })
                }}
                className={classNames("w-full", buttonClassNames({}))}
              >
                Create Google Doc
              </Button>
            ))}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const resourcesQuery = gql`
  query ResourcesQuery($id: ID!) {
    viewer {
      email
    }
    session(id: $id) {
      startsAt
      googledocUrl
      realtimeboardPending
      realtimeboards {
        id
        displayName
      }
    }
  }
`

const createGoogledocMutation = gql`
  mutation createGoogledoc($id: ID!) {
    createGoogledoc(id: $id) {
      failures {
        message
      }
    }
  }
`

export default SessionResources
