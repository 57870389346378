import React, { useContext } from "react"

import { AlertLevel } from "~tailwindui/types/enums"

import { AlertMessageBox, H2 } from "~tailwindui/Basics"

import TutorContext from "components/Tutor/TutorContext"

import ManageSchedules from "./ManageSchedules"

export type TutorSchedulesProps = Record<string, never>

const TutorSchedules: React.FC<TutorSchedulesProps> = () => {
  const { viewer } = useContext(TutorContext)
  return (
    <>
      <H2>Tutoring Availability</H2>
      <div className="mb-6">
        {viewer.requiresNewSchedule ? (
          <AlertMessageBox level={AlertLevel.Warning}>
            Either you have no availabilities scheduled, or they will expire
            within the next 2 weeks. Please update or add new availabilities in
            order to be eligible to accept new sessions.
          </AlertMessageBox>
        ) : (
          <AlertMessageBox level={AlertLevel.Info}>
            Potential clients will only be allowed to request sessions occuring
            during these times, so please keep them up to date!
          </AlertMessageBox>
        )}

        <ManageSchedules />
      </div>
    </>
  )
}

export default TutorSchedules
