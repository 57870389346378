import React, { useContext } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { findGradeOption, gradeOptionsFromTiers } from "src/grade-options"

import { gql, useClient, useMutation } from "hooks/urql"

import {
  CheckboxField,
  Field,
  FormFailures,
  SelectField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

import FormStateContext from "../FormStateContext"
import GradeOptionsContext from "../GradeOptionsContext"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  gradeId: Yup.string().required("Grade is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .required("Please confirm your password")
    .equals([Yup.ref("password")], "Passwords do not match"),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the Air Tutors privacy policy"
  ),
})

const CollegeProfileStep = props => {
  const client = useClient()
  const [, register] = useMutation(registerMutation, client)
  const [store, setStore] = useContext(FormStateContext)
  const { tiers } = useContext(GradeOptionsContext)

  const formState = store.collegeProfileFields || {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gradeId: "",
    schoolName: "",
    password: "",
    passwordConfirmation: "",
    termsAccepted: false,
  }
  const setFormState = state => {
    setStore({ ...store, collegeProfileFields: state })
  }
  const gradeOptions = gradeOptionsFromTiers(
    tiers.filter(tier => tier.name === "upper")
  )

  const handleSubmit = (values, actions) => {
    setFormState(values)
    register({ input: values })
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { failures, redirectTo } = result.data.collegeRegistration
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.sessionStorage.removeItem("registrationWizard")
            window.location.href = redirectTo
          }
        },
        e => {
          if (e.message) {
            handleFailure(actions, [e])
          } else {
            handleFailure(actions)
          }
        }
      )
      .catch(e => {
        if (e.message) {
          handleFailure(actions, [e])
        } else {
          handleFailure(actions)
        }
      })
  }

  return (
    <div className="rounded-md border-2 border-solid border-gray-200 bg-gray-50 p-5">
      <h2 className="mt-0">Profile</h2>
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field name="firstName" label="First Name" autoFocus />
          <Field name="lastName" label="Last Name" />
          <Field name="email" label="Email" autoComplete="email" />
          <Field
            name="phoneNumber"
            label="Phone Number"
            autoComplete="phoneNumber"
          />
          <SelectField
            name="gradeId"
            label="Grade"
            options={gradeOptions}
            defaultValue={findGradeOption(gradeOptions, formState.gradeId)}
          />
          <Field name="schoolName" label="School" autoComplete="school" />
          <Field
            name="password"
            type="password"
            label="Password"
            autoComplete="new-password"
          />
          <Field
            name="passwordConfirmation"
            type="password"
            label="Password Confirmation"
            autoComplete="new-password"
          />
          <CheckboxField
            name="termsAccepted"
            label={
              <>
                I accept the terms and conditions of the&nbsp;
                <a href="/privacy-policy" target="_blank">
                  Air Tutors privacy policy
                </a>
              </>
            }
          />

          <div className="mt-3 flex flex-col items-center justify-between space-y-2 sm:flex-row sm:space-y-0">
            <button type="button" onClick={props.reset} className="btn red">
              Back
            </button>
            <SubmitButton text="Next: Wrap-up" className="btn solid green" />
          </div>
          <FormFailures />
        </Form>
      </Formik>
    </div>
  )
}

const registerMutation = gql`
  mutation ($input: CollegeRegistrationInputObject!) {
    collegeRegistration(input: $input) {
      failures {
        message
      }
      redirectTo
    }
  }
`

export default CollegeProfileStep
