import React, { Fragment, useState } from "react"

import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"

import { SelectOption } from "~tailwindui/types/SelectOption"

import classNames from "src/classNames"

export type SimpleSelectProps = {
  label?: string
  options: SelectOption[]
  onChange: (opt: Selected) => void
  value?: SelectOption
  className?: string
}

type Selected = SelectOption | undefined

const SimpleSelect: React.FC<
  SimpleSelectProps &
    Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "onChange" | "value">
> = ({ label, options, onChange, value, className = "" }) => {
  const [selected, setSelected] = useState<Selected>(value)

  const handleChange = selected => {
    onChange(selected)
    setSelected(selected)
  }

  const items = open => {
    const content = (
      <div>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map(option => (
              <Listbox.Option
                key={`${option.label}-${option.sublabel}`}
                className={({ active }) =>
                  classNames(
                    active ? "bg-sky-600 text-white" : "text-gray-900",
                    "relative cursor-default select-none py-2 px-3"
                  )
                }
                value={option}
              >
                {({ active }) => {
                  const isSelected = option.value === selected?.value
                  return (
                    <>
                      <div className="flex">
                        <span
                          className={classNames(
                            active || isSelected
                              ? "font-semibold"
                              : "font-normal",
                            "truncate"
                          )}
                        >
                          {option.label}
                        </span>

                        <span
                          className={classNames(
                            active ? "text-sky-200" : "text-gray-500",
                            "ml-2 truncate"
                          )}
                        >
                          {option.sublabel}
                        </span>
                      </div>

                      {isSelected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-sky-600",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )
                }}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )

    return content
  }
  return (
    <div className="mb-3">
      <Listbox value={selected} onChange={handleChange}>
        {({ open }) => (
          <>
            {label && (
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                {label}
              </label>
            )}

            <div className={classNames("relative min-w-[160px]", className)}>
              <div>
                <Listbox.Button className="relative min-h-[2.25rem] w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6">
                  <span className="inline-flex w-full truncate">
                    <span className="truncate">
                      {(selected as SelectOption)?.label}
                    </span>
                    <span className="ml-2 truncate text-gray-500">
                      {(selected as SelectOption)?.sublabel}
                    </span>
                  </span>

                  <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
              </div>

              {items(open)}
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

export default SimpleSelect
