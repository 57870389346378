import React, { ChangeEventHandler } from "react"

import { faFrown, faMeh, faSmile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import classNames from "src/classNames"

export type RatingProps = {
  label: string
  name: string
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

const Rating: React.FC<RatingProps> = ({ label, name, value, onChange }) => {
  return (
    <div className="mx-auto mb-8 flex w-3/4 items-center justify-center">
      <div className="grow text-left">{label}</div>
      <div className="flex">
        <div>
          <label>
            <div
              className={classNames(
                "mr-2 h-10 w-10 border-none hover:cursor-pointer hover:p-0 hover:text-4xl hover:text-red-500 hover:opacity-100",
                value === "weak"
                  ? "p-0 text-4xl text-red-500 opacity-100"
                  : "p-1 text-3xl opacity-75"
              )}
            >
              <FontAwesomeIcon icon={faFrown} />
            </div>
            <input
              className="hidden"
              type="radio"
              value="weak"
              defaultChecked={value === "weak"}
              name={name}
              onChange={onChange}
            />
          </label>
        </div>

        <div>
          <label>
            <div
              className={classNames(
                "mr-2 h-10 w-10 border-none hover:cursor-pointer hover:p-0 hover:text-4xl hover:text-orange-500 hover:opacity-100",
                value === "moderate"
                  ? "p-0 text-4xl text-orange-500 opacity-100"
                  : "p-1 text-3xl opacity-75"
              )}
            >
              <FontAwesomeIcon icon={faMeh} />
            </div>
            <input
              className="hidden"
              type="radio"
              value="moderate"
              name={name}
              onChange={onChange}
            />
          </label>
        </div>

        <div>
          <label>
            <div
              className={classNames(
                "mr-2 h-10 w-10 border-none hover:cursor-pointer hover:p-0 hover:text-4xl hover:text-emerald-500 hover:opacity-100",
                value === "strong"
                  ? "p-0 text-4xl text-emerald-500 opacity-100"
                  : "p-1 text-3xl opacity-75"
              )}
            >
              <FontAwesomeIcon icon={faSmile} />
            </div>
            <input
              className="hidden"
              type="radio"
              value="strong"
              name={name}
              onChange={onChange}
            />
          </label>
        </div>
      </div>
    </div>
  )
}

export default Rating
