import React from "react"

import { Form, Formik } from "formik"
import { useHistory } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"
import * as Yup from "yup"

import { Color } from "~tailwindui/types/enums"

import {
  AlertMessageBox,
  Button,
  WithLoadingIndicator,
} from "~tailwindui/Basics"
import {
  CheckboxInput,
  Failures,
  SubmitButton,
  TextInput,
} from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"
import { buttonClassNames } from "~tailwindui/helpers/classNameHelpers"

import classNames from "src/classNames"

import { path } from "components/Tutor/TutorRoutes"

export type CompleteSessionModalProps = {
  id: string
  endsAt: string
  channel: any
}

const ValidationSchema = Yup.object().shape({
  earlyAcknowledgement: Yup.boolean().when("isEarly", {
    is: true,
    then: Yup.boolean().oneOf([true], "Acknowledgement required"),
  }),
  endedEarlyReason: Yup.string().when("isEarly", {
    is: true,
    then: Yup.string().required(
      "Please describe why the session was ended early."
    ),
  }),
})

const CompleteSessionModal: React.FC<CompleteSessionModalProps> = ({
  id,
  endsAt,
  channel,
}) => {
  const history = useHistory()
  const formState = {
    completedLessonResourceIds: [],
    isEarly: new Date().getTime() < new Date(endsAt).getTime() - 1000 * 60,
    earlyAcknowledgement: false,
    endedEarlyReason: "",
  }

  const [result] = useQuery({
    query: lessonResourcesQuery,
    variables: { id },
  })

  const [_, runMutation]: any[] = useMutation(completeSessionMutation)

  const handleSubmit = (values, actions, closeModal) => {
    runMutation({ id, ...values })
      .then(
        response => {
          const { failures } = response.data.completeSession
          if (failures.length > 0) {
            actions.setStatus(failures[0].message)
          } else {
            closeModal()
            channel.perform("end_session")
            history.push(path("sessionBill", { id }))
          }
        },
        () => actions.setStatus("An error occurred")
      )
      .catch(() => actions.setStatus("An error occurred"))
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Modal.Dialog
      buttonText="End Session"
      buttonClassNames={classNames("mb-1 w-full", buttonClassNames({}))}
    >
      {closeModal => (
        <WithLoadingIndicator result={result}>
          {({ data }) => (
            <>
              <Modal.Header>End Session</Modal.Header>

              <Formik
                initialValues={formState}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) =>
                  handleSubmit(values, actions, closeModal)
                }
              >
                {({ status, values }) => (
                  <Form className="flex max-h-[calc(70vh-90px)] flex-col">
                    <Modal.Body>
                      <p>
                        Are you sure? This will send all participants to the
                        post-session form.
                      </p>
                      {values.isEarly && (
                        <CheckboxInput
                          checkboxType="single"
                          name="earlyAcknowledgement"
                          label="I acknowledge that I am ending this session early."
                        />
                      )}
                      {values.earlyAcknowledgement && (
                        <TextInput
                          name="endedEarlyReason"
                          label="Please describe why:"
                        />
                      )}
                      {data.session.currentLessonResources.length > 0 && (
                        <div className="form-group">
                          <h3 className="mb-0 font-medium">Worksheets</h3>
                          <small className="form-text">Mark if completed</small>
                          <CheckboxInput
                            checkboxType="multi"
                            name="completedLessonResourceIds"
                            options={data.session.currentLessonResources.map(
                              resource => ({
                                value: resource.id,
                                label: (
                                  <>
                                    <div>
                                      {resource.lessonTopic.name}:{" "}
                                      {resource.name}
                                    </div>
                                    {resource.isLast && (
                                      <div className="text-danger">
                                        This is the final worksheet for this
                                        chapter
                                      </div>
                                    )}
                                  </>
                                ),
                              })
                            )}
                          />
                        </div>
                      )}
                    </Modal.Body>

                    <Modal.Footer>
                      <div className="flex flex-col">
                        {status && <AlertMessageBox>{status}</AlertMessageBox>}

                        <div className="flex justify-end space-x-2">
                          <Failures />
                          <Button color={Color.Red} onClick={closeModal}>
                            Cancel
                          </Button>
                          <SubmitButton>End the session</SubmitButton>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </WithLoadingIndicator>
      )}
    </Modal.Dialog>
  )
}

const lessonResourcesQuery = gql`
  query SessionQuery($id: ID!) {
    session(id: $id) {
      currentLessonResources {
        id
        name
        isLast
        lessonTopic {
          name
        }
      }
    }
  }
`

const completeSessionMutation = gql`
  mutation (
    $id: ID!
    $completedLessonResourceIds: [ID!]!
    $endedEarlyReason: String
    $earlyAcknowledgement: Boolean
  ) {
    completeSession(
      id: $id
      completedLessonResourceIds: $completedLessonResourceIds
      endedEarlyReason: $endedEarlyReason
      earlyAcknowledgement: $earlyAcknowledgement
    ) {
      session {
        id
      }
      failures {
        message
      }
    }
  }
`

export default CompleteSessionModal
