import React, { useContext } from "react"

import { Button } from "~tailwindui/Basics"
import Modal from "~tailwindui/Modal"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"

export type IncomingStudentsModalProps = Record<never, any>

const IncomingStudentsModal: React.FC<IncomingStudentsModalProps> = () => {
  const { incomingStudents, setIncomingStudents } = useContext(CloudroomContext)

  return (
    <Modal.Dialog
      hideButton
      isOpen={incomingStudents}
      closeModal={() => setIncomingStudents(false)}
    >
      {closeModal => (
        <>
          <Modal.Header>Incoming Students</Modal.Header>

          <Modal.Body>
            <p className="lead">
              Another tutor was unable to make their session. One or more
              students have been reassigned to your session and will be joining
              momentarily.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button onClick={closeModal}>Dismiss</Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal.Dialog>
  )
}

export default IncomingStudentsModal
