import React from "react"

import {
  ArrowDownIcon,
  ArrowUpIcon,
  MinusIcon,
} from "@heroicons/react/20/solid"

import { ChangeDirection, Color, ValueFormat } from "~tailwindui/types/enums"

import { chipIconColorClassNames } from "~tailwindui/helpers/classNameHelpers"
import directionChange from "~tailwindui/helpers/directionChange"

import classNames from "src/classNames"
import formatValue from "src/formatValue"

type ChipItemProps = {
  label: string
  value: number
  Icon: React.FC<any>
  iconColor: Color
  allStatsUrl?: string
  changeValue?: number
  valueFormat?: ValueFormat
  changeValueFormat?: ValueFormat
  subtitle?: string
  subtitleValue?: number
  subtitleValueFormat?: ValueFormat
}

const ChipItem: React.FC<ChipItemProps> = ({
  label,
  value,
  allStatsUrl,
  changeValue,
  Icon,
  iconColor,
  valueFormat,
  changeValueFormat,
  subtitle,
  subtitleValue,
  subtitleValueFormat,
}) => {
  const changeDirection = directionChange(changeValue)
  return (
    <div
      className={classNames(
        allStatsUrl ? "pb-12" : "pb-5",
        "relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
      )}
    >
      <dt>
        <div
          className={classNames(
            chipIconColorClassNames(iconColor),
            "absolute rounded-md p-3"
          )}
        >
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          {label}
        </p>
      </dt>

      <dd>
        <div
          className={classNames(
            subtitle ? "pb-5 md:pb-6" : "pb-2 sm:pb-4",
            "ml-16"
          )}
        >
          <div className="flex items-baseline pb-4 sm:pb-3">
            <p className="text-2xl font-semibold text-gray-900">
              {formatValue(value, valueFormat)}
            </p>
            {changeValue && (
              <p
                className={classNames(
                  changeDirection === ChangeDirection.Increase &&
                    "text-emerald-600",
                  changeDirection === ChangeDirection.Decrease &&
                    "text-red-600",
                  changeDirection === ChangeDirection.Constant &&
                    "text-orange-500",
                  "ml-2 flex items-baseline text-sm font-semibold"
                )}
              >
                {changeDirection === ChangeDirection.Increase ? (
                  <ArrowUpIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-emerald-600"
                    aria-hidden="true"
                  />
                ) : changeDirection === ChangeDirection.Decrease ? (
                  <ArrowDownIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                ) : changeDirection === ChangeDirection.Constant ? (
                  <MinusIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-orange-500"
                    aria-hidden="true"
                  />
                ) : null}

                <span className="sr-only">
                  {" "}
                  {changeDirection === ChangeDirection.Increase
                    ? "Increased"
                    : changeDirection === ChangeDirection.Decrease
                    ? "Decreased"
                    : changeDirection === ChangeDirection.Constant
                    ? "Unchanged"
                    : "Unknown"}
                  by{" "}
                </span>
                {formatValue(changeValue, changeValueFormat)}
              </p>
            )}
          </div>
          {subtitle ? (
            <p className="italic text-gray-500">
              {subtitle} {formatValue(subtitleValue, subtitleValueFormat)}
            </p>
          ) : null}
        </div>
        {allStatsUrl && (
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <a
                href={allStatsUrl}
                className="font-medium text-sky-600 hover:text-sky-500"
              >
                View all<span className="sr-only"> {label} stats</span>
              </a>
            </div>
          </div>
        )}
      </dd>
    </div>
  )
}

export default ChipItem
export type { ChipItemProps }
