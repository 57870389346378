import { gql } from "urql"

export const SessionFields = gql`
  fragment SessionFields on Session {
    id
    directory
    airtutors
    startsAt
    endsAt
    statusState
    billState
    scheduledDurationMinutes
    mayCancelByAdmin
    mayRequestSubstitute
    studentCloudroomPath
    cloudroomPath
    ghostCloudroomPath
    cloudroomActive
    future
    formattedActualDuration
    formattedBilledDuration
    earlyAcknowledgement
    endedEarlyReason
    noShow
    substituteReason
    compedReason
    splitBill
    durationChangeState
    changeDurationPath
    tutorFeedback
    topicsCovered
    formattedSubstituteNotes
    tutorPaidAt
    canceledBy
    cancellationReason
    mayComp
    canExcuseAbsences
    createdTwilioCloudroom
    onDemand
    realtimeboards {
      id
      displayName
      url
    }
    attachments {
      filename
      downloadUrl
    }
    googledocUrl
    googleSpreadsheetUrl
    tutor {
      id
      email
      phoneNumber
      type
      fullName
      showPath
    }
    tutor {
      id
      email
      phoneNumber
      fullName
      showPath
    }
    requestedTutors {
      id
      email
      phoneNumber
      fullName
      showPath
    }
    students {
      id
      fullName
      email
      phoneNumber
      parentName
      parentEmail
      parentPhoneNumber
      showPath
      studentSession(sessionId: $id) {
        id
        participationLevel
        noShow
        excusedAbsence
        present
      }
      parent {
        id
        showPath
      }
    }
    subject {
      id
      name
    }
    studyGroup {
      id
      name
      showPath
    }
    invoices {
      id
      aasmState
      formattedBilledPrice
      stripeIntentId
      prepaidHoursUsed
      payerName
      payerShowPath
      createdAt
    }
    cloudroomRecordings {
      url
      name
      createdAt
      duration
    }
    cloudroomEventLogs {
      filename
      downloadUrl
    }
    sessionPresences {
      id
      joinedAt
      departedAt
      user {
        id
        type
        fullName
      }
    }
    activeSessionPresences: sessionPresences(active: true) {
      id
      joinedAt
      departedAt
      user {
        id
        type
        fullName
      }
    }
    sessionParticipants {
      id
      startedAt
      endedAt
      formattedDuration
      roomSid
      user {
        id
        fullName
      }
    }
    sessionParticipantSummaries {
      name
      formattedDuration
    }
    cloudroomAvLogs {
      id
      interfaceType
      interfaceState
      createdAt
      event
      user {
        fullName
      }
    }
  }
`
