import React, { useContext } from "react"

import { gql, useQuery } from "urql"

import { AlertLevel } from "~tailwindui/types/enums"

import TutorContext from "~Tutor/TutorContext"
import { AlertMessageBox, H2, WithLoadingIndicator } from "~tailwindui/Basics"

import TutorSubjectsTable from "components/Tutor/components/TutorSubjects/Table"

export type TutorSubjectsProps = Record<any, never>

const TutorSubjects: React.FC<TutorSubjectsProps> = () => {
  const { viewer } = useContext(TutorContext)
  const tutorId = viewer.id
  const [result, refetchQuery] = useQuery({
    query,
    variables: { tutorId },
  })

  return (
    <WithLoadingIndicator result={result}>
      {({ data: { viewer } }) => (
        <>
          <H2>Your Subjects</H2>

          {viewer.directory && (
            <AlertMessageBox level={AlertLevel.Info}>
              These are your approved subjects for organization tutoring only.
              They may differ from your Directory subjects.
            </AlertMessageBox>
          )}

          <TutorSubjectsTable
            tiers={viewer.tiers}
            approvalType={"airtutors"}
            refetchQuery={refetchQuery}
          />
        </>
      )}
    </WithLoadingIndicator>
  )
}

const query = gql`
  query ViewerQuery($tutorId: ID!) {
    viewer {
      id
      directory
      tiers {
        id
        gradeRange
        subjectBuckets(tutorId: $tutorId, approvalType: airtutors) {
          id
          name
          approvalState(tutorId: $tutorId, approvalType: airtutors)
        }
      }
    }
  }
`

export default TutorSubjects
