import React, { useEffect, useRef, useState } from "react"

import { Form, Formik } from "formik"
import { TypedDocumentNode, useClient } from "urql"
import { pipe, subscribe } from "wonka"
import * as Yup from "yup"

import { AlertLevel, Color } from "~tailwindui/types/enums"

import { AlertMessageBox, Button } from "~tailwindui/Basics"
import { SubmitButton } from "~tailwindui/Form"
import Modal from "~tailwindui/Modal"

import createBlob from "src/createBlob"
import titlecase from "src/titlecase"

export type ExportModalProps = {
  title: string
  afterClose?: () => void
  buttonText?: string
  subscription: TypedDocumentNode
  subscriptionName: string
  convertFormValues?: (any) => any
  initialValues?: object
  buttonClassNames?: string
  filename?: string
}

const exportSessionsValidationSchema = Yup.object().shape({
  startingAt: Yup.string().required("Please specify a start date"),
  endingAt: Yup.string().required("Please specify an end date"),
  timezone: Yup.string().required(
    "Please select a timezone for use in the export"
  ),
})

const ExportModal: React.FC<ExportModalProps> = ({
  title,
  afterClose,
  buttonText = "Export",
  subscription,
  subscriptionName,
  convertFormValues,
  initialValues,
  buttonClassNames,
  filename = "airtutors sessions export.csv",
  children,
}) => {
  const client = useClient()
  const [csvUrl, setCsvUrl] = useState<string>()
  const [exportMessages, setExportMessages] = useState<string[]>([])
  const [messageAlertLevel, setMessageAlertLevel] = useState<
    AlertLevel | undefined
  >()
  const csvDownloadLink = useRef<HTMLAnchorElement>()

  const requestExport = (values, actions, closeModal) => {
    let variables = { ...values }
    if (convertFormValues) variables = convertFormValues(variables)

    const { unsubscribe } = pipe(
      client.subscription(subscription, variables),
      subscribe((result: any) => {
        if (result.error) {
          const errors = []
          if (
            process.env.NODE_ENV === "development" &&
            result.error?.networkError?.error
          ) {
            setMessageAlertLevel(AlertLevel.Error)
            errors.push("Error type: backend")
            errors.push(result.error.networkError.error)
          }
          setExportMessages(errors.concat([result.error.message]))
          actions.setSubmitting(false)
          return
        }

        const { status, errorMessages, csv } = result.data[subscriptionName]

        if (errorMessages.length) {
          setExportMessages(errorMessages.map(message => ({ message })))
          actions.setSubmitting(false)
          unsubscribe()
        } else {
          if (status === "done") {
            const blobUrl = createBlob(csv, "text/csv")
            setCsvUrl(blobUrl)
            closeModal()
            setExportMessages([])
            unsubscribe()
          } else if (status === "enqueued") {
            setMessageAlertLevel(AlertLevel.Info)
            setExportMessages([
              "Export enqueued. Please bear with us as this process can take a while. Leave this window open and your file will be downloaded once it's ready.",
            ])
          } else {
            setMessageAlertLevel(AlertLevel.Info)
            setExportMessages([titlecase(status)])
            actions.setSubmitting(false)
          }
        }
      })
    )
  }

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl(null)
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  return (
    <>
      <a
        ref={csvDownloadLink}
        href={csvUrl}
        download={filename}
        className="hidden"
      >
        csv
      </a>
      <Modal.Dialog
        buttonText={buttonText}
        afterClose={afterClose}
        buttonClassNames={buttonClassNames}
      >
        {closeModal => (
          <Formik
            initialValues={{ ...initialValues, editing: true }}
            validationSchema={exportSessionsValidationSchema}
            onSubmit={(values, actions) =>
              requestExport(values, actions, closeModal)
            }
          >
            <Form className="flex max-h-[calc(70vh-90px)] flex-col">
              <Modal.Header>{title}</Modal.Header>
              <Modal.Body>{children}</Modal.Body>
              <Modal.Footer>
                <div className="w-full">
                  {exportMessages.length > 0 && (
                    <div className="mb-2">
                      <AlertMessageBox level={messageAlertLevel}>
                        <div className="flex list-disc flex-col space-y-1 pl-5">
                          {exportMessages.map(message => (
                            <li key={message}>{message}</li>
                          ))}
                        </div>
                      </AlertMessageBox>
                    </div>
                  )}
                  <div className="flex justify-end space-x-2">
                    <Button color={Color.Red} onClick={closeModal}>
                      Cancel
                    </Button>

                    <SubmitButton text="Export" />
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        )}
      </Modal.Dialog>
    </>
  )
}

export default ExportModal
