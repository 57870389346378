import dayjs from "dayjs"

export const sectionValidator = section => {
  if (!section.startsOn) return "Section must have a start date"

  if (dayjs(section.startsOn).diff(dayjs(), "days", true) < 6)
    return "Section start must be at least one week in the future"

  if (
    section.availabilities.some(
      availability =>
        !availability.weekday || !availability.startsAt || !availability.endsAt
    )
  )
    return "All sessions must have a weekday, start time and end time"

  if (
    section.availabilities.some(
      availability => !availability.startsAt.isBefore(availability.endsAt)
    )
  )
    return "Session start times must occur before end times"

  if (
    section.availabilities.some(
      availability =>
        Math.round(
          availability.endsAt.diff(availability.startsAt, "hour", true)
        ) > 6
    )
  )
    return "Sessions may not be longer than 6 hours"
}
